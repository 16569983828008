import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderingCardEntry, ResolvedLink } from '@acorn/util';

@Component({
  selector: 'acorn-ordering-cards',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ordering-cards.component.html',
  styleUrls: ['./ordering-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderingCardsComponent {
  @HostBinding('class.inline')
  @Input() inlineEnabled = false;

  @Input() orderEnabled = false;
  @Input() cards?: ResolvedLink<OrderingCardEntry>[] = [];
}

import { Pipe, PipeTransform } from '@angular/core';
import { join } from 'lodash';

import { ApplicationUser } from '@acorn/util';

@Pipe({
  name: 'displayUsersName',
  standalone: true,
})
export class DisplayUsersNamePipe implements PipeTransform {
  transform(value: ApplicationUser[]) {
    return join(
      value.map((applicationUser) => applicationUser.firstName),
      ' & '
    );
  }
}

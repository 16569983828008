export interface Feedback {
  customer: { name: string };
  service: {
    rating: { rating: number };
    review: string;
    title: string;
    created_at: number;
  };
  url: string;
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, fromEvent, throttleTime } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-application-navigation',
  standalone: true,
  imports: [
    CommonModule,
    IconSpriteModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  @Input() isLoading = false;
  @Input() primaryButtonLabel = 'Next';
  @Input() primaryButtonEnabled = true;
  @Input() backButtonEnabled = true;
  @Input() public customBackClass: string = "";
  @Input() public customNextClass: string = "";

  @Output() next = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        filter(() => !this.isLoading),
        throttleTime(500),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((event) => {
        const hasAnyDialog = document.querySelector(
          '.cdk-global-overlay-wrapper'
        );
        if (
          event.key === 'Enter' &&
          this.primaryButtonLabel === 'Next' &&
          !hasAnyDialog
        ) {
          this.next.emit();
        }
      });
  }

  onBackHandler(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.back.emit();
  }
}

import {
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StrategyActionPlanService } from '../../data-access/strategy-action-plan.service';
import { assetMapping } from 'libs/feature-application/src/lib/feature-wealth-strategy-options/utils/wealth-helper';
import { ActionDetail, ActionInfo } from '../actions/strategy-action-details';

@Component({
    selector: 'acorn-see-action-popup',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './see-action-popup.component.html',
    styleUrls: ['./see-action-popup.component.scss'],
})
export class SeeActionPopupComponent implements OnInit {
    @Input() public data!: any;
    @Input() public strategyId!: string;
    @Input() public applicationId!: string;

    #dialogRef = inject(MatDialogRef<SeeActionPopupComponent>);
    #toastrService = inject(ToastrService);
    #strategyActionPlanSvc = inject(StrategyActionPlanService);

    isContentVisible: boolean[] = [];

    public isChanged = false;
    public goals: any;
    public timeframetoReceiveBenefits: any;
    public relevantWealthBuildingBlocks: any;
    public actionInfo!: ActionInfo;

    constructor(private cdr: ChangeDetectorRef) {
        this.isContentVisible = new Array(14).fill(false);
    }

    ngOnInit(): void {
        if (this.data) {
            this.getReleavantActions();
        }
    }

    toggleContentVisibility(index: number): void {
        this.isContentVisible[index] = !this.isContentVisible[index];
    }

    activeStates: any[] = [];

    toggleActive(index: number): void {
        this.activeStates[index] = !this.activeStates[index];
    }

    close() {
        this.#dialogRef.close(this.isChanged);
    }

    onCheckboxChange(actionId: string, actionDetailsId: string, event: Event) {
        const checked = (event.target as HTMLInputElement).checked;
        if (checked) {
            this.saveActionDetails(actionId, actionDetailsId);
        } else {
            this.deleteActionDetails(actionId, actionDetailsId);
        }
        this.isChanged = true;
    }

    saveActionDetails(actionId: any, actionDetailsId: any) {
        this.#strategyActionPlanSvc
            .SaveActionDetail(
                this.applicationId,
                this.strategyId,
                actionId,
                actionDetailsId
            )
            .subscribe(
                (res: any) => {
                    this.#toastrService.success(
                        'Action added successfully',
                        'Success'
                    );
                    this.updateActionDetails(actionDetailsId, actionDetailsId);
                },
                (err: any) => {
                    this.#toastrService.error(
                        err.error.Message,
                        'Error'
                    );                }
            );
    }

    updateActionDetails(Id: any, value: any) {
        const updatedActionDetails = this.data.actiondetails.map(
            (action: any) => {
                if (action.id == Id) {
                    return {
                        ...action,
                        applicationActionDetails: value, // Update the applicationActionDetails
                    };
                }
                return action; // Return the original action if Id doesn't match
            }
        );

        // Update the state with the new array
        this.data.actiondetails = updatedActionDetails;
        this.cdr.detectChanges();
    }

    deleteActionDetails(actionId: any, actionDetailsId: any) {
        this.#strategyActionPlanSvc
            .DeleteActionDetail(
                this.applicationId,
                this.strategyId,
                actionId,
                actionDetailsId
            )
            .subscribe(
                (res: any) => {
                    this.#toastrService.success(
                        'Action removed successfully',
                        'Success'
                    );
                    this.updateActionDetails(actionDetailsId, null);
                },
                (err: any) => {
                    this.#toastrService.error(
                        err.error.Message,
                        'Error'
                    );                }
            );
    }

    getRemainingActions(actionDetails: ActionDetail[]): number {
        return actionDetails?.filter(
            (detail) => detail?.applicationActionDetails == null
        ).length;
    }
    get getCount() {
        const len = this.data.actiondetails.filter(
            (e: any) => e.applicationActionDetails === null
        ).length;

        if (len === 0) {
            return this.data.actiondetails.length;
        }
        return len;
    }

    getStatusMessage(actionDetails: ActionDetail[]): { message: string; statusClass: string } {
        const maxActions = 10;
        const remaining = this.getRemainingActions(actionDetails);

        if (remaining === 0) {
            return { message: 'All done', statusClass: 'success' };
        } else if (remaining > 0 && remaining < maxActions) {
            return { message: `Actions remaining`, statusClass: 'warning' };
        } else {
            return { message: `Actions remaining`, statusClass: 'alert' };
        }
    }

    getReleavantActions() {
        this.#strategyActionPlanSvc
            .GetRelavantActions(
                this.applicationId,
                this.strategyId,
                this.data?.actionId,
                this.data.isActionOriginal
            )
            .subscribe(
                (res:any) => {
                    const filteredGoals =  res?.data?.goals.filter((goal: any) => goal);

                    const relevantBlocks = assetMapping.filter(asset =>
                        res?.data?.relevantWealthBuildingBlocks.includes(asset.title)
                    );

                    this.goals = filteredGoals;
                    this.timeframetoReceiveBenefits = res?.data?.timeframetoReceiveBenefits;
                    this.relevantWealthBuildingBlocks = relevantBlocks;
                    this.actionInfo = {
                        ...res.data.actionInfo,
                        keyRisk: this.formatConsideration(res.data.actionInfo.keyRisk),
                        potentialCost: res.data.actionInfo.potentialCost,//this.addLineBreaks(res.data.actionInfo.potentialCost),
                        consideration: this.formatConsideration(res.data.actionInfo.consideration),
                        definition: res.data.actionInfo.definition,// this.addLineBreaks(res.data.actionInfo.definition),
                        reference: res.data.actionInfo.reference
                        .replace(/<b>.*?<\/b>\s*/, '')
                        .replace(/<\/a>/g, '</a><br><br>')
                        .trim()
                    };
                    },
                (err: any) => {
                    this.#toastrService.error(
                        err.error.Message,
                        'Error'
                    );
                }
            );
    }

    public get actiondetails(){
        const actions = this.data.actiondetails;

        actions.sort((a:any, b:any) => {
            if (a.applicationActionDetails === null && b.applicationActionDetails !== null) return -1;
            if (a.applicationActionDetails !== null && b.applicationActionDetails === null) return 1;
            return 0;
          });

        return actions;
    }

    private addLineBreaks(text: string): string {
        return text.replace(/<\/b>/g, '</b><br>');
    }

    private formatConsideration(text: string): string {
        const formattedSegments: string[] = [];
        const segments = text.split(/(<\/?b>)/);
        let isBold = false;

        segments.forEach((segment) => {
            if (segment === '<b>') {
                isBold = true;
                return;
            }
            if (segment === '</b>') {
                isBold = false;
                return;
            }

            if (segment.trim()) {
                if (isBold) {
                    formattedSegments.push(`<b>${segment.trim()}</b><br>`);
                } else {
                    formattedSegments.push(`${segment.trim()}<br>`);
                }
                formattedSegments.push('<br>');
            }
        });

        return formattedSegments.join('').replace(/<br>\s*$/, '');
    }
}

import { ChangeDetectorRef, Component, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyActionPlanService } from '../../data-access/strategy-action-plan.service';
import { ToastrService } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SeeActionPopupComponent } from '../see-action-popup/see-action-popup.component';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TimeFrameEnum } from '@acorn/util';

export interface ActionDetail {
    name: string;
    id: string;
    actionId: string;
    applicationActionDetails: string;
    description: string;
}

export interface TimeFrame {
    name: string;
    displayName: string;
    timeFrameList: {
        topic: string;
        actionId: number;
        actiondetails: ActionDetail[];
    }[];
    showDefault: boolean;
}

export interface StrategyDetails {
    strategyId: string;
    strategyOption: string | null;
    name: string | null;
    approvedDate: string | null;
    selectedByCustomerDate: string | null;
    selectedByAdminDate: string | null;
    description: string | null;
    timeFrames: TimeFrame[];
}

@Component({
    selector: 'acorn-actions',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        ProgressBarComponent,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        MatIconModule,
    ],
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
    @Output() hasStrategyEmit = new EventEmitter<{
        hasStrategyNull: boolean;
        strategy: any;
    }>();

    @Input() applicationId!: string;

    public animationDirection: { [key: number]: 'right' | 'left' | null } = {};

    #strategyActionPlanSvc = inject(StrategyActionPlanService);
    cd = inject(ChangeDetectorRef);
    activeStates: boolean[][] = [];
    #dialog = inject(MatDialog);

    public strategy: any = [];
    public timeFrames = Object.values(TimeFrameEnum);
    public showAll: { [key: number]: boolean } = {};
    public isLoading = false;
    selectedStrategy!: StrategyDetails;
    hasStrategyNull = false;
    isContentVisible: boolean[][][] = [];
    isContentTimeframeVisible: boolean[] = [];
    isContentActionVisible: boolean[] = [];
    #toastrService = inject(ToastrService);
    selectedTabIndex = 0;
    selectedTimeFrame: any;
    public isSecondYear = false;
    public showPreviousYear = false;
    public showPreviousYearTabs = false;
    public isMobile = false;
    public timeFramesList = [
        { displayName: 'First 90 days' },
        { displayName: '3-6 months' },
        { displayName: '6-9 months' },
        { displayName: '9-12 months' },
    ];

    public selectedEmptyTabIndex = 0;

    updateSelectedTimeFrame() {
        if (this.selectedStrategy?.timeFrames) {
            this.selectedTimeFrame = this.selectedStrategy.timeFrames[this.selectedTabIndex];
        }
    }

    public splitIntoChunks(arr: any[], chunkSize: number): any[][] {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    }

    ngOnInit(): void {
        this.getStrategyActionPlan();
        this.updateSelectedTimeFrame();
        this.checkIfMobile(window.innerWidth);
    }

    public getStrategyActionPlan() {
        this.isLoading = true;

        if (!this.applicationId) {
            return;
        }
        this.#strategyActionPlanSvc.GetStrategyActionPlan(this.applicationId).subscribe({
            next: (res: any) => {
                const strategy = res.data;

                this.hasStrategyNull = strategy.strategy == null ? true : false;
                this.hasStrategyEmit.emit({
                    hasStrategyNull: this.hasStrategyNull,
                    strategy: strategy,
                });

                this.selectedStrategy = {
                    strategyId: strategy.strategyId || null,
                    strategyOption: strategy.type || null,
                    name: strategy.strategy?.name || null,
                    approvedDate: strategy.approvedDate || null,
                    selectedByCustomerDate: strategy.selectedByCustomerDate || null,
                    selectedByAdminDate: strategy.selectedByAdminDate || null,
                    description: strategy.strategy?.description || null,
                    timeFrames: strategy.timeFrames
                        ? strategy.timeFrames.map((timeFrame: any) => {
                              if (timeFrame.name === TimeFrameEnum.FirstSetofActionsOutsideof12Months) {
                                  timeFrame.displayName = '12-24 months';
                              }
                              return {
                                  name: timeFrame.name || null,
                                  displayName: timeFrame.displayName || null,
                                  showDefault: timeFrame.showDefault,
                                  timeFrameList: timeFrame.data.map((data: any) => ({
                                      topic: data.topic || null,
                                      actionId: data.actionId || null,
                                      rank: data.rank || null,
                                      quantifiedValue: data.quantifiedValue || null,
                                      strategyId: data.strategyId || null,
                                      typicalTimeToReceiveBenefit: data.typicalTimeToReceiveBenefit || null,
                                      timeFrameId: data.timeFrameId || null,
                                      displayRank: data.displayRank || null,
                                      actiondetails: data.actionDetails,
                                      isActionOriginal:data.isActionOriginal
                                  })),
                              };
                          })
                        : [],
                };

                this.isSecondYear = this.checkIfSecondYear(this.selectedStrategy?.selectedByAdminDate);
                this.isLoading = false;

                this.cd.markForCheck();
            },
            error: (error: any) => {
                this.isLoading = false;
                this.#toastrService.error(
                    error.error.Message,
                    'Error'
                );
            },
        });
    }

    public toggleView() {
        this.showPreviousYearTabs = !this.showPreviousYearTabs;
    }

    get displayedTimeFrames() {
        if (this.showPreviousYearTabs) {
            return this.selectedStrategy?.timeFrames?.filter((tf) => tf.name !== TimeFrameEnum.FirstSetofActionsOutsideof12Months);
        } else {
            return this.isSecondYear
                ? this.selectedStrategy?.timeFrames?.filter((tf) => tf.name === TimeFrameEnum.FirstSetofActionsOutsideof12Months)
                : this.selectedStrategy?.timeFrames?.filter((tf) => tf.name !== TimeFrameEnum.FirstSetofActionsOutsideof12Months);
        }
    }

    checkIfSecondYear(dateString: any): boolean {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const oneYearBack = new Date(currentDate);
        oneYearBack.setFullYear(currentDate.getFullYear() - 1);

        return (
            inputDate.getFullYear() === oneYearBack.getFullYear() &&
            inputDate.getMonth() === oneYearBack.getMonth() &&
            inputDate.getDate() === oneYearBack.getDate()
        );
    }

    calculateProgress(actionDetails: any[]): number {
        if (!actionDetails || actionDetails.length === 0) {
            return 0;
        }

        const completedCount = actionDetails.filter((detail) => detail.applicationActionDetails != null).length;

        // if (completedCount === actionDetails.length) {
        //     const nextTimeFrame = this.selectedStrategy?.timeFrames[this.selectedTabIndex + 1];

        //     if (nextTimeFrame) {
        //         nextTimeFrame.showDefault = false;
        //     }
        // } else {
        //     this.selectedStrategy?.timeFrames.forEach((value: TimeFrame, index: number) => {
        //         if (index > this.selectedTabIndex) {
        //             console.log(index, this.selectedTabIndex);
        //             value.showDefault = true;
        //         }
        //     });
        // }

        return Math.min(completedCount, actionDetails.length);
    }
    public actiondetails(actions:any):Array<ActionDetail>{

        actions.sort((a:any, b:any) => {
            if (a.applicationActionDetails === null && b.applicationActionDetails !== null) return -1;
            if (a.applicationActionDetails !== null && b.applicationActionDetails === null) return 1;
            return 0;
          });

        return actions;
    }

    seeActionDetails(action: any) {
        const modal: MatDialogRef<SeeActionPopupComponent> = this.#dialog.open(SeeActionPopupComponent, {
            width: '900px',
            disableClose: true,
            hasBackdrop: true,
            panelClass: 'scrollable-dialog',
        });
        modal.componentInstance.data = action;
        modal.componentInstance.strategyId = this.selectedStrategy?.strategyId;
        modal.componentInstance.applicationId = this.applicationId;
        modal.afterClosed().subscribe(() => {
            this.updateShowDefault();
        });
    }

    getRemainingActions(actionDetails: ActionDetail[]): number {
        return actionDetails?.filter((detail) => detail?.applicationActionDetails == null).length;
    }

    getStatusMessage(actionDetails: ActionDetail[]): { message: string; statusClass: string } {
        const maxActions = 10;
        const remaining = this.getRemainingActions(actionDetails);

        if (remaining === 0) {
            return { message: 'All done', statusClass: 'success' };
        } else if (remaining > 0 && remaining < maxActions) {
            return { message: `${remaining} remaining`, statusClass: 'warning' };
        } else {
            return { message: `${maxActions} remaining`, statusClass: 'alert' };
        }
    }

    toggleContentVisibility(timeFrameIndex: number, actionIndex: number, actionDetailsIndex: number): void {
        if (!this.isContentVisible[timeFrameIndex]) {
            this.isContentVisible[timeFrameIndex] = [];
        }
        if (!this.isContentVisible[timeFrameIndex][actionIndex]) {
            this.isContentVisible[timeFrameIndex][actionIndex] = [];
        }
        this.isContentVisible[timeFrameIndex][actionIndex][actionDetailsIndex] =
            !this.isContentVisible[timeFrameIndex][actionIndex][actionDetailsIndex];
    }

    onCheckboxChange(timeFrameIndex: number, index: number, actionId: string, actionDetailsId: string, event: Event) {
        const checked = (event.target as HTMLInputElement).checked;
        if (checked) {
            this.saveActionDetails(timeFrameIndex, index, actionId, actionDetailsId);
        } else {
            this.deleteActionDetails(timeFrameIndex, index, actionId, actionDetailsId);
        }
    }

    saveActionDetails(timeFrameIndex: number, index: number, actionId: any, actionDetailsId: any) {
        this.#strategyActionPlanSvc
            .SaveActionDetail(this.applicationId, this.selectedStrategy?.strategyId, actionId, actionDetailsId)
            .subscribe({
                next: (res: any) => {
                    this.#toastrService.success('Action added successfully', 'Success');

                    this.updateActionDetails(timeFrameIndex, index, actionDetailsId, actionDetailsId);
                },
                error: (err: any) => {
                    this.#toastrService.error(
                        err.error.Message,
                        'Error'
                    );
                 },
            });
    }

    deleteActionDetails(timeFrameIndex: number, index: number, actionId: any, actionDetailsId: any) {
        this.#strategyActionPlanSvc
            .DeleteActionDetail(this.applicationId, this.selectedStrategy?.strategyId, actionId, actionDetailsId)
            .subscribe({
                next: () => {
                    this.#toastrService.success('Action deleted successfully', 'Success');

                    this.updateActionDetails(timeFrameIndex, index, actionDetailsId, null);
                },
                error: (err: any) => {
                    this.#toastrService.error(
                        err.error.Message,
                        'Error'
                    );
                },
            });
    }

    updateActionDetails(timeFrameIndex: number, index: number, actionDetailsId: string, value: any) {
        const timeFrame: any = this.selectedStrategy?.timeFrames[timeFrameIndex];
        const actiondetails = timeFrame.timeFrameList[index];
        const updatedActionDetails = actiondetails.actiondetails.map((action: any) => {
            if (action.id === actionDetailsId) {
                return {
                    ...action,
                    applicationActionDetails: value, // Update the applicationActionDetails
                };
            }
            return action; // Return the original action if Id doesn't match
        });

        this.selectedStrategy.timeFrames[timeFrameIndex].timeFrameList[index].actiondetails = updatedActionDetails;

        const nullActions = this.selectedStrategy.timeFrames[timeFrameIndex].timeFrameList[index].actiondetails.filter(
            (e) => e.applicationActionDetails === null
        );

        if (nullActions.length === 0) {
            const nextTimeFrame = this.selectedStrategy.timeFrames[timeFrameIndex + 1];

            if (nextTimeFrame) {
                nextTimeFrame.showDefault = false;
            }
        } else {
            this.selectedStrategy?.timeFrames.forEach((value: TimeFrame, index: number) => {
                if (index > timeFrameIndex) {
                    console.log(index, this.selectedTabIndex);
                    value.showDefault = true;
                }
            });
        }
    }

    updateShowDefault() {
        this.selectedStrategy?.timeFrames.forEach((value: TimeFrame, index: number) => {
            if (index > 0) {
                // Getting previous null selections
                const countOfNulls = this.selectedStrategy.timeFrames[index - 1].timeFrameList
                    .flatMap((x) => x.actiondetails) // Flatten the actiondetails array
                    .filter((e) => e.applicationActionDetails === null).length; // Filter for null values

                value.showDefault = countOfNulls !== 0;
            }
        });
    }

    toggleActive(timeFrameIndex: number, actionIndex: number): void {
        if (!this.activeStates[timeFrameIndex]) {
            this.activeStates[timeFrameIndex] = [];
        }

        this.activeStates[timeFrameIndex][actionIndex] = !this.activeStates[timeFrameIndex][actionIndex];
    }

    onTimeFrameChange(timeFrame: any) {
        this.selectedTimeFrame = timeFrame;
    }

    public getDaysText(name: string) {
        switch (name) {
            case 'ThreeToSixMonths':
                return '90 days';
            case 'SixToNineMonths':
                return '6 months';
            case 'NineToTwelveMonths':
                return '9 months';

            default:
                return '';
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkIfMobile(event.target.innerWidth);
    }

    checkIfMobile(width: number) {
        this.isMobile = width < 768;
    }
}

import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { GoalSettingsComponent } from './ui/goal-settings/goal-settings.component';
import {
  Application,
  ApplicationGoalSettings,
  ApplicationStep,
  ApplicationUser,
  GoalSettingPartsStep,
  GoalSettingStep,
} from '@acorn/util';
import { ReflectionPopupComponent } from './ui';
import { MatDialog } from '@angular/material/dialog';
import { YourGoalsComponent } from './ui/yourGoals/your-goals.component';
import { STEP_STRUCTURE } from './ui/utlis/step-structure.const';
import { cloneDeep } from 'lodash';
import { GoalPart, GoalStepStructure } from '../util';
import { CalendarpopupComponent } from './ui/Calendarpopup/calendarpopup.component';
import { NavigationComponent } from '@acorn/common-ui';

@Component({
  selector: 'acorn-feature-goals',
  standalone: true,
  imports: [
    CommonModule,
    IconSpriteModule,
    GoalSettingsComponent,
    ReflectionPopupComponent,
    YourGoalsComponent,
    NavigationComponent,
  ],
  templateUrl: './feature-goals.component.html',
  styleUrls: ['./feature-goals.component.scss'],
})
export class FeatureGoalsComponent {
  @Output() updatedProgress = new EventEmitter<{
    roundStep: GoalSettingStep;
    partStep: GoalSettingPartsStep;
  }>();

  videoUrl =
    'https://acornstorageuat.blob.core.windows.net/assets/Line-drawing-videos.mp4';

  public welcomeTemplate!: boolean;
  public applicationGoals: any;
  currentPartStep: any;

  #dialog = inject(MatDialog);
  currentStepStructure = signal<GoalStepStructure | null>(null);
  currentPartStructure = signal<GoalPart | null>(null);
  public show: boolean = false;

  @Input({ required: true }) currentApplication!: Application;

  @Input({ required: true }) set applicationGoal(
    value: ApplicationGoalSettings
  ) {
    this.#applicationGoal = value;
    if (this.currentApplication?.currentStep === ApplicationStep.Goal) {
      if (this.applicationGoal?.applicationGoals?.Round1.length > 0) {
        this.welcomeTemplate = false;
        this.show = false;
      } else {
        this.welcomeTemplate = true;
        this.show = false;
      }
    } else if (this.currentApplication?.currentStep !== undefined) {
      this.welcomeTemplate = true;
      this.show = false;
    } else {
      this.show = true;
    }
  }

  @Input() isWelcomeBackEditable: boolean = false;

  @Output() triggerWebhook = new EventEmitter<string>();

  @Input({ required: true }) goalStep!: GoalSettingStep;

  @Input({ required: true }) partStep!: GoalSettingPartsStep;
  stepStructure: GoalStepStructure[] = cloneDeep(STEP_STRUCTURE);

  @Output() adjustStep = new EventEmitter<void>();
  @Output() goalSettingsStep = new EventEmitter<void>();

  @Output() back = new EventEmitter<void>();

  @Input() applicationUsers: ApplicationUser[] = [];
  @Input() isDashboardApp: boolean = false;

  @Output() finishedStep = new EventEmitter<{
    isUpdateApplicationProgress: boolean;
  }>();

  #applicationGoal!: ApplicationGoalSettings;

  get applicationGoal() {
    return this.#applicationGoal;
  }

  @Input({ required: true }) latestApplicationStep!: ApplicationStep;
  @Input() isFileNotes: boolean = false;

  getStarted() {
    this.welcomeTemplate = false;
  }

  onBack(): void {
    this.welcomeTemplate = true;
    //this.back.emit();
  }

  onHandleBack() {
    this.back.emit();
  }

  public openReflectionPopup() {
    this.#dialog.open(ReflectionPopupComponent, {
      width: '800px',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  moveToWealthCoach() {
    this.#dialog.open(CalendarpopupComponent, {
      width: '1200px',
      height: '600px',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  onfinishedStep($event: any) {
    this.finishedStep.emit({ isUpdateApplicationProgress: $event });
  }

  onTriggerWebhook($event: any) {
    this.triggerWebhook.emit($event);
  }

  updateCurrentProgress(value:any){
    this.updatedProgress.emit(value)
  }
}

<h3>{{ groupDependant }}</h3>

<ng-container *ngIf="formArray.controls.length">
  <div *ngFor="let item of formArray.controls; index as index" class="item">
    <div class="title">
      <p>
        {{ dependantName }}
        {{ index + 1 }}
      </p>

      <button
        mat-stroked-button
        class="remove-button"
        (click)="onRemove(index)"
      >
        Remove
        <svg-icon-sprite
          iconPositionEnd
          [src]="'assets/sprites/sprite.svg#delete-bin'"
          [width]="'24px'"
        ></svg-icon-sprite>
      </button>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Age</mat-label>

      <mat-form-field
        class="small remove-bottom"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <ng-container *ngIf="isChild; else otherTemplate">
          <mat-select [formControl]="item" placeholder="Age">
            <mat-option
              *ngFor="let age of AgeListForChild"
              [value]="age"
              class="answer-option"
            >
              {{ age }}
            </mat-option>
          </mat-select>
        </ng-container>

        <ng-template #otherTemplate>
          <input matInput placeholder="Age" [formControl]="item"/>
        </ng-template>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>
  </div>
</ng-container>

<button mat-stroked-button class="add-button" (click)="addMore()">
  Add
  <mat-icon iconPositionEnd>add</mat-icon>
</button>

<acorn-alert *ngIf="showAlert">
  Please fill all the values or complete the steps below.
</acorn-alert>
<div class="application__header">
  <div class="container">
    <span
      *ngFor="let step of applicationStepper; let i = index"
      class="stepper"
      [class.active]="selectedIndex === i"
      [class.d-none]="canHideWealthStrategyOptions(step.value)"
      (click)="selectStepByIndex(i)"
    >
      <ng-container *ngIf="selectedIndex === i; else otherStatus">
        <mat-icon>check_circle_outline</mat-icon>
      </ng-container>

      <ng-template #otherStatus>
        <ng-container *ngIf="isStepChecked(i); else uncheckTemplate">
          <mat-icon class="check_circle">check_circle</mat-icon>
        </ng-container>

        <ng-template #uncheckTemplate>
          <mat-icon>radio_button_unchecked</mat-icon>
        </ng-template>
      </ng-template>
      <span class="label">{{ step.viewValue }}</span>
    </span>
  </div>
</div>


<mat-form-field
  class="application__dropdown"
  appearance="outline"
  subscriptSizing="dynamic"
>
  <mat-icon matPrefix class="prefix-icon">check_circle_outline</mat-icon>

  <mat-icon matSuffix class="suffix-icon">arrow_back_ios</mat-icon>

  <mat-select
    hideSingleSelectionIndicator
    [value]="ApplicationStepIndex[latestApplicationStep]"
    (selectionChange)="selectStepByIndex($event.value)"
  >
    <mat-option
      *ngFor="let step of applicationStepper"
      [value]="step.value"
      [class.d-none]="canHideWealthStrategyOptions(step.value)"
      [disabled]="step.value > ApplicationStepIndex[latestApplicationStep]  || isStepDisabled(step.value)"
    >
      {{ step.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="application__content" >
  <div [ngTemplateOutlet]="selected!.content"></div>
</div>

import { ProfileFormValue, SEPARATOR } from '@acorn/util';

export const convertProfileForm = (
  profileFormValue: ProfileFormValue
): any => ({
  ...profileFormValue,
  children: convertArrayToSeparatorString(profileFormValue.children),
  elderlyParent: convertArrayToSeparatorString(profileFormValue.elderlyParent),
  lovedOneWithSpecialNeed: convertArrayToSeparatorString(
    profileFormValue.lovedOneWithSpecialNeed
  ),
  partnerChildren: convertArrayToSeparatorString(
    profileFormValue.partnerChildren || []
  ),
  partnerElderlyParent: convertArrayToSeparatorString(
    profileFormValue.partnerElderlyParent || []
  ),
  partnerLovedOneWithSpecialNeed: convertArrayToSeparatorString(
    profileFormValue.partnerLovedOneWithSpecialNeed || []
  ),
});

export const convertArrayToSeparatorString = (list?: string[] | null): string =>
  (list || [])
    .filter((item) => !!item)
    .join(SEPARATOR);

    
<form [formGroup]="formGroup">
  <section class="basic-information">
    <div class="form-field-wrapper">
      <mat-label>First name</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <input matInput formControlName="firstName" />

        <mat-error>
          {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Date of birth</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <input
          matInput
          placeholder="Enter date of birth"
          formControlName="dateOfBirth"
          [max]="maxDateOfBirth"
          [matDatepicker]="datepicker"
        />

        <mat-datepicker-toggle matIconSuffix [for]="datepicker">
          <svg-icon-sprite
            matDatepickerToggleIcon
            [src]="'assets/sprites/sprite.svg#calendar-line'"
            [width]="'22px'"
          ></svg-icon-sprite>
        </mat-datepicker-toggle>

        <mat-datepicker #datepicker></mat-datepicker>

        <mat-error>
          {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Email address</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <input matInput formControlName="email" />

        <mat-error>
          {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Citizenship status</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <mat-icon
          matSuffix
          class="suffix-select-icon"
          [class.triggered]="citizenshipStatusSelection.panelOpen"
        >
          keyboard_arrow_down
        </mat-icon>

        <mat-select
          #citizenshipStatusSelection
          formControlName="citizenshipStatus"
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let citizenshipStatus of CitizenshipStatusOptions"
            [value]="citizenshipStatus.value"
          >
            {{ citizenshipStatus.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Marital status</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <mat-icon
          matSuffix
          class="suffix-select-icon"
          [class.triggered]="maritalStatusSelection.panelOpen"
        >
          keyboard_arrow_down
        </mat-icon>

        <mat-select
          #maritalStatusSelection
          formControlName="maritalStatus"
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let maritalStatus of MaritalStatusOptions"
            [value]="maritalStatus.value"
          >
            {{ maritalStatus.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </section>

  <section *ngIf="hasPartner" class="partner-details">
    <h3>Partner details</h3>

    <div class="form-field-wrapper">
      <mat-label>Partner’s first name</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <input matInput formControlName="partnerFirstName" />
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Partner’s date of birth</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <input
          matInput
          placeholder="Enter date of birth"
          formControlName="partnerDateOfBirth"
          [max]="maxDateOfBirth"
          [matDatepicker]="partnerDatepicker"
        />

        <mat-datepicker-toggle matIconSuffix [for]="partnerDatepicker">
          <svg-icon-sprite
            matDatepickerToggleIcon
            [src]="'assets/sprites/sprite.svg#calendar-line'"
            [width]="'22px'"
          ></svg-icon-sprite>
        </mat-datepicker-toggle>

        <mat-datepicker #partnerDatepicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Partner’s citizenship status</mat-label>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <mat-icon
          matSuffix
          class="suffix-select-icon"
          [class.triggered]="partnerCitizenshipStatusSelection.panelOpen"
        >
          keyboard_arrow_down
        </mat-icon>

        <mat-select
          #partnerCitizenshipStatusSelection
          formControlName="partnerCitizenshipStatus"
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let citizenshipStatus of CitizenshipStatusOptions"
            [value]="citizenshipStatus.value"
          >
            {{ citizenshipStatus.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </section>

  <mat-divider></mat-divider>

  <mat-tab-group
    class="tab-group--bordered"
    [class.tab-group--borderless]="!hasPartner"
    [disableRipple]="true"
  >
    <mat-tab label="Your dependants">
      <div class="dependant-content">
        <acorn-dependant
          groupDependant="Children"
          dependantName="Child"
          [isChild]="true"
          [formArray]="formGroup.controls.children"
        ></acorn-dependant>

        <acorn-dependant
          groupDependant="Elderly parents"
          dependantName="Parent"
          [formArray]="formGroup.controls.elderlyParent"
        ></acorn-dependant>

        <acorn-dependant
          groupDependant="Loved one with special needs"
          dependantName="Person"
          [formArray]="formGroup.controls.lovedOneWithSpecialNeed"
        ></acorn-dependant>
      </div>
    </mat-tab>

    <mat-tab *ngIf="hasPartner" label="Partner's dependants">
      <div class="dependant-content">
        <acorn-dependant
          groupDependant="Children"
          dependantName="Child"
          [isChild]="true"
          [formArray]="formGroup.controls.partnerChildren"
        ></acorn-dependant>

        <acorn-dependant
          groupDependant="Elderly parents"
          dependantName="Parent"
          [formArray]="formGroup.controls.partnerElderlyParent"
        ></acorn-dependant>

        <acorn-dependant
          groupDependant="Loved one with special needs"
          dependantName="Person"
          [formArray]="formGroup.controls.partnerLovedOneWithSpecialNeed"
        ></acorn-dependant>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>

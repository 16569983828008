import { ACORN_TOOLTIP } from '@acorn/util';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TooltipDirective } from '@acorn/common-ui';

@Component({
  selector: 'acorn-wealth-building-blocks',
  standalone: true,
  templateUrl: './wealth-building-blocks.component.html',
  styleUrls: ['./wealth-building-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TooltipDirective],
})
export class WealthBuildingBlocksComponent {
  protected readonly ACORN_TOOLTIP = ACORN_TOOLTIP;
}

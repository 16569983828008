<mat-accordion>
  <mat-expansion-panel [disabled]="expandDisabled" [expanded]="isExpand">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span
          acornTooltip
          [title]="
            tooltipMessage
              ? ''
              : (question!.question.content
                | displayOrderingQuestion : questionIndex)
          "
          [tooltipTitle]="tooltipTitle"
          [tooltipMessage]="tooltipMessage"
        >
          {{
            question!.question.content | displayOrderingQuestion : questionIndex
          }}
          <ng-container
            *ngIf="question.question.answerType === AnswerType.PercentDropdown"
          >
            between {{ applicationUsers | displayUsersName }}
          </ng-container>
        </span>
      </mat-panel-title>

      <button
        *ngIf="hasRemoveButton"
        mat-stroked-button
        class="remove-button"
        (click)="removeClicked.emit()"
        [disabled]="isFileNotes"
      >
        Remove
        <svg-icon-sprite
          iconPositionEnd
          [src]="'assets/sprites/sprite.svg#delete-bin'"
          [width]="'24px'"
        ></svg-icon-sprite>
      </button>
    </mat-expansion-panel-header>

    <form [ngSwitch]="question.question.answerType" [formGroup]="formGroup" [ngClass]="{'disabled-select': isFileNotes}">
      <!-- <p *ngIf="question.label">{{ question.label }}</p> -->

      <p *ngIf="question.label">{{ question!.label === 'Investment property' ? 'Property value' : question!.label }}
      </p>

      <mat-radio-group
        *ngSwitchCase="AnswerType.TabChoice"
        aria-label="Select an option"
        class="inline"
        color="primary"
        formControlName="answerContent"
      >
        <mat-radio-button
          *ngFor="let option of question.question.answerSelections"
          [value]="option.content"
        >
          {{ option.content }}
        </mat-radio-button>
      </mat-radio-group>

      <ng-container *ngSwitchCase="AnswerType.Amount">
        <mat-form-field
          class="small"
          appearance="outline"
          subscriptSizing="dynamic"
          [class.readonly]="inputElement.disabled || question.readonly"
        >
          <span matPrefix>$</span>

          <input
            #inputElement
            type="text"
            matInput
            mask="separator"
            thousandSeparator=","
            placeholder="Amount"
            formControlName="answerContent"
            [readOnly]="inputElement.disabled"
            [outputTransformFn]="outputTransformFn"
          />
        </mat-form-field>

        <div
          *ngIf="question.isSuperannuationIncluded"
          class="form-field-wrapper"
        >
          <mat-label>Superannuation</mat-label>

          <mat-radio-group
            aria-label="Select an option"
            class="inline"
            color="primary"
            formControlName="superannuation"
          >
            <mat-radio-button [value]="FinancialSuperannuation.Included">
              Included
            </mat-radio-button>

            <mat-radio-button [value]="FinancialSuperannuation.Excluded">
              Excluded
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="question.isTaxStatusIncluded" class="form-field-wrapper">
          <mat-label>Tax Status</mat-label>

          <mat-radio-group
            aria-label="Select an option"
            class="inline"
            color="primary"
            formControlName="taxStatus"
          >
            <mat-radio-button [value]="FinancialTaxStatus.PreTax">
              Pre-Tax
            </mat-radio-button>

            <mat-radio-button [value]="FinancialTaxStatus.PostTax">
              Post Tax
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="question.isFrequencyIncluded" class="form-field-wrapper">
          <mat-label>Frequency</mat-label>

          <mat-radio-group
            aria-label="Select an option"
            class="inline"
            color="primary"
            formControlName="frequency"
          >
            <mat-radio-button
              [value]="FinancialFrequency.Annually"
              (click)="onFrequencyChanged($event, FinancialFrequency.Annually)"
            >
              Annually
            </mat-radio-button>

            <mat-radio-button
              [value]="FinancialFrequency.Monthly"
              (click)="onFrequencyChanged($event, FinancialFrequency.Monthly)"
            >
              Monthly
            </mat-radio-button>

            <mat-radio-button
              [value]="FinancialFrequency.Weekly"
              (click)="onFrequencyChanged($event, FinancialFrequency.Weekly)"
            >
              Weekly
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.Number">
        <mat-form-field
          class="small"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            type="text"
            matInput
            mask="0*"
            placeholder="Number"
            formControlName="answerContent"
           
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.Percent">
        <mat-form-field
          class="small"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <span matPrefix>%</span>

          <input
            type="text"
            matInput
            mask="percent.2"
            placeholder="Percent"
            formControlName="answerContent"
            [class.readonly]="isFileNotes"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.Year">
        <mat-form-field
          class="small"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            placeholder="Date"
            [matDatepicker]="datepicker"
            formControlName="answerContent"
          />

          <mat-datepicker-toggle matIconSuffix [for]="datepicker">
            <svg-icon-sprite
              matDatepickerToggleIcon
              [src]="'assets/sprites/sprite.svg#calendar-line'"
              [width]="'22px'"
            ></svg-icon-sprite>
          </mat-datepicker-toggle>

          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.YearDropdown">
        <mat-form-field
          class="small"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-icon
            matSuffix
            class="suffix-select-icon"
            [class.triggered]="selectElement.panelOpen"
          >
            keyboard_arrow_down
          </mat-icon>

          <mat-select
            #selectElement
            [hideSingleSelectionIndicator]="true"
            formControlName="answerContent"
            [disabled]="isFileNotes"
          >
            <mat-option
              *ngFor="let option of YEAR_OPTIONS"
              [value]="option.value"
              [disabled]="isFileNotes"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.PercentDropdown">
        <acorn-ownership-balance
          [respondent1]="applicationUsers[0]?.firstName"
          [respondent2]="applicationUsers[1]?.firstName"
          formControlName="answerContent"
          [isFileNotes]="isFileNotes"
        ></acorn-ownership-balance>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.InterestRate">
        <acorn-interest-rate
          formControlName="answerContent"
          [isFileNotes]="isFileNotes"
        ></acorn-interest-rate>
      </ng-container>

      <ng-container *ngSwitchCase="AnswerType.RepaymentStructure">
        <acorn-repayment-structure
          formControlName="answerContent"
          [isFileNotes]="isFileNotes"
        ></acorn-repayment-structure>
      </ng-container>

      <div
        *ngIf="formGroup.hasError('amountRequired') && isSubmitted"
        class="error-message message"
      >
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#exclamation-solid'"
          [width]="'24px'"
        ></svg-icon-sprite>
        {{ ERROR_MESSAGE.ALL_FIELDS_ARE_REQUIRED }}
      </div>

      <div
        *ngIf="warningMessage || question.question.warningDescription"
        class="warning-message"
      >
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#question-sign'"
          [width]="'28px'"
        ></svg-icon-sprite>
        {{ warningMessage || question.question.warningDescription }}
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>

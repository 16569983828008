import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfirmFsgStore {
  readonly #innerIsConfirmFSG$ = new ReplaySubject<boolean>(1);

  isConfirmFSG$: Observable<boolean> = this.#innerIsConfirmFSG$.asObservable();

  setConfirmFSG(isConfirmFsg: boolean): void {
    this.#innerIsConfirmFSG$.next(isConfirmFsg);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';

import { CommonModule, ViewportScroller } from '@angular/common';

import { CdkStepperModule } from '@angular/cdk/stepper';

import { first, isNil, orderBy } from 'lodash';

import { CategoryService } from '@acorn/data-access';

import {
  AboutYouQuestion,
  AnswerSelection,
  AnswerType,
  ApplicationAboutYouResponse,
  ApplicationStep,
  ApplicationUser,
  Category,
  CategoryStatus,
} from '@acorn/util';
import { NavigationComponent } from '@acorn/common-ui';
import {
  ApplicationStepIndex,
  findLatestStepIndex,
  getUpdatedCategoryStatus,
} from '@acorn/feature-application';

import {
  NestedVerticalStepperComponent,
  VerticalStepperComponent,
  WelcomeScreenComponent,
} from '../ui';

import {
  CommonAnswerComponent,
  DependantsComponent,
  LastReviewComponent,
} from './ui';

import { TemplateType } from './utils';
import { StepStatePipe } from '../util';

@Component({
  selector: 'acorn-about-you',
  standalone: true,
  templateUrl: './about-you.component.html',
  styleUrls: ['./about-you.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    WelcomeScreenComponent,
    DependantsComponent,
    CdkStepperModule,
    VerticalStepperComponent,
    NestedVerticalStepperComponent,
    NavigationComponent,
    CommonAnswerComponent,
    LastReviewComponent,
    StepStatePipe,
  ],
})
export class AboutYouComponent implements OnInit {
  #categoryService = inject(CategoryService);
  #cdr = inject(ChangeDetectorRef);
  #viewport = inject(ViewportScroller);

  @Input({ required: true }) applicationId!: string;
  @Input({ required: true }) applicationUsers!: ApplicationUser[];
  @Input({ required: true }) latestApplicationStep!: ApplicationStep;
  @Input() onlyAboutYou: boolean = false;

  @Input({ required: true }) categories: Category[] = [];
  @Input({ required: true }) questions: AboutYouQuestion[] = [];
  @Input({ required: true }) answerSelections: AnswerSelection[] = [];
  @Input() isFileNotes: boolean = false;


  @Input({ required: true }) set applicationAboutYou(
    value: ApplicationAboutYouResponse[]
  ) {
    this.latestApplicationAboutYou.set(value);
  }

  @Output() back = new EventEmitter<void>();
  @Output() finishedStep = new EventEmitter<{
    isUpdateApplicationProgress: boolean;
  }>();

  protected readonly TemplateType = TemplateType;
  protected readonly CategoryStatus = CategoryStatus;

  selectedStep = signal<number | undefined>(undefined);
  selectedChildStep = signal<number | undefined>(undefined);

  markAsSeen = signal(false);
  latestApplicationAboutYou = signal<ApplicationAboutYouResponse[]>([]);

  selectedParentCategory = computed(() => {
    const selectedStepIndex = this.selectedStep();

    if (selectedStepIndex === undefined) {
      return null;
    }

    return this.categories[selectedStepIndex];
  });
  selectedCategory = computed(() => {
    const parentCategory = this.selectedParentCategory();

    if (!parentCategory?.childItems?.length) {
      return null;
    }

    return parentCategory.childItems[this.selectedChildStep()!];
  });
  selectedQuestions = computed(() => {
    const selectedCategory = this.selectedCategory();

    if (!selectedCategory) {
      return [];
    }

    return orderBy(this.questions, 'order').filter(
      (question) => question.aboutYouCategory.id === selectedCategory.id
    );
  });
  selectedPrimaryQuestion = computed(() => {
    const primaryQuestions = this.selectedQuestions();

    return primaryQuestions[0];
  });
  selectedTemplateType = computed(() => {
    const selectedQuestions = this.selectedQuestions();

    if (!selectedQuestions?.length) {
      return null;
    }

    const firstQuestion = first(selectedQuestions)!;

    if (firstQuestion.isMultiAnswerQuestion) {
      return TemplateType.MultiAnswerQuestion;
    }

    if (firstQuestion.question.answerType === AnswerType.LastReviewDropDown) {
      return TemplateType.LastReview;
    }

    return TemplateType.Default;
  });

  ngOnInit() {
    const [stepIndex, childStepIndex] = findLatestStepIndex(this.categories , this.onlyAboutYou);
    this.selectedStep.set(stepIndex);
    this.selectedChildStep.set(childStepIndex);
  }

  onInitializeStep(): void {
    this.selectedStep.set(0);
    this.selectedChildStep.set(0);
  }

  onSelectStep(index: number): void {
    const updatedCategory = this.categories[index];

    if (!updatedCategory) {
      return;
    }

    if (updatedCategory.status !== CategoryStatus.Complete) {
      this.selectedStep.set(index);
      return;
    }

    this.selectedStep.set(index);
    this.selectedChildStep.set(0);
  }

  onSelectChildStep(index: number): void {
    this.selectedChildStep.set(index);
    this.#scrollToTop();
  }

  onUpdateApplicationAboutYou(
    updatedApplicationAboutYou: ApplicationAboutYouResponse[]
  ): void {
    this.latestApplicationAboutYou.set(updatedApplicationAboutYou);
  }

  onHandleBack(): void {
    const selectedCategory = this.selectedCategory();
    const selectedParentCategory = this.selectedParentCategory();

    if (!selectedCategory || !selectedParentCategory) {
      return;
    }

    if (selectedCategory.isFirstItem && selectedParentCategory.isFirstItem) {
      this.back.emit();
      return;
    }

    if (selectedCategory.isFirstItem) {
      const currentStepIndex = this.selectedStep()!;
      this.selectedStep.set(currentStepIndex - 1);

      const childStepIndex =
        this.categories[currentStepIndex - 1].childItems?.length!;
      this.selectedChildStep.set(childStepIndex - 1);

      return;
    }

    this.selectedChildStep.update((index) => index! - 1);
    this.#scrollToTop();
  }

  onBeforeHandleNext(): void {
    const selectedCategory = this.selectedCategory();

    if (!selectedCategory) {
      return;
    }

    if (selectedCategory.status !== CategoryStatus.Complete) {
      this.#markSelectedCategoryAsComplete();
    } else {
      this.onHandleNext();
    }
  }

  onHandleNext(): void {
    const selectedCategory = this.selectedCategory();

    if (!selectedCategory) {
      return;
    }

    if (this.selectedCategory()?.isLastItem) {
      if (this.selectedParentCategory()?.isLastItem) {
        const isUpdateApplicationProgress =
          ApplicationStepIndex[this.latestApplicationStep] <=
          ApplicationStepIndex[ApplicationStep.FinancialSituation];

        this.finishedStep.emit({ isUpdateApplicationProgress });
      } else {
        this.selectedStep.update((step) => step! + 1);
        this.selectedChildStep.set(0);
      }
    } else {
      this.selectedChildStep.update((index) => index! + 1);
    }

    this.markAsSeen.set(true);
  }

  #scrollToTop(): void {
    this.#viewport.scrollToPosition([0, 0]);
  }

  #markSelectedCategoryAsComplete(): void {
    const selectedStepIndex = this.selectedStep();
    const selectedChildStepIndex = this.selectedChildStep();

    if (isNil(selectedStepIndex) || isNil(selectedChildStepIndex)) {
      return;
    }

    const updatedCategoryStatus = getUpdatedCategoryStatus(
      this.categories,
      selectedStepIndex,
      selectedChildStepIndex
    );

    if (!updatedCategoryStatus.length) {
      return;
    }

    this.#cdr.markForCheck();

    this.#categoryService
      .updateStatusCategory(this.applicationId, updatedCategoryStatus)
      .subscribe(({ isSuccess }) => {
        if (!isSuccess) {
          return;
        }

        this.onHandleNext();
      });
  }
}

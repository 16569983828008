import { NavigationComponent } from '@acorn/common-ui';
import { Application, ApplicationPlan, ApplicationUser, WEBHOOK_STATUS } from '@acorn/util';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NestedVerticalStepperComponent,
  VerticalStepperComponent,
  WelcomeScreenComponent,
} from '../ui';
import { SetupDetailsComponent, SetupPlanComponent } from './ui';
import { environment } from '@env';

@Component({
  selector: 'acorn-getting-started',
  templateUrl: 'getting-started.component.html',
  styleUrls: ['getting-started.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkStepperModule,
    NgForOf,
    VerticalStepperComponent,
    NestedVerticalStepperComponent,
    SetupPlanComponent,
    SetupDetailsComponent,
    NgIf,
    NavigationComponent,
    NgSwitch,
    NgSwitchCase,
    WelcomeScreenComponent,
  ],
})
export class GettingStartedComponent implements OnInit {
  #router = inject(Router);

  @Input({ required: true }) currentApplication!: Application;
  @Input({ required: true }) applicationPlan?: ApplicationPlan;
  @Input() applicationUsers: ApplicationUser[] | null = [];
  @Input() allowNavigateToHome = true;
  // @Input() isFileNotes : any;

  @Output() finishedStep = new EventEmitter<{
    isUpdateApplicationProgress: boolean;
  }>();
  @Output() syncApplicationUsers = new EventEmitter<ApplicationUser[]>();
  @Output() backToTheQuotePage = new EventEmitter<void>();
  @Output() triggerWebhook = new EventEmitter<string>();
  @Input() isFileNotes: boolean = false;

  selectedIndex = signal<number>(0);
  maskAsRead = signal(false);

  ngOnInit() {
    this.maskAsRead.set(!!this.applicationPlan);
    this.selectedIndex.set(this.applicationPlan ? 1 : 0);
  }

  onSelectIndexChanged(selectedIndex: number): void {
    this.selectedIndex.set(selectedIndex);
  }

  onSubmitPlan(applicationPlan: ApplicationPlan): void {

    this.applicationPlan = applicationPlan;
    this.selectedIndex.set(1);
  }

  onHandleBack(): void {
    if (this.selectedIndex() === 0) {
      this.#navigateToHome();
      return;
    }

    this.selectedIndex.set(this.selectedIndex() - 1);
  }

  onHandleNext(
    isUpdateApplications: boolean,
    applicationUsers: ApplicationUser[]
  ): void {
    const status = WEBHOOK_STATUS.your_goals;
    this.triggerWebhook.emit(status);
    this.finishedStep.emit({
      isUpdateApplicationProgress: !isUpdateApplications,
    });
    this.syncApplicationUsers.emit(applicationUsers);
  }

  navigateToTheQuotePage(): void {
    this.backToTheQuotePage.emit();    
  }

  navigateToHome(): void{
    //this.#router.navigateByUrl('/home');
    window.location.href = environment.wordpressURL;
  }

  #navigateToHome() {
    if (!this.allowNavigateToHome) {
      return;
    }

    this.#router.navigateByUrl('/home');
  }
}

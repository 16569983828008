<ng-container *ngIf="markAsSeen(); else welcomeTemplate">
  <acorn-vertical-stepper [selectedIndex]="0">
    <cdk-step label="Your wealth health" [state]="isFinishedStep ? 'done' : ''">
      <acorn-nested-vertical-stepper
        [selectedIndex]="selectedCategoryIndex()"
        (selectedIndexChange)="onCategoryChanged($event)"
      >
        <cdk-step
          label="Wealth Building Blocks"
          [state]="selectedCategoryIndex() > 0 || isFinishedStep ? 'done' : ''"
        ></cdk-step>
        <cdk-step
          label="Wealth health score"
          [state]="selectedCategoryIndex() > 1 || isFinishedStep ? 'done' : ''"
        ></cdk-step>

        <cdk-step *ngIf="isApplicationEditable"
          label="Are you happy"
          [state]="isFinishedStep ? 'done' : ''"
        ></cdk-step>
      </acorn-nested-vertical-stepper>
    </cdk-step>
  </acorn-vertical-stepper>

  <div class="step__body">
    <ng-container [ngSwitch]="selectedCategoryIndex()">
      <acorn-wealth-building-blocks
        *ngSwitchCase="0"
      ></acorn-wealth-building-blocks>

      <acorn-wealth-health-score
        *ngSwitchCase="1"
        [wealthHealthScore]="wealthHealthScore()!"
        [isDashboardApp]="isDashboardApp"
      ></acorn-wealth-health-score>

      <acorn-wealth-happy-screen
      [wealthHealthScore]="wealthHealthScore()!"
      [isDashboardApp]="isDashboardApp"
        *ngSwitchCase="2"
      ></acorn-wealth-happy-screen>
    </ng-container>

    <acorn-application-navigation
      (back)="onHandleBack()"
      (next)="onHandleNext()"
    ></acorn-application-navigation>
  </div>

  <div></div>
</ng-container>

<ng-template #welcomeTemplate>
  <div></div>

  <acorn-welcome-screen
    imageName="wealth-health.svg"
    title="Wealth Health"
    [description]="description"
    estimatedTime="5"
  >
    <acorn-application-navigation
      (back)="back.emit()"
      (next)="markAsSeen.set(true)"
    ></acorn-application-navigation>
  </acorn-welcome-screen>

  <div></div>
</ng-template>

import {
  CommonResponse,
  CustomerProfile,
  CustomerUser,
  Subscription,
} from '@acorn/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@env';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  #httpClient = inject(HttpClient);

  #currentUser = new ReplaySubject<CustomerUser>(1);

  get currentUser$(): Observable<CustomerUser> {
    return this.#currentUser.asObservable();
  }

  setCurrentUser(user: CustomerUser) {
    this.#currentUser.next(user);
  }

  getCurrentUser(headers?: HttpHeaders) {
    return this.#httpClient.get<CommonResponse<CustomerUser>>(
      `${environment.apiUrls.customer}/api/User/Current`,
      { headers }
    );
  }

  getCurrentProfile() {
    return this.#httpClient.get<CommonResponse<CustomerProfile>>(
      `${environment.apiUrls.customer}/api/User/CurrentProfile`
    );
  }

  updateCurrentProfile(profile: Partial<CustomerProfile>) {
    return this.#httpClient.put<CommonResponse<CustomerProfile>>(
      `${environment.apiUrls.customer}/api/User/CurrentProfile`,
      profile
    );
  }

  clearCurrentPartnerProfile(): Observable<CommonResponse<CustomerProfile>> {
    return this.#httpClient.post<CommonResponse<CustomerProfile>>(
      `${environment.apiUrls.customer}/api/User/ClearCurrentPartnerProfile`,
      {}
    );
  }

  updateConfirmFsgUser(isConfirm: boolean) {
    return this.#httpClient.put<CommonResponse<boolean>>(
      `${environment.apiUrls.customer}/api/User/ConfirmFSG`,
      isConfirm
    );
  }

  cancelSubscription(subscriptionId: string, cancelReason: string) {
    return this.#httpClient.post<CommonResponse<CustomerProfile>>(
      `${environment.apiUrls.customer}/api/Membership/subscription-cancel`,
      { subscriptionId, cancelReason }
    );
  }

  getWealthCoachCalendarLink(): Observable<CommonResponse<string>> {
    return this.#httpClient.get<CommonResponse<string>>(
      `${environment.apiUrls.customer}/api/User/Calendar`
    );
  }
}

<ng-container *ngIf="!this.isManualFlow">
    <div class="container-fluid financial-projection-container">
        <h2 class="title" *ngIf="!this._selectedStrategy?.selectedByAdminDate">
            Your financial projection and goal <br />likelihood if you continue as is
        </h2>

        <h2 class="title" *ngIf="this._selectedStrategy?.selectedByAdminDate">
            Your financial projection and goal <br />likelihood if you follow your strategy
        </h2>
        <p class="title" *ngIf="this._selectedStrategy?.selectedByAdminDate" style="color: #323e48 !important">
            Your projected net asset position —
            <span style="font-weight: 900">
                If you follow your
                <span style="color: #f47621 !important">{{ this._selectedStrategy?.name }}</span>
                strategy and action plan.</span
            >
        </p>

        <div class="chart-container w-80">
            <acorn-default-chart [customeClass]="'financial-chart'" [data]="chartData" [chartId]="'1'"></acorn-default-chart>
        </div>
    </div>

    <div style="margin: 50px 0px">
        <div class="bottom-container container">
            <h4 class="mb-15">Your goal details</h4>
            <p class="mb-15">
                If you continue managing your money as is, this is how likely you are to achieve your goals within the parameters you set
                (e.g. timeframe, cost).
            </p>
            <p class="mb-15">
                If a goal is showing as unlikely, this does not mean you can never achieve them but may mean you need to change your time or
                cost expectations. Speak to your Wealth Coach to understand more,
            </p>
            <div class="goal-wrap" *ngIf="this.goalMetricsQuestions && !this._selectedStrategy?.selectedByAdminDate">
                <div class="goal-container mt-2" *ngFor="let goal of this.goalMetricsQuestions; let k = index">
                    <div class="d-flex strategy-inner">
                        <div class="strategy-text">
                            <p class="number Unlikely">{{ k + 1 }}</p>
                        </div>
                        <div class="goal-header">
                            <div class="header-row">
                                <h2>{{ goal?.goalName }}</h2>
                            </div>
                            <div class="status Unlikely">Unlikely</div>
                        </div>
                        <div class="single-goal-main" style="display: flex">
                            <div
                                *ngFor="let question of goal.questions; let j = index"
                                class="single-goal sgoal-{{ j }}"
                                [ngClass]="{
                                    'single-goal-question': !question.childrenQuestions?.length
                                }"
                            >
                                <div class="single-questoin-wrap" style="display: flex; justify-content: space-between">
                                    <p
                                        class="single-questoin-num"
                                        *ngIf="isFirstQuestionInGroup(goal.questions, question) && !question.childrenQuestions.length"
                                    >
                                        {{ getGroupNumber(goal.questions, question) }}
                                    </p>
                                    <div *ngIf="!question.childrenQuestions.length" class="single-questoin-value">
                                        <div *ngIf="j === 0" class="value-1">
                                            <p>
                                                {{ question.answerType === 'Amount' ? '$' : '' }}
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>

                                        <div *ngIf="j === 1" class="value-2">
                                            <p>
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>

                                        <div *ngIf="j === 2" class="value-3">
                                            <p>
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="question.childrenQuestions.length" class="child-questions" style="display: flex">
                                    <div class="goal-valaues" style="display: flex">
                                        <div
                                            class="values-main"
                                            *ngFor="let childQuestion of question.childrenQuestions"
                                            style="display: flex"
                                        >
                                            <div
                                                class="goal-value-inner"
                                                style="display: flex; flex-direction: column; justify-content: space-around"
                                            >
                                                <div class="values-inner" *ngFor="let answer of childQuestion.childAnswers; let l = index">
                                                    <div class="value-col" *ngIf="l === 0">
                                                        <p>
                                                            {{ question.answerType === 'Amount' ? '$' : '' }}
                                                            {{ question.selectedAnswer?.content }}
                                                        </p>
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                    <div class="num-col" *ngIf="l === 1">
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                    <div class="result-col" *ngIf="l === 2">
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="goal-wrap" *ngIf="this._selectedStrategy?.selectedByAdminDate && this.goalsData">
                <div class="goal-container mt-2" *ngFor="let goal of this.goalsData; let k = index">
                    <div class="d-flex strategy-inner">
                        <div class="strategy-text">
                            <p class="number {{ goal?.goalStatus }}">{{ k + 1 }}</p>
                        </div>
                        <div class="goal-header">
                            <div class="header-row">
                                <h2>{{ goal?.goalName }}</h2>
                            </div>
                            <div class="status {{ goal?.goalStatus }}">
                                {{ goal?.goalStatus }}
                            </div>
                        </div>
                        <div class="single-goal-main" style="display: flex">
                            <div
                                *ngFor="let question of goal.questions; let j = index"
                                class="single-goal sgoal-{{ j }}"
                                [ngClass]="{
                                    'single-goal-question': !question.childrenQuestions?.length
                                }"
                            >
                                <div class="single-questoin-wrap" style="display: flex; justify-content: space-between">
                                    <p
                                        class="single-questoin-num"
                                        *ngIf="isFirstQuestionInGroup(goal.questions, question) && !question.childrenQuestions.length"
                                    >
                                        {{ getGroupNumber(goal.questions, question) }}
                                    </p>
                                    <div *ngIf="!question.childrenQuestions.length" class="single-questoin-value">
                                        <div *ngIf="j === 0" class="value-1">
                                            <p>
                                                {{ question.answerType === 'Amount' ? '$' : '' }}
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>

                                        <div *ngIf="j === 1" class="value-2">
                                            <p>
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>

                                        <div *ngIf="j === 2" class="value-3">
                                            <p>
                                                {{ question.selectedAnswer?.content }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="question.childrenQuestions.length" class="child-questions" style="display: flex">
                                    <div class="goal-valaues" style="display: flex">
                                        <div
                                            class="values-main"
                                            *ngFor="let childQuestion of question.childrenQuestions"
                                            style="display: flex"
                                        >
                                            <div
                                                class="goal-value-inner"
                                                style="display: flex; flex-direction: column; justify-content: space-around"
                                            >
                                                <div class="values-inner" *ngFor="let answer of childQuestion.childAnswers; let l = index">
                                                    <div class="value-col" *ngIf="l === 0">
                                                        <p>
                                                            {{ question.answerType === 'Amount' ? '$' : '' }}
                                                            {{ question.selectedAnswer?.content }}
                                                        </p>
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                    <div class="num-col" *ngIf="l === 1">
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                    <div class="result-col" *ngIf="l === 2">
                                                        <p>{{ answer?.content }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center">
        <a class="see-assumption" (click)="openAssumptions()">See key assumptions</a>
    </div>
</ng-container>

<div *ngIf="this.isManualFlow">
    <div class="container financial-manual-container">
        <h2>
            Download your SOA to see your <br />
            financial projection
        </h2>

        <button class="edit-button" (click)="downloadChosenSOA()" [disabled]="loading" *ngIf="this._selectedStrategy?.selectedByAdminDate">
            <ng-container *ngIf="!loading">Download your chosen strategy Statement of Advice PDF</ng-container>
            <ng-container *ngIf="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </ng-container>
          </button>
    </div>
</div>

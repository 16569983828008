import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { ButtonComponent } from '../button';

@Component({
  selector: 'acorn-contact-us',
  templateUrl: 'contact-us.component.html',
  styleUrls: ['contact-us.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, ButtonComponent],
})
export class ContactUsComponent {
  @Input() title = '';
  @Input() description = '';

  sendEmail() {
    const recipient = 'info@wealthmaximiser.com.au';
    window.open(`mailto:${recipient}`, '_blank');
  }
}

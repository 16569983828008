import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SafePipe } from '@acorn/util';

@Component({
  selector: 'acorn-projection-popup',
  standalone: true,
  imports: [CommonModule,SafePipe],
  templateUrl: './projection-popup.component.html',
  styleUrls: ['./projection-popup.component.scss'],
})
export class ProjectionPopupComponent {
  #dialogRef = inject(MatDialogRef<ProjectionPopupComponent>);
  videoUrl = "https://acornstorageuat.blob.core.windows.net/assets/Line-drawing-videos.mp4"

constructor(private sanitizer: DomSanitizer  ) {
}

sanitizedUrl(url: string): SafeResourceUrl {
  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}
  closeVideo(){
    this.#dialogRef.close();
  }
}

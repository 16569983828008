import { AbstractControl, ValidatorFn } from '@angular/forms';
import { AnswerType } from '../enum';
import { FinancialQuestion } from '../interfaces';
import { cloneDeep, isEmpty } from 'lodash';

export function mustBeInFuture(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const financialYear = currentMonth >= 7 ? currentYear + 1 : currentYear;

    if (control.value) {
      const inputYear = control.value;

      if (inputYear < currentYear) {
        return { pastYear: true };
      }

      if (inputYear < financialYear) {
        return {
          invalidYear: {
            message: `Please update your goal achievement date to reflect the correct financial year - e.g. If Dec ${inputYear}, enter ${financialYear} as this is the matching financial year.`,
            suggestedYear: financialYear
          }
        };
      }
    }

    return null;
  };
}
export function mustBeAdultAge(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value && Number(control.value) < 18 ? { invalidAge: true } : null;
}

export function questionAmountTypeRequiredValidator(
  question: FinancialQuestion
): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    if (question.question.answerType !== AnswerType.Amount) {
      return null;
    }

    const answer = cloneDeep(formGroup.getRawValue());
    const isRequiredFields = [];
    delete answer.financialQuestionId;

    if (!question.isTaxStatusIncluded) {
      delete answer.taxStatus;
    }

    if (!question.isFrequencyIncluded) {
      delete answer.frequency;
    }

    if (!question.isSuperannuationIncluded) {
      delete answer.superannuation;
    }

    Object.values(answer).forEach((item) => {
      if (!isEmpty(item)) {
        isRequiredFields.push(item);
      }
    });

    if (
      isRequiredFields.length > 0 &&
      isRequiredFields.length < Object.values(answer).length
    ) {
      return { amountRequired: true };
    }

    return null;
  };
}

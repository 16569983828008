import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationStrategyDocumentModelDto, assetMapping, PDFDownloadType } from '../../utils/wealth-helper';
import { StrategyServiceHelper } from '../../../helpers/strategy-service.helper';

@Component({
    selector: 'acorn-popup',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
})
export class WealthStrategyOptionPopupComponent {
    #dialogRef = inject(MatDialogRef<WealthStrategyOptionPopupComponent>);
    dialogData = inject(MAT_DIALOG_DATA);
    strategySvc = inject(StrategyServiceHelper);

    onClose() {
        this.#dialogRef.close();
    }

    getImagePath(bb: string): string {
        const match = assetMapping.find((item) => item.title === bb);
        return match ? match.imagePath : '';
    }

    formatStrategyOption(option: string): string {
        return option.replace(/(\d+)/, ' $1');
    }


    public getGoals(goals:Array<any>,value:boolean):Array<any>{
        return goals.filter(e=> e.isLikely === value);
        // return goals.sort((a, b) => b.isLikely - a.isLikely);
    }

    downloadChosenSOA(){
        const url = `https://dev-pdf.wealthmaximiser.com.au/wealth-statergy/${this.dialogData?.strategy?.applicationId}`;

        const data: ApplicationStrategyDocumentModelDto = {
            applicationId: this.dialogData?.strategy?.applicationId,
            blobUrl: "",
            pdfDownloadTypeId: PDFDownloadType.ChosenStrategy,
            documentUrl: url
          };

        this.strategySvc.download(data ,"Chosen SOA")
    }
}

<div class="popup-main" *ngIf="data.actiondetails">
    <div class="popup-inner">
        <div class="popup-content">
            <div class="popup-head d-flex gap10">
                <p class="number">{{ data.displayRank }}</p>
                <h2>{{ data.topic }}</h2>
            </div>
            <div>
                <button class="close-button" (click)="close()">&#x2715;</button>
            </div>
            <div class="action-text">
                <div class="toggle d-flex gap10" [ngClass]="getStatusMessage(data.actiondetails).statusClass">
                    <h3>{{ getCount }}</h3>
                    <p>{{ getStatusMessage(data.actiondetails).message }}</p>
                </div>
                <div [ngClass]="{ active: activeStates[1] }" class="toggle-content" *ngIf="data?.actiondetails">
                    <div class="toggle-text d-flex action">
                        <div class="points-col">
                            <div class="points" *ngFor="let item of actiondetails; let i = index">
                                <label>
                                    <span [innerHTML]="'<b>'+item.name+': </b>'"></span>
                                    <span class="hidden-content" *ngIf="isContentVisible[i]">
                                        <span [innerHTML]="item.description"></span>
                                        <a (click)="toggleContentVisibility(i); $event.preventDefault()"> See Less</a>
                                    </span>
                                    <span class="see-more" *ngIf="!isContentVisible[i]">
                                        <a (click)="toggleContentVisibility(i); $event.preventDefault()"> See more </a>
                                    </span>
                                    <input
                                        type="checkbox"
                                        [checked]="item.applicationActionDetails !== null"
                                        (change)="onCheckboxChange(item.actionId, item.id, $event)"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div (click)="toggleActive(1)" class="toggle mobi-toggle">
                        <p class="hide">Hide Action details</p>
                        <p class="show">Show Action details</p>
                    </div> -->
                </div>
                <div class="action-details" *ngIf="actionInfo">
                    <h3>More Action Details</h3>
                    <h4>What and Why</h4>

                    <div class="more-text" *ngIf="actionInfo?.whatItIs?.trim()">
                        <b>What is it</b>
                        <p [innerHTML]="actionInfo?.whatItIs"></p>
                    </div>

                    <div class="more-text" *ngIf="actionInfo?.whyDoIt?.trim()">
                        <b>Why do it</b>
                        <p [innerHTML]="actionInfo?.whyDoIt"></p>
                    </div>

                    <div class="more-text" *ngIf="actionInfo?.definition?.trim()">
                        <a class="href">Definitions</a>
                        <p [innerHTML]="actionInfo?.definition"></p>
                    </div>

                    <div class="more-text" *ngIf="actionInfo?.keyRisk?.trim()">
                        <a class="href">Key risks</a>
                        <p [innerHTML]="actionInfo?.keyRisk"></p>
                    </div>

                    <div class="more-text" *ngIf="actionInfo?.potentialCost?.trim()">
                        <a class="href">Potential costs</a>
                        <p [innerHTML]="actionInfo?.potentialCost"></p>
                    </div>

                    <div class="more-text" *ngIf="actionInfo?.consideration?.trim()">
                        <a class="href">Considerations</a>
                        <p [innerHTML]="actionInfo?.consideration"></p>
                    </div>
                </div>

                <div class="link-text d-flex" *ngIf="timeframetoReceiveBenefits?.length >0">
                    <div class="type-col">
                        <div>
                            <h5>Typical time to receive benefit</h5>
                            <div>
                                <ng-container *ngFor="let timeframe of timeframetoReceiveBenefits">
                                    <a class="link" >{{ timeframe }}</a>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="type-col"  *ngIf="goals.length > 0">
                        <div>
                            <h5>Relevant Wealth Goals</h5>
                            <ng-container *ngFor="let goal of goals">
                                <a class="text-align-left link orange" >{{ goal }}</a>
                            </ng-container>
                        </div>
                    </div>

                    <div class="type-col">
                        <div *ngIf="relevantWealthBuildingBlocks.length > 0">
                            <h5>Relevant Wealth Building Blocks</h5>
                            <div class="block-box">
                                <ng-container *ngFor="let block of relevantWealthBuildingBlocks">
                                    <div class="block-pyramid__item">
                                        <div class="building-block">
                                            <img [src]="block.imagePath" class="h-85" />
                                            <div class="block-label w-full">
                                                {{ block.title }}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-col" *ngIf="this.actionInfo?.reference">
                    <div class="info-text">
                        <h5>For further information</h5>
                        <p [innerHTML]="actionInfo?.reference"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { TooltipDirective } from '@acorn/common-ui';
import {
    AnswerSelection,
    AnswerType,
    ApplicationUser,
    DisplayOrderingQuestionPipe,
    DisplayUsersNamePipe,
    ERROR_MESSAGE,
    FinancialFrequency,
    FinancialQuestion,
    FinancialSuperannuation,
    FinancialTaxStatus,
    QuestionType,
} from '@acorn/util';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    DestroyRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { omit, values } from 'lodash';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskDirective } from 'ngx-mask';
import { AmountForm, YEAR_OPTIONS } from '../../utils';
import { InterestRateComponent } from '../interest-rate';

import { OwnershipBalanceComponent } from '../ownership-balance/ownership-balance.component';
import { RepaymentStructureComponent } from '../repayment-structure';

@Component({
    selector: 'acorn-single-question',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatExpansionModule,
        IconSpriteModule,
        MatDatepickerModule,
        MatSelectModule,
        NgxMaskDirective,
        MatButtonModule,
        RepaymentStructureComponent,
        InterestRateComponent,
        TooltipDirective,
        DisplayOrderingQuestionPipe,
        MatIconModule,
        MatSliderModule,
        OwnershipBalanceComponent,
        DisplayUsersNamePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './single-question.component.html',
    styleUrls: ['./single-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleQuestionComponent implements OnInit {
    #destroyRef = inject(DestroyRef);

    @HostBinding('class.dual-respondent')
    @Input()
    isDualRespondent? = false;

    @HostBinding('class.remove-padding-header')
    @Input()
    removePaddingHeader? = false;

    @Input({ required: true }) question!: FinancialQuestion;
    @Input({ required: true }) formGroup!: FormGroup<AmountForm>;
    @Input() answerSelections: AnswerSelection[] = [];

    @Input() expandDisabled = false;
    @Input() questionIndex = 0;
    @Input() hasRemoveButton = false;
    @Input() isSubmitted = false;
    @Input() isExpand = true;
    @Input() warningMessage: string | undefined;
    @Input() applicationKey!: string;
    @Input() applicationUsers: ApplicationUser[] = [];
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;

    @Output() removeClicked = new EventEmitter<void>();
    @Output() removeWarningMessage = new EventEmitter<{
        applicationKey: string;
        id: string;
    }>();

    protected readonly FinancialSuperannuation = FinancialSuperannuation;
    protected readonly FinancialTaxStatus = FinancialTaxStatus;
    protected readonly FinancialFrequency = FinancialFrequency;
    protected readonly AnswerType = AnswerType;
    protected readonly YEAR_OPTIONS = YEAR_OPTIONS;
    protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

    get tooltipMessage() {
        return this.question.question.description;
    }

    get tooltipTitle() {
        return this.question.question.content;
    }

    ngOnInit(): void {
        this.#expandPanelWhenEnteredValue();
        this.formGroup.controls.answerContent.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
            next: () => {
                if (this.warningMessage) {
                    this.removeWarningMessage.emit({
                        applicationKey: this.applicationKey,
                        id: this.question.id,
                    });
                }
            },
        });

        if (this.question && this.question.question.questionType === QuestionType.HaveYouEverOwnedPropertyInAustralia) {
            this.question.question.answerSelections.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        }
    }

    outputTransformFn = (value: string | number | null | undefined): string =>
        // @ts-ignore
        value?.toString();

    onFrequencyChanged(event: Event, frequency: FinancialFrequency): void {
        if (this.isFileNotes) {
            return;
        }
        event.preventDefault();

        if (this.formGroup.controls.frequency.value === frequency) {
            this.formGroup.controls.frequency.setValue(null);
        } else {
            this.formGroup.controls.frequency.setValue(frequency);
        }
    }

    #expandPanelWhenEnteredValue(): void {
        const formValue = this.formGroup.value;
        const countEnteredValue = values(omit(formValue, ['financialQuestionId'])).filter(Boolean).length;
        if (countEnteredValue) {
            this.isExpand = true;
        }
    }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';

import {
  FormControl,
  FormGroupDirective,
  FormRecord,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { CommonModule, ViewportScroller } from '@angular/common';

import { catchError, combineLatest, EMPTY, tap } from 'rxjs';

import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import {
  chunk,
  fill,
  first,
  forEach,
  forOwn,
  isDate,
  max,
  orderBy,
} from 'lodash';
import { NgxMaskDirective } from 'ngx-mask';

import {
  Answer,
  AnswerType,
  Application,
  ApplicationGoal,
  ApplicationGoalSettings,
  ApplicationUser,
  ERROR_MESSAGE,
  GoalMetricsQuestion,
  GoalQuestion,
  mustBeInFuture,
} from '@acorn/util';

import {
  ApplicationAnswerService,
  ApplicationGoalService,
} from '@acorn/data-access';

import {
  AlertComponent,
  ClickableTooltipDirective,
  NavigationComponent,
} from '@acorn/common-ui';

import { GoalPart, GoalStepStructure } from '../../../util';
import { Helper } from '../../../helpers/helper';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  #isSubmitted: WritableSignal<boolean>;
  constructor(isSubmitted: WritableSignal<boolean>) {
    this.#isSubmitted = isSubmitted;
  }

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(control && control.invalid && this.#isSubmitted());
  }
}

@Component({
  selector: 'acorn-goal-metrics',
  templateUrl: 'goal-metrics.component.html',
  styleUrls: ['goal-metrics.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDividerModule,
    CommonModule,
    IconSpriteModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    NavigationComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    AlertComponent,
    ClickableTooltipDirective,
  ],
})
export class GoalMetricsComponent implements OnInit {
  #applicationGoalService = inject(ApplicationGoalService);
  #applicationAnswerService = inject(ApplicationAnswerService);
  #viewport = inject(ViewportScroller);
  #helper = inject(Helper);

  @Input({ required: true }) applicationGoal!: ApplicationGoalSettings;
  @Input({ required: true }) round!: GoalStepStructure;
  @Input({ required: true }) part!: GoalPart;
  @Input() applicationUsers: ApplicationUser[] = [];
  @Input() isDashboardApp: boolean = false;
  @Input({ required: true }) currentApplication!: Application;
  @Input() isFileNotes: boolean = false;
  @Input() isAppComplete = false;

  @Output() backClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();

  goalMetricsQuestions = signal<GoalMetricsQuestion[]>([]);
  renderedForm = signal(false);
  isLoading = signal(false);
  errorMessages = signal<string[]>([]);
  serverErrorMessage = signal<string>('');
  isSubmitted = signal(false);

  readonly AnswerType = AnswerType;

  formRecord: FormRecord<FormRecord<FormControl>> = new FormRecord({});
  answerTypeMapping = new Map<string, AnswerType>();
  matcher = new MyErrorStateMatcher(this.isSubmitted);

  #currentApplicationUserId = '';
  applicationId: any;

  ngOnInit() {
    this.#getApplicationUserId();

      combineLatest([
        this.#applicationGoalService.getCurrentQuestions(),
        this.#applicationAnswerService.getApplicationAnswer(
          this.#currentApplicationUserId
        ),
      ]).subscribe(([questionsResponse, answersResponse]) => {
        //Added by Kumar
        if (answersResponse.data.length <= 0) {
          localStorage.setItem('goalValue', 'null');
        } else {
          localStorage.removeItem('goalValue');
        }

        this.#defineGoalMetrics(
          questionsResponse.data || [],
          answersResponse.data || []
        );
      });
  }

  addMoreQuestion(
    formRecord: FormRecord<FormControl>,
    question: GoalQuestion
  ): void {
    if (!question.totalGroups) {
      question.totalGroups = [];
    }

    question.totalGroups.push(0);
    question.childrenQuestions.forEach((childQuestion) => {
      const suffix = question.totalGroups?.length
        ? question.totalGroups.length - 1
        : 0;
      this.#appendControlToFormRecord(
        formRecord,
        childQuestion,
        suffix,
        childQuestion.defaultValue || ''
      );
    });
  }

  onDeleteSubQuestion(
    goalId: string,
    question: GoalQuestion,
    index: number
  ): void {
    if (!question.totalGroups?.length) {
      return;
    }

    question.totalGroups.splice(index, 1);
    question.childrenQuestions.forEach((childQuestion) => {
      this.#removeControlFromFormRecord(goalId, childQuestion.id, index);
    });
  }

  onSubmit(): void {
    if(this.isFileNotes || this.isAppComplete){
      this.nextClicked.emit();
      return
    }

    this.formRecord.markAllAsTouched();
    this.isSubmitted.set(true);
    this.errorMessages.set([]);
    this.serverErrorMessage.set('');

    if (this.formRecord.invalid) {
      this.#getAllErrorMessages();
      this.#viewport.scrollToPosition([0, 0]);
      return;
    }

    localStorage.removeItem('goalValue');
    this.isLoading.set(true);

    this.#applicationAnswerService
      .saveApplicationAnswer(
        this.#getSelectedAnswers(),
        this.currentApplication.id
      )
      .pipe(
        tap(({ isSuccess, message }) => {
          this.isLoading.set(false);
          this.#helper.updateStatus(this.applicationUsers[0].applicationId);

          if (!isSuccess) {
            this.serverErrorMessage.set(
              message || ERROR_MESSAGE.INTERNAL_ERROR
            );
            this.#viewport.scrollToPosition([0, 0]);
            return;
          }

          if (
            !this.isDashboardApp &&
            this.currentApplication?.isLatestGoalBeingApplied !== true
          ) {
            this.updateGoalStep(this.applicationUsers[0].applicationId, () => {
              this.nextClicked.emit();
            });
          }else{
            this.nextClicked.emit();
          }
        }),
        catchError(() => {
          this.serverErrorMessage.set(ERROR_MESSAGE.INTERNAL_ERROR);
          this.#viewport.scrollToPosition([0, 0]);
          return EMPTY;
        })
      )
      .subscribe();
  }

  updateGoalStep(applicationId: string, onSuccess: any = undefined) {
    this.#applicationGoalService.updateGoalStatus(applicationId).subscribe(
      (res: any) => {
        if (res.isSuccess) {
          if (onSuccess) {
            window.dispatchEvent(new CustomEvent("yourEventType", {detail: true}));
            onSuccess();
          }
        }
      },
      (err: any) => {
        this.serverErrorMessage.set(ERROR_MESSAGE.INTERNAL_ERROR);
        this.#viewport.scrollToPosition([0, 0]);
        return EMPTY;
      }
    );
  }

  #defineGoalMetrics(
    questions: GoalMetricsQuestion[],
    answers: Answer[]
  ): void {
    if (!questions?.length) {
      return;
    }

    const mappingGoalMetricsQuestions = orderBy(questions, 'order').map(
      (goalMetricsQuestion) => {
        const { questions: subQuestions, goalId } = goalMetricsQuestion;

        const goalFormRecord = new FormRecord<FormControl>({});
        const convertedQuestions = orderBy(subQuestions, 'order').map(
          (question) => {
            if (
              question.isNumberOfItemQuestion &&
              question.childrenQuestions.length
            ) {
              let maxGroup = 0;
              question.childrenQuestions.forEach((childQuestion) => {
                const selectedAnswers = answers.filter(
                  (answer) =>
                    answer.questionId === childQuestion.id &&
                    answer.goalId === goalId
                );

                if (selectedAnswers.length > 0) {
                  selectedAnswers.forEach((selectedAnswer) => {
                    maxGroup =
                      max([maxGroup, selectedAnswer.group]) || maxGroup;

                    this.#appendControlToFormRecord(
                      goalFormRecord,
                      childQuestion,
                      selectedAnswer.group,
                      selectedAnswer.content || childQuestion.defaultValue
                    );
                  });
                } else {
                  this.#appendControlToFormRecord(
                    goalFormRecord,
                    childQuestion,
                    0,
                    childQuestion.defaultValue || ''
                  );
                }

                this.answerTypeMapping.set(
                  childQuestion.id,
                  childQuestion.answerType
                );
              });

              // initialize total groups to render UI
              const initTotalGroups = maxGroup + 1 || 1;
              question.totalGroups = fill(Array(initTotalGroups), 0);
            } else {
              const selectedAnswer = answers.find(
                (answer) =>
                  answer.questionId === question.id && answer.goalId === goalId
              );

              const formControl = new FormControl<string>(
                selectedAnswer?.content || question.defaultValue || '',
                this.#getValidatorsByAnswerType(question.answerType)
              );
              goalFormRecord.addControl(question.id, formControl);
              this.answerTypeMapping.set(question.id, question.answerType);
            }

            return question;
          }
        );

        this.formRecord.addControl(goalId, goalFormRecord);

        const goalName = this.applicationGoal.originalGoals.find(
          (goal) => goal.id === goalId
        )?.content;

        return {
          ...goalMetricsQuestion,
          goalName,
          questions: convertedQuestions,
        };
      }
    );

    this.goalMetricsQuestions.set(mappingGoalMetricsQuestions);
    this.renderedForm.set(true);
  }

  #getSelectedAnswers(): Answer[] {
    const answers: Answer[] = [];
    forOwn(this.formRecord.value, (formRecordValue, goalId) => {
      forOwn(formRecordValue, (answer: string | number, questionId) => {
        const convertedAnswer = isDate(answer)
          ? answer.toISOString()
          : answer?.toString();

        const [partIds, group] = chunk(questionId.split('-'), 5);

        answers.push({
          goalId,
          questionId: partIds.join('-'),
          content: convertedAnswer,
          applicationUserId: this.#currentApplicationUserId,
          group: group?.length > 0 ? parseInt(first(group!)!, 10) : 0,
        });
      });
    });

    return answers;
  }

  #appendControlToFormRecord(
    formRecord: FormRecord<FormControl>,
    question: GoalQuestion,
    suffix: number,
    value?: string
  ): void {
    const formControl = new FormControl<string>(
      value || '',
      this.#getValidatorsByAnswerType(question.answerType)
    );
    formRecord.addControl(`${question.id}-${suffix}`, formControl);
  }

  #removeControlFromFormRecord(
    goalId: string,
    questionId: string,
    suffix: number
  ): void {
    this.formRecord.controls[goalId].removeControl(`${questionId}-${suffix}`);
  }

  #getValidatorsByAnswerType(answerType: AnswerType): ValidatorFn[] {
    const validators = [Validators.required];

    if (answerType === AnswerType.Year) {
      validators.push(mustBeInFuture());
    }

    return validators;
  }

  #getAllErrorMessages(): void {
    const messages: string[] = [];
    forEach(this.formRecord.controls, (formRecord) => {
      forEach(formRecord.controls, (control, questionId) => {
        if (control.hasError('required')) {
          const removedIndexQuestionId = questionId.replace(/-[0-9]{1,2}$/, '');

          switch (this.answerTypeMapping.get(removedIndexQuestionId)) {
            case AnswerType.Amount: {
              messages.push('Amount is required');
              return;
            }

            case AnswerType.Number: {
              messages.push('Number is required');
              return;
            }

            case AnswerType.Percent: {
              messages.push('Percent is required');
              return;
            }

            case AnswerType.Year: {
              messages.push('Year is required');
              return;
            }
          }
        }

        if (control.hasError('invalidYear')) {
          messages.push(control.errors?.['invalidYear'].message);
        }

        if (control.hasError('pastYear')) {
          messages.push('Year must be not in the past');
        }
      });
    });

    this.errorMessages.set(messages);
  }

  #getApplicationUserId(): void {
    const applicationUserId = first(this.applicationUsers)?.id;

    if (!applicationUserId) {
      return;
    }

    this.#currentApplicationUserId = applicationUserId;
  }
}

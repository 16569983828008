<!-- <mat-card>
  <mat-card-content >
    <div class="header">
      <h5>Set up</h5>
      <h3>Who is this for?</h3>
      <p>
        Before we start, are you looking to create a plan for yourself or
        yourself and
        <span acornTooltip [tooltipMessage]="tooltip">someone else?</span>
      </p>
    </div>

    <acorn-alert *ngIf="errorMessage()" type="error">
      <b>{{ errorMessage() }}</b>
    </acorn-alert>

    <mat-radio-group color="primary" [(ngModel)]="applicationPlan">
      <mat-radio-button [value]="ApplicationPlan.ForYou">
        A plan for you
      </mat-radio-button>

      <mat-radio-button [value]="ApplicationPlan.ForYouAndOther">
        A plan for you and someone else
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<acorn-application-navigation
  [backButtonEnabled]="allowNavigateToHome"
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation> -->


<mat-card  [editable]="false">
  <mat-card-content [editable]="false">
    <div class="header" >
      <h5>Set up</h5>
      <h3>Who is this for?</h3>
      <p>
        Before we start, are you looking to create a plan for yourself or
        yourself and
        <span acornTooltip [tooltipMessage]="tooltip">someone else?</span>
      </p>
    </div>

    <acorn-alert *ngIf="errorMessage()" type="error">
      <b>{{ errorMessage() }}</b>
    </acorn-alert>

    <mat-radio-group color="primary" [(ngModel)]="applicationPlan"  [disabled]="isFileNotes || isAppComplete"  [ngClass]="{'disabled-select': isFileNotes || isAppComplete}">
      <mat-radio-button [value]="ApplicationPlan.ForYou">
        A plan for you
      </mat-radio-button>

      <mat-radio-button [value]="ApplicationPlan.ForYouAndOther">
        A plan for you and someone else
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<acorn-application-navigation
  [backButtonEnabled]="allowNavigateToHome"
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>

import { inject, Injectable } from "@angular/core";
import { ApplicationStrategyDocumentModelDto } from "../feature-wealth-strategy-options/utils/wealth-helper";
import { FeatureWealthStrategyOptionsService } from "../feature-wealth-strategy-options/data-access/feature-wealth-strategy-options.service";

@Injectable()

export class StrategyServiceHelper {
  applicationSvc = inject(FeatureWealthStrategyOptionsService);

  download(data:ApplicationStrategyDocumentModelDto , fileName:string) {
      this.applicationSvc.Download(data?.applicationId ,data).subscribe(
        (response) => {
            const blob = response.body;

            if (blob) {
              const link = document.createElement('a');
              const url = window.URL.createObjectURL(blob);

              link.href = url;
              link.download = fileName + '.pdf';
              link.click();

              window.URL.revokeObjectURL(url);
              link.remove();
            }
         },
        (error: any) => {
          console.error('Error Download', error);
        }
      );
  }
}

import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureWealthStrategyOptionsService } from '../../data-access/feature-wealth-strategy-options.service';
import { CustomerUser } from '@acorn/util';
import { riskToleranceList } from '../../utils/wealth-helper';
@Component({
  selector: 'acorn-wealth-strategy-options-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wealth-strategy-options-home.component.html',
  styleUrls: ['./wealth-strategy-options-home.component.scss'],
})
export class WealthStrategyOptionsHomeComponent {
  @Input() currentUser!: CustomerUser;
  @Input() applicationId: any;

  public value: any;
  public applicationUsers: any;
  public selectedRiskDescription: string | null = null;

  #wealthStrategyOptionsSvc = inject(FeatureWealthStrategyOptionsService);
  cd = inject(ChangeDetectorRef)

  ngOnInit(): void {
    this.applicationUsers = this.currentUser.currentApplication?.applicationUsers;

    if(this.applicationId){
      this.GetRiskTolerance();
    }
  }

  public GetRiskTolerance() {
    this.#wealthStrategyOptionsSvc.GetRiskTolerance(this.applicationId).subscribe(
      (res: any) => {
        this.value = res?.data?.value;
        this.cd.markForCheck()
        this.updateRiskToleranceDescription(res?.data?.value);
      },
      (err: any) => {
        console.error('Error fetching strategies calculation:', err);
      }
    );
  }

  private updateRiskToleranceDescription(value :string) {
    const applicationUserCount = this.applicationUsers?.length ?? 0;

    let type = '';

    if (applicationUserCount === 1) {
      if (value === 'Low') {
        type = 'single-low';
      } else if (value === 'Medium') {
        type = 'single-medium';
      } else if (value === 'High') {
        type = 'single-high';
      }
    } else if (applicationUserCount === 2) {
      if (value === 'Low') {
        type = 'household-low';
      } else if (value === 'Medium') {
        type = 'household-medium';
      } else if (value === 'High') {
        type = 'household-high';
      }
    }

    const riskDescription = riskToleranceList.find((item) => item.type === type);
    this.selectedRiskDescription = riskDescription ? riskDescription.description : 'Description not found';
  }

  getRiskLevel(value: string): number {
    switch(value) {
      case 'Low': return 1;
      case 'Medium': return 2;
      case 'High': return 3;
      default: return 0;
    }
  }
}

<mat-card>
  <mat-card-content>
    <div class="header">
      <h4>{{ parentCategory.name }}</h4>

      <h1>{{ currentCategory.name }}</h1>

      <p>{{ questions[0]!.aboutYouCategory.description }}</p>
    </div>

    <acorn-alert *ngIf="formRecord.invalid && isSubmitted()" type="error">
      <p class="alert-title">
        To proceed, {{ displayedTotalMessages() }} to be made
      </p>

      <ul *ngFor="let message of errorMessages()" class="alert-messages">
        <li>{{ message }}</li>
      </ul>
    </acorn-alert>

    <acorn-alert *ngIf="serverErrorMessage()" type="error">
      <b>{{ serverErrorMessage() }}</b>
    </acorn-alert>

    <div class="content">
      <mat-accordion
        *ngFor="
          let item of formRecord.controls | keyvalue : applicationUserOrder
        "
        class="person-card"
      >
        <mat-expansion-panel
          [expanded]="true"
          [disabled]="layoutService.screenSize() !== ScreenSize.Mobile"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ formTemplate[item.key].applicationUserName }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let question of questions">
            <div class="form-field-wrapper">
              <mat-label>
                {{
                  formTemplate[item.key].questions[question.questionId].content
                }}
              </mat-label>

              <mat-form-field
                class="small"
                appearance="outline"
                subscriptSizing="dynamic"
                
                [ngClass]="{'disabled-select': isFileNotes}"
                (mousedown)="isFileNotes ? $event.preventDefault() : null"
              >
                <mat-icon
                  matSuffix
                  *ngIf="!isFileNotes"
                  class="suffix-select-icon"
                  [class.triggered]="selectElement.panelOpen"
                  [class.disabled]="selectElement.disabled"
                >
                  keyboard_arrow_down
                </mat-icon>

                <mat-select
                  #selectElement
                  [hideSingleSelectionIndicator]="true"
                  [formControl]="item.value.controls[question.questionId]"
                  (selectionChange)="$event.source.close()"
                  [disabled]="isFileNotes"
                >
                  <mat-option
                    *ngFor="
                      let option of formTemplate[item.key].questions[
                        question.questionId
                      ].options
                    "
                    [value]="option.id"
                    [disabled]="isFileNotes"
                  >
                    {{ option.content }}
                  </mat-option>
                </mat-select>

                <mat-error
                  *ngIf="
                    isSubmitted() &&
                    item.value.controls[question.questionId].hasError(
                      'required'
                    )
                  "
                >
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#exclamation-solid'"
                    [width]="'24px'"
                  ></svg-icon-sprite>

                  {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>
import {
  AlertComponent,
  CheckboxComponent,
  NavigationComponent,
  TooltipDirective,
} from '@acorn/common-ui';

import { atLeastSelectedValidator } from '@acorn/feature-application';

import {
  AboutYouAnswerResponse,
  AboutYouQuestion,
  AboutYouUserAnswer,
  AnswerSelection,
  AnswerType,
  ERROR_MESSAGE,
  KOQuestionType,
  mustBeAdultAge,
  REPLACE_TEXT,
  SEPARATOR,
} from '@acorn/util';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Input,
  OnChanges,
  signal,
  SimpleChanges,
} from '@angular/core';

import {
  FormControl,
  FormRecord,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { forEach, union } from 'lodash';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskDirective } from 'ngx-mask';

import { filter } from 'rxjs';
import { KickoutDialogComponent, WarningDialogComponent } from '../../../ui';
import { ModalType } from '../../utils/enum/modal-type.enum';
import { getMessageBaseOnQuestionType } from '../../utils/get-message-base-on-question-type';

import { BaseAboutYouContentComponent } from '../base-about-you-content';

@Component({
  selector: 'acorn-common-answer',
  templateUrl: 'common-answer.component.html',
  styleUrls: [
    '../base-about-you-content/base-about-you-content.component.scss',
    'common-answer.component.scss',
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    IconSpriteModule,
    AlertComponent,
    CheckboxComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    NavigationComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CheckboxComponent,
    MatTooltipModule,
    TooltipDirective,
    NgxMaskDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
})
export class CommonAnswerComponent
  extends BaseAboutYouContentComponent
  implements OnChanges
{
  #dialog = inject(MatDialog);

  @Input({ required: true }) answerSelections: AnswerSelection[] = [];
  @Input({ required: true }) primaryQuestion!: AboutYouQuestion;
  @Input() isFileNotes: boolean = false;

  protected readonly AnswerType = AnswerType;
  protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

  selections = signal<AnswerSelection[]>([]);

  formRecord: FormRecord<FormControl<any>> = new FormRecord({});

  get tooltip() {
    return this.primaryQuestion?.question?.description;
  }

  get isCheckboxMax3() {
    return (
      this.primaryQuestion?.question?.answerType === AnswerType.CheckboxMax3
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['currentCategory'] ||
      changes['questions'] ||
      changes['primaryQuestion']
    ) {
      this.#calculateTemplate();
    }
  }

  onRadioChanged(): void {
    this.formRecord.markAsDirty();
  }

  onCheckboxChanged(
    formControl: FormControl,
    selectionId: string,
    checked: boolean,
    isNoneOfTheAbove: boolean
  ): void {
    formControl.markAsDirty();
    const noneSelection = this.selections().find(
      (selection) => selection.isNoneOfTheAbove
    );
    const existingNone = formControl.value.includes(noneSelection?.id);
    const currentValue = isNoneOfTheAbove
      ? []
      : existingNone
      ? []
      : formControl.value || [];

    if (checked) {
      formControl.setValue(union(currentValue, [selectionId]));
    } else {
      formControl.setValue(
        currentValue.filter((item: string) => item !== selectionId)
      );
    }
  }

  onSubmit(): void {
    if (this.isFileNotes) {
      this.next.emit();
      return;
    }

    this.formRecord.markAsDirty();
    this.isSubmitted.set(true);
    this.errorMessages.set([]);
    this.serverErrorMessage.set('');
    if (this.formRecord.invalid) {
      this.#getAllErrorMessages();
      return;
    }

    const userAnswers = this.#convertFormValueToUserAnswer();

    if (userAnswers.find((userAnswer) => userAnswer.isKOQuestion)) {
      const hasOneRespondentGotKnockout =
        this.isDualRespondent &&
        userAnswers.filter((userAnswer) => userAnswer.isKOQuestion).length ===
          1;

      this.openKnockoutDialog(hasOneRespondentGotKnockout);
      return;
    }

    if (userAnswers.find((userAnswer) => userAnswer.isShowWarning)) {
      let message = getMessageBaseOnQuestionType(
        this.primaryQuestion.question.questionType,
        ModalType.Warning,
        this.isDualRespondent
      );

      if (
        message?.includes(REPLACE_TEXT) &&
        this.primaryQuestion.question.koQuestionValue
      ) {
        const answers = userAnswers.find(
          (userAnswer) => userAnswer.isShowWarning
        );
        const optionIdsSelected =
          answers?.answers
            .find((it) => it.questionId === this.primaryQuestion.questionId)
            ?.content?.split(SEPARATOR) || [];
        const optionValuesSelected = this.answerSelections
          .filter((it) => optionIdsSelected.includes(it.id))
          .map((it) => it.content);

        const warningValues =
          this.primaryQuestion.question.koQuestionValue.split(SEPARATOR);
        const warningMessageValue = optionValuesSelected
          .filter((it) => warningValues.includes(it))
          .map(this.#firstLetterLowercase);

        message = message?.replace(
          REPLACE_TEXT,
          warningMessageValue.join(', ')
        );
      }

      this.#dialog
        .open(WarningDialogComponent, {
          autoFocus: false,
          data: {
            type: 'warning',
            messages: [message],
          },
        })
        .afterClosed()
        .pipe(filter((isContinue) => isContinue))
        .subscribe(() => this.updateAnswer(userAnswers));

      return;
    }

    this.updateAnswer(userAnswers);
  }

  openKnockoutDialog(hasOneRespondentGotKnockout: boolean) {
    const message = getMessageBaseOnQuestionType(
      this.primaryQuestion.question.questionType,
      ModalType.KnockOut,
      hasOneRespondentGotKnockout
    );

    this.#dialog.open(KickoutDialogComponent, {
      autoFocus: false,
      maxWidth: '90vw',
      data: {
        message: message ? [message] : '',
      },
    });
  }

  #calculateTemplate(): void {
    if (!this.answerSelections.length || !this.primaryQuestion?.questionId) {
      return;
    }

    this.isSubmitted.set(false);

    this.selections.set(
      this.answerSelections.filter(
        (answerSelection) =>
          answerSelection.questionId === this.primaryQuestion.questionId
      )
    );

    this.formRecord = new FormRecord({});

    for (const applicationUser of this.applicationUsers) {
      this.formTemplate[applicationUser.id] = {
        applicationUserId: applicationUser.id,
        applicationUserName: applicationUser.firstName,
        order: applicationUser.order,
      };

      const formControl: FormControl<string> = new FormControl(
        this.#getInitialControlValue(
          this.primaryQuestion.question.answerType,
          this.#getPrimaryAnswers(applicationUser.id)
        ),
        this.#getValidatorsByAnswerType(
          this.primaryQuestion.question.answerType,
          this.primaryQuestion.isRequired
        )
      );

      this.formRecord.addControl(applicationUser.id, formControl);

      if (!this.isDualRespondent) {
        break;
      }
    }
  }

  #getInitialControlValue(
    answerType: AnswerType,
    selectedAnswers: AboutYouAnswerResponse[]
  ): any {
    switch (answerType) {
      case AnswerType.CheckboxMax3:
      case AnswerType.Checkbox: {
        const selectedAnswer = selectedAnswers.find(
          (answer) => answer.questionId === this.primaryQuestion.questionId
        );

        if (!selectedAnswer?.answerContent) {
          return [];
        }

        return selectedAnswer.answerContent.split(SEPARATOR);
      }

      case AnswerType.Radio: {
        const selectedAnswer = selectedAnswers.find(
          (answer) => answer.questionId === this.primaryQuestion.questionId
        );

        const selected = this.selections().find(
          (selection) => selection.content === selectedAnswer?.answerContent
        );

        if (!selected) {
          return '';
        }

        return selected.id;
      }

      case AnswerType.AdultAge:
      case AnswerType.Text: {
        const selectedAnswer = selectedAnswers.find(
          (answer) => answer.questionId === this.primaryQuestion.questionId
        );

        return (
          selectedAnswer?.answerContent ||
          this.primaryQuestion.defaultValue ||
          ''
        );
      }
    }
  }

  #getPrimaryAnswers(applicationUserId: string): AboutYouAnswerResponse[] {
    if (!this.applicationUsers.length || !this.applicationAboutYou.length) {
      return [];
    }

    return (
      this.applicationAboutYou.find(
        (item) => item.applicationUserId === applicationUserId
      )?.answers || []
    );
  }

  #getValidatorsByAnswerType(answerType: AnswerType, isRequired: boolean) {
    // this is special case
    if (answerType === AnswerType.AdultAge) {
      return [mustBeAdultAge()];
    }

    if (!isRequired) {
      return [];
    }

    switch (answerType) {
      case AnswerType.Checkbox: {
        return [atLeastSelectedValidator(1)];
      }

      case AnswerType.CheckboxMax3: {
        return [atLeastSelectedValidator(1, 3)];
      }

      default: {
        return [Validators.required];
      }
    }
  }

  #convertFormValueToUserAnswer(): AboutYouUserAnswer[] {
    const userAnswers: AboutYouUserAnswer[] = [];

    forEach(this.formRecord.controls, (formRecord, applicationUserId) => {
      let answerContent = '';
      let answerOrder = 0;
      let isKOQuestion = false;
      let isShowWarning = false;

      switch (this.primaryQuestion.question.answerType) {
        case AnswerType.CheckboxMax3:
        case AnswerType.Checkbox: {
          answerContent = (formRecord.value as string[]).join(SEPARATOR);

          for (const answerId of formRecord.value as string[]) {
            const answer = this.selections().find(
              (item) => item.id === answerId
            );

            isShowWarning = isShowWarning || !!answer?.isShowWarning;
            isKOQuestion = this.#handlerKOQuestion(answer && answer.content);

            if (isKOQuestion) break;
          }
          break;
        }

        case AnswerType.AdultAge:
        case AnswerType.Text: {
          answerContent = formRecord.value;
          isKOQuestion = this.#handlerKOQuestion(answerContent);
          break;
        }

        default: {
          const selectedAnswer = this.selections().find(
            (selection) => selection.id === formRecord.value
          );

          answerContent = selectedAnswer?.content || '';
          answerOrder = selectedAnswer?.order || 0;
          isShowWarning = selectedAnswer?.isShowWarning || false;
          isKOQuestion = this.#handlerKOQuestion(answerContent);
          break;
        }
      }

      userAnswers.push({
        isKOQuestion,
        applicationUserId: applicationUserId,
        isShowWarning: isShowWarning,
        answers: [
          {
            content: answerContent || this.primaryQuestion.defaultValue || '',
            questionId: this.primaryQuestion.questionId,
            isRequired: this.primaryQuestion.isRequired,
            order: answerOrder,
          },
        ],
      });
    });

    return userAnswers;
  }

  #handlerKOQuestion(answerContent: string | undefined): boolean {
    if (this.primaryQuestion.question.isKOQuestion && answerContent) {
      switch (this.primaryQuestion.question.koQuestionType) {
        case KOQuestionType.Equal:
          return (
            answerContent === this.primaryQuestion.question.koQuestionValue
          );

        case KOQuestionType.OneOf: {
          const koQuestionValue =
            this.primaryQuestion.question.koQuestionValue.split(SEPARATOR);
          return koQuestionValue.includes(answerContent);
        }
      }
    }

    return false;
  }

  #getAllErrorMessages(): void {
    const messages: string[] = [];
    forEach(this.formRecord.controls, (control) => {
      if (control.hasError('required')) {
        messages.push(ERROR_MESSAGE.MAKE_A_SELECTION);
      }

      if (control.hasError('invalidAtLeast')) {
        if (this.isCheckboxMax3) {
          messages.push(ERROR_MESSAGE.SELECT_UP_TO_3);
        } else {
          messages.push(ERROR_MESSAGE.SELECT_MANY_OPTIONS);
        }
      }

      if (control.hasError('invalidAge')) {
        messages.push(ERROR_MESSAGE.ADULT_AGE);
      }
    });

    this.errorMessages.set(messages);
  }

  #firstLetterLowercase(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
}

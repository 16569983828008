import { Injectable, signal } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { map } from 'rxjs/operators';

import { ScreenSize } from '../enum';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  screenSize = signal<ScreenSize>(ScreenSize.Desktop);

  readonly #displayNameMap = new Map([
    [Breakpoints.XSmall, ScreenSize.Mobile],
    [Breakpoints.TabletPortrait, ScreenSize.Tablet],
    [Breakpoints.TabletLandscape, ScreenSize.Tablet],
    [Breakpoints.WebLandscape, ScreenSize.Desktop],
  ]);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Tablet,
        Breakpoints.WebLandscape,
      ])
      .pipe(
        map((result) => {
          const matchedKey = <string>(
            Object.keys(result.breakpoints).find(
              (key) => !!result.breakpoints[key]
            )
          );

          return this.#displayNameMap.get(matchedKey) as ScreenSize;
        })
      )
      .subscribe((screenSize: ScreenSize) => {
        this.screenSize.set(screenSize);
      });
  }
}

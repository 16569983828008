import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  BuildingBlockType,
  SubscriptionPlan,
  WealthHealthCategory,
  WealthHealthScore,
} from '@acorn/util';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserSubscriptionPlanService } from '@acorn/data-access';
import { WealthHealthPopupComponent } from '../wealth-health-popup/wealth-health-popup.component';

@Component({
  selector: 'acorn-wealth-happy-screen',
  standalone: true,
  imports: [CommonModule, MatExpansionModule,WealthHealthPopupComponent],
  templateUrl: './wealth-happy-screen.component.html',
  styleUrls: ['./wealth-happy-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WealthHappyScreenComponent {
  @Input({ required: true }) wealthHealthScore?: WealthHealthScore;
  @Input() isDashboardApp: boolean = false;
  #userSubscriptionPlanService = inject(UserSubscriptionPlanService);

  readonly categoryNameMapping = {
    [WealthHealthCategory.IndependenceAndLegacy]: 'Independence and Legacy',
    [WealthHealthCategory.Accumulation]: 'Wealth Accumulation',
    [WealthHealthCategory.Foundation]: 'Wealth Foundation',
  };

  readonly buildingBlockMapping = {
    [BuildingBlockType.Income]: 'Income',
    [BuildingBlockType.SavingsAndBudgeting]: 'Savings & Budgeting',
    [BuildingBlockType.TaxAndStructuring]: 'Tax & Structuring',
    [BuildingBlockType.WealthProtection]: 'Wealth Protection',
    [BuildingBlockType.SharesAndInvestments]: 'Shares & Investments',
    [BuildingBlockType.Super]: 'Super',
    [BuildingBlockType.Property]: 'Property',
    [BuildingBlockType.EstatePlanning]: 'Estate Planning',
    [BuildingBlockType.RetirementPlanning]: 'Retirement Planning',
  };

  subscriptionPlan = toSignal<SubscriptionPlan | undefined>(
    this.#userSubscriptionPlanService.userSubscriptionPlan$
  );
}

import {
  LocalStorageService,
  UserService,
  UserSubscriptionPlanService,
} from '@acorn/data-access';
import { CommonResponse, CustomerUser } from '@acorn/util';
import { environment } from '@env';
import {
  catchError,
  Observable,
  of,
  OperatorFunction,
  pipe,
  switchMap,
  tap,
} from 'rxjs';

import { ConfirmFsgStore } from '../../../store';
import { generateAuthenticationHeader } from '../../../store/generate-authentication-header.util';
import { SecurityService } from '../../data-access';
import { STORAGE_KEY } from '../constants';

interface Token {
  token: string;
  refreshToken: string;
}

const GOOGLE_TAG_MANAGER_ID = 'GTM-KB7FK6MH';

export function appInitializerFactory(
  securityService: SecurityService,
  localStorageService: LocalStorageService,
  userService: UserService,
  confirmFsgStore: ConfirmFsgStore,
  userSubscriptionPlanService: UserSubscriptionPlanService
): Observable<unknown> | void {
  {
    if (environment.production) {
      const scriptHotJar = generateScriptHotJar();
      const scriptTagManager = generateScriptGoogleTagManager();
      const noScriptGoogleTagManager = generateNoScriptGoogleTagManager();
      const head = window.document.getElementsByTagName('head')[0];

      head.appendChild(scriptTagManager);
      head.appendChild(scriptHotJar);
      window.document
        .getElementsByTagName('body')[0]
        .appendChild(noScriptGoogleTagManager);
    }

    const auth: Token | null = localStorageService.getData<Token>(
      STORAGE_KEY.authInfo
    );
    securityService.isAuthenticated.set(!!auth);

    if (auth) {
      return getCurrentUser(
        securityService,
        userService,
        confirmFsgStore,
        userSubscriptionPlanService,
        auth.token
      ).pipe(
        catchError((res) => {
          if (res.status === 401) {
            return refreshToken(
              securityService,
              localStorageService,
              userService,
              userSubscriptionPlanService,
              confirmFsgStore,
              auth
            );
          }

          securityService.handleRemoveToken();
          confirmFsgStore.setConfirmFSG(true);
          return of(null);
        })
      );
    }

    securityService.isAuthenticated.set(false);
  }
}

function getCurrentUser(
  securityService: SecurityService,
  userService: UserService,
  confirmFsgStore: ConfirmFsgStore,
  userSubscriptionPlanService: UserSubscriptionPlanService,
  token: string
): Observable<CommonResponse<CustomerUser> | null> {
  const newHeaders = generateAuthenticationHeader(token);

  return userService.getCurrentUser(newHeaders).pipe(
    tap((res) => {
      confirmFsgStore.setConfirmFSG(res.data.confirmedFSG);
      userSubscriptionPlanService.setSubscription(
        res.data.currentSubscription || undefined,
        res.data.currentSubscription?.subscriptionPlan
      );
      userService.setCurrentUser(res.data);
      securityService.isAuthenticated.set(true);
    })
  );
}

function refreshToken(
  securityService: SecurityService,
  localStorageService: LocalStorageService,
  userService: UserService,
  userSubscriptionPlanService: UserSubscriptionPlanService,
  confirmFsgStore: ConfirmFsgStore,
  auth: Token
): Observable<unknown> {
  return securityService.refreshToken(auth.token, auth.refreshToken).pipe(
    tap((refreshTokenResponse) =>
      localStorageService.saveData(
        STORAGE_KEY.authInfo,
        JSON.stringify({
          token: refreshTokenResponse.data.token,
          refreshToken: refreshTokenResponse.data.refreshToken,
        })
      )
    ),
    switchMap((refreshTokenResponse) =>
      getCurrentUser(
        securityService,
        userService,
        confirmFsgStore,
        userSubscriptionPlanService,
        refreshTokenResponse.data.token
      ).pipe(customCatchError(confirmFsgStore, securityService))
    ),
    customCatchError(confirmFsgStore, securityService)
  );
}

function customCatchError(
  confirmFsgStore: ConfirmFsgStore,
  securityService: SecurityService
): OperatorFunction<unknown, unknown> {
  return pipe(
    catchError(() => {
      securityService.handleRemoveToken();
      confirmFsgStore.setConfirmFSG(true);
      return of(null);
    })
  );
}

function generateScriptGoogleTagManager(): HTMLScriptElement {
  const scriptTagManager = window.document.createElement('script');
  scriptTagManager.type = 'text/javascript';
  scriptTagManager.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
    `;

  return scriptTagManager;
}

function generateNoScriptGoogleTagManager(): HTMLScriptElement {
  const scriptTagManager = window.document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.setAttribute(
    'src',
    `https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`
  );
  iframe.style.width = '0';
  iframe.style.height = '0';
  scriptTagManager.appendChild(iframe);

  return <HTMLScriptElement>scriptTagManager;
}

function generateScriptHotJar(): HTMLScriptElement {
  const scriptHotJar = window.document.createElement('script');
  scriptHotJar.type = 'text/javascript';
  scriptHotJar.innerHTML = `
      (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3723423,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

  return scriptHotJar;
}

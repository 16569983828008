import { LocalStorageService } from '@acorn/data-access';
import { CommonResponse } from '@acorn/util';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { environment } from '@env';

import { STORAGE_KEY } from '../utils';

@Injectable({ providedIn: 'root' })
export class SecurityService {
  #httpClient = inject(HttpClient);

  #localStorageService = inject(LocalStorageService);

  isAuthenticated = signal<boolean>(false);

  signOut(token: string) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.identity}/api/Account/logout`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
  }

  refreshToken(
    token: string,
    refreshToken: string
  ) {
    return this.#httpClient.post<
      CommonResponse<{ token: string; refreshToken: string }>
    >(`${environment.apiUrls.identity}/api/Account/refresh-token`, {
      token,
      refreshToken,
    });
  }

  handleAddToken(data: any): void {
    const token = data.token;
    const refreshToken = data.refreshToken;
    
    this.#localStorageService.saveData(
      STORAGE_KEY.authInfo,
      JSON.stringify({ token, refreshToken })
    );
  }

  handleUpdateToken(token: string, refreshToken: string): void {
    this.#localStorageService.saveData(
      STORAGE_KEY.authInfo,
      JSON.stringify({ token, refreshToken })
    );
  }

  handleRemoveToken(): void {
    this.#localStorageService.removeData(STORAGE_KEY.authInfo);
    this.isAuthenticated.set(false);
  }
}

import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import {  ApplicationStep } from '@acorn/util';

@Component({
  selector: 'acorn-error-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent {
  @Output() tryAgain = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  
  onClose() {
    this.close.emit();
  }

  retry() {
    this.tryAgain.emit();
  }
}
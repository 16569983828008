import { RenderNode } from '@contentful/rich-text-html-renderer';

export interface BaseContentFul {
  description: string;
  image: ImageContent;
  order: string;
  imageUrl: string;
  title: string;
}

export interface ImageContent {
  fields: {
    file: {
      url: string;
    };
  };
}

export interface Options {
  renderNode?: RenderNode;
}

export interface HyperlinkType {
  uri: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import { DependantFormTemplate } from '../interfaces';

@Pipe({
  name: 'acornDisplayDependantTitle',
  standalone: true,
})
export class DisplayDependantTitlePipe implements PipeTransform {
  transform(
    value: { [key: string]: DependantFormTemplate },
    applicationUserId: string,
    questionId: string
  ): string {
    return value[applicationUserId].questions[questionId].answerTitle;
  }
}

import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ApplicationGoalService,
    WealthHealthService,
} from '@acorn/data-access';
import { WealthHealthCategory, WealthHealthScore } from '@acorn/util';
import { FormsModule } from '@angular/forms';
import { StrategyActionPlanService } from '../../data-access/strategy-action-plan.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { GoalSuccessPopupComponent } from '../popup/popup.component';

@Component({
    selector: 'acorn-wealth-health',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './wealth-health.component.html',
    styleUrls: ['./wealth-health.component.scss'],
})
export class WealthHealthComponent implements OnInit {
    @Input({ required: true }) applicationId!: string;
    @Output() learningHub = new EventEmitter<boolean>();
    @Input({ required: true }) public set selectedStrategy(value: any) {
        this._selectedStrategy = value;

        if (this._selectedStrategy) {
            this.getGoals(this._selectedStrategy?.strategyId);
        }
    };

    #strategyActionPlanSvc = inject(StrategyActionPlanService);
    #wealthHealthService = inject(WealthHealthService);
    #applicationGoalSvc = inject(ApplicationGoalService);
    #toastrService = inject(ToastrService);
    #dialog = inject(MatDialog);
    cd = inject(ChangeDetectorRef);
    _selectedStrategy: any;

    goals: Array<any> = [];
    sampleGoals: Array<any> = [
        { goalId: 1, goalName: 'Start or expand a share portfolio', selectedGoal: true },
        { goalId: 2, goalName: 'Prepare for a major celebration (e.g. wedding)', selectedGoal: false },
        { goalId: 3, goalName: 'Increase savings over time', selectedGoal: false },
        { goalId: 4, goalName: 'Invest in an investment property', selectedGoal: false },
        { goalId: 5, goalName: 'Pay off mortgage', selectedGoal: false }
    ];

    public learningHubData: any[] = [
        {
            title: 'Budgets don’t work but these things do',
            url: 'https://www.wealthmaximiser.com.au/media/budgets-dont-work-but-these-things-do/',
            type: 'Blog',
            image: 'assets/learning-hub/budgets-dont-work-but-these-things-do.jpg'
        },
        {
            title: 'Psychology of Money',
            url: 'https://www.wealthmaximiser.com.au/media/psychology-of-money/',
            type: 'Blog',
            image: 'assets/learning-hub/psychology-of-money.jpg'
        },
    ]

    wealthHealthScore!: WealthHealthScore;
    strategyId!: string;

    readonly categoryNameMapping = {
        [WealthHealthCategory.IndependenceAndLegacy]: 'Independence and Legacy',
        [WealthHealthCategory.Accumulation]: 'Wealth Accumulation',
        [WealthHealthCategory.Foundation]: 'Wealth Foundation',
    };
    model: any;

    ngOnInit(): void {
        this.#loadWealthHealthScoreData();

        if (this._selectedStrategy) {
            this.getGoals(this._selectedStrategy?.strategyId);
        }
    }

    public getGoals(strategyId: any) {
        this.#applicationGoalSvc
            .getApplicationGoal(this.applicationId, strategyId)
            .subscribe(
                (res: any) => {
                    this.goals = res.data;
                    this.cd.markForCheck();
                    this.cd.detectChanges();
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }

    public updateGoal(event: any, goalId: string) {
        const checked = (event.target as HTMLInputElement).checked;
        this.#applicationGoalSvc
            .saveApplicationGoal(this.applicationId, goalId, checked)
            .subscribe(
                (res: any) => {
                    if (checked) {
                        this.open();
                        setTimeout(() => {
                            this.model.close();
                        }, 3000);
                    } else {
                        this.#toastrService.success(
                            `Goal removed successfully`,
                            'Success'
                        );
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }

    #loadWealthHealthScoreData(): void {
        this.#wealthHealthService.getScore().subscribe(
            (response: any) => {
                const { data, isSuccess } = response;

                if (!isSuccess || !data) {
                    return;
                }

                this.wealthHealthScore = data;
            },
            (error: any) => {
                console.error('Error loading wealth health score:', error);
            }
        );
    }

    public open() {
        this.model = this.#dialog.open(GoalSuccessPopupComponent, {
            width: 'auto',
            disableClose: true,
            hasBackdrop: true,
        });
    }

    public openLink(item:any){
        window.open(item.url ,"_blank")
    }
}

import {
  AlertComponent,
  NavigationComponent,
  TooltipDirective,
} from '@acorn/common-ui';

import {
  AboutYouAnswer,
  AboutYouUserAnswer,
  AnswerType,
  mustBeAdultAge,
} from '@acorn/util';

import { CommonModule, KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

import {
  FormArray,
  FormControl,
  FormRecord,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { fill, forEach, forIn, isNil, orderBy } from 'lodash';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskDirective } from 'ngx-mask';
import {
  DisplayDependantNamePipe,
  DisplayDependantTitlePipe,
} from '../../utils';

import { DependantFormTemplate } from '../../utils/interfaces';

import { BaseAboutYouContentComponent } from '../base-about-you-content';

@Component({
  selector: 'acorn-dependants',
  templateUrl: 'dependants.component.html',
  styleUrls: ['dependants.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    IconSpriteModule,
    AlertComponent,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    NavigationComponent,
    ReactiveFormsModule,
    DisplayDependantTitlePipe,
    DisplayDependantNamePipe,
    NgxMaskDirective,
    MatDatepickerModule,
    TooltipDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
})
export class DependantsComponent
  extends BaseAboutYouContentComponent
  implements OnInit
{
  @ViewChildren(MatAccordion) accordions!: QueryList<MatAccordion>;
  @Input() isFileNotes : boolean = false;

  protected readonly AnswerType = AnswerType;
  protected readonly AgeList = fill(Array(19), 0).map((_, index) =>
    index.toString()
  );

  phoneResponsive = 599.98;

  override formTemplate: { [key: string]: DependantFormTemplate } = {};

  formRecord: FormRecord<FormRecord<FormArray<FormControl<string | null>>>> =
    new FormRecord({});

  dependantOrder = (
    a: KeyValue<string, unknown>,
    b: KeyValue<string, unknown>
  ): number => {
    const value = this.formTemplate[Object.keys(this.formTemplate)[0]];
    const objectA = value?.questions
      ? (value?.questions[a.key] as { order: number })
      : { order: 0 };
    const objectB = value?.questions
      ? (value?.questions[b.key] as { order: number })
      : { order: 0 };

    return objectA.order > objectB.order ? 1 : 0;
  };

  ngOnInit() {
    this.applicationUsers.forEach((applicationUser) => {
      const applicationUserFormRecord = new FormRecord<
        FormArray<FormControl<any>>
      >({});

      const formTemplate: DependantFormTemplate = {
        applicationUserId: applicationUser.id,
        applicationUserName: applicationUser.firstName,
        order: applicationUser.order,
        questions: {},
      };

      const savedAnswers =
        this.applicationAboutYou.find(
          (item) => item.applicationUserId === applicationUser.id
        )?.answers || [];

      orderBy(this.questions, 'order').forEach((question) => {
        const selectedAnswers = savedAnswers.filter(
          (item) => item.questionId === question.questionId
        );

        const formArray: FormArray<FormControl<string | null>> =
          new FormArray<FormControl>([]);

        selectedAnswers.forEach((answer) => {
          if (answer.multiAnswerItems && answer.multiAnswerItems.length > 1) {
            orderBy(answer.multiAnswerItems, 'order').forEach((answerItem) => {
              const answerContent = isNil(answerItem?.content)
                ? ''
                : answerItem.content;

              if (answerContent) {
                const validators =
                  question.question.answerType === AnswerType.AdultAge
                    ? [mustBeAdultAge()]
                    : [];

                formArray.push(
                  new FormControl<string | null>(answerContent, validators)
                );
              }
            });
          } else if (answer.answerContent) {
            const validators =
              question.question.answerType === AnswerType.AdultAge
                ? [mustBeAdultAge()]
                : [];

            formArray.push(
              new FormControl<string | null>(answer.answerContent, validators)
            );
          }
        });

        applicationUserFormRecord.addControl(question.questionId, formArray);
        formTemplate.questions[question.questionId] = {
          answerTitle: question.multiAnswerQuestionTitle,
          dependantName: question.question.content,
          question: question.question.content,
          description: question.question.description,
          answerType: question.question.answerType,
          order: question.order,
          isRequired: question.isRequired,
        };
      });

      this.formRecord.addControl(applicationUser.id, applicationUserFormRecord);
      this.formTemplate[applicationUser.id] = formTemplate;
    });
  }

  addMore(applicationUserId: string, questionId: string): void {
    const currentQuestionControl =
      this.formRecord.controls[applicationUserId].controls[questionId];

    const currentQuestion = this.questions.find(
      (question) => question.questionId === questionId
    );

    if (!currentQuestionControl || !currentQuestion) {
      return;
    }

    const validators =
      currentQuestion.question.answerType === AnswerType.AdultAge
        ? [mustBeAdultAge()]
        : [];
    const formControl = new FormControl<string>('', validators);
    currentQuestionControl.push(formControl);
  }

  onRemove(
    applicationUserId: string,
    questionId: string,
    removedIndex: number
  ): void {
    const currentQuestionControl =
      this.formRecord.controls[applicationUserId].controls[questionId];

    if (!currentQuestionControl) {
      return;
    }

    currentQuestionControl.removeAt(removedIndex);
  }

  onSubmit(): void {
 if (this.isFileNotes) {
   this.next.emit();
   return;
 }

    this.formRecord.markAsDirty();
    this.isSubmitted.set(true);
    this.errorMessages.set([]);
    this.serverErrorMessage.set('');

    if (this.formRecord.invalid) {
      this.#getAllErrorMessages();
      return;
    }

    this.updateAnswer(this.#convertFormValueToUserAnswer());
  }

  #convertFormValueToUserAnswer(): AboutYouUserAnswer[] {
    const userAnswers: AboutYouUserAnswer[] = [];

    forEach(this.formRecord.controls, (formRecord, applicationUserId) => {
      const selectedApplicationUser = this.formTemplate[applicationUserId];

      const aboutYouAnswers: AboutYouAnswer[] = [];
      forIn(formRecord.controls, (formArray, questionId) => {
        aboutYouAnswers.push({
          questionId,
          content: '',
          isRequired: selectedApplicationUser.questions[questionId].isRequired,
          isMultiAnswerQuestion: !!formArray.controls.length,
          order: selectedApplicationUser.questions[questionId].order,
          multiAnswerItems: (formArray.value || []).map((answer, index) => ({
            order: index,
            content: answer || '',
          })),
        });
      });

      userAnswers.push({
        applicationUserId: applicationUserId,
        answers: aboutYouAnswers,
      });
    });

    return userAnswers;
  }

  #getAllErrorMessages(): void {
    const messages: string[] = [];
    forEach(this.formRecord.controls, (formRecord) => {
      if (formRecord.invalid) {
        forEach(formRecord.controls, (formArray) => {
          if (formArray.invalid) {
            forEach(formArray.controls, (formControl) => {
              if (formControl.hasError('invalidAge')) {
                messages.push('Age is greater than or equal to 18 years');
              }
            });
          }
        });
      }
    });

    this.errorMessages.set(messages);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if ((event.target as Window).innerWidth > this.phoneResponsive) {
      this.accordions.forEach((accordion) => accordion.openAll());
    }
  }
}

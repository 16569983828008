<ng-container>
  <acorn-vertical-stepper
    #cdkStepper
    [selectedIndex]="currentStepStructure()!.order - 1"
    (selectedIndexChange)="onSelectStepChanged($event)"
  >
    <cdk-step
      *ngFor="let step of stepStructures()"
      [label]="step.label"
      [state]="step.state || ''"
    >
      <acorn-nested-vertical-stepper
        [selectedIndex]="
          (currentPartStructure()?.order ?? 0) <= (step.parts?.length || 0)
            ? 0
            : 0
        "
        (selectedIndexChange)="onSelectPartChanged($event, step.id)"
      >
        <cdk-step
          *ngFor="let part of step.parts"
          [label]="part.label"
          [state]="part.state || ''"
        >
        </cdk-step>
      </acorn-nested-vertical-stepper>
    </cdk-step>
  </acorn-vertical-stepper>

  <div class="step__body" [ngSwitch]="currentStepStructure()?.id">
    <ng-container *ngSwitchCase="GoalStep.GoalSetting">
      <acorn-goal-settings
        [currentApplication]="currentApplication"
        [partStep]="goalCurrentPartstep()!"
        [applicationGoal]="applicationGoal"
        [applicationUsers]="applicationUsers"
        (finish)="onFinishGoalSettings($event)"
        (backClicked)="onBack()"
        (updatedProgress)="updateCurrentProgress($event)"
        [isFileNotes]="isFileNotes"
      >
      </acorn-goal-settings>
    </ng-container>

    <ng-container *ngSwitchCase="GoalStep.RoundVerify">
      <acorn-confirm-and-prioritise
        [goals]="goalsTemplate()"
        (nextClicked)="onHandleNext($event)"
        (backClicked)="onHandleBack()"
        [isFileNotes]="isFileNotes"
      ></acorn-confirm-and-prioritise>
    </ng-container>

    <ng-container *ngSwitchCase="GoalStep.GoalMetrics">
      <acorn-goal-metrics
        [currentApplication]="currentApplication"
        [isDashboardApp]="isDashboardApp"
        [applicationGoal]="applicationGoal"
        [round]="currentStepStructure()!"
        [part]="currentPartStructure()!"
        [applicationUsers]="applicationUsers"
        (backClicked)="onHandleBack()"
        (nextClicked)="onFinishStep()"
        [isFileNotes]="isFileNotes"
      >
      </acorn-goal-metrics>
    </ng-container>
  </div>
</ng-container>

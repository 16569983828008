<div class="container is-fluid gradient-bg pd-40">
    <div class="container">
        <div class="row">
            <div class="columns">
                <div class="col-20"></div>
                <div class="col-60">
                    <h3>
                        What's Next
                    </h3>
                    <p class="mb-20">
                        Your Wealth Coach will help you determine which strategy is best for you, and then talk you through your personalised action plan, 
                        Statement of Advice and your dashboard in your Strategy Session.
                    </p>

                    <p class="mb-20">
                        If you select next you can see your empty dashboard - 
                        this will be fully loaded after your Strategy Session with your Wealth Coach.
                    </p>
                    <p>Contact <b>support@wealthmaximiser.com.au</b> if you have any questions.</p>
                </div>
                <div class="col-20"></div>
            </div>
        </div>
    </div>
</div>
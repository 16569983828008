import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonComponent } from '@acorn/common-ui';
import { LayoutService, ScreenSize } from '@acorn/util';

@Component({
  selector: 'acorn-share-social-button',
  standalone: true,
  imports: [
    CommonModule,
    IconSpriteModule,
    MatButtonModule,
    ButtonComponent,
    MatMenuModule,
    RouterLink,
    ClipboardModule,
  ],
  templateUrl: './share-social-button.component.html',
  styleUrls: ['./share-social-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareSocialButtonComponent {
  layoutService = inject(LayoutService);

  readonly location = location;

  get isMobile() {
    return this.layoutService.screenSize() === ScreenSize.Mobile;
  }

  shareIOS(): void {
    if (this.isMobile && navigator.share) {
      navigator
        .share({
          title: 'Web Share API Draft',
          text: 'Take a look at this spec!',
          url: location.href,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }
}

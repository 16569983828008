<mat-card>
  <mat-card-content>
    <div class="header">
      <h5>Set up</h5>

      <h3>Your details</h3>

      <p *ngIf="applicationPlan === ApplicationPlan.ForYouAndOther">
        What would you like us to call you?
      </p>
    </div>

    <acorn-alert *ngIf="hasError()" type="error">
      <b>{{ hasError() }}</b>
    </acorn-alert>

    <form
      *ngIf="
        applicationPlan === ApplicationPlan.ForYou;
        else dualPersonsTemplate
      "
      class="single-form"
      [formGroup]="forYouForm"
    >
      <div class="form-field-wrapper">
        <mat-label>Your full name</mat-label>

        <mat-form-field
          appearance="outline"
          class="small"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            type="text"
            formControlName="firstName"
            placeholder="Enter full name"
            [disabled]="isFileNotes || isAppComplete"
          />

          <mat-error *ngIf="isSubmitted()">
            <ng-container
              *ngIf="forYouForm.controls.firstName.hasError('minlength')"
            >
              {{ ERROR_MESSAGE.YOUR_FIRST_NAME_IS_TOO_SHORT }}
            </ng-container>

            <ng-container
              *ngIf="forYouForm.controls.firstName.hasError('required')"
            >
              {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field-wrapper">
        <mat-label>Date of birth</mat-label>

        <mat-form-field appearance="outline" class="small">
          <input
            matInput
            placeholder="Enter date of birth"
            formControlName="dateOfBirth"
            [matDatepicker]="datepicker"
            [max]="dateOfBirthValidation"
            [disabled]="isFileNotes || isAppComplete"
          />

          <mat-datepicker-toggle matIconSuffix [for]="datepicker">
            <svg-icon-sprite
              matDatepickerToggleIcon
              [src]="'assets/sprites/sprite.svg#calendar-line'"
              [width]="'22px'"
            ></svg-icon-sprite>
          </mat-datepicker-toggle>

          <mat-datepicker #datepicker></mat-datepicker>

          <mat-error
            *ngIf="
              isSubmitted() &&
              forYouForm.controls.dateOfBirth.hasError('required')
            "
          >
            {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>

<ng-template #dualPersonsTemplate>
  <div class="dual-persons">
    <mat-card class="person-card">
      <h3>Person 1</h3>

      <form [formGroup]="forYouForm">
        <div class="form-field-wrapper">
          <mat-label>Full name</mat-label>

          <mat-form-field
            appearance="outline"
            class="small"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              type="text"
              formControlName="firstName"
              placeholder="Enter full name"
              [disabled]="isFileNotes || isAppComplete"
            />

            <mat-error *ngIf="isSubmitted()">
              <ng-container
                *ngIf="forYouForm.controls.firstName.hasError('minlength')"
              >
                {{ ERROR_MESSAGE.YOUR_FIRST_NAME_IS_TOO_SHORT }}
              </ng-container>

              <ng-container
                *ngIf="forYouForm.controls.firstName.hasError('required')"
              >
                {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-wrapper">
          <mat-label>Date of birth</mat-label>

          <mat-form-field
            appearance="outline"
            class="small"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              placeholder="Enter date of birth"
              formControlName="dateOfBirth"
              [matDatepicker]="datepicker"
              [max]="dateOfBirthValidation"
              [disabled]="isFileNotes || isAppComplete"
            />

            <mat-datepicker-toggle matIconSuffix [for]="datepicker">
              <svg-icon-sprite
                matDatepickerToggleIcon
                [src]="'assets/sprites/sprite.svg#calendar-line'"
                [width]="'22px'"
              ></svg-icon-sprite>
            </mat-datepicker-toggle>

            <mat-datepicker #datepicker></mat-datepicker>

            <mat-error
              *ngIf="
              isSubmitted() &&
              forYouForm.controls.dateOfBirth.hasError('required')
            "
            >
              {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card>

    <mat-card class="person-card">
      <h3>Person 2</h3>

      <form [formGroup]="someoneElseForm">
        <div class="form-field-wrapper">
          <mat-label>Full name</mat-label>

          <mat-form-field
            appearance="outline"
            class="small"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              type="text"
              formControlName="firstName"
              placeholder="Enter full name"
              [disabled]="isFileNotes || isAppComplete"
            />

            <mat-error *ngIf="isSubmitted()">
              <ng-container
                *ngIf="someoneElseForm.controls.firstName.hasError('minlength')"
              >
                {{ ERROR_MESSAGE.YOUR_FIRST_NAME_IS_TOO_SHORT }}
              </ng-container>

              <ng-container
                *ngIf="someoneElseForm.controls.firstName.hasError('required')"
              >
                {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-wrapper">
          <mat-label>Date of birth</mat-label>

          <mat-form-field
            appearance="outline"
            class="small"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              placeholder="Enter date of birth"
              formControlName="dateOfBirth"
              [matDatepicker]="partnerDatepicker"
              [max]="dateOfBirthValidation"
              [disabled]="isFileNotes || isAppComplete"
            />

            <mat-datepicker-toggle matIconSuffix [for]="partnerDatepicker">
              <svg-icon-sprite
                matDatepickerToggleIcon
                [src]="'assets/sprites/sprite.svg#calendar-line'"
                [width]="'22px'"
              ></svg-icon-sprite>
            </mat-datepicker-toggle>

            <mat-datepicker #partnerDatepicker></mat-datepicker>

            <mat-error
              *ngIf="
              isSubmitted() &&
              someoneElseForm.controls.dateOfBirth.hasError('required')
            "
            >
              {{ ERROR_MESSAGE.FIELD_IS_REQUIRED }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card>
  </div>
</ng-template>

<svg-icon-sprite
  [src]="'assets/sprites/sprite.svg#question-sign'"
  [width]="'28px'"
></svg-icon-sprite>

<h3>{{ dialogData.title }}</h3>

<p>{{ dialogData.message }}</p>

<button mat-flat-button color="primary" (click)="dialogRef.close(true)">
  Confirm
</button>

<button mat-stroked-button (click)="dialogRef.close(false)">Cancel</button>

<mat-divider></mat-divider>

<div class="grid quick_links_light">
  <img src="assets/images/logo-dark.svg" alt="" />

  <h3>© {{ currentYear }} NobleOak</h3>

  <ul>
    <li><a href="https://wealthmaximiser.com.au/">Home</a></li>

    <li><a href="https://wealthmaximiser.com.au/about-us">About Us</a></li>

    <!--<li *ngIf="securityService.isAuthenticated()" routerLink="/pricing">
      Pricing
    </li>-->

    <li><a href="https://wealthmaximiser.com.au/pricing">Pricing</a></li>

    <li><a href="https://www.wealthmaximiser.com.au/financial-services-guide/">Financial Services Guide</a></li>

    <li><a href="https://www.wealthmaximiser.com.au/terms-and-conditions/">Terms & Conditions</a></li>

    <li><a href="https://www.wealthmaximiser.com.au/privacy-policy/">Privacy Policy</a></li>
  </ul>
</div>

import {
  Goal,
  GoalSettingPartsStep,
  GoalSettingPartStep,
  GoalSettingStep,
  GoalStep,
  PartStep,
} from '@acorn/util';
import { StepState } from '@angular/cdk/stepper';

export interface StepStructure {
  id: GoalStep;
  order: number;
  label: string;
  state: StepState;
  maximumRequiredGoals: number;
  minimumRequiredGoals: number;
  parts: PartStructure[];
}

export interface PartStructure {
  id: PartStep;
  order: number;
  label: string;
  stepperLabel?: string;
  state: StepState;
  description: string;
  isLastPart?: boolean;
  isFirstPart?: boolean;
  isHidden?: boolean;
}

export interface GoalQuestion {
  name: string;
  questions: Question[];
  goalType?: 'multiple';
}

export interface Question {
  name: string;
  subQuestions?: Question[];
  type: 'amount' | 'date' | 'array';
}

export interface GoalStepStructure1 {
  id: GoalSettingPartStep;
  order: number;
  label: string;
  state: StepState;
  icon: any;
  Description: string;
  maximumRequiredGoals: number;
  minimumRequiredGoals: number;
  isLastStep: boolean;
  goals?: Goal[];
  selected: { id: string; order: number }[];
}

export interface GoalPart {
  id: GoalSettingPartsStep;
  label: string;
  order: number;
  isFirstPart?: boolean;
  isLastPart?: boolean;
  description?: string;
  icon?: string;
  state?: string;
}

export interface GoalStepStructure {
  id: GoalSettingStep;
  order: number;
  state?: string;
  maximumRequiredGoals: number;
  minimumRequiredGoals: number;
  label: string;
  parts: GoalPart[];
}

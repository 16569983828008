<div class="container">
  <div class="card-wrapper">
  <div class="wealth-popup" *ngIf="popup1">
    <img src="assets/icons/close.svg" class="close-btn" (click)="this.popup1 = !this.popup1">
    <div>
      <span class="wealth-header">What is a Wealth Health score?</span>
    </div>
    <img src="assets/icons/icon.svg" class="action-btn">
    <p class="wealth-text">A Wealth Health score is a high level metric (out of 10) that measures your overall financial well-being and health
      in relation to the nine wealth Building Blocks. It provides an evaluation of your financial situation and helps
      identify areas of strength and areas that may need improvement. It is another important input into your Wealth
      Maximiser strategy.</p>
  </div>
  <div class="wealth-popup" *ngIf="popup2">
    <span><img src="assets/icons/close.svg" class="close-btn" (click)="this.popup2 = !this.popup2"></span>
    <div>
      <span class="wealth-header">How do I improve my Wealth Health Score?</span>
    </div>
    <img src="assets/icons/icon.svg"  class="action-btn">
    <p class="wealth-text">We have identified the key action steps and strategies necessary to increase your Wealth Health Score. These
      recommendations are part of our Wealth Maximiser advice offering. Click next to proceed with your preferred plan.
    </p>
  </div>
  </div>
</div>
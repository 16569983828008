import { Pipe, PipeTransform } from '@angular/core';

import { MaritalStatus } from '../enum';

@Pipe({
  name: 'maritalStatus',
  standalone: true,
})
export class MaritalStatusPipe implements PipeTransform {
  transform(value: MaritalStatus) {
    switch (value) {
      case MaritalStatus.Single: {
        return 'Single';
      }

      case MaritalStatus.Divorced: {
        return 'Divorced';
      }

      case MaritalStatus.Widowed: {
        return 'Widowed';
      }

      case MaritalStatus.Separation: {
        return 'Going through a separation';
      }

      case MaritalStatus.Married: {
        return 'Married/in a civil partnership';
      }

      case MaritalStatus.RelationshipWithSharedFinances: {
        return 'In a relationship with shared finances';
      }

      case MaritalStatus.RelationshipWithSeparateFinances: {
        return 'In a relationship with separate finances';
      }

      default: {
        return '';
      }
    }
  }
}

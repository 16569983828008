<div class="modal-overlay">
  <div class="modal-container">
    <div class="modal-header mt-1 mb-1">
      <img src="assets/images/d-question-sign.svg" alt="Question Icon">
    </div>

    <div class="modal-title mb-1">
      <h6> Are you sure you want to request the ability to edit your details?</h6>
    </div>

    <div class="modal-content">
     <p>Once your request is approved you will lose access to your dashboard and be required to update your details. Your Wealth Coach will then review your strategy and action plan to see if they are still appropriate, before you can see your dashboard again.</p>
    </div>

    <button class="button button-confirm" (click)="onConfirm()">Confirm</button>
    <button class="button button-cancel" (click)="onCancel()">Cancel</button>
  </div>
</div>

import { inject, Injectable } from "@angular/core";
import { ApplicationStrategyDocumentModelDto } from "../feature-wealth-strategy-options/utils/wealth-helper";
import { FeatureWealthStrategyOptionsService } from "../feature-wealth-strategy-options/data-access/feature-wealth-strategy-options.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as saveAs from "file-saver";
import { ToastrService } from "ngx-toastr";

@Injectable()

export class StrategyServiceHelper {
  applicationSvc = inject(FeatureWealthStrategyOptionsService);
  #ngxLoader = inject(NgxUiLoaderService);
  #toastrService = inject(ToastrService);

  download(data: ApplicationStrategyDocumentModelDto, fileName: string, callback: (success: boolean) => void) {
    this.applicationSvc.Download(data?.applicationId, data).subscribe(
      (response) => {
        const blob = response.body;
        let fileName = 'report.pdf';
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        saveAs(response.body as Blob, fileName);
        callback(true);
      },
      (error: any) => {
        this.#toastrService.error('Error downloading PDF', 'Error');
        callback(true);
      }
    );
  }
}

import { AfterViewInit, Component, HostBinding, inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FeatureApplicationComponent } from '@acorn/feature-application';
import { BaseContentfulService, UserService, UserSubscriptionPlanService } from '@acorn/data-access';
import { APPLICATION_ID, ContentTypeIds, CustomerUser, QuoteContent } from '@acorn/util';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RequestPopupComponent } from './request-popup/request-popup.component';

@Component({
    selector: 'acorn-about-you',
    standalone: true,
    imports: [CommonModule, FeatureApplicationComponent],
    templateUrl: './about-you.component.html',
    styleUrls: ['./about-you.component.scss'],
})
export class AboutYouComponent implements OnInit, AfterViewInit {
    @Input() isDashboardApp = false;
    @Input() userId: any;
    @Input() customerUser!: CustomerUser;

    @HostBinding('class.first-start-application')
    get isDualRespondent() {
        return this.quoteContent();
    }

    #userService = inject(UserService);
    #contentFullService = inject(BaseContentfulService);
    #router = inject(Router);
    #viewport = inject(ViewportScroller);
    #dialog = inject(MatDialog);

    currentUser = signal<CustomerUser | null>(null);
    quoteContent = signal<QuoteContent | null>(null);
    isShownQuoteContent = signal<boolean>(false);
    applicationId: string | undefined;
    isReCalculateAfterEdit: boolean | undefined;
    data!: any;

    public getCurrentUser() {
        combineLatest([
            this.#userService.getCurrentUser(),
            this.#contentFullService.getContentful(ContentTypeIds.InspirationQuotePage),
        ]).subscribe(([{ data }, [quoteContent]]) => {
            this.currentUser.set(data);
            this.applicationId = data.currentApplication?.id;
            this.isReCalculateAfterEdit = data.currentApplication?.recalculateAfterEdit;
            this.quoteContent.set(quoteContent.fields as any);
            this.data = data.currentApplication;
            if (!this.currentUser()?.currentApplication) {
                this.isShownQuoteContent.set(true);
            }
        });
    }

    ngOnInit(): void {
        if(this.customerUser){
            this.currentUser.set(this.customerUser);
            this.applicationId = this.customerUser.currentApplication?.id;
        }else{
            this.getCurrentUser();
        }
    }

    public showTheQuoteContent(): void {
        this.isShownQuoteContent.set(true);
        this.#viewport.scrollToPosition([0, 0]);
    }

    ngAfterViewInit(): void {
        this.getCurrentUser();
    }

    public requestEdit() {
        const modal = this.#dialog.open(RequestPopupComponent, {
            width: '500px',
            disableClose: true,
            hasBackdrop: true,
            data: {
                applicationId: this.applicationId,
            },
        });

        modal.componentInstance.close.subscribe(() => {
            modal.close();
        });

        modal.componentInstance.confirm.subscribe(() => {
            modal.close();
            this.#router.navigateByUrl('');
        });
    }
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'acorn-warning-dialog',
  standalone: true,
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDialogModule, MatButtonModule, IconSpriteModule],
})
export class WarningDialogComponent {
  #dialogRef = inject(MatDialogRef<WarningDialogComponent>);

  dialogData = inject(MAT_DIALOG_DATA);

  close(): void {
    this.#dialogRef.close(false);
  }

  next(): void {
    this.#dialogRef.close(true);
  }
}

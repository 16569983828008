import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import { APPLICATION_ID, ApplicationUser, CommonResponse } from '@acorn/util';

@Injectable()
export class ApplicationUserService {
  #httpClient = inject(HttpClient);
  #applicationUserId = inject(APPLICATION_ID, { optional: true });

  createAll(
    applicationUsers: Pick<
      ApplicationUser,
      'firstName' | 'dateOfBirth' | 'order'
    >[]
  ) {
    const params = this.#applicationUserId?.value
      ? `/CreateAll?applicationId=${this.#applicationUserId.value}`
      : '/Current/CreateAll';

    return this.#httpClient.post<CommonResponse<ApplicationUser[]>>(
      `${environment.apiUrls.customer}/api/ApplicationUser${params}`,
      applicationUsers
    );
  }

  updateAll(
    applicationUsers: Pick<
      ApplicationUser,
      'firstName' | 'dateOfBirth' | 'order' | 'id'
    >[]
  ) {
    const params = this.#applicationUserId?.value
      ? `/UpdateAll?applicationId=${this.#applicationUserId.value}`
      : '/Current/UpdateAll';

    return this.#httpClient.put<CommonResponse<ApplicationUser[]>>(
      `${environment.apiUrls.customer}/api/ApplicationUser${params}`,
      applicationUsers
    );
  }
}

<ng-container *ngIf="!wealthStrategyOptions">
    <div class="dashboard-container">
        <div class="container-fluid is-fluid with-bg dashboard-header">
            <div class="left-half-circle"></div>
            <div class="container dash-col">
                <div class="row">
                    <div class="columns">
                        <div class="col-60 z-9">
                            <h2>Hi {{ respondentInfo ? getRespondentInfo : ('Name' | titlecase) }},</h2>
                            <div *ngIf="(selectedTabIndex === 0 || selectedTabIndex === 2) && this.showLearningHub === false">
                                <div>
                                    <p class="pad-bot10">
                                        <span>Welcome to your dashboard</span>
                                    </p>
                                    <p class="see-text" *ngIf="!this.selectedStrategy?.selectedByAdminDate && isExpanded">
                                        Once you have had your Wealth Coach Strategy session, this will be filled with your action plan,
                                        details about Wealth Coach conversations, your Wealth Health score and information about your
                                        current situation.
                                    </p>
                                    <p class="see-text" *ngIf="this.selectedStrategy?.selectedByAdminDate && isExpanded">
                                        Here you can track your action plan progress, find out about group coaching, download your strategy
                                        and see insights into your current situation, risk tolerance and behaviours, and check out your
                                        Wealth Health score.
                                    </p>
                                    <button class="see-btn" (click)="toggleText()">
                                        {{ isExpanded ? 'Show Less' : 'Show More' }}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="(selectedTabIndex === 0 || selectedTabIndex === 2) && this.showLearningHub === true">
                                <div>
                                    <p class="pad-bot10">
                                        <span>Welcome to your Learning Hub</span>
                                    </p>
                                    <p>Here you can access your personalised library of learning resources</p>
                                </div>
                            </div>
                            <div *ngIf="selectedTabIndex === 3">
                                <div>
                                    <p class="pad-bot10">
                                        <span>Welcome to About you</span>
                                    </p>
                                    <p>
                                        Here you can see your answers to the questionnaire which have been used to create your personalised
                                        strategy and action plan
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="selectedTabIndex === 4">
                                <div>
                                    <p class="pad-bot10">
                                        <span>Welcome to your Financial Projection</span>
                                    </p>
                                    <p>
                                        Here you can access your projected net asset position - if no significant changes to your wealth
                                        situation are made. Once you have chosen your strategy, this will reflect following that and your
                                        action plan.
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="selectedTabIndex === 5">
                                <div>
                                    <p class="pad-bot10">
                                        <span>Welcome to Useful information</span>
                                    </p>
                                    <p>Here you can access legal documents and information on the Wealth Maximiser service</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-40 d-flex justify-content-end"></div> -->
                    </div>
                </div>
            </div>
            <div class="right-bg">
                <img src="/assets/images/d-header.svg" alt="" />
            </div>
        </div>

        <div class="container-fluid dashboard-body">
            <main class="mt-2">
                <div
                    class="custom-nav-container"
                    [ngClass]="{
                        'financial-nav-container': selectedTabIndex === 4
                    }"
                >
                    <div class="custom-nav-wrap">
                        <ul class="custom-nav-header container">
                            <ng-container *ngFor="let tab of tabs; let i = index">
                                <li
                                    *ngIf="tab.label !== 'Wealth Health'"
                                    (click)="selectTab(i)"
                                    [ngClass]="{
                                        active: selectedTabIndex === i
                                    }"
                                    class="nav-link"
                                >
                                    <div
                                        class="nav-item-content"
                                        style="
                                            padding: 5px;
                                            box-shadow: 0px 1.1px 2.2px 0px #0000000f;
                                            border: 1.1px solid var(--mode-base-200, #e4e4e7);
                                            background: var(--mode-base-50, #fcfcfc);
                                            border-radius: 13px;
                                            margin-right: 10px;
                                        "
                                    >
                                        <img [src]="tab.icon" alt="tab.icon" class="nav-icon" />
                                    </div>
                                    <span>{{ tab.label }}</span>
                                </li>

                                <a
                                    *ngIf="tab.label === 'Wealth Health'"
                                    (click)="goToWealthHealth()"
                                    class="nav-link"
                                    [ngClass]="{
                                        active: selectedTabIndex === i
                                    }"
                                >
                                    <div
                                        class="nav-item-content"
                                        style="
                                            padding: 5px;
                                            box-shadow: 0px 1.1px 2.2px 0px #0000000f;
                                            border: 1.1px solid var(--mode-base-200, #e4e4e7);
                                            background: var(--mode-base-50, #fcfcfc);
                                            border-radius: 13px;
                                            margin-right: 10px;
                                        "
                                    >
                                        <img [src]="tab.icon" alt="tab.icon" class="nav-icon" />
                                    </div>
                                    <span>{{ tab.label }}</span>
                                </a>
                            </ng-container>
                        </ul>

                        <div class="dot-navigation">
                            <div class="dot active"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                    <div class="dot-navigation">
                        <div class="dot active"></div>
                        <div class="dot"></div>
                    </div>
                </div>

                <div class="custom-tab-content">
                    <acorn-information *ngIf="this.showUserInfo && !this.showLearningHub"></acorn-information>
                    <acorn-learning-hub *ngIf="this.showLearningHub && !this.showUserInfo"></acorn-learning-hub>

                    <div *ngIf="!this.showUserInfo && !this.showLearningHub">
                        <div *ngIf="selectedTabIndex === 0 && applicationId" class="tab-content">
                            <acorn-actions-empty
                                [applicationId]="this.applicationId"
                                [selectedStrategy]="this.selectedStrategy"
                                (userInfo)="userInfo()"
                                (aboutYou)="showAboutYou()"
                                (learningHub)="setLearningHub()"
                                (hasStrategy)="hasStrategy($event.hasStrategyNull, $event.strategy)"
                            ></acorn-actions-empty>
                        </div>
                        <div *ngIf="selectedTabIndex === 1" class="tab-content"></div>
                        <div *ngIf="selectedTabIndex === 3" class="tab-content">
                            <acorn-about-you
                                [userId]="this.userId"
                                [isDashboardApp]="true"
                                [customerUser]="currentUser()!"
                            ></acorn-about-you>
                        </div>
                        <div *ngIf="selectedTabIndex === 4" class="tab-content financial-nav-container">
                            <acorn-financial-projection
                                [chartData]="this.chartData"
                                [currentUser]="currentUser()!"
                                [applicationId]="this.applicationId"
                                [selectedStrategy]="this.selectedStrategy"
                            ></acorn-financial-projection>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
    <acorn-footer *ngIf="!this.isDashboardApp"></acorn-footer>
</ng-container>

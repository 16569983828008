<div class="container">
  <div style="text-align: center">
    <p class="content">
      We have updated our Goal Setting Activity! <br /><br />
      Please return to the ‘Your Goals’ section of your application to reselect
      your most important financial goals. <br /><br />Your previously submitted
      financial information has been saved.
    </p>
    <button mat-button class="back-btn" (click)="backToGoal()">
      Back To Goal
    </button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { noop } from 'rxjs';

import { SecurityService } from '../../data-access';

@Component({
  selector: 'acorn-get-started-button',
  templateUrl: 'get-started-button.component.html',
  styleUrls: ['get-started-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, IconSpriteModule],
})
export class GetStartedButtonComponent {
  #securityService = inject(SecurityService);
  #router = inject(Router);

  getStarted() {
    if (this.#securityService.isAuthenticated()) {
      this.#router.navigate(['/']).then(noop);
      return;
    }

    this.#router.navigate(['/auth/sign-up']).then(noop);
  }
}

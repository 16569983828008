<form [formGroup]="formGroup" [ngClass]="{'disabled-select': isFileNotes}">
  <div class="form-field-wrapper">
    <mat-radio-group
      aria-label="Select an option"
      class="inline"
      color="primary"
      formControlName="repaymentStructure"
     
    >
      <mat-radio-button
        [value]="RepaymentStructure['P&I']"
        (click)="onRepaymentStructureChanged($event, RepaymentStructure['P&I'])"
      >
        Principal & Interest (P&I)
      </mat-radio-button>

      <mat-radio-button
        [value]="RepaymentStructure.InterestOnly"
        (click)="
          onRepaymentStructureChanged($event, RepaymentStructure.InterestOnly)
        "
      >
        Interest Only
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div
    *ngIf="
      formGroup.controls.repaymentStructure.value ===
      RepaymentStructure.InterestOnly
    "
    class="form-field-wrapper expiry-of-interest"
  >
    <mat-label>Expiry of Interest Only term</mat-label>

    <div>
      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <mat-select
          placeholder="Month"
          formControlName="repaymentExpireMonth"
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option *ngFor="let month of MONTH_OPTIONS" [value]="month.value">
            {{ month.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="small"
        subscriptSizing="dynamic"
      >
        <mat-select
          placeholder="Year"
          formControlName="repaymentExpireYear"
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let year of FUTURE_YEAR_OPTIONS"
            [value]="year.value"
          >
            {{ year.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

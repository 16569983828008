import { Injectable } from '@angular/core';

import { createClient, Entry, FieldsType } from 'contentful';
import { EntrySkeletonType } from 'contentful/dist/types/types/query/util';

import { environment } from '@env';
import { ContentTypeIds } from '@acorn/util';

@Injectable({ providedIn: 'root' })
export class BaseContentfulService {
  cdaClient = createClient({
    space: environment.contentfulConfig.space,
    accessToken: environment.contentfulConfig.accessToken,
  });

  getContentful(
    contentType: ContentTypeIds,
    query?: object
  ): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type:
              environment.contentfulConfig.contentTypeIds[contentType],
          },
          query
        )
      )
      .then((res) => res.items);
  }

  getEntry<T>(entryId: string, query?: object): Promise<T> {
    return this.cdaClient
      .getEntry(entryId, {
        include: 10,
        ...query,
      })
      .then((res) => res.fields) as Promise<T>;
  }

  getEntries<T>(contentType: ContentTypeIds, query?: object) {
    return this.cdaClient
      .getEntries({
        content_type: contentType,
        include: 10,
        ...query,
      })
      .then((response) => response.items.map((item) => item.fields)) as Promise<
      T[]
    >;
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { NgIf } from '@angular/common';

// TODO: dat.nguyent remove this component
@Component({
  selector: 'button[acorn-button]',
  templateUrl: 'button.html',
  styleUrls: ['button.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ButtonComponent {
  @Input() color: 'primary' | 'secondary' = 'secondary';

  @HostBinding('class')
  @Input()
  size: 'default' | 'large' = 'default';

  @HostBinding('class')
  get colorClass() {
    return `${this.color}-color`;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-kickout-dialog',
  standalone: true,
  templateUrl: './kickout-dialog.component.html',
  styleUrls: ['./kickout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDialogModule, MatButtonModule, IconSpriteModule],
})
export class KickoutDialogComponent implements OnInit {
  #dialogRef = inject(MatDialogRef<KickoutDialogComponent>);
  #dialogData = inject(MAT_DIALOG_DATA, { optional: true });

  message = signal<string | null>(null);

  ngOnInit() {
    if (this.#dialogData && this.#dialogData.message) {
      this.message.set(this.#dialogData.message);
    }
  }

  close(): void {
    this.#dialogRef.close();
  }
}

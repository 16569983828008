<div [ngSwitch]="currentStep()">
    <ng-container *ngSwitchCase="'WelcomeBackRiskTolerance'">
        <acorn-wealth-strategy-options-home
            [applicationId]="currentUser.currentApplication?.id!"
            [currentUser]="currentUser"
        ></acorn-wealth-strategy-options-home>
    </ng-container>

    <ng-container *ngSwitchCase="'WealthStrategyOption'">
        <acorn-wealth-strategy-options
            *ngIf="strategies"
            [applicationId]="applicationId"
            [currentUser]="currentUser"
            [userId]="currentUser.currentApplication?.userId!"
            [strategies]="this.strategies"
            [onlyWealthstrategyOptions]="onlyWealthstrategyOptions"
            [isFileNotes]="isFileNotes"
        ></acorn-wealth-strategy-options>
    </ng-container>

    <ng-container *ngSwitchCase="'WhatsNext'">
        <acorn-whats-next></acorn-whats-next>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <p>Select a step to continue.</p>
    </ng-container>
</div>

<acorn-application-navigation
    class="acron-navigation white-bg"
    (back)="onHandleBack()"
    (next)="onHandleNext()"
></acorn-application-navigation>

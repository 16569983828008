import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

export {};
declare global {
  interface Window {
    Calendly: any;
  }
}

@Component({
  selector: 'acorn-wealth-coach-schedule',
  templateUrl: 'wealth-coach-schedule.component.html',
  styleUrls: ['wealth-coach-schedule.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class WealthCoachScheduleComponent implements AfterViewInit {
  @Input() countScheduleTime!: number;
  @Input() scheduleTitle!: string;
  @Input() calendarLink!: string;
  @Input() isFileNotes = false;

  @ViewChild('calendarElem', { read: ElementRef })
  calendarElem!: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    window.Calendly.initInlineWidget({
      url: this.calendarLink,
      parentElement: this.calendarElem.nativeElement,
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayOrderingQuestion',
  standalone: true,
})
export class DisplayOrderingQuestionPipe implements PipeTransform {
  transform(value: string, index: number) {
    if (!value) {
      return '';
    }

    return value.replace('#{order}', index.toString());
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FormArray,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-dependant',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    IconSpriteModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './dependant.component.html',
  styleUrls: ['./dependant.component.scss'],
})
export class DependantComponent {
  @Input() groupDependant = '';
  @Input() dependantName = '';
  @Input() label = '';
  @Input() isChild = false;

  @Input() formArray = new FormArray<FormControl<string | null>>([]);

  @Output() changed = new EventEmitter<string>();

  protected readonly AgeListForChild = Array.from({ length: 18 }, (_, index) =>
    (index + 1).toString()
  );

  addMore(): void {
    this.formArray.push(new FormControl<string>('', { nonNullable: true }));
  }

  onRemove(index: number): void {
    this.formArray.removeAt(index);
  }
}

<section>
  <div class="header">
    <h4>Confirm & prioritise</h4>

    <h1>Your top 5</h1>

    <!--<p>
      Congratulations on identifying your top 5 goals. Keep lower priority goals
      in mind as your priorities evolve. Review and rearrange your top 5 goals
      if necessary.
    </p>-->
    <p>Congratulations on choosing your goals!</p>
    <ul>
      <li>
        1) The next step is to prioritise these goals to finalise your
        <span style="color: #f47621; font-weight: 600; font-size: 15px"
          >TOP 5.</span
        >
      </li>
      <li>2) Tap and drag to arrange in order of importance.</li>
      <li>
        3) Your top 5 goals will form the foundation of your financial plan.
      </li>
    </ul>

    <!--<h6>
      Tap and drag to arrange these goals in your preferred order of priority if
      required.
    </h6>-->
  </div>
  <acorn-alert *ngIf="errorMessage()" type="error">
    <b>{{ errorMessage() }}</b>
  </acorn-alert>
  <div class="divider">
    <h5>Top 5 goals</h5>

    <mat-divider></mat-divider>
  </div>

  <div
    cdkDropList
    #top5Goals="cdkDropList"
    [cdkDropListData]="top5GoalList()"
    [cdkDropListConnectedTo]="[next5Goals]"
    (cdkDropListDropped)="drop($event, 'top')"
    [cdkDropListDisabled]="isFileNotes"
  >
    <div
      cdkDrag
      *ngFor="let item of top5GoalList(); let index = index"
      class="goal goal--primary"
      [cdkDragStartDelay]="isMobile ? 100 : 0"
      [cdkDragDisabled]="isFileNotes"
    >
      <div class="number">{{ index + 1 }}</div>

      <h4>{{ item.content }}</h4>

      <div cdkDragHandle class="drag-handle">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#draggable'"
          [width]="'22px'"
        ></svg-icon-sprite>
      </div>

      <ul>
        <li *ngFor="let tag of item?.tags">{{ tag }}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="next5GoalList()?.length" class="divider">
    <h5>Next 5 goals</h5>

    <mat-divider></mat-divider>
  </div>

  <div
    cdkDropList
    #next5Goals="cdkDropList"
    [cdkDropListData]="next5GoalList()"
    [cdkDropListConnectedTo]="[top5Goals]"
    (cdkDropListDropped)="drop($event, 'next')"
    [cdkDropListDisabled]="isFileNotes"
  >
    <div
      cdkDrag
      *ngFor="let item of next5GoalList(); let index = index"
      class="goal"
      [cdkDragStartDelay]="isMobile ? 100 : 0"
      [cdkDragDisabled]="isFileNotes"
    >
      <div class="number">{{ index + 6 }}</div>

      <h4>{{ item.content }}</h4>

      <div cdkDragHandle class="drag-handle">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#draggable'"
          [width]="'22px'"
        ></svg-icon-sprite>
      </div>

      <ul>
        <li *ngFor="let tag of item?.tags">{{ tag }}</li>
      </ul>
    </div>
  </div>
</section>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="backClicked.emit()"
  (next)="onNext()"
></acorn-application-navigation>

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { GoalStepStructure1 } from '../../../util';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ClickableTooltipDirective } from '@acorn/common-ui';
import { GoalManagementService } from '../../data-access/goal-management.service';
import { STEP_STRUCTURE1 } from '../utlis/step-structure.const';

interface Goal {
  id: string;
  content: string;
  tags: string[];
  order: number;
  reportOrder: number;
  description: string;
  selected?: boolean;
  showDescription?: boolean;
}

@Component({
  selector: 'acorn-goals-step',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    IconSpriteModule,
    ClickableTooltipDirective,
  ],
  templateUrl: './goals-step.component.html',
  styleUrls: ['./goals-step.component.scss'],
})
export class GoalsStepComponent implements OnInit {
  #viewport = inject(ViewportScroller);
  #goalManagementService = inject(GoalManagementService);

  @Output() selectionChange = new EventEmitter<void>();

  @Input() public set goalLength(value: number) {
    this._goalLength = value;
  }

  @Input() public set step(value: GoalStepStructure1) {
    this._step = value;
    this.initializeFormControls();
  }

  @Input() public isDisabled: boolean = false;

  @Input() isFileNotes: boolean = false;
  @Input() isAppComplete = false;

  selectedGoalsControl = new FormControl<string[]>([], { nonNullable: true });
  maxGoals: number = 10;
  pageX: number = 0;
  pageY: number = 0;
  showTooltip: boolean = false;
  showWarning: boolean = false;
  goalsForm!: FormGroup;
  WealthType = STEP_STRUCTURE1;

  @ViewChildren(ClickableTooltipDirective)
  clickableChildren!: QueryList<ClickableTooltipDirective>;

  public _step!: GoalStepStructure1;
  public _goalLength!: number;
  public reorderedGoals: any = [];

  ngOnInit(): void {
    this.#scrollToTop();
    this.initializeFormControls();
  }

  #scrollToTop(): void {
    this.#viewport.scrollToPosition([0, 0]);
  }

  toggleGoal(event: any, goalId: string, isSelected: boolean): void {
    const target = event.target as HTMLElement;

    // Get the bounding client rectangle of the element
    const rect = target.getBoundingClientRect();

    // Element's position relative to the viewport
    const x = rect.left;
    const y = rect.bottom;
    this.pageX = x;
    this.pageY = y + 100;

    const isClickOnTooltip = this.clickableChildren.some((it) =>
      (it.elementRef.nativeElement as HTMLElement).contains(
        event.target as HTMLElement
      )
    );

    if (this._goalLength >= 10 && !isSelected && !isClickOnTooltip) {
      this.showWarning = true;
      return;
    } else {
      this.showWarning = false;
    }

    if (isClickOnTooltip) {
      return;
    }

    const control = this.goalsForm?.get(goalId) as FormControl;
    if (control) {
      const goals = this.WealthType.filter(
        (type) => type.selected && type.selected.length > 0
      )
        .map((type) => type.selected)
        .flat();

      if (control.value || (!control.value && goals.length < this.maxGoals)) {
        control.setValue(!control.value);
      } else {
        control.setValue(false);
        return;
      }
    }
  }

  isGoalDisabled(goalId: string): boolean {
    //const selectedCount = this.selectedGoalsControl.value.length;
    return false;
  }

  checkLength($event: any) {
    if (this._goalLength >= 10) {
      $event.source._checked = false;
    }
  }

  initializeFormControls() {
    if (this._step && this._step.goals) {
      const controls = this._step.goals.reduce(
        (acc: { [key: string]: FormControl }, goal) => {
          acc[goal.id] = new FormControl(false);
          return acc;
        },
        {} as { [key: string]: FormControl }
      );

      this.goalsForm = new FormGroup(controls);

      if (this._step.selected) {
        this._step.selected.forEach((selectedGoal) => {
          const control = this.goalsForm.get(selectedGoal.id);
          if (control) {
            control.setValue(true);
          }
        });

        const selectedGoalIds = this._step.selected.map((goal) => goal.id);
        this.selectedGoalsControl.setValue(selectedGoalIds, {
          emitEvent: false,
        });
      }

      this.goalsForm.valueChanges.subscribe((values) => {
        const selected = Object.keys(values)
          .filter((key) => values[key])
          .map((id) => {
            const goal = this._step.goals?.find((g) => g.id === id);
            return { id, order: goal?.order ?? 0 };
          });

        this.selectedGoalsControl.setValue(
          selected.map((s) => s.id),
          { emitEvent: false }
        );

        this._step.selected = selected;

        this.selectionChange.emit();
      });

      this.selectedGoalsControl.value.forEach((goalId) => {
        if (this.goalsForm.controls[goalId]) {
          this.goalsForm.controls[goalId].setValue(true);
        }
      });
    }
  }
}

<ng-container>
<div class="container" [class.mb-4]="!isMobile" [class.mt-4]="!isMobile">
        <div class="f-heading">
            <div class="f-mheading" [class.text-center]="!isMobile">
                <h1>Your financial projection if you <br>continue as is
                </h1>
            </div>
            <p class="pa-1 f-subheading text-center">Your projected net asset position
                <span style="font-weight: 400;">- if no significant changes to your wealth situation are made.</span>
            </p>
            <p class="mb-4 f-subheading  text-center" style="font-weight: 400;">This is indicative only, to see your personalised wealth strategy options and more accurate projection <br> if you continue as is vs if you follow a wealth strategy, book a time with a Wealth Coach on the next page
            </p>
        </div>
        <mat-divider *ngIf="isMobile" [class.mb-1]="isMobile"></mat-divider>
        <div class="f-chart" id="chart-container">
            <p [class.mt-2]="isMobile" *ngIf="isMobile && !this.expanded && processedData && processedData.length > 0"
                (click)="viewFullscreen()" class="d-flex justify-content-center p-expand">
                Expand &nbsp;<img src="/assets/images/enlarge.svg" width="15px"></p>

            <div class="d-flex" *ngIf="this.expanded" style="justify-content: space-between;">
                <p class="p-m-expand" style="font-size: 13px;"><strong>Financial Projection</strong>&nbsp; If no significant changes to your
                    wealth situation are made.</p>
                <button style="font-size: 12px;" class="icon-button d-flex" (click)="onClose()">
                    Close &#x2715;
                </button>
            </div>
            <div #player id="player">
                <div  style="color: white; background-color: white; height: 440px"
                    [style.height]="isMobile ? '370px' : '440px'" *ngIf="showDeafult">
                    <canvas #barCanvas></canvas>
                </div>
            </div>
            <div class="spinner" *ngIf="isLoading && processedData.length !== 0">
                <div class="spinner-inner"></div>
            </div>

            <div class="no-data" *ngIf="processedData && processedData.length === 0 && !showDeafult">
                <p>No Data Found</p>
            </div>
        </div>
        <mat-divider *ngIf="isMobile"></mat-divider>
    </div>

    <div class="bottom-content">
        <div class="f-body-goal-details container-fluid"
            [style.box-shadow]="isMobile ? 'none' : '0px -6px 6px rgba(160, 160, 160, 0.3);'">
            <div class="f-goal-container pa-15" [class.mt-4]="!isMobile">
                <div class="goal-footer text-center pa-2">
                    <p class="goal-f-c">Have you entered everything correctly, or do you want to go back and adjust?
                    </p>
                    <!-- <p class="goal-f-c" *ngIf="isMobile">Sometimes this can make goals more achievable.</p> -->
                    <div class="btn-grp d-flex mt-3">
                        <button mat-button class="goal-btn" (click)="moveToGoalRoundVerify()" [disabled]="isFileNotes">Adjust goals</button>
                        <button mat-button class="goal-btn" (click)="moveToFinancialSituation()"  [disabled]="isFileNotes">Adjust financial
                            situation</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" [class.mt-4]="!isMobile" [class.mt-2]="isMobile">
            <a class="see-assumption" (click)="openAssumptions()">See key assumptions</a>
            <mat-divider *ngIf="isMobile" [class.mt-4]="isMobile"></mat-divider>
        </div>
        <acorn-application-navigation (back)="onHandleBack()" (next)="onHandleNext()"></acorn-application-navigation>
    </div>
</ng-container>

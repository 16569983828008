import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ApplicationPlan, ApplicationUser, ERROR_MESSAGE } from '@acorn/util';
import { AlertComponent, NavigationComponent } from '@acorn/common-ui';
import { ApplicationUserService } from '@acorn/data-access';
import { iif } from 'rxjs';
import { NgxMaskDirective } from 'ngx-mask';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { subYears } from 'date-fns';
import { Helper } from '../../../helpers/helper';
import { calculateAge, formatDateOrNull } from '../../../util';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from '../../../ui';

@Component({
    selector: 'acorn-setup-details',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCardModule,
        NavigationComponent,
        AlertComponent,
        NgxMaskDirective,
        MatDatepickerModule,
        IconSpriteModule,
    ],
    templateUrl: './setup-details.component.html',
    styleUrls: ['./setup-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupDetailsComponent implements OnInit {
    #applicationUserService = inject(ApplicationUserService);

    @HostBinding('class.dual-respondent')
    get isDualRespondent() {
        return this.applicationPlan === ApplicationPlan.ForYouAndOther;
    }

    @Input() applicationPlan: ApplicationPlan = ApplicationPlan.ForYou;
    @Input() applicationUsers: ApplicationUser[] = [];
    @Input() isFileNotes: boolean = false;
    @Input() isAppComplete = false;

    @Output() back = new EventEmitter<void>();
    @Output() next = new EventEmitter<{
        isUpdateApplications: boolean;
        applicationUsers: ApplicationUser[];
    }>();

    protected readonly ApplicationPlan = ApplicationPlan;
    protected readonly dateOfBirthValidation = subYears(new Date(), 20);
    protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

    isLoading = signal(false);
    isSubmitted = signal(false);

    #helper = inject(Helper);
    #dialog = inject(MatDialog);

    forYouForm = new FormGroup({
        firstName: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.minLength(2)],
        }),
        dateOfBirth: new FormControl('', {
            nonNullable: true,
            validators: Validators.required,
        }),
    });
    someoneElseForm = new FormGroup({
        firstName: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.minLength(2) ],
        }),
        dateOfBirth: new FormControl('', {
            nonNullable: true,
            validators: Validators.required,
        }),
    });

    hasError = signal<string>('');

    ngOnInit() {
        if (this.isFileNotes || this.isAppComplete) {
            this.forYouForm.disable();
            this.someoneElseForm.disable();
        }

        const [forYou, someoneElse] = this.applicationUsers;

        if (forYou) {
            this.forYouForm.patchValue(forYou);
        }

        if (someoneElse) {
            this.someoneElseForm.patchValue(someoneElse);
        }
    }

    onSubmit(): void {
        this.hasError.set('');
        this.isSubmitted.set(true);

        const forYouFormRawValue = this.forYouForm.getRawValue();
        const someoneElseFormRawValue = this.someoneElseForm.getRawValue();

        const forYouDateOfBirth = formatDateOrNull(forYouFormRawValue.dateOfBirth) || '';
        const someoneFormDateOfBirth = formatDateOrNull(someoneElseFormRawValue.dateOfBirth) || '';

        const forYouAge = calculateAge(forYouDateOfBirth);
        const someoneElseAge = calculateAge(someoneFormDateOfBirth);

        if (forYouAge < 20) {
            this.showAgeRestrictionPopup();
            return;
        }

        if (someoneElseAge < 20) {
            this.showAgeRestrictionPopup();
            return;
        }

        const hasError =
            this.applicationPlan === ApplicationPlan.ForYou
                ? this.forYouForm.invalid
                : this.forYouForm.invalid || this.someoneElseForm.invalid;

        if (hasError) {
            this.hasError.set(ERROR_MESSAGE.ALL_ARE_REQUIRED);
            return;
        }

        this.#upsertApplicationUsers();
    }

    #upsertApplicationUsers(): void {
        const forYouFormRawValue = this.forYouForm.getRawValue();
        const someoneElseFormRawValue = this.someoneElseForm.getRawValue();

        const forYouDateOfBirth = formatDateOrNull(forYouFormRawValue.dateOfBirth) || '';
        const someoneFormDateOfBirth = formatDateOrNull(someoneElseFormRawValue.dateOfBirth) || '';

        forYouFormRawValue.dateOfBirth = forYouDateOfBirth;
        someoneElseFormRawValue.dateOfBirth = someoneFormDateOfBirth;

        const applicationUsers = [
            {
                ...forYouFormRawValue,
                order: 0,
                id: '00000000-0000-0000-0000-000000000000',
                applicationId: '',
            },
        ];

        if (this.applicationPlan === ApplicationPlan.ForYouAndOther) {
            applicationUsers.push({
                ...someoneElseFormRawValue,
                order: 1,
                id: '00000000-0000-0000-0000-000000000000',
                applicationId: '',
            });
        }

        if (this.isFileNotes || this.isAppComplete) {
            this.next.emit({ isUpdateApplications: false, applicationUsers });
            return;
        }

        this.isLoading.set(true);
        const isUpdateApplications = this.applicationUsers.length > 0 && this.applicationUsers.length === applicationUsers.length;

        iif(
            () => isUpdateApplications,
            this.#applicationUserService.updateAll(
                applicationUsers.map((item, index) => ({
                    ...item,
                    id: this.applicationUsers[index]?.id ?? null,
                    applicationId: this.applicationUsers[index]?.applicationId ?? null,
                }))
            ),
            this.#applicationUserService.createAll(applicationUsers)
        ).subscribe(({ isSuccess, message, data }) => {
            this.isLoading.set(false);
            this.#helper.updateStatus(data[0].applicationId);
            if (!isSuccess) {
                this.hasError.set(message);
                return;
            }

            this.next.emit({ isUpdateApplications, applicationUsers: data });
        });
    }

    public showAgeRestrictionPopup(): void {
        this.#dialog.open(WarningDialogComponent, {
            autoFocus: false,
            data: {
                type: 'error',
                messages: [`Unfortunately, at this time we cannot provide advice to individuals under the age of 20.`],
            },
        });
    }
}

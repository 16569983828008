import { ApplicationStep, WealthHealthStep } from '@acorn/util';

export const ApplicationStepIndex = {
  [ApplicationStep.GettingStarted]: 0,
  [ApplicationStep.Goal]: 1,
  [ApplicationStep.AboutYou]: 2,
  [ApplicationStep.FinancialSituation]: 3,
  [ApplicationStep.WealthHealth]: 4,
  [ApplicationStep.FinancialProjection]: 5,
  [ApplicationStep.WealthStrategyOptions]: 6,
  [ApplicationStep.WealthCoach]: 7,
  [ApplicationStep.Dashboard]: 8,
};

export const WealthHealthStepIndex = {
  [WealthHealthStep.WealthBuildingBlock]: 0,
  [WealthHealthStep.WealthScore]: 1,
  [WealthHealthStep.WealthHappy]: 2,
};

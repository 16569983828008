import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { debounceTime, startWith } from 'rxjs';
import { FIXED_RATE_TERM_OPTIONS, isInterestRateValid } from '../../utils';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { ERROR_KEY } from '@acorn/util';

@Component({
  selector: 'acorn-interest-rate',
  standalone: true,
  imports: [
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
  templateUrl: './interest-rate.component.html',
  styleUrls: ['./interest-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => InterestRateComponent),
      multi: true,
    },
  ],
})
export class InterestRateComponent
  implements ControlValueAccessor, OnInit
{
  #cdr = inject(ChangeDetectorRef);
  @Input() isFileNotes: boolean  = false;

  protected readonly FIXED_RATE_TERM_OPTIONS = FIXED_RATE_TERM_OPTIONS;

  formGroup = new FormGroup({
    fixedPerVariable: new FormControl<number>(0, { nonNullable: true }),
    fixedInterestRate: new FormControl<string>(''),
    variableInterestRate: new FormControl<string>(''),
    remainingFixedRateTermMonth: new FormControl<string>(''),
  });

  fixedRateControl = new FormControl<string>('');
  variableRateControl = new FormControl<string>('');

  ngOnInit() {
    this.formGroup.controls.fixedPerVariable.valueChanges
      .pipe(startWith(0), debounceTime(500))
      .subscribe((fixedPerVariable) => {
        this.variableRateControl.setValue(fixedPerVariable.toString());
        this.fixedRateControl.setValue((100 - fixedPerVariable).toString());

        if (fixedPerVariable === 100) {
          this.formGroup.controls.fixedInterestRate.disable();
          this.formGroup.controls.remainingFixedRateTermMonth.disable();
          this.formGroup.controls.variableInterestRate.enable();
        } else if (fixedPerVariable === 0) {
          this.formGroup.controls.fixedInterestRate.enable();
          this.formGroup.controls.remainingFixedRateTermMonth.enable();
          this.formGroup.controls.variableInterestRate.disable();
        } else {
          this.formGroup.controls.fixedInterestRate.enable();
          this.formGroup.controls.remainingFixedRateTermMonth.enable();
          this.formGroup.controls.variableInterestRate.enable();
        }

        this.#cdr.detectChanges();
      });

    this.formGroup.valueChanges.subscribe((value) => {
      this.onChange(JSON.stringify(value));
    });
  }

  onFixedRateChanged(fixedRate: string): void {
    if (Number(fixedRate) > 100) {
      return;
    }

    this.formGroup.controls.fixedPerVariable.setValue(100 - Number(fixedRate));
  }

  onVariableRateChanged(variableRate: string): void {
    if (Number(variableRate) > 100) {
      return;
    }

    this.formGroup.controls.fixedPerVariable.setValue(Number(variableRate));
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  writeValue(value: string): void {
    if (!value) {
      return;
    }

    try {
      const formValue = JSON.parse(value);
      this.formGroup.patchValue(formValue);

      this.onChange(JSON.stringify(formValue));
    } catch (e) {
      console.log(e);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (!isDisabled) {
      return;
    }

    this.formGroup.disable();
  }
}

import { ChangeDetectionStrategy, Component, computed, EventEmitter, HostBinding, inject, Input, Output, signal } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';

import { unionBy } from 'lodash';

import {
    AboutYouAnswer,
    AboutYouAnswerResponse,
    AboutYouQuestion,
    AboutYouUserAnswer,
    ApplicationAboutYouResponse,
    ApplicationUser,
    Category,
    LayoutService,
    ScreenSize,
} from '@acorn/util';

import { AboutYouService } from '@acorn/data-access';
import { ApplicationUserFormTemplate } from '@acorn/feature-application';
import { Helper } from '../../../helpers/helper';

@Component({
    selector: 'acorn-base-about-you-content',
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``,
    styleUrls: ['base-about-you-content.component.scss'],
})
export class BaseAboutYouContentComponent {
    protected readonly layoutService = inject(LayoutService);
    protected readonly aboutYouService = inject(AboutYouService);
    #helper = inject(Helper);

    @Input({ required: true }) applicationId!: string;
    @Input({ required: true }) applicationUsers!: ApplicationUser[];

    @Input({ required: true }) parentCategory!: Category;
    @Input({ required: true }) currentCategory!: Category;

    @Input({ required: true }) questions: AboutYouQuestion[] = [];
    @Input({ required: true })
    applicationAboutYou: ApplicationAboutYouResponse[] = [];

    @Output() async = new EventEmitter<ApplicationAboutYouResponse[]>();
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    @HostBinding('class.dual-respondent')
    get isDualRespondent() {
        return this.currentCategory?.isDualRespondent && this.applicationUsers.length === 2;
    }

    protected readonly ScreenSize = ScreenSize;

    isLoading = signal<boolean>(false);
    errorMessages = signal<string[]>([]);
    displayedTotalMessages = computed(() =>
        this.errorMessages().length > 1 ? `${this.errorMessages().length} changes need` : `${this.errorMessages().length} change needs`
    );
    serverErrorMessage = signal<string>('');
    isSubmitted = signal(false);

    formTemplate: { [key: string]: ApplicationUserFormTemplate } = {};

    applicationUserOrder = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number =>
        this.formTemplate[a.key]?.order > this.formTemplate[b.key]?.order ? 1 : 0;

    protected updateAnswer(userAnswers: AboutYouUserAnswer[]): void {
        userAnswers.forEach((item) => {
          delete item.isKOQuestion;
          delete item.isShowWarning;
        });

        this.isLoading.set(true);

        const latestUserAnswers: AboutYouUserAnswer[] = userAnswers;
        this.applicationAboutYou.forEach((item) => {
          const userAnswer = userAnswers.find(
            (userAnswer) => userAnswer.applicationUserId === item.applicationUserId
          );

          if (!userAnswer) {
            latestUserAnswers.push({
              applicationUserId: item.applicationUserId,
              answers: this.#mapAboutYouAnswersToUpdatedAnswers(item.answers),
            });
          } else {
            userAnswer.answers = unionBy(
              userAnswer.answers,
              this.#mapAboutYouAnswersToUpdatedAnswers(item.answers),
              'questionId'
            );
          }
        });

        this.aboutYouService
          .updateAnswers(this.applicationId, latestUserAnswers)
          .subscribe(({ isSuccess, data, message }) => {
            this.#helper.updateStatus(this.applicationId);

            if (!isSuccess) {
              this.isLoading.set(false);
              this.serverErrorMessage.set(message);
              return;
            }

            this.async.emit(data);

            this.handleBeforeNext();
          });
      }

    private handleBeforeNext(): void {
        this.isLoading.set(false);
        this.next.emit();
    }

    #mapAboutYouAnswersToUpdatedAnswers(aboutYouAnswers: AboutYouAnswerResponse[]): AboutYouAnswer[] {
        return (aboutYouAnswers || []).map((item) => ({
            order: item.order,
            content: item.answerContent,
            questionId: item.questionId,
            isRequired: false,
            isMultiAnswerQuestion: item.isMultiAnswerQuestion,
            multiAnswerItems: item.multiAnswerItems,
        }));
    }


    protected updateNewAnswer(userAnswers: AboutYouUserAnswer[] | any , isFromSuperFunds:boolean = false): void {
        userAnswers.forEach((item: { isKOQuestion: any; isShowWarning: any; }) => {
            delete item.isKOQuestion;
            delete item.isShowWarning;
        });

        this.isLoading.set(true);

        const latestUserAnswers: AboutYouUserAnswer[] = userAnswers;

        this.applicationAboutYou.forEach((item) => {
            if(isFromSuperFunds){
                const v = userAnswers.filter((userAnswer: { applicationUserId: string; }) => userAnswer.applicationUserId === item.applicationUserId);
                const a = Object.values(
                    v.reduce((acc: any, item: { applicationUserId: string | number; answers: any; }) => {
                        if (!acc[item.applicationUserId]) {
                            acc[item.applicationUserId] = {
                                applicationUserId: item.applicationUserId,
                                answers: [...item.answers],
                            };
                        } else {
                            acc[item.applicationUserId].answers.push(...item.answers);
                        }
                        return acc;
                    }, {})
                );
                if (v.length === 0) {
                    latestUserAnswers.push({
                        applicationUserId: item.applicationUserId,
                        answers: this.#mapAboutYouAnswersToUpdatedAnswers(item.answers),
                    });
                } else {
                    const mergedAnswers = unionBy(
                        a.flatMap((i:any) => i.answers),
                        this.#mapAboutYouAnswersToUpdatedAnswers(item.answers),
                        'questionId'
                    );
                    latestUserAnswers.push({
                        applicationUserId: item.applicationUserId,
                        answers: mergedAnswers,
                    });
                }

            }
        });

        this.aboutYouService.updateAnswers(this.applicationId, latestUserAnswers).subscribe(({ isSuccess, data, message }) => {
            this.#helper.updateStatus(this.applicationId);

            if (!isSuccess) {
                this.isLoading.set(false);
                this.serverErrorMessage.set(message);
                return;
            }

            this.async.emit(data);

            this.handleBeforeNext();
        });
    }
}

export enum BillingInterval {
  Month = 'month',
  Year = 'year',
}

export enum MembershipType {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
}

export interface PricingCheckout {
  coupon?: string;
  productId: string;
  currency: string;
  billingIntervalType: BillingInterval;
  redirectUrl: string;
}

export interface PricingResponse {
  sessionId?: string;
  id?: string;
}

export interface UpdateSubscription {
  coupon?: string;
  subscriptionId: string;
  productId: string;
}

export interface PricingPackage {
  id: string;
  name: MembershipType;
  packageUrlBadge: string;
  description: string;
  price: number;
  originalPrice: number;
  percentOff: number;
  amountOff: number;
  currency: string;
  interval: BillingInterval;
  validBenefits: string[];
  invalidBenefits: string[];
  tag: string[];
}

export interface UpComingInvoice {
  amountDue: number;
  dueDate: Date;
  appliedBalance: number;
  discounts: Discount[];
  invoiceLines: InvoiceLine[];
  subtotal: number;
  total: number;
  totalDiscountAmount: number;
}

interface Discount {
  couponId: string;
  amount: number;
}

interface InvoiceLine {
  description: string;
  unitPrice: number;
  amount: number;
}

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'acorn-progress-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent  {
 @Input()  number: any;
 @Input()  filledSlices!: number;

 @Input({required:true}) slices =10;

 public get slicesArray(){
  return new Array(this.slices)
 }
  
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import {
  APPLICATION_ID,
  CommonResponse,
  WealthHealthScore,
} from '@acorn/util';

@Injectable()
export class WealthHealthService {
  #httpClient = inject(HttpClient);
  #applicationUserId = inject(APPLICATION_ID);

  getScore() {
    const params = this.#applicationUserId.value ? `/score?applicationId=${this.#applicationUserId.value}` : '/current/score';

    return this.#httpClient.get<CommonResponse<WealthHealthScore>>(
      `${environment.apiUrls.customer}/api/ApplicationWealthHealth${params}`
    );
  }
}

<div class="container">
  <button class="close-button" (click)="onClose()">&#x2715;</button>
  <div class="mt-2">
    <iframe
      src="https://calendly.com/wealthmaximiser/goalsettingwithawealthcoach?month=2024-09"
      width="100%"
      height="500px"
      frameborder="0"
    ></iframe>
  </div>
</div>

import { Directive, HostListener } from '@angular/core';

import { BaseTooltipDirective } from './base-tooltip.directive';

@Directive({ selector: '[acornClickableTooltip]', standalone: true })
export class ClickableTooltipDirective extends BaseTooltipDirective {
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const isClickOnCurrentElement = (
      this.elementRef.nativeElement as HTMLElement
    ).contains(event.target as HTMLElement);
    if (isClickOnCurrentElement && !this.isActive()) {
      this.show();
    } else {
      this.hide();
    }
  }
}

<div mat-dialog-content>
  <div class="content">
    <svg-icon-sprite
      classes="primary-color"
      [src]="'assets/sprites/sprite.svg#warning-sign'"
      [width]="'30px'"
    ></svg-icon-sprite>

    <h1>We’ve hit a snag</h1>
    <p *ngIf="message(); else defaultTemplate">{{ message() }}</p>
  </div>

  <button mat-stroked-button (click)="close()">Close</button>
</div>

<ng-template #defaultTemplate>
  <p>
    Unfortunately some of the details provided means we cannot provide advice
    for you at this time. Details that commonly limit the advice we can
    provide include:
  </p>

  <ul>
    <li>Complex financial situations that exceed the tool's capabilities.</li>
    <li>Unique financial goals that require greater customisation.</li>
    <li>Mismatch with the person's demographic factors.</li>
    <li>Legal or regulatory constraints in different jurisdictions.</li>
  </ul>

  <p>
    Please contact Wealth Maximiser for more information on how to proceed:
    <a href="mailto:info@wealthmaximiser.com.au">
      info@wealthmaximiser.com.au
    </a>
  </p>
</ng-template>

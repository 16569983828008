import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {
  FormControl,
  FormRecord,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

import { forEach, map } from 'lodash';

import {
  AboutYouUserAnswer,
  AnswerSelection,
  ERROR_MESSAGE,
} from '@acorn/util';
import { AlertComponent, NavigationComponent } from '@acorn/common-ui';

import { BaseAboutYouContentComponent } from '../base-about-you-content';

import { LastReviewFormTemplate } from '../../utils/interfaces';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'acorn-last-review',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    NavigationComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    AlertComponent,
    MatExpansionModule,
    IconSpriteModule,
    MatIconModule
  ],
  templateUrl: 'last-review.component.html',
  styleUrls: [
    '../base-about-you-content/base-about-you-content.component.scss',
    'last-review.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastReviewComponent
  extends BaseAboutYouContentComponent
  implements OnInit
{
  @Input({ required: true }) answerSelections: AnswerSelection[] = [];
  @Input() isFileNotes : boolean = false;

  protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

  override formTemplate: { [key: string]: LastReviewFormTemplate } = {};

  formRecord: FormRecord<FormRecord<FormControl<string | null>>> =
    new FormRecord({});

  ngOnInit() {
    this.applicationUsers.forEach((applicationUser) => {
      const applicationUserFormRecord = new FormRecord<
        FormControl<string | null>
      >({});
      const formTemplate: LastReviewFormTemplate = {
        applicationUserId: applicationUser.id,
        applicationUserName: applicationUser.firstName,
        order: applicationUser.order,
        questions: {},
      };

      const savedAnswers =
        this.applicationAboutYou.find(
          (item) => item.applicationUserId === applicationUser.id
        )?.answers || [];

      this.questions?.forEach((question) => {
        const answer = savedAnswers.find(
          (item) => item.questionId === question.questionId
        );
        const selectedAnswerSelection = this.answerSelections.find(
          (item) =>
            item.content === answer?.answerContent &&
            item.questionId === answer?.questionId
        );

        applicationUserFormRecord.addControl(
          question.questionId,
          new FormControl<string>(
            selectedAnswerSelection?.id || '',
            question.isRequired ? Validators.required : null
          )
        );

        const filteredOptions = this.answerSelections
          .filter((item) => item.questionId === question.questionId)
          .map((item) => ({
            id: item.id,
            content: item.content,
            order: item.order,
          }));

        formTemplate.questions[question.questionId] = {
          content: question.question.content,
          isRequired: question.isRequired,
          options: filteredOptions || [],
        };
      });

      this.formRecord.addControl(applicationUser.id, applicationUserFormRecord);
      this.formTemplate[applicationUser.id] = formTemplate;
    });
  }

  onSubmit(): void {
    if (this.isFileNotes) {
      this.next.emit();
      return;
    }

    this.formRecord.markAsDirty();
    this.formRecord.markAllAsTouched();
    this.isSubmitted.set(true);
    this.errorMessages.set([]);
    this.serverErrorMessage.set('');

    if (this.formRecord.invalid) {
      this.errorMessages.set([ERROR_MESSAGE.ALL_ARE_REQUIRED]);
      return;
    }

    this.updateAnswer(this.#convertFormValueToUserAnswer());
  }

  #convertFormValueToUserAnswer(): AboutYouUserAnswer[] {
    const userAnswers: AboutYouUserAnswer[] = [];

    forEach(this.formRecord.controls, (formRecord, applicationUserId) => {
      const selectedApplicationUser = this.formTemplate[applicationUserId];
      userAnswers.push({
        applicationUserId: selectedApplicationUser.applicationUserId,
        answers: map(formRecord.controls, (formControl, questionId) => {
          const selectedQuestion =
            selectedApplicationUser.questions[questionId];
          const selectedOption = selectedQuestion.options.find(
            (option) => option.id === formControl.value
          );

          return {
            questionId,
            isRequired: selectedQuestion.isRequired,
            order: selectedOption?.order || 0,
            content: selectedOption?.content || '',
          };
        }),
      });
    });

    return userAnswers;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  NgIf,
  NgSwitch,
  NgSwitchCase,
  ViewportScroller,
} from '@angular/common';

import { catchError, EMPTY, finalize, iif, of, switchMap, take } from 'rxjs';

import { CdkStepperModule } from '@angular/cdk/stepper';

import { AlertComponent, NavigationComponent } from '@acorn/common-ui';
import {
  ApplicationService,
  UserSubscriptionPlanService,
  WealthHealthService,
} from '@acorn/data-access';
import {
  ApplicationStatus,
  ApplicationStep,
  ApplicationSubStep,
  CustomerUser,
  WealthHealthScore,
  WealthHealthStep,
} from '@acorn/util';
import { ApplicationStepIndex } from '@acorn/feature-application';

import { ArrayQuestionComponent } from '../feature-financial-situation/ui';

import {
  NestedVerticalStepperComponent,
  VerticalStepperComponent,
  WelcomeScreenComponent,
} from '../ui';
import {
  WealthBuildingBlocksComponent,
  WealthHappyScreenComponent,
  WealthHealthScoreComponent,
} from './ui';
import {
  getSelectedCategoryIndex,
  isWealthScoreStep,
} from './helpers/wealth-health.helper';

@Component({
  selector: 'acorn-wealth-health',
  templateUrl: 'wealth-health.component.html',
  styleUrls: ['wealth-health.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AlertComponent,
    ArrayQuestionComponent,
    CdkStepperModule,
    NavigationComponent,
    WelcomeScreenComponent,
    NgIf,
    VerticalStepperComponent,
    NestedVerticalStepperComponent,
    WealthHealthScoreComponent,
    WealthBuildingBlocksComponent,
    WealthHappyScreenComponent,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class WealthHealthComponent implements OnInit {
  #wealthHealthService = inject(WealthHealthService);
  #applicationService = inject(ApplicationService);
  #userSubscriptionPlanService = inject(UserSubscriptionPlanService);
  #viewport = inject(ViewportScroller);

  @Input({ required: true }) latestApplicationStep!: ApplicationStep;
  @Input() isDashboardApp: boolean = false;
  @Input() isFileNotes: boolean = false;
  @Input() currentUser!: CustomerUser;
  @Output() back = new EventEmitter<void>();
  @Output() finishedStep = new EventEmitter<{
    isUpdateApplicationProgress: boolean;
  }>();

  public isApplicationEditable: boolean = false;

  readonly description = `While understanding your current financial situation is crucial, so too is assessing your Wealth Health. By gaining an understanding of the "Wealth building blocks" and your current status in relation to these building blocks, referred to as "Wealth Health", you can more effectively grasp the priorities and strategies needed to achieve your goals.`;

  selectedCategoryIndex = signal<number>(0);
  markAsSeen = signal(false);
  isLoading = signal<boolean>(false);
  wealthHealthScore = signal<WealthHealthScore | null>(null);

  get isFinishedStep() {
    return (
      ApplicationStepIndex[this.latestApplicationStep] >
      ApplicationStepIndex[ApplicationStep.WealthHealth]
    );
  }

  get currentAppSubStep(): ApplicationSubStep | null {
    return this.currentUser.currentApplication?.currentSubStep || null;
  }

  ngOnInit() {
    this.#setupWealthHealthStepIndex();

    if(this.currentUser.currentApplication?.status === "Active" && this.currentUser.currentSubscription !== null){
      if(this.currentUser.currentSubscription?.countScheduleTime > 0){
        this.isApplicationEditable = true;
      }
    }

    if (isWealthScoreStep(this.selectedCategoryIndex(), this.isApplicationEditable)) {
      this.#loadWealthHealScoreData();
    }
  }

  onCategoryChanged(index: number): void {
    this.selectedCategoryIndex.set(index);

    if (index !== 1) {
      return;
    }

    this.#loadWealthHealScoreData();
  }

  onHandleBack(): void {
    this.#scrollToTop();

    if (this.selectedCategoryIndex() === 0) {
      this.back.emit();
      return;
    }

    this.selectedCategoryIndex.update((index) => index - 1);
  }

  onHandleNext(): void {
    if(this.isApplicationEditable && this.selectedCategoryIndex() === 2){
      sessionStorage.setItem("welcomeBackEditable", "false");
      this.#applicationService
        .updateCurrentApplicationStatus(ApplicationStatus.Complete)
        .subscribe(() => {
          // window.location.reload();
        });
    }

    const wealthStep = isWealthScoreStep(this.selectedCategoryIndex(), this.isApplicationEditable)
      ? WealthHealthStep.WealthScore
      : WealthHealthStep.WealthBuildingBlock;

    this.#userSubscriptionPlanService.userSubscription$
      .pipe(
        take(1),
        switchMap((userSubscription) =>
          iif(
            () => this.isFinishedStep,
            of(Boolean(!userSubscription)),
            this.#applicationService
              .updateCurrentProgress(ApplicationStep.WealthHealth, wealthStep)
              .pipe(switchMap(() => of(true)))
          )
        )
      )
      .subscribe({
        next: (isUpdateProgress) => {
          this.#scrollToTop();
          this.#handleWealthHealthNextStep(isUpdateProgress);
        },
      });

  }

  #handleWealthHealthNextStep(isUpdateApplicationProgress: boolean): void {
    if(this.isApplicationEditable) {
      isUpdateApplicationProgress = true;
    }
    
    if (isWealthScoreStep(this.selectedCategoryIndex(), this.isApplicationEditable)) {
      this.finishedStep.emit({ isUpdateApplicationProgress });
    } else {
      this.selectedCategoryIndex.update((index) => index + 1);
    }
  }

  #loadWealthHealScoreData(): void {
    this.isLoading.set(true);
    this.#wealthHealthService
      .getScore()
      .pipe(
        catchError(() => EMPTY),
        finalize(() => this.isLoading.set(false))
      )
      .subscribe(({ data, isSuccess }) => {
        if (!isSuccess || !data) {
          return;
        }

        this.wealthHealthScore.set(data);
      });
  }

  #setupWealthHealthStepIndex(): void {
    const isFinishSubStep =
      Boolean(this.currentAppSubStep) &&
      (Object.values(WealthHealthStep) as string[]).includes(
        this.currentAppSubStep!.toString()
      );

    this.markAsSeen.set(this.isFinishedStep || isFinishSubStep);

    if (this.markAsSeen()) {
      this.selectedCategoryIndex.set(
        getSelectedCategoryIndex(this.isFinishedStep, this.currentAppSubStep, this.isApplicationEditable)
      );
    }
  }

  #scrollToTop(): void {
    this.#viewport.scrollToPosition([0, 0]);
  }
}

import { CdkStepper } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import {
    ApplicationStatus,
    ApplicationStep,
    CustomerUser,
    SubscriptionPlan,
} from '@acorn/util';

import { ApplicationStepIndex } from '../../util';
import { AlertComponent } from '@acorn/common-ui';
import { Directionality } from '@angular/cdk/bidi';

@Component({
    selector: 'acorn-application-stepper',
    templateUrl: 'application-stepper.component.html',
    styleUrls: ['application-stepper.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        [{ provide: CdkStepper, useExisting: ApplicationStepperComponent }],
    ],
    imports: [
        CommonModule,
        MatIconModule,
        IconSpriteModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        AlertComponent,
    ],
})
export class ApplicationStepperComponent extends CdkStepper {
    @Input() currentUser!: CustomerUser;
    @Input({ required: true }) latestApplicationStep!: ApplicationStep;
    @Input() subscriptionPlan!: SubscriptionPlan | undefined;
    @Input({ required: true }) dashboardLastStep!: ApplicationStep;
    @Input() onlyAboutYou = false;
    @Input() isFromSummary = false;
    @Input() isFileNotes = false;
    @Input() isDashboardApp = false;

    // Added by Kumar
    public showAlert = false;

    constructor(
        _dir: Directionality,
        _changeDetectorRef: ChangeDetectorRef,
        _elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef
    ) {
        localStorage.removeItem('goalValue');
        super(_dir, _changeDetectorRef, _elementRef);
        // this.updateStepper();
    }

    applicationStepper = [
        {
            value: 0,
            viewValue: 'Summary',
        },
        {
            value: 1,
            viewValue: 'About You',
        },
        {
            value: 2,
            viewValue: 'Your Goals',
        },
        {
            value: 3,
            viewValue: 'Financial Snapshot',
        },
        {
            value: 4,
            viewValue: 'Risk Tolerance',
        },
        {
            value: 5,
            viewValue: 'Wealth Health Score',
        },
        // milestone2-changes
        {
            value: 6,
            viewValue: 'Financial Projection',
        },
        {
            value: 7,
            viewValue: 'Wealth Strategy Options',
        },
        {
            value: 8,
            viewValue: 'Wealth Coach',
        },
    ];

    public readonly ApplicationStepIndex = ApplicationStepIndex;

    selectStepByIndex(index: number): void {
        if (index === 0 && (!this.isDashboardApp && !this.isFileNotes)) {
            window.location.href = "";
        }

        if (localStorage.getItem('goalValue') === 'null') {
            this.showAlert = true;

            setTimeout(() => {
                this.showAlert = false;
                this.cdr.detectChanges();
            }, 3000);

            return;
        }

        if (this.onlyAboutYou || this.isFromSummary) {
            if (index > ApplicationStepIndex[this.dashboardLastStep]) {
                return;
            }
        } else {
            if (index > ApplicationStepIndex[this.latestApplicationStep]) {
                return;
            }
        }

        if (
            index === ApplicationStepIndex[ApplicationStep.WealthCoach] ||
            index ===
                ApplicationStepIndex[ApplicationStep.FinancialProjection] ||
            index ===
                ApplicationStepIndex[ApplicationStep.WealthStrategyOptions]
        ) {
            if (!this.subscriptionPlan) {
                return;
            }
        }

        this.selectedIndex = index;
    }

    isStepChecked(index: number): boolean {
        if (this.onlyAboutYou || this.isFromSummary) {
            return (
                this.selectedIndex > index ||
                this.#isStepFinishedByDashboardLastStep(index)
            );
        }
        return this.selectedIndex > index || this.#isStepFinished(index);
    }

    #isStepFinished(index: number): boolean {
        const wealthCoachIndex =
            ApplicationStepIndex[ApplicationStep.WealthCoach];
        const financialProjectionIndex =
            ApplicationStepIndex[ApplicationStep.FinancialProjection];
        const wealthStrategyOptionsIndex =
            ApplicationStepIndex[ApplicationStep.WealthStrategyOptions];

        if (
            (index === wealthCoachIndex ||
                index === financialProjectionIndex ||
                index === wealthStrategyOptionsIndex) &&
            !this.subscriptionPlan
        ) {
            return false;
        }

        return index <= ApplicationStepIndex[this.latestApplicationStep];
    }

    isStepDisabled(stepValue: number): boolean {
        if (
            stepValue ===
                ApplicationStepIndex[ApplicationStep.FinancialProjection] ||
            stepValue ===
                ApplicationStepIndex[ApplicationStep.WealthStrategyOptions] ||
            stepValue === ApplicationStepIndex[ApplicationStep.WealthCoach]
        ) {
            return !this.subscriptionPlan;
        }
        return false;
    }

    #isStepFinishedByDashboardLastStep(index: number): boolean {
        const wealthCoachIndex =
            ApplicationStepIndex[ApplicationStep.WealthCoach];
        const financialProjectionIndex =
            ApplicationStepIndex[ApplicationStep.FinancialProjection];
        const wealthStrategyOptionsIndex =
            ApplicationStepIndex[ApplicationStep.WealthStrategyOptions];

        if (
            (index === wealthCoachIndex ||
                index === financialProjectionIndex ||
                index === wealthStrategyOptionsIndex) &&
            !this.subscriptionPlan
        ) {
            return false;
        }

        return index <= ApplicationStepIndex[this.dashboardLastStep];
    }

    public isStepHidden(stepValue: number): boolean {
        return (
            this.currentUser &&
            this.currentUser.currentApplication?.status ===
                ApplicationStatus.Complete &&
            stepValue ===
                ApplicationStepIndex[ApplicationStep.WealthStrategyOptions]
        );
    }

    canHideWealthStrategyOptions(value: number) {
        if (
            value === 7 &&
            (this.currentUser?.currentApplication?.status !==
                ApplicationStatus.Complete ||
                this.currentUser?.currentApplication
                    ?.isAllStrategiesApproved !== true || !this.currentUser?.currentApplication?.goalApprovedDate  || this.currentUser?.currentApplication
                    ?.recalculateAfterEdit === true)
        ) {
            return true;
        }

        return false;
    }
}

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { from, map, noop } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

import {
  ActionMembershipPlan,
  ApplicationStatus,
  ApplicationStep,
  CustomerUser,
  HomeEntry,
  MembershipType,
  SafePipe,
} from '@acorn/util';
import { ApplicationService, BaseContentfulService } from '@acorn/data-access';
import { environment } from '@env';
import { NavigationComponent } from '@acorn/common-ui';

@Component({
  selector: 'acorn-completed-successfully-profile',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    IconSpriteModule,
    MatButtonModule,
    SafePipe,
    MatProgressSpinnerModule,
    NavigationComponent
  ],
  templateUrl: './completed-successfully-profile.component.html',
  styleUrls: ['./completed-successfully-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedSuccessfullyProfileComponent implements OnInit {
  #contentFullService = inject(BaseContentfulService);
  #router = inject(Router);
  #applicationService = inject(ApplicationService);

  isAbleToUpgrade = false;
  videoUrl = signal<string>('');

  @Input() currentUser !: CustomerUser;
  @Input() recalculateAfterEdit = false;
  @Input() memberShip: MembershipType = MembershipType.Bronze;
  @Input() actionList: ActionMembershipPlan[] = [];
  @Input() featureImageUrl: string = '';
  @Input() isFileNotes: Boolean = false;

  protected readonly ApplicationStatus = ApplicationStatus;

  ngOnInit(): void {
    from(
      this.#contentFullService.getEntry<HomeEntry>(
        environment.contentfulConfig.entryIds.homePage
      )
    )
      .pipe(map((homeEntry) => homeEntry.meetTheWealthCoachesUrl))
      .subscribe({
        next: (video) => {
          this.videoUrl.set(video);
        },
      });
  }

  goToWealthPhilosophy(): void {
    this.#router
      .navigate([
        '/article',
        environment.contentfulConfig.entryIds.wealthPhilosophyPage,
      ])
      .then(noop);
  }

  gotoPricingPage(): void {
    this.#router.navigate(['/pricing']).then(noop);
  }

  gotoDashboard(): void {
    this.#applicationService
        .updateCurrentProgress(ApplicationStep.Dashboard, null, null)
        .subscribe(() => {
            this.#router.navigate(['/dashboard']).then(noop);
        });
}
}

<div class="title">Wealth Building Blocks</div>

<p class="description">
  As you think about your financial future, it is helpful to understand the
  building blocks to wealth. <br /><br />

  <span
    acornTooltip
    [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_BUILDING_BLOCKS.TITLE"
    [tooltipMessage]="
      ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_BUILDING_BLOCKS.CONTENT
    "
    [isDisplayIconShowTooltip]="false"
  >
    Wealth Building Blocks
  </span>
  are the fundamental components that contribute to maximising wealth and
  achieving your key goals. It's important to lay the right foundation, whilst
  ensuring you are accumulating wealth and preparing for your financial
  independence and legacy. <br /><br />

  These building blocks are below. Click on them for more information.
</p>

<div class="block-pyramid">
  <div class="block-pyramid__item bg-orange">
    <div
      class="label"
      acornTooltip
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.GOAL.CONTENT"
      [isDisplayIconShowTooltip]="false"
    >
      Goal Achievement
    </div>

    <div
      class="building-block bg-whtie"
      acornTooltip
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.GOAL.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.GOAL.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/goal-3x.png" />
      <div class="block-label">Goals</div>
    </div>
  </div>

  <div class="block-pyramid__item bg-light-gray">
    <div
      class="label"
      acornTooltip
      [tooltipMessage]="
        ACORN_TOOLTIP.WEALTH_HEALTH.INDEPENDENCE_AND_LEGACY.CONTENT
      "
      [isDisplayIconShowTooltip]="false"
    >
      Independence and Legacy
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.ESTATE.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.ESTATE.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/estate-3x.png" />
      <div class="block-label">Estate Planning</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.RETIREMENT.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.RETIREMENT.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/retirement-3x.png" />
      <div class="block-label">Retirement Planning</div>
    </div>
  </div>

  <div class="block-pyramid__item bg-light-gray">
    <div
      class="label"
      acornTooltip
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_ACCUMULATION.CONTENT"
      [isDisplayIconShowTooltip]="false"
    >
      Wealth Accumulation
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.STOCKS.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.STOCKS.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/stocks-3x.png" />
      <div class="block-label">Shares & Investments</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.SUPER.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.SUPER.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/super-3x.png" />
      <div class="block-label">Superannuation</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.PROPERTY.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.PROPERTY.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/property-3x.png" />
      <div class="block-label">Property</div>
    </div>
  </div>

  <div class="block-pyramid__item bg-gray">
    <div
      class="label"
      acornTooltip
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_FOUNDATION.CONTENT"
      [isDisplayIconShowTooltip]="false"
    >
      Wealth Foundation
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.INCOME.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.INCOME.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/income-3x.png" />
      <div class="block-label">Income</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.SAVINGS.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.SAVINGS.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img class="h-90" src="assets/images/savings-3x.png" />
      <div class="block-label">Savings, Budgeting & Debt</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.TAX.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.TAX.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/tax-3x.png" />
      <div class="block-label">Tax & Structuring</div>
    </div>

    <div
      acornTooltip
      class="building-block"
      [tooltipMessage]="ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_PROTECTION.CONTENT"
      [tooltipTitle]="ACORN_TOOLTIP.WEALTH_HEALTH.WEALTH_PROTECTION.TITLE"
      [isDisplayIconShowTooltip]="false"
    >
      <img src="assets/images/wealth-protection-3x.png" />
      <div class="block-label">Wealth Protection & Insurance</div>
    </div>
  </div>
</div>

import {
  ComponentRef,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';

import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[acornBaseTooltip]', standalone: true })
export class BaseTooltipDirective implements OnInit, OnDestroy {
  #overlay = inject(Overlay);
  #overlayPositionBuilder = inject(OverlayPositionBuilder);

  elementRef = inject(ElementRef);

  @Input() tooltipTitle?: string;
  @Input() tooltipMessage? = '';

  isActive = signal(false);

  #overlayRef?: OverlayRef;
  #tooltipRef: ComponentRef<TooltipComponent> | null = null;
  #scrollStrategy: ScrollStrategy = inject(ScrollStrategyOptions).close();

  protected positionStrategy!: FlexibleConnectedPositionStrategy;
  protected positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -5,
      panelClass: 'tooltip-panel-center-top',
    },
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 5,
      panelClass: 'tooltip-panel-center-bottom',
    },
  ];

  ngOnInit(): void {
    this.createOverlay(this.elementRef);
  }

  ngOnDestroy(): void {
    this.hide();
  }

  protected show(): void {
    if (
      !this.tooltipMessage ||
      !this.#overlayRef ||
      this.#overlayRef.hasAttached()
    ) {
      return;
    }

    this.isActive.set(true);

    const tooltipPortal = new ComponentPortal(TooltipComponent);

    this.#tooltipRef = this.#overlayRef.attach(tooltipPortal);
    this.#tooltipRef.instance.label = this.tooltipTitle;
    this.#tooltipRef.instance.text = this.tooltipMessage;
  }

  protected hide(): void {
    this.isActive.set(false);
    this.#tooltipRef = null;
    this.#overlayRef?.detach();
  }

  protected createOverlay(
    connected: FlexibleConnectedPositionStrategyOrigin
  ): void {
    this.positionStrategy = this.#overlayPositionBuilder
      .flexibleConnectedTo(connected)
      .withPositions(this.positions);

    this.positionStrategy.positionChanges.subscribe(
      (connectedOverlayPositionChange) => {
        if (!this.#overlayRef || !this.#tooltipRef) {
          return;
        }

        this.#tooltipRef.setInput(
          'position',
          connectedOverlayPositionChange.connectionPair.panelClass as string
        );
        this.#tooltipRef.changeDetectorRef.detectChanges();
      }
    );

    this.#overlayRef = this.#overlay.create({
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.#scrollStrategy,
    });
  }
}

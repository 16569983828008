<ng-container *ngIf="welcomeTemplate; else chooseGoal">
  <div style="background: linear-gradient(180deg, #ffffff 0%, #f3f2f2 100%)">
    <div class="goal-setting-container">
      <div class="goal-setting-header">
        <h2>Goal Setting <span>(5-10 mins)</span></h2>
        <p class="goal-note">
          When was the last time you thought about your financial goals?
        </p>
        <p class="goal-m-15">
          Goals are your future targets, both short term (0-2 years) and long
          term (2+ years).
        </p>
        <p>
          Choosing goals forms the foundation of your entire Wealth Maximiser
          financial plan, so it's important to spend some time thinking about
          what’s most important to you when taking that first step in your
          wealth building journey.
          <a style="cursor: pointer" (click)="openReflectionPopup()"
            >Discover more about your core values and help realise your goals
            here.</a
          >
        </p>
      </div>

      <div class="steps-container goal-m-40">
        <div class="step">
          <div class="step-header">
            <div class="step-number">1</div>
            <div class="step-title">Visualise</div>
          </div>
          <div class="step-content">
            <div class="step-icon image-center">
              <img src="/assets/svg/visualise.svg" alt="Visualise" />
            </div>
            <div class="step-description">
              <p class="mb-15">
                Reflect on your values to start figuring out your most important
                goals.
              </p>
              <p>Take some time to think about your financial future.</p>
            </div>
          </div>
        </div>
        <div class="arrow-container">
          <img src="/assets/svg/arrow.svg" alt="Arrow" />
        </div>

        <div class="step">
          <div class="step-header">
            <div class="step-number">2</div>
            <div class="step-title">Choose your goals</div>
          </div>
          <div class="step-content">
            <div class="step-icons">
              <div class="step-icon-goal image-center">
                <img src="/assets/svg/goal1.svg" alt="Icon 2" />
              </div>
              <div class="step-icon-goal image-center">
                <img src="/assets/svg/goal2.svg" alt="Icon 3" />
              </div>
              <div class="step-icon-goal image-center">
                <img src="/assets/svg/goal3.svg" alt="Icon 4" />
              </div>
              <div class="step-icon-goal image-center">
                <img src="/assets/svg/goal4.svg" alt="Icon 5" />
              </div>
            </div>
            <div class="step-description">
              <p class="mb-15">
                Choose a minimum of 5 goals across our 4 wealth focus areas.
              </p>
              <p>
                For best results, we recommend including a mix of short and long
                term goals.
              </p>
            </div>
          </div>
        </div>
        <div class="arrow-container">
          <img src="/assets/svg/arrow.svg" alt="Arrow" />
        </div>

        <div class="step">
          <div class="step-header">
            <div class="step-number">3</div>
            <div class="step-title">Prioritise</div>
          </div>
          <div class="step-content">
            <div class="step-icon image-center">
              <img src="/assets/svg/prioritise.svg" alt="Prioritise" />
            </div>
            <div class="step-description">
              <p class="mb-15">
                Prioritise your top 5 goals in order of importance.
              </p>
              <p>We will create a plan to help you achieve them!</p>
            </div>
          </div>
        </div>
      </div>

      <div class="get-started image-center">
        <acorn-application-navigation
          class="pa-10"
          (next)="getStarted()"
          (back)="onHandleBack()"
          [primaryButtonLabel]="'Get Started'"
        ></acorn-application-navigation>
      </div>
    </div>
  </div>
  <div class="bottem-content text-center">
    <div class="goal-setting-bottom-container">
      <!-- <p>
        Not sure how to get started? <br />
        Watch the video below to start choosing your goals
      </p>
      <div class="video-container goal-m-40">
        <Video muted width="100%" class="video" controls>
          <source src="{{ videoUrl }}" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> -->

      <p>Still unsure of your goals?</p>

      <div class="image-center goal-m-15">
        <div class="chat-btn d-flex align-center" (click)="moveToWealthCoach()">
          <img src="assets/images/support-icon.svg" />
          <span>Chat with a Wealth Coach</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #chooseGoal>
  <div *ngIf="!this.show">
    <acorn-your-goals
      [goalStep]="goalStep"
      [partStep]="partStep"
      [applicationUsers]="applicationUsers"
      [currentApplication]="currentApplication"
      [applicationGoal]="applicationGoal"
      [isWelcomeBackEditable]="isWelcomeBackEditable"
      (backClicked)="onBack()"
      (updatedProgress)="updateCurrentProgress($event)"
      (finishedStep)="onfinishedStep($event)"
      (triggerWebhook)="onTriggerWebhook($event)"
      [isDashboardApp]="isDashboardApp"
      [isFileNotes]="isFileNotes"
    ></acorn-your-goals>
  </div>
</ng-template>

<div *ngIf="show" class="loading-container">
  <p>Loading...</p>
</div>


import { CitizenshipStatus, DependantEnum, MaritalStatus } from '../enum';
import { DropDownOption } from '../interfaces';

export const DependantOption = {
  [DependantEnum.Children]: 'Children',
  [DependantEnum.ElderlyParent]: 'Elderly parent',
  [DependantEnum.LovedOneWithSpecialNeeds]: 'Loved one with special needs',
};

export const MaritalStatusOptions: DropDownOption<MaritalStatus>[] = [
  {
    value: MaritalStatus.Single,
    viewValue: 'Single',
  },
  {
    value: MaritalStatus.Divorced,
    viewValue: 'Divorced',
  },
  {
    value: MaritalStatus.Widowed,
    viewValue: 'Widowed',
  },
  {
    value: MaritalStatus.Separation,
    viewValue: 'Going through a separation',
  },
  {
    value: MaritalStatus.Married,
    viewValue: 'Married/in a civil partnership',
  },
  {
    value: MaritalStatus.RelationshipWithSharedFinances,
    viewValue: 'In a relationship with shared finances',
  },
  {
    value: MaritalStatus.RelationshipWithSeparateFinances,
    viewValue: 'In a relationship with separate finances',
  },
];

export const CitizenshipStatusOptions: DropDownOption<CitizenshipStatus>[] = [
  {
    value: CitizenshipStatus.PermanentResidentWithAllFinancial,
    viewValue:
      'Australian citizen/permanent resident with all financial assets in Australia',
  },
  {
    value: CitizenshipStatus.PermanentResidentWithSomeFinancial,
    viewValue:
      ' Australian citizen/permanent resident with some financial assets in another country',
  },
  {
    value: CitizenshipStatus.TemporaryVisaHolder,
    viewValue: 'Temporary visa holder',
  },
];

export const SEPARATOR = '|sts|';
export const REPLACE_TEXT = '$text';

export const WEBHOOK_STATUS = {
  wealth_health: 'Wealth Health',
  your_goals: 'Your Goals',
  about_you_and_financial_situation: 'About You and Financial Situation',
  wealth_coach: 'Wealth Coach',
  signed_up_not_started: 'Signed Up (Application Not Started) and Getting Started'
};

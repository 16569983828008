import { INLINES } from '@contentful/rich-text-types';

import { HyperlinkType, Options } from '../interfaces';

export const optionsCustomizeHyperlink: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next): string =>
      `<a class="secondary" href="${(node.data as HyperlinkType).uri}">${next(
        node.content
      )}</a>`,
  },
};

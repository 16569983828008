<h4>Try Wealth Maximiser</h4>

<button mat-flat-button color="primary" (click)="getStarted()">
  <div class="button-content-wrapper">
    <span>Get Started</span>

    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#arrow-right'"
      [width]="'24px'"
    ></svg-icon-sprite>
  </div>
</button>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'acorn-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, NgIf, MatIconModule],
})
export class AvatarComponent {
  @Input() url = '';

  @Input()
  size = 'medium';

  @HostBinding('class')
  get sizeClass() {
    return `size-${this.size}`;
  }
}

import {
  AfterContentInit,
  Directive,
  ElementRef,
  inject,
  Renderer2,
} from '@angular/core';

import { BaseTooltipDirective } from './base-tooltip.directive';
import { OverlayPositionBuilder } from '@angular/cdk/overlay';

@Directive({ selector: '[acornPartialTooltip]', standalone: true })
export class PartialTooltipDirective
  extends BaseTooltipDirective
  implements AfterContentInit
{
  #elementRef = inject(ElementRef);
  #renderer2 = inject(Renderer2);
  #overlayPositionBuilder = inject(OverlayPositionBuilder);

  tooltipPattern = /<tooltip>(?<content>.*)<\/tooltip>/;

  override ngOnInit() {
    // prevent create overlay
  }

  ngAfterContentInit() {
    const htmlElement = this.#elementRef.nativeElement as HTMLElement;

    if (this.tooltipPattern.test(htmlElement.innerText || '')) {
      const result = RegExp(this.tooltipPattern).exec(
        htmlElement.innerText || ''
      )!;
      const tooltipContent = result.groups!['content'];
      const span = this.#renderer2.createElement('span');
      span.innerText = tooltipContent;

      this.createOverlay(span);
      this.#listenTooltipEvent(span);

      const rawInnerText = htmlElement.innerText;
      htmlElement.innerText = '';
      rawInnerText.split(RegExp(this.tooltipPattern)).forEach((item) => {
        if (item === tooltipContent) {
          this.#renderer2.appendChild(htmlElement, span);
        } else if (item.length) {
          this.#renderer2.appendChild(
            htmlElement,
            this.#renderer2.createText(item)
          );
        }
      });
    } else {
      this.createOverlay(this.#elementRef);
      this.#listenTooltipEvent(this.#elementRef.nativeElement);
    }
  }

  #listenTooltipEvent(tooltipElement: HTMLElement): void {
    this.#renderer2.addClass(tooltipElement, 'acorn-tooltip');

    this.#renderer2.listen(tooltipElement, 'mouseenter', () => {
      this.#renderer2.addClass(tooltipElement, 'active');
      this.show();
    });
    this.#renderer2.listen(tooltipElement, 'mouseleave', () => {
      this.#renderer2.removeClass(tooltipElement, 'active');
      this.hide();
    });
  }
}

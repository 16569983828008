import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../../../../../../apps/customer-portal/src/app/common/ui';

@Component({
    selector: 'acorn-learning-hub',
    standalone: true,
    imports: [CommonModule, FooterComponent],
    templateUrl: './learning-hub.component.html',
    styleUrls: ['../information/information.component.scss', './learning-hub.component.scss'],
})
export class LearningComponent {
    public learningHub: learningHub[] = [
        {
            title: 'Budgets don’t work but these things do',
            description: 'When it comes to saving and spending money, we\'re not all the same. Yet, the financial advice world has treated us as if we are, particularly when they tell us to \'do a budget\'.',
            url: 'https://www.wealthmaximiser.com.au/media/budgets-dont-work-but-these-things-do/',
            type: 'Blog',
            imgae: 'assets/learning-hub/budgets-dont-work-but-these-things-do.jpg'
        },
        {
            title: 'How to save for your first home?',
            description: 'Many young Australians find the prospect of buying their own home a challenge. Without financial assistance from family - it can be extremely difficult to enter the property market.',
            url: 'https://www.wealthmaximiser.com.au/media/saving-up-for-your-first-home/',
            type: 'Blog',
            imgae: 'assets/learning-hub/how-to-save-for-your-first-home.jpg'
        },
        {
            title: 'Psychology of Money  ',
            description: 'Would you like to know what makes someone successful with money whilst others are more challenged? It\'s simple. It\'s their money mindset! ',
            url: 'https://www.wealthmaximiser.com.au/media/psychology-of-money/',
            type: 'Blog',
            imgae: 'assets/learning-hub/psychology-of-money.jpg'
        },
        {
            title: 'What is Passive Income How to Create One',
            description: 'Going to work and earning a wage isn\'t the only way you can make money.',
            url: 'https://www.wealthmaximiser.com.au/media/what-is-a-passive-income-and-how-to-create-one/',
            type: 'Media',
            imgae: 'assets/learning-hub/passive-income.jpg'
        },
        {
            title: 'Understanding Key Tax Concepts and Strategies',
            description: 'Understanding Key Tax Concepts and Strategies',
            url: 'https://www.wealthmaximiser.com.au/media/tax-strategies-product-selection-support/',
            type: 'Media',
            imgae: 'assets/learning-hub/tax.jpg'
        },
        {
            title: 'How to Secure Your Next Work Promotion',
            description: 'How to Secure Your Next Work Promotion: Essential Tips for Australians',
            url: 'https://www.wealthmaximiser.com.au/media/how-to-secure-your-next-work-promotion-essential-tips-for-australians/',
            type: 'Media',
            imgae: 'assets/learning-hub/new-job.jpg'
        }
    ];

    constructor() {
        this.learningHub = this.learningHub.map(item => ({
            ...item,
            description: this.truncateText(item.description, 7)
        }));
    }

    truncateText(text: string, limit: number): string {
        const words = text.split(' ');
        return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
    }

    itemsToShow = 6;

    showMore(): void {
        this.itemsToShow = this.learningHub.length;
    }

    open(item:any){
        window.open(item.url ,"_blank")
    }

}

export interface learningHub {
    title: string;
    description: string;
    url: string;
    type: string;
    imgae: string;
}

import {
  ApplicationPlan,
  ApplicationStatus,
  ApplicationStep,
  GoalSettingPartsStep,
  GoalSettingStep,
  GoalStep,
  PartStep,
  WealthCoachStep,
  WealthHealthStep,
} from '@acorn/util';

export interface Application {
  id: string;
  latest: boolean;
  name: string;
  status: ApplicationStatus;
  application: {
    id: string;
    status: ApplicationStatus;
  };
  userId: string;
  applicationMode?: ApplicationPlan;
  currentStep: ApplicationStep;
  currentSubStep: GoalSettingStep | WealthHealthStep | WealthCoachStep; // TODO: AboutYouStep, Financial Step...
  currentPartStep: GoalSettingPartsStep;
  applicationUsers: ApplicationUser[];
  email: string;
  createdDate: string;
  isLatestGoalBeingApplied: boolean;
  isCalculateRequired?: boolean;
  lastCalculatedDate?: Date;
  isAllStrategiesApproved?: boolean;
  goalApprovedDate?: Date;
  isManualFlow?: boolean;
  recalculateAfterEdit?:boolean
}

export interface ApplicationUser {
  id: string;
  applicationId: string;
  firstName: string;
  dateOfBirth: string;
  order: number;
}

export interface Answer {
  goalId: string;
  content: string;
  applicationUserId: string;
  questionId: string;
  group: number;
}

export interface ApplicationFilter {
  search: string;
  sortBy?: string;
  sortDirection?: string;
  pageNumber: number;
  pageSize: number;
  status?: string;
}

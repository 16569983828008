import {
    CommonModule,
    NgOptimizedImage,
    NgTemplateOutlet,
} from '@angular/common';
import { NavigationComponent } from '@acorn/common-ui';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { ApplicationStatus, ApplicationStep, CustomerUser, HomeEntry, MembershipType, SafePipe } from '@acorn/util';
import { from, map, noop } from 'rxjs';
import { ApplicationService, BaseContentfulService } from '@acorn/data-access';
import { environment } from '@env';

@Component({
    selector: 'acorn-returning-customers',
    templateUrl: 'returning-customers.component.html',
    styleUrls: ['returning-customers.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatStepperModule,
        MatButtonModule,
        NgTemplateOutlet,
        IconSpriteModule,
        MatIconModule,
        SafePipe,
        NgOptimizedImage,
        NavigationComponent,
    ],
})
export class ReturningCustomersComponent implements OnInit {
    #router = inject(Router);
    #applicationService = inject(ApplicationService);
    #contentFullService = inject(BaseContentfulService);

    readonly MemberShipType = MembershipType;
    @Input() currentUser !: CustomerUser;
    @Input() recalculateAfterEdit = false;
    @Input() memberShip = MembershipType.Gold;
    @Input() featureImageUrl = '';
    @Input() welcomeBackEditable = false;
    @Input() isFileNotes = false;

    videoUrl = signal<string>('');

    @Output() backToGoalStep = new EventEmitter<void>();

    isAbleToUpgrade = false;
    protected readonly ApplicationStatus = ApplicationStatus;

    ngOnInit(): void {
        from(
            this.#contentFullService.getEntry<HomeEntry>(
              environment.contentfulConfig.entryIds.homePage
            )
          )
            .pipe(map((homeEntry) => homeEntry.meetTheWealthCoachesUrl))
            .subscribe({
              next: (video) => {
                this.videoUrl.set(video);
              },
            });
        this.isAbleToUpgrade = this.memberShip !== MembershipType.Gold;
    }

    gotoPricingPage(): void {
        this.#router.navigate(['/pricing']).then(noop);
    }

    onHandleNext(): void {
        this.backToGoalStep.emit();
    }

    gotoDashboard(): void {
        this.#applicationService
            .updateCurrentProgress(ApplicationStep.Dashboard, null, null)
            .subscribe(() => {
                this.#router.navigate(['/dashboard']).then(noop);
            });
    }

    goToWealthPhilosophy(): void {
        this.#router
          .navigate([
            '/article',
            environment.contentfulConfig.entryIds.wealthPhilosophyPage,
          ])
          .then(noop);
    }
}

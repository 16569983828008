import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormRecord,
  Validators,
} from '@angular/forms';

import { isNil, orderBy, filter, isEmpty } from 'lodash';

import {
  FinancialAnswer,
  FinancialQuestion,
  questionAmountTypeRequiredValidator,
  QuestionType,
} from '@acorn/util';

import { AmountForm, PopulatedField, ReadonlyField } from './form.interface';

export const POPULATED_VALUE_REGEX =
  /(?<replacement>\${(\[(?<groupQuestionId>[0-9a-fA-F-]*)].)?(?<questionId>[0-9a-fA-F-]*)}(?<order>\[order])?.(?<field>[a-zA-Z]*))/;

export const initFormByQuestion = (
  financialQuestion: FinancialQuestion,
  answer?: FinancialAnswer
): FormGroup<AmountForm> =>
  new FormGroup<AmountForm>(
    {
      financialQuestionId: new FormControl<string>(financialQuestion.id, {
        nonNullable: true,
      }),
      superannuation: new FormControl(answer?.superannuation || null),
      frequency: new FormControl(answer?.frequency || null),
      taxStatus: new FormControl(answer?.taxStatus || null),
      answerContent: new FormControl(
        {
          value: answer?.answerContent || '',
          disabled: financialQuestion.readonly,
        },
        financialQuestion.isRequired ? Validators.required : null
      ),
    },
    {
      validators: questionAmountTypeRequiredValidator(financialQuestion),
    }
  );

export const initFormByPopulatedQuestion = (
  financialQuestion: FinancialQuestion,
  answers: FinancialAnswer[],
  syncWithOrder: number
): FormGroup<AmountForm> => {
  const calculatedFormValue = getPopulatedValueFromAnswers(
    financialQuestion.populateValueFrom,
    answers,
    syncWithOrder
  );

  const calculateFunction = Function(`return ${calculatedFormValue}`);
  const answerContent = calculateFunction.toString();

  new FormControl(
    {
      value: answerContent,
      disabled: financialQuestion.readonly,
    },
    financialQuestion.isRequired ? Validators.required : null
  ).getRawValue();

  return new FormGroup<AmountForm>({
    financialQuestionId: new FormControl<string>(financialQuestion.id, {
      nonNullable: true,
    }),
    superannuation: new FormControl(null),
    frequency: new FormControl(null),
    taxStatus: new FormControl(null),
    answerContent: new FormControl(
      {
        value: answerContent,
        disabled: financialQuestion.readonly,
      },
      financialQuestion.isRequired ? Validators.required : null
    ),
  });
};

export const initFinancialForm = (
  questions: FinancialQuestion[],
  answers?: FinancialAnswer[]
): [FormRecord, PopulatedField[], ReadonlyField[]] => {
  const financialForm: FormRecord<any> = new FormRecord({});
  const populatedFields: PopulatedField[] = [];
  const readonlyFields: ReadonlyField[] = [];

  questions.forEach((question) => {
    if (question.populateValueFrom) {
      populatedFields.push({
        questionId: question.id,
        listenFieldChanged: question.listenFieldChanged,
        conditionPopulated: question.conditionPopulated,
        populateValueFrom: question.populateValueFrom,
      });
    }

    if (question.readonlyWhen) {
      readonlyFields.push({
        questionId: question.id,
        condition: question.readonlyWhen,
      });
    }

    if (question.isMultiAnswer) {
      const formArray = new FormArray<AbstractControl>([]);

      if (question.childrenQuestions?.length) {
        const formArrayLength = filter(answers, [
          'financialQuestionId',
          question.childrenQuestions[0].id,
        ]).length;

        for (let index = 0; index < formArrayLength; index++) {
          const formRecord = new FormRecord({});

          question.childrenQuestions.forEach((childQuestion) => {
            const answer = answers?.find(
              (item) =>
                item.financialQuestionId === childQuestion.id &&
                item.order === index
            );
            const formControl = initFormByQuestion(childQuestion, answer);
            formRecord.addControl(childQuestion.id, formControl);
          });

          formArray.push(formRecord);
        }
      } else {
        const filteredAnswers = answers?.filter(
          (item) => item.financialQuestionId === question.id
        );
        orderBy(filteredAnswers || [], 'order').forEach((answer) => {
          const formControl = initFormByQuestion(question, answer);

          if (formControl) {
            formArray.push(formControl);
          }
        });
      }

      financialForm.addControl(question.id, formArray);

      return;
    }

    if (question.childrenQuestions?.length) {
      if (question.syncNumberOfItemFrom) {
        const formArray = new FormArray<any>([]);
        const numberOfItemFrom = (answers || []).filter(
          (answer) =>
            answer.financialQuestionId === question.syncNumberOfItemFrom
        ).length;

        for (let index = 0; index < numberOfItemFrom; index++) {
          const formRecord = new FormRecord({});

          question.childrenQuestions.forEach((childQuestion) => {
            if (childQuestion.populateValueFrom) {
              populatedFields.push({
                parentQuestionId: question.id,
                questionId: childQuestion.id,
                listenFieldChanged: childQuestion.listenFieldChanged,
                conditionPopulated: childQuestion.conditionPopulated,
                populateValueFrom: childQuestion.populateValueFrom,
              });
            }

            const answer = answers?.find(
              (answer) =>
                answer.financialQuestionId === childQuestion.id &&
                answer.order === index
            );

            const form =
              childQuestion.populateValueFrom &&
              !childQuestion.listenFieldChanged
                ? initFormByPopulatedQuestion(
                    childQuestion,
                    answers || [],
                    index
                  )
                : initFormByQuestion(childQuestion, answer);

            if (form) {
              formRecord.addControl(childQuestion.id, form);
            }
          });

          formArray.push(formRecord);
        }

        financialForm.addControl(question.id, formArray);
      } else {
        const formRecord = new FormRecord({});

        question.childrenQuestions.forEach((childQuestion) => {
          if (childQuestion.populateValueFrom) {
            populatedFields.push({
              parentQuestionId: question.id,
              questionId: childQuestion.id,
              listenFieldChanged: childQuestion.listenFieldChanged,
              conditionPopulated: childQuestion.conditionPopulated,
              populateValueFrom: childQuestion.populateValueFrom,
            });
          }

          if (childQuestion.readonlyWhen) {
            readonlyFields.push({
              parentQuestionId: question.id,
              questionId: childQuestion.id,
              condition: childQuestion.readonlyWhen,
            });
          }

          const answer = answers?.find(
            (answer) => answer.financialQuestionId === childQuestion.id
          );
          const form =
            childQuestion.populateValueFrom && !childQuestion.listenFieldChanged
              ? initFormByPopulatedQuestion(childQuestion, answers || [], -1)
              : initFormByQuestion(childQuestion, answer);

          if (form) {
            formRecord.addControl(childQuestion.id, form);
          }
        });

        financialForm.addControl(question.id, formRecord);
      }

      return;
    }

    const answer = answers?.find(
      (answer) => answer.financialQuestionId === question.id
    );
    const form = initFormByQuestion(question, answer);

    if (form) {
      financialForm.addControl(question.id, form);
    }
  });

  return [financialForm, populatedFields, readonlyFields];
};

export const getPopulatedValue = (
  expression: string,
  financialForm: FormRecord
): string => {
  if (!expression || !financialForm) {
    return '';
  }

  let calculatedExpression = expression;
  let regexResult;

  while (
    (regexResult = RegExp(POPULATED_VALUE_REGEX).exec(calculatedExpression)) !==
    null
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { replacement, questionId, field, groupQuestionId } =
      regexResult.groups!;
    const formGroup = getAmountFormById(
      financialForm,
      questionId,
      groupQuestionId
    );

    if (formGroup && formGroup.value) {
      const updatedValue = (formGroup.value as any)[field];

      if (isNil(updatedValue) || isEmpty(updatedValue)) {
        calculatedExpression = calculatedExpression.replace(
          replacement,
          'null'
        );
      } else {
        calculatedExpression = calculatedExpression.replace(
          replacement,
          !isNaN(+updatedValue) ? updatedValue.toString() : `"${updatedValue}"`
        );
      }
    }
  }

  return calculatedExpression;
};

export const getPopulatedValueFromAnswers = (
  expression: string,
  answers: FinancialAnswer[],
  syncWithOrder: number
): string => {
  if (!expression || !answers?.length) {
    return '';
  }

  let calculatedExpression = expression;
  let regexResult;

  while (
    (regexResult = RegExp(POPULATED_VALUE_REGEX).exec(calculatedExpression)) !==
    null
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { replacement, questionId, field, order } = regexResult.groups!;

    let selectedAnswer: FinancialAnswer | undefined;

    if (order === `[order]` && syncWithOrder >= 0) {
      selectedAnswer = answers.find(
        (item) =>
          item.financialQuestionId === questionId &&
          item.order === syncWithOrder
      );
    } else {
      selectedAnswer = answers.find(
        (item) => item.financialQuestionId === questionId
      );
    }

    if (!selectedAnswer) {
      break;
    }

    const updatedValue = (selectedAnswer as any)[field];

    if (isNil(updatedValue) || isEmpty(updatedValue)) {
      calculatedExpression = calculatedExpression.replace(replacement, 'null');
    } else {
      calculatedExpression = calculatedExpression.replace(
        replacement,
        !isNaN(+updatedValue) ? updatedValue.toString() : `"${updatedValue}"`
      );
    }
  }

  return calculatedExpression;
};

export const getWarningFormula = (
  expression: string,
  answers: FinancialAnswer[],
  updatedAnswers?: FinancialAnswer[],
  answer?: FinancialAnswer
): string => {
  if (!expression || !answers?.length) {
    return '';
  }

  let calculatedExpression = expression;
  let regexResult;
  const sameQuestions = answers.filter(
    (it) => it.financialQuestionId === answer?.financialQuestionId
  );
  let questionOrder: number | undefined;
  if (sameQuestions.length > 1) {
    questionOrder = sameQuestions.findIndex(
      (it) => it.answerContent === answer?.answerContent
    );
  }
  while (
    (regexResult = RegExp(POPULATED_VALUE_REGEX).exec(calculatedExpression)) !==
    null
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { replacement, questionId, field, order } = regexResult.groups!;
    const selectedAnswer =
      answers.find(
        (item) =>
          item.financialQuestionId === questionId &&
          (questionOrder !== undefined
            ? item.answerContent === answer?.answerContent
            : true)
      ) ||
      (updatedAnswers || []).find(
        (item) =>
          item.financialQuestionId === questionId &&
          (order && questionOrder !== undefined
            ? item.order === questionOrder
            : true)
      );
    if (!selectedAnswer) {
      break;
    }

    const updatedValue = (selectedAnswer as any)[field];
    if (isNil(updatedValue) || isEmpty(updatedValue)) {
      calculatedExpression = calculatedExpression.replace(replacement, 'null');
    } else {
      calculatedExpression = calculatedExpression.replace(
        replacement,
        !isNaN(+updatedValue) ? updatedValue.toString() : `"${updatedValue}"`
      );
    }
  }

  return calculatedExpression;
};

export const getAmountFormById = (
  financialForm: FormRecord,
  questionId: string,
  groupQuestionId?: string
): FormGroup<AmountForm> | null => {
  if (
    groupQuestionId &&
    financialForm.controls[groupQuestionId] instanceof FormGroup
  ) {
    return (financialForm.controls[groupQuestionId] as FormGroup).controls[
      questionId
    ] as FormGroup<AmountForm>;
  }

  return (financialForm as FormRecord<FormGroup<AmountForm>>).controls[
    questionId
  ];
};

export const isNoWarningMessagesExist = (
  map1: Map<string, Map<string, string>>,
  map2: Map<string, Map<string, string>>
): boolean => {
  for (const [key1, subMap1] of map1) {
    if (!map2.has(key1)) {
      return false;
    }
    const subMap2 = map2.get(key1);
    for (const [subKey1, value1] of subMap1) {
      if (!subMap2?.has(subKey1) || subMap2.get(subKey1) !== value1) {
        return false;
      }
    }
  }
  return true;
};

export function getIncomeControl(
  groupQuestionForm: FormRecord,
  questions: FinancialQuestion[]
): AbstractControl | null {
  const incomeQuestion = questions.find((item) =>
    [QuestionType.PrimaryIncome, QuestionType.SecondaryIncome].includes(
      item.question.questionType
    )
  );

  if (!incomeQuestion) return null;

  if (incomeQuestion.question.questionType === QuestionType.PrimaryIncome) {
    return groupQuestionForm.get(incomeQuestion.id);
  }

  const salaryQuestion = incomeQuestion.childrenQuestions?.find(
    (it) => it.question.questionType === QuestionType.BaseSalary
  );

  return (
    (salaryQuestion &&
      (groupQuestionForm.get(incomeQuestion.id) as FormRecord).get(
        salaryQuestion.id
      )) ||
    null
  );
}

import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultChartComponent } from '@acorn/feature-application';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { KeyAssumptionsPopupComponent } from 'libs/feature-application/src/lib/feature-financial-projection/ui/key-assumptions-popup/key-assumptions-popup.component';
import { Answer, AnswerType, CustomerUser, ApplicationGoalSettings } from '@acorn/util';
import { max } from 'date-fns';
import { orderBy, fill } from 'lodash';
import { combineLatest, first } from 'rxjs';
import { ApplicationGoalService, ApplicationAnswerService } from '@acorn/data-access';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StrategyActionPlanService } from '../../data-access/strategy-action-plan.service';
import { ToastrService } from 'ngx-toastr';

export interface GoalMetricsQuestion {
  goalId: string;
  goalName?: string;
  questions: any;
  explainer: string;
  order: number;
}

@Component({
  selector: 'acorn-financial-projection',
  standalone: true,
  imports: [CommonModule , DefaultChartComponent,MatDialogModule],
  templateUrl: './financial-projection.component.html',
  styleUrls: ['./financial-projection.component.scss'],
})
export class FinancialProjectionComponent implements OnInit {
  @Input() chartData: any;
  @Input() applicationId!: string;
  @Input() public set selectedStrategy (value :any){
      this._selectedStrategy = value;
  };

  @Input({ required: true }) set currentUser(value: CustomerUser) {
    this.#currentUser = value;
    this.isManualFlow = this.#currentUser.currentApplication?.isManualFlow;
  }

  #ngxLoader = inject(NgxUiLoaderService);
  #strategyActionPlanSvc = inject(StrategyActionPlanService);
  #toastrService = inject(ToastrService);
  #dialog = inject(MatDialog);
  #applicationGoalService = inject(ApplicationGoalService);
  #applicationAnswerService = inject(ApplicationAnswerService);

  answers: Answer[] = [];
  goalsData!: any;
  isManualFlow: boolean | undefined;
  goalMetricsQuestions :any;
  _selectedStrategy: any;
  #currentUser!: CustomerUser;
  applicationGoal!: ApplicationGoalSettings;
  #currentApplicationUserId = '';

  goals = [
    {
      number: 1,
      title: 'Save for a major purchase (e.g. car or holiday house)',
      status: 'Likely',
      amountYears: [
        { amount: '$20,000', year: 2026 },
        { amount: '$25,000', year: 2030 },
        { amount: '$30,000', year: 2040 },
      ],
    },
    {
      number: 2,
      title: 'Prepare for a major celebration (e.g. wedding)',
      status: 'Likely',
      amountYears: [
        { amount: '$20,000', year: 2026 },
        { amount: '$25,000', year: 2030 },
        { amount: '$30,000', year: 2040 },
      ],
    },
    {
      number: 3,
      title: 'Increase savings over time',
      status: 'Likely',
      amountYears: [
        { amount: '$20,000', year: 2026 },
        { amount: '$25,000', year: 2030 },
        { amount: '$30,000', year: 2040 },
      ],
    },
    {
      number: 4,
      title: 'Invest in an investment property',
      status: 'Likely',
      amountYears: [
        { amount: '$20,000', year: 2026 },
        { amount: '$25,000', year: 2030 },
        { amount: '$30,000', year: 2040 },
      ],
    },
    {
      number: 5,
      title: 'Pay off mortgage',
      status: 'Unlikely',
      amountYears: [
        { amount: '$20,000', year: 2026 },
        { amount: '$25,000', year: 2030 },
        { amount: '$30,000', year: 2040 },
      ],
    },
  ];

  ngOnInit(): void {
       this.getGoal();
       this.getQuestionsAndAnswers();
  }

  public openAssumptions() {
    this.#dialog.open(KeyAssumptionsPopupComponent, {
      width: '1200px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        applicationId: this.applicationId,
      },
    });
  }

  getQuestionsAndAnswers() {
    if (this.#currentUser && this.#currentApplicationUserId) {
      this.#ngxLoader.start();

      combineLatest([
        this.#applicationGoalService.getCurrentQuestions(),
        this.#applicationAnswerService.getApplicationAnswer(
          this.#currentApplicationUserId
        ),
      ]).subscribe(([questionsResponse, answersResponse]) => {
        this.#defineGoalMetrics(
          questionsResponse.data || [],
          answersResponse.data || []
        );
      });
    }
  }

  #defineGoalMetrics(
    questions: GoalMetricsQuestion[],
    answers: Answer[]
  ): void {
    if (!questions || !questions.length) {
      return;
    }

    const excludedContents = [
      "How many children do you foresee having?",
      "How often do you anticipate taking vacations within the upcoming 5 years?"
    ];

    const mappingGoalMetricsQuestions = orderBy(questions, 'order').map(
      (goalMetricsQuestion) => {
        const { questions: subQuestions, goalId } = goalMetricsQuestion;

        const filteredSubQuestions = subQuestions.filter((subQuestion: { content: string; }) =>
          !excludedContents.includes(subQuestion.content)
        );

        const convertedQuestions = orderBy(filteredSubQuestions, 'order').map(
          (question) => {
            let selectedAnswer = answers.find(
              (answer) =>
                answer.questionId === question.id && answer.goalId === goalId
            );

            if (question.childrenQuestions.length && question.isNumberOfItemQuestion) {
              let maxGroup = 0;

              question.childrenQuestions.forEach(
                (childQuestion: { childAnswers: Answer[]; id: string; answerType: AnswerType; }) => {
                  const selectedAnswers = answers.filter(
                    (answer) =>
                      answer.questionId === childQuestion.id &&
                      answer.goalId === goalId
                  );

                  if (selectedAnswers.length > 0) {
                    maxGroup = Math.max(maxGroup, ...selectedAnswers.map((a) => a.group)) || maxGroup;
                    childQuestion.childAnswers = selectedAnswers;
                  } else {
                    console.warn(
                      `No answers found for child questionId: ${childQuestion.id} and goalId: ${goalId}`
                    );
                  }
                }
              );

              const initialTotalGroups = maxGroup + 1;
              question.totalGroups = fill(Array(initialTotalGroups), 0);

              return question;
            } else {
              question.selectedAnswer = selectedAnswer;
            }

            return question;
          }
        );

        const sortedQuestions = this.sortQuestionsByType(convertedQuestions);

        const goalName = this.applicationGoal?.originalGoals.find(
          (goal) => goal.id === goalId
        )?.content;

        return {
          ...goalMetricsQuestion,
          goalName,
          questions: sortedQuestions,
        };
      }
    );

    this.goalMetricsQuestions = mappingGoalMetricsQuestions;
    this.getGoalLikelyStatus(this._selectedStrategy?.strategyId);
    // this.getStrategyActionPlan();
  }

  private sortQuestionsByType(questions: any[]): any[] {
    const answerTypes = questions.map(q => q.answerType);
    const uniqueTypes = [...new Set(answerTypes)];

    return questions.sort((a, b) => {
      const orderA = this.getCustomOrder(a.answerType, uniqueTypes.length);
      const orderB = this.getCustomOrder(b.answerType, uniqueTypes.length);
      return orderA - orderB;
    });
  }

  private getCustomOrder(answerType: AnswerType, typeCount: number): number {
    if (typeCount === 2) {
      if (answerType === AnswerType.Amount) return 1;
      if (answerType === AnswerType.Year) return 2;
    }

    if (typeCount === 3) {
      if (answerType === AnswerType.Amount) return 1;
      if (answerType === AnswerType.Number) return 2;
      if (answerType === AnswerType.Year) return 3;
    }

    return 4;
  }

  #getApplicationUserId(): void {
    const applicationUserId = this.#currentUser?.currentApplication?.applicationUsers?.[0]?.id;

    if (!applicationUserId) {
      return;
    }

    this.#currentApplicationUserId = applicationUserId;

    if (this.#currentApplicationUserId) {
      this.getQuestionsAndAnswers();
    }
  }

  public getGoal() {
    this.#applicationGoalService
      .getCurrentApplicationGoal()
      .subscribe(({ data }) => {
        this.applicationGoal = data;
       this.#getApplicationUserId();
      });
  }

  private getGoalLikelyStatus(strategyId: any) {
    this.#strategyActionPlanSvc
      .GetGoalLikelyStatus(this.applicationId, strategyId)
      .subscribe({
        next: (res: any) => {
          this.initializeStrategies(res.data)
        },
        error: (err: any) => {
            this.#toastrService.error(
                err.error.Message,
                'Error'
            );
        },
      });
  }

  private initializeStrategies(strategyGoals: any) {
    const goalMapping = new Map<string, any>(
      this.goalMetricsQuestions?.map((goal: any) => {
        if (!goal.goalId) {
          console.warn('Goal without ID:', goal);
        }
        return [goal.goalId, goal];
     })
    );

    const strategyGoalsArray = Array.isArray(strategyGoals) ? strategyGoals : [strategyGoals];

    const updatedGoals: Array<any | undefined> = [];

    strategyGoalsArray.forEach((strategyGoal: any) => {
      const matchingGoal = { ...goalMapping.get(strategyGoal.goalId) };

      if (matchingGoal) {
        matchingGoal.goalStatus = strategyGoal.isLikely ? 'Likely' : 'Unlikely';
      }

      updatedGoals.push(matchingGoal);
    });

    const sortedGoals = updatedGoals.filter(Boolean).sort((a, b) => {
            return (a?.order ?? 0) - (b?.order ?? 0);
        });

    this.goalsData = [...sortedGoals];
  }

  getGroupNumber(questions: any[], currentQuestion: any): number {
    const groupIds = new Set();
    let groupNumber = 0;

    for (const question of questions) {
      if (!groupIds.has(question.group)) {
        groupIds.add(question.group);
        groupNumber++;
      }

      if (question === currentQuestion) {
        return groupNumber;
      }
    }

    return groupNumber;
  }

  isFirstQuestionInGroup(questions: any, currentQuestion: any): boolean {
    const currentGroup = currentQuestion.group;

    return (
      questions.findIndex((q: any) => q.group === currentGroup) ===
      questions.indexOf(currentQuestion)
    );
  }

}

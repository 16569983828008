<acorn-application-stepper
  [currentUser]="currentUser!"
  [latestApplicationStep]="latestApplicationStep()!"
  [selectedIndex]="applicationStepIndex()"
  [subscriptionPlan]="subscriptionPlan()"
  [dashboardLastStep]="this.dashboardLastStep"
  [onlyAboutYou]="onlyAboutYou"
  (selectedIndexChange)="stepperChanged($event)"
>
  <cdk-step label="Getting Started">
    <acorn-getting-started
      *ngIf="
        applicationStep() === ApplicationStep.GettingStarted &&
        currentUser &&
        currentUser.currentApplication
      "
      class="grid-container"
      [allowNavigateToHome]="allowNavigateToHome"
      [currentApplication]="currentUser.currentApplication"
      [applicationPlan]="currentUser.currentApplication.applicationMode"
      [applicationUsers]="currentUser.currentApplication.applicationUsers"
      [isFileNotes]="isFileNotes"
      (syncApplicationUsers)="onSyncApplicationUsers($event)"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.Goal,
          GoalStep.GoalSetting,
          PartStep.Part1
        )
      "
      (backToTheQuotePage)="navigateToTheQuotePage()"
      (triggerWebhook)="triggerWebhook($event)"
    ></acorn-getting-started>
  </cdk-step>

  <!-- <cdk-step label="Your Goals">
    <acorn-your-goals
      *ngIf="applicationStep() === ApplicationStep.Goal && isReadyForGoal()"
      class="grid-container"
      [applicationGoal]="applicationGoal()!"
      [applicationId]="currentUser.currentApplication?.id!"
      [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
      [latestApplicationStep]="latestApplicationStep()!"
      [goalStep]="goalStep()!"
      [partStep]="partStep()!"
      [isWelcomeBackEditable]="isWelcomeBackEditable"
      [isFileNotes]="isFileNotes"
      (back)="applicationStep.set(ApplicationStep.GettingStarted)"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.AboutYou,
          null
        )
      "
      (updatedProgress)="updateCurrentProgress($event)"
      (triggerWebhook)="triggerWebhook($event)"
    ></acorn-your-goals>
  </cdk-step> -->
  <cdk-step label="Your Goals" *ngIf="currentUser">
    <acorn-feature-goals
      [currentApplication]="currentUser?.currentApplication!"
      [applicationGoal]="applicationGoal()!"
      [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
      [latestApplicationStep]="latestApplicationStep()!"
      (back)="applicationStep.set(ApplicationStep.GettingStarted)"
      (finishedStep)="updateProgress(true, ApplicationStep.AboutYou, null)"
      (adjustStep)="updateProgress(false, ApplicationStep.WealthCoach, null)"
      (updatedProgress)="updateCurrentProgress($event)"
      [goalStep]="goalStep()!"
      [partStep]="partStep()!"
      [isFileNotes]="isFileNotes"
      [isWelcomeBackEditable]="isWelcomeBackEditable"
      (triggerWebhook)="triggerWebhook($event)"
      [isDashboardApp]="isDashboardApp"
    >
    </acorn-feature-goals>
  </cdk-step>

  <cdk-step label="About You" *ngIf="currentUser">
    <acorn-about-you
      *ngIf="
        isReadyForAboutYou() && applicationStep() === ApplicationStep.AboutYou
      "
      class="grid-container"
      [onlyAboutYou]="onlyAboutYou"
      [latestApplicationStep]="latestApplicationStep()!"
      [categories]="aboutYouCategories()"
      [applicationId]="currentUser.currentApplication?.id!"
      [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
      [questions]="aboutYouQuestions()"
      [answerSelections]="answerSelections()"
      [applicationAboutYou]="applicationAboutYou()"
      (back)="applicationStep.set(ApplicationStep.Goal)"
      [isFileNotes]="isFileNotes"
      [isDisable]="isDisable"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.FinancialSituation,
          null
        )
      "
    ></acorn-about-you>
  </cdk-step>

  <cdk-step
    label="Financial Situation"
    *ngIf="currentUser && currentUser.currentApplication?.applicationUsers"
  >
    <acorn-feature-financial-situation
      *ngIf="
        financialSituation() &&
        applicationStep() === ApplicationStep.FinancialSituation
      "
      [latestApplicationStep]="latestApplicationStep()!"
      [categories]="financialSituation()!.categories"
      [applicationId]="currentUser.currentApplication?.id!"
      [applicationUsers]="currentUser.currentApplication?.applicationUsers!"
      [questions]="financialSituation()!.questions"
      [answers]="financialSituation()!.answers"
      [isFileNotes]="isFileNotes"
      [answerSelections]="financialSituation()!.answerSelections"
      (back)="updateProgress(false, ApplicationStep.AboutYou, null)"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.WealthHealth,
          null
        )
      "
      (triggerWebhook)="triggerWebhook($event)"
    ></acorn-feature-financial-situation>
  </cdk-step>
  <cdk-step label="Wealth Health">
    <acorn-wealth-health
      *ngIf="applicationStep() === ApplicationStep.WealthHealth"
      class="grid-container"
      [currentUser]="currentUser"
      [isDashboardApp]="isDashboardApp"
      [latestApplicationStep]="latestApplicationStep()!"
      [isFileNotes]="isFileNotes"
      (back)="updateProgress(false, ApplicationStep.FinancialSituation, null)"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.FinancialProjection,
          null
        )
      "
    ></acorn-wealth-health>
  </cdk-step>

  <cdk-step label="Financial Projection" *ngIf="currentUser">
    <acorn-feature-financial-projection
      *ngIf="applicationStep() === ApplicationStep.FinancialProjection"
      [isManualFlow]="currentUser.currentApplication?.isManualFlow"
      [applicationId]="currentUser.currentApplication?.id!"
      [latestApplicationStep]="latestApplicationStep()!"
      (back)="updateProgress(false, ApplicationStep.WealthHealth, null)"
      [isFileNotes]="isFileNotes"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.WealthStrategyOptions,
          null
        )
      "
      (adjustStep)="
        updateProgress(
          false,
          ApplicationStep.Goal,
          GoalStep.RoundVerify,
          null,
          false,
          true
        )
      "
      (financialSituation)="
        updateProgress(
          false,
          ApplicationStep.FinancialSituation,
          null,
          null,
          true
        )
      "
      (isCalculationStopped)="isCalculationStopped($event)"
      (movetoPrevious)="movetoPrevious()"
    ></acorn-feature-financial-projection>
  </cdk-step>
  <!-- *ngIf="currentUser && currentUser?.currentApplication?.status === ApplicationStatus.Complete" -->
  <cdk-step label="Wealth Strategy Options">
    <acorn-feature-wealth-strategy-options
      *ngIf="applicationStep() === ApplicationStep.WealthStrategyOptions"
      [onlyWealthstrategyOptions]="onlyWealthstrategyOptions"
      [currentUser]="currentUser"
      [currentSubStep]="currentSubStep()!"
      [applicationId]="currentUser.currentApplication?.id!"
      [latestApplicationStep]="latestApplicationStep()!"
      (back)="updateProgress(false, ApplicationStep.FinancialProjection, null)"
      (finishedStep)="
        updateProgress(
          $event.isUpdateApplicationProgress,
          ApplicationStep.WealthCoach,
          null
        )
      "
    ></acorn-feature-wealth-strategy-options>
  </cdk-step>
  <cdk-step label="Wealth Coach">
    <acorn-wealth-coach
      *ngIf="isShowWealthCoach()"
      [currentUser]="currentUser"
      [isFileNotes]="isFileNotes"
      [isDashboardApp]="isDashboardApp"
      [calendarLink]="calendarLink"
      (back)="
        updateProgress(false, ApplicationStep.WealthStrategyOptions, null)
      "
      (backToGoal)="onBackToGoal()"
    ></acorn-wealth-coach>
  </cdk-step>
</acorn-application-stepper>

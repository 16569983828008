<div class="happy-wrapper">
    <img src="assets/images/are-you-happy-hero.svg" class="happy-image" alt="Are you Happy?" />

    <h1 class="title">Are you happy with your answers?</h1>

    <div class="intro-content">
        <p>If not select "Back" to make final adjustments. 

        If you are happy select "Next" to submit your questionnaire, after this your Wealth Coach will review your details and begin preparing your strategies and action plan.</p>
    </div>

    <h1 class="title">What happens next?</h1>
    <ul class="numbered-list">
        <li>
            <span>1</span>
            <h3>Receive your strategies</h3>
            <p>Within 5 business days you will receive an email with your Top 3 Wealth Strategies to choose from</p>
        </li>
        <li>
            <span>2</span>
            <h3>Enjoy your Wealth Coach strategy session</h3>
            <p>Attend your Wealth Coach strategy session to decide which strategy to follow and understand your action plan</p>
        </li>
        <li>
            <span>3</span>
            <h3>Receive your action plan</h3>
            <p>Receive your chosen strategy Statement of Advice including year long plan and first 90 days breakdown</p>
        </li>
        <li>
            <span>4</span>
            <h3>Take action</h3>
            <p>Follow your action plan to maximise your Wealth and achieve your goals!</p>
        </li>
        <li>
            <span>5</span>
            <h3>Check-in with your Wealth Coach</h3>
            <p>We will email you when it's time to schedule your next Wealth Coach check-in</p>
        </li>
    </ul>

    <div  class="coach-video">
        <img src="assets/images/meet-coach-video.jpg" alt="" />
        <p>WATCH: Meet the Wealth Coaches</p>
    </div>
</div>

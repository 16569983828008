<h1>Wealth Health Score</h1>

<p>
  Well done for making a great start on your Wealth Health journey! <br> Below is your current Wealth Health score.
</p>

<p>
  Wealth Maximiser can help you manage and improve your Wealth Health.
</p>

<div *ngIf="wealthHealthScore" class="card-wrapper">
  <div class="overall">
    <div class="overall-header">Overall Wealth Health</div>
    <span class="overall-score">
      {{ wealthHealthScore.overallWealthHealthScore }}/10
    </span>

    <p>{{ wealthHealthScore.content }}</p>
  </div>

  <div class="card-content">
    <mat-expansion-panel
      *ngFor="let category of wealthHealthScore.wealthHealthCategories"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ categoryNameMapping[category.wealthHealthCategory] }}
        </mat-panel-title>

        <mat-panel-description>
          {{ category.categoryScore }}/10
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        *ngIf="category?.wealthHealthResponse?.buildingBlocks?.length"
        class="tags"
      >
        <span
          *ngFor="
            let buildingBlock of category.wealthHealthResponse.buildingBlocks
          "
        >
          {{ buildingBlockMapping[buildingBlock] }}
        </span>
      </div>
      
      <div *ngIf="category.categoryScore !== 10">
        <i>{{ category.content }}</i>

        <ul *ngIf="category?.wealthHealthResponse?.responses?.length">
          <ng-container *ngFor="let response of category.wealthHealthResponse.responses; let i = index">
            <li *ngIf="this.subscriptionPlan()|| i === 0 || isDashboardApp">{{ response }}</li>
          </ng-container>
          <li *ngIf="this.subscriptionPlan() == undefined && isDashboardApp == false">Want to know more? Click next
          </li>
        </ul>
      </div>
      <div *ngIf="category.categoryScore === 10">
        <h4 class="info-text">Want to know more? Click next.</h4>
      </div>
      
    </mat-expansion-panel>
  </div>
  <div class="next-steps">
    <div class="next-steps-header">What happens next?</div>
    <div class="img-txt">
      <img src="assets/icons/pigy.svg" class="img-align">Choose from three personalised financial
        strategies
    </div>
    <div class="img-txt">
      <img src="assets/icons/meetme.svg" class="img-align">Book your session with one of our expert wealth
      coaches
    </div>
    <div class="img-txt">
      <img src="assets/icons/action.svg" class="img-align">Receive your annual action plan
    </div>
  </div>
</div>
<acron-wealth-health-dialog></acron-wealth-health-dialog>

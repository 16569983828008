import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoalManagementService {
  private selectedGoalsSubject = new BehaviorSubject<Map<any, Set<string>>>(new Map());
  private progressSubject = new BehaviorSubject<number>(0);

  getSelectedGoals(): Observable<Map<any, Set<string>>> {
    return this.selectedGoalsSubject.asObservable();
  }

  getProgress(): Observable<any> {
    return this.progressSubject.asObservable();
  }

  updateSelectedGoals(wealthTypeId: string, selectedGoals: Set<string>): void {
    const currentGoals = this.selectedGoalsSubject.value;
    currentGoals.set(wealthTypeId, selectedGoals);
    this.selectedGoalsSubject.next(currentGoals);
    this.updateProgress();
  }

  private updateProgress(): void {
    const currentGoals = this.selectedGoalsSubject.value;
    const totalGoals = Array.from(currentGoals.values()).flat().length;
    this.progressSubject.next(totalGoals);
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SecurityActions = createActionGroup({
  source: 'Security',
  events: {
    'Sign In Success': props<{ token: string; refreshToken: string }>(),
    'Sign In Failure': emptyProps(),
    'Sign Out': emptyProps(),
    'Sign Out Success': emptyProps(),
    'Sign Out Failure': emptyProps(),
  },
});

import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyOptionComponent } from '../strategy-option/strategy-option.component';
import { FeatureWealthStrategyOptionsService } from '../../data-access/feature-wealth-strategy-options.service';
import { CustomerUser } from '@acorn/util';
import { FormsModule } from '@angular/forms';
import { ApplicationStrategyDocumentModelDto, PDFDownloadType, StrategyImages } from '../../utils/wealth-helper';
import { StrategyServiceHelper } from '../../../helpers/strategy-service.helper';
import { environment } from '@env';
import { MatDialog } from '@angular/material/dialog';
import { WealthCoachPopupComponent } from '../../../ui/wealthCoach-popup/wealth-coach-popup.component';
import { take, map, catchError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'acorn-wealth-strategy-options',
    standalone: true,
    imports: [CommonModule, StrategyOptionComponent, FormsModule],
    templateUrl: './wealth-strategy-options.component.html',
    styleUrls: ['./wealth-strategy-options.component.scss'],
})
export class WealthStrategyOptionsComponent implements OnInit {
    @Input() public strategies: any;
    @Input() currentUser!: CustomerUser;
    @Input() applicationId: any;
    @Input() userId: any;
    @Input() onlyWealthstrategyOptions!: boolean;
    @Input() isFileNotes: boolean = false;

    #wealthStrategyOptionsSvc = inject(FeatureWealthStrategyOptionsService);
    strategySvc = inject(StrategyServiceHelper);
    #cdr = inject(ChangeDetectorRef);
    #toastrService = inject(ToastrService);

    public selectedStrategies: string[] = [];
    public showError = '';
    public videoUrl = "https://acornstorageuat.blob.core.windows.net/assets/wealth_strategy_options%20(1080p).mp4";

    loading!: boolean;
    #dialog = inject(MatDialog);

    ngOnInit(): void {
        this.strategies?.forEach((strategy: { selected: boolean; selectedByCustomerDate: any }) => {
            strategy.selected = !!strategy.selectedByCustomerDate;
        });
    }

    getStrategyImage(strategyName: string): string {
        const strategy = StrategyImages.find((s) => s.title === strategyName);
        return strategy ? strategy.imagePath : 'assets/images/default-portfolio.svg';
    }

    onCheckboxChange() {
        this.saveStrategies();
    }

    saveStrategies() {
        this.selectedStrategies = this.strategies.filter((strategy: any) => strategy.selected).map((strategy: any) => strategy.strategyId);

        if (this.selectedStrategies.length > 0) {
            this.#wealthStrategyOptionsSvc.SaveStrategies(this.selectedStrategies, this.applicationId, this.userId).subscribe({
                next: (res: any) => {
                    this.#toastrService.success('Strategy saved successfully', 'Success');
                },
                error: () => {
                    this.showError = 'Oops, something went wrong.';
                },
            });
        }
    }

    download3StrategiesPDF() {
        this.loading = true;

        // this.openWealthCoachPopup(false, false).subscribe((isApproved) => {
        //     if (isApproved) {

                const url = `${environment.pdfUrl}wealth-strategy-options/${this.applicationId}`;

                const data: ApplicationStrategyDocumentModelDto = {
                    applicationId: this.applicationId,
                    blobUrl: '',
                    pdfDownloadTypeId: PDFDownloadType.ThreeStrategyOption,
                    documentUrl: url,
                };

                this.strategySvc.download(data, '3 Strategies', (success: boolean) => {
                    this.loading = false;
                    this.#cdr.detectChanges();
                });
            // }
        // });
    }

    public openWealthCoachPopup(isShowPreviousSOA: boolean, isShowCallofDate: boolean) {
        const dialogRef = this.#dialog.open(WealthCoachPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
            width:'500px',
            data: {
                applicationId: this.applicationId,
                isShowPreviousSOA: isShowPreviousSOA,
                isShowCallofDate: isShowCallofDate,
            },
        });

        dialogRef.afterClosed().subscribe(()=>{
            this.loading= false;
            this.#cdr.detectChanges();
        })

        return dialogRef.componentInstance.added.pipe(
            take(1),
            map(() => true),
            catchError(() => of(false))
        );
    }

    public formatStrategyOption(option: string): string {
        return option?.replace(/(\d+)/, ' $1');
    }
}

<div class="about-you-container container">
    <div class="about-header">
        <h1>
            About you
            <span class="updated-date">Updated {{ data.lastCalculatedDate | date : 'MMM yyyy' }} </span>
        </h1>

        <div class="status-container">
            <button class="edit-button" (click)="requestEdit()" *ngIf="!isReCalculateAfterEdit">Request ability to edit</button>
        </div>
    </div>

    <p class="description">
        Here you can see your previous answers to the Wealth Maximiser application. Select back and next to navigate. If you would like to
        change your responses, request the ability to edit.
    </p>

    <hr class="divider" />

    <h3 mat-dialog-title class="mb-1">Application details</h3>
    <acorn-feature-application
        [onlyAboutYou]="true"
        [currentUser]="currentUser()!"
        (backToTheQuotePage)="showTheQuoteContent()"
        [isDashboardApp]="false"
    ></acorn-feature-application>
</div>

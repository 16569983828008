<div class="wealth-main" id="wealth-main" #wealthMain>
    <div class="container">
        <div class="wealth-inner" *ngIf="!this.wealthHealthScore">
            <div class="wealth-text">
                <h4 class="head-text">Wealth Health <b class="green"> 3</b><span style="color: #d8dfe1">/</span>10</h4>
                <p class="para-text">Speak to your Wealth Coach to understand how to improve your Wealth Health Score.</p>
            </div>
            <div class="wealth-chart">
                <div class="wealth-foundation">
                    <div class="triangle-1 triangle">
                        <div class="triangle-text">
                            <p><b>3 </b>/10</p>
                        </div>
                        <h5 class="tri-text">Wealth Foundation</h5>
                    </div>
                    <div class="triangle-2 triangle">
                        <div class="triangle-text">
                            <p><b>4 </b>/10</p>
                        </div>
                        <h5 class="tri-text">Independence & Legacy</h5>
                    </div>
                    <div class="triangle-3 triangle">
                        <div class="triangle-text">
                            <p><b>4 </b>/10</p>
                        </div>
                        <h5 class="tri-text">Wealth Accumulation</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="wealth-inner" *ngIf="this.wealthHealthScore">
            <div class="wealth-text">
                <h4 class="head-text">
                    Wealth Health
                    <b class="green">{{ wealthHealthScore.overallWealthHealthScore }}</b>
                    <span style="color: #d8dfe1">/</span>10
                </h4>
                <p class="para-text">
                    {{ wealthHealthScore.content }}
                </p>
            </div>
            <div class="wealth-chart">
                <div class="wealth-foundation">
                    <div
                        *ngFor="let category of wealthHealthScore.wealthHealthCategories; let i = index"
                        class="triangle"
                        [ngClass]="'triangle-' + (i + 1)"
                    >
                        <div class="triangle-text">
                            <p>
                                <b>{{ category.categoryScore }}</b
                                >/10
                            </p>
                        </div>
                        <h5 class="tri-text">{{ categoryNameMapping[category.wealthHealthCategory] }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="video-section">
            <div class="video-inner">
                <div class="video-group">
                    <div class="video-content mb-1" *ngFor="let item of learningHubData">
                            <div class="video-col" (click)="openLink(item)">
                                <div class="video-img"> 
                                <img [src]="item.image" />
                                </div>
                                <div class="blog-content">
                                    <h4>{{item.title}}</h4>
                                    <a class="video-btn" href="#">{{item.type}}</a>
                                </div>
                            </div>
                    </div>
                </div>

                <div class="video-text">
                    <div class="mobile-hide">
                        <h4 class="head-text">Learn More</h4>

                        <p class="para-text">Check out more financial learning <br />resources.</p>
                    </div>
                    <a class="btn-black" (click)="learningHub.emit(true)"> Learning Hub</a>
                </div>
            </div>
        </div>
        <div class="goals">
            <div class="goals-inner">
                <div class="goal-point" *ngIf="this._selectedStrategy?.selectedByAdminDate">
                    <div *ngFor="let goal of goals" class="points">
                        <label
                            >{{ goal.goalName }}
                            <input type="checkbox" [checked]="goal.completedDate !== null" (change)="updateGoal($event, goal.goalId)" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="goal-point" *ngIf="!this._selectedStrategy?.selectedByAdminDate">
                    <div *ngFor="let goal of sampleGoals" class="points">
                        <label style="cursor: not-allowed;">
                            {{ goal.goalName }}
                            <input type="checkbox"
                                   [disabled]="true"
                                   [checked]="goal.completedDate !== null && _selectedStrategy?.selectedByAdminDate !== null"
                                   />
                            <span  style="cursor: not-allowed;" class="checkmark"></span>
                        </label>

                    </div>
                </div>
                <div class="goal-text">
                    <h4 class="head-text">Your Goals</h4>

                    <p class="para-text text-align-left">Have you completed any of your goals?</p>

                    <p class="para-text text-align-left">Don’t forget to tick them off so your Wealth Coach can congratulate you!</p>
                </div>
            </div>
        </div>
    </div>
</div>

import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';

import { ACORN_TOOLTIP, Application, ApplicationPlan, ERROR_MESSAGE } from '@acorn/util';
import { AlertComponent, NavigationComponent, TooltipDirective } from '@acorn/common-ui';
import { ApplicationService } from '@acorn/data-access';

@Component({
    selector: 'acorn-setup-plan',
    standalone: true,
    imports: [CommonModule, MatRadioModule, FormsModule, NavigationComponent, MatCardModule, AlertComponent, TooltipDirective],
    templateUrl: './setup-plan.component.html',
    styleUrls: ['./setup-plan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SetupPlanComponent {
    #applicationService = inject(ApplicationService);

    @Input() currentApplication!: Application;
    @Input() applicationPlan?: ApplicationPlan = ApplicationPlan.ForYou;
    @Input() allowNavigateToHome = true;
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;

    @Input() isDisable = false;

    @Output() back = new EventEmitter<void>();
    @Output() next = new EventEmitter<ApplicationPlan>();

    readonly ApplicationPlan = ApplicationPlan;

    tooltip: string = ACORN_TOOLTIP.GETTING_STARTED.SOMEONE_ELSE;

    isLoading = signal(false);
    errorMessage = signal('');

    onSubmit(): void {
        if (this.isFileNotes || this.isAppComplete) {
            this.next.emit(this.applicationPlan);
            return;
        }

        this.errorMessage.set('');

        if (!this.applicationPlan) {
            this.errorMessage.set(ERROR_MESSAGE.ALL_ARE_REQUIRED);
            return;
        }

        this.isLoading.set(true);
        this.#applicationService
            .updateCurrentApplication({
                ...this.currentApplication,
                applicationMode: this.applicationPlan,
            })
            .subscribe(({ isSuccess, message }) => {
                this.isLoading.set(false);

                if (!isSuccess) {
                    this.errorMessage.set(message || ERROR_MESSAGE.INTERNAL_ERROR);
                    return;
                }

                this.next.emit(this.applicationPlan);
            });
    }
}

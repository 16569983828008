<acorn-header
  id="header"
  [hasSignedIn]="securityService.isAuthenticated()"
  (signIn)="signIn()"
  (signOut)="signOut()"
></acorn-header>

<div class="main-content">
    <router-outlet></router-outlet>
</div>

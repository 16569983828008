import { Component,OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskDirective } from 'ngx-mask';
import { FinancialProjectionService } from '../../data-access-financial-projection';
import { ApplicationAssumtionEnum } from '@acorn/util';

@Component({
  selector: 'acorn-key-assumptions-popup',
  standalone: true,
  imports: [CommonModule, MatDividerModule,
    NgxMaskDirective],
  providers: [FinancialProjectionService],
  templateUrl: './key-assumptions-popup.component.html',
  styleUrls: ['./key-assumptions-popup.component.scss'],
})
export class KeyAssumptionsPopupComponent implements OnInit {
  #dialogRef = inject(MatDialogRef<KeyAssumptionsPopupComponent>);
  #applcationsCalculation = inject(FinancialProjectionService);

  dialogData = inject(MAT_DIALOG_DATA);

  isMobile: boolean = false;
  assumptions: any;
  assumptionsPart1: any[] = [];
  assumptionsPart2: any[] = [];
  assumptionsPart3: any[] = [];
  assumptionsCalled: boolean = false;

  onClose(): void {
    this.#dialogRef.close();
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768;

    this.getAssumptions();
  }


  public getAssumptions() {
    this.#applcationsCalculation.getApplications(this.dialogData.applicationId).subscribe((res: any) => {
      this.assumptions = res
        .filter((item: { respondentId: string | null; }) => item.respondentId !== 'Respondent2')
        .map((item: { assumption: { name: any; measure: string, groupCode: string }; value: any; applicationId: any; }) => ({
          name: item.assumption.name,
          group: item.assumption.groupCode,
          value: item.value,
          applicationId: item.applicationId,
          measure: item.assumption.measure
        }));

      const enumOrder = [
        "LifestyleAndBehaviour",
        "InflationEscalationRates",
        "InvestmentReturns",
        "Superannuation",
        "Debt",
        "OtherAssumptions",
        "WealthStrategies",
      ];

      const lifestyleOrder = ["Targeted Retirement Age", "Maximum Age for Projections"];

      this.assumptions.sort((a: { group: string; name: string; }, b: { group: string; name: string; }) => {
        const groupA = enumOrder.indexOf(a.group);
        const groupB = enumOrder.indexOf(b.group);

        if (groupA === groupB) {
          if (a.group === 'LifestyleAndBehaviour' && b.group === 'LifestyleAndBehaviour') {
            return lifestyleOrder.indexOf(a.name) - lifestyleOrder.indexOf(b.name);
          }
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }

        return groupA - groupB;
      });

      if (this.assumptions) {
        this.splitAssumptions();
      }
    });
  }

  splitAssumptions() {
    const total = this.assumptions.length;
    const partSize = Math.ceil(total / 3);

    this.assumptionsPart1 = this.assumptions.slice(0, partSize);
    this.assumptionsPart2 = this.assumptions.slice(partSize, partSize * 2);
    this.assumptionsPart3 = this.assumptions.slice(partSize * 2, total);
  }

  formatValue(value: any, measure: string): string {
    if (value === null || value === undefined || value === 0 || value === '0') {
      if (measure.includes('%')) {
        return '-';
      }
      if (measure.includes('$')) {
        return '-';
      }
      return '-';
    }
    if (measure.includes('%')) {
      return (parseFloat(value) * 100).toFixed(2) + '%';
    }
    if (measure.includes('$')) {
      return '$' + parseFloat(value).toLocaleString();
    }

    if (measure.includes('# yrs')) {
      return value + ' years';
    }
    return value;
  }
}

<div class="container is-fluid gradient-bg pd-40">
    <div class="container">
        <div class="row">
            <div class="columns">
                <div class="col-20"></div>
                <div class="col-60">
                    <h3>
                        We have provided three potential strategy options for
                        you to review.
                    </h3>
                    <p>
                        You can explore all three and then discuss the options
                        with the Wealth Coach at the next step.
                    </p>
                    <div class="watch-section">
                        <div><img src="assets/images/watch-section.jpg" /></div>
                        <div>
                            WATCH: What to consider when choosing your strategy
                            options
                        </div>
                    </div>
                </div>
                <div class="col-20"></div>
            </div>
        </div>
    </div>
</div>
<div class="container is-fluid white-bg pd-40">
    <div class="container strategy-options">
        <div class="row">
            <acorn-strategy-option
                [strategies]="strategies"
            ></acorn-strategy-option>
        </div>
    </div>
</div>
<div class="container is-fluid white-bg pd-40">
    <div class="container">
        <div class="row" *ngIf="onlyWealthstrategyOptions !== true">
            <div class="columns">
                <div class="col-20"></div>
                <div class="col-60">
                    <p>
                        Choose the wealth strategies you would like to discuss
                        with your Wealth Coach
                    </p>

                    <div class="wealth-strategies">
                        <div
                            class="option-container"
                            *ngFor="let strategy of strategies"
                        >
                            <input
                                type="checkbox"
                                [id]="strategy.strategyId"
                                name="portfolio"
                                [disabled]="isFileNotes"
                                [value]="strategy.strategyOption"
                                [(ngModel)]="strategy.selected"
                                (ngModelChange)="onCheckboxChange()"
                                class="custom-checkbox"
                            />
                            <img
                                [src]="getStrategyImage(strategy.name)"
                                [alt]="strategy.name"
                                [class]="'ml-1 ' + strategy.name"
                            />
                            <label [for]="strategy.strategyId">
                                <span class="option-label">{{ formatStrategyOption(strategy.strategyOption) }}</span
                                ><br />
                                <span class="option-description">{{
                                    strategy.name
                                }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-20"></div>
            </div>
        </div>
        <div class="row">
            <div class="columns">
                <div class="col-20"></div>
                <div class="col-60">
                    <div class="buttons">
                        <button class="edit-button" (click)="download3StrategiesPDF()" [disabled]="loading"
                        [ngClass]="{ 'disabled-select': !currentUser?.currentApplication?.isAllStrategiesApproved && !currentUser?.currentApplication?.goalApprovedDate }"
                        >
                            <ng-container *ngIf="!loading">Download 3 strategies PDF summary</ng-container>
                            <ng-container *ngIf="loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </ng-container>
                          </button>
                    </div>
                </div>
                <div class="col-20"></div>
            </div>
        </div>
    </div>
</div>

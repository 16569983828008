<ng-container>
  <div class="actions-container container" *ngIf="this.applicationId">
    <acorn-actions (hasStrategyEmit)="hasStrategyEmit($event.hasStrategyNull, $event.strategy)" [applicationId]="this.applicationId"></acorn-actions>
  </div>
  <acorn-wealth-health [applicationId]="this.applicationId" (learningHub)="setLearningHub()" [selectedStrategy]="selectedStrategy"></acorn-wealth-health>

  <div class="quick-links">
    <div class="quick-inner">
      <div class="quick-text">
        <a  (click)="toggleAboutYou()"> About you </a>
      </div>
      <div class="quick-text">
        <a id="new" (click)="toggleUsefulInfo()"> Useful Information </a>
      </div>
    </div>
  </div>
</ng-container>

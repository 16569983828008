import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

export interface DialogConfirmationData {
  title: string;
  message: string;
}

@Component({
  selector: 'acorn-dialog-confirmation',
  templateUrl: 'dialog-confirmation.component.html',
  styleUrls: ['dialog-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    IconSpriteModule,
  ],
})
export class DialogConfirmationComponent {
  dialogData: DialogConfirmationData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<DialogConfirmationComponent>);
}

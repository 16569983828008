import { LayoutService, ScreenSize } from '@acorn/util';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, Input } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import {
  ConvertZeroToDashPipe,
  FINANCIAL_ICON_MAPPING,
  FinancialSnapshotType,
} from '../../utils';

@Component({
  selector: 'acorn-information-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    IconSpriteModule,
    CommonModule,
    ConvertZeroToDashPipe,
  ],
  animations: [
    trigger('expand', [
      state('visible', style({ height: '*' })),
      state('hidden', style({ height: '0' })),
      transition('visible <=> hidden', animate('300ms ease-in-out')),
    ]),
  ],
})
export class InformationPanelComponent {
  layoutService = inject(LayoutService);

  @HostBinding('class')
  @Input()
  type: FinancialSnapshotType = FinancialSnapshotType.Income;
  @Input() total = 0;

  get isDesktop(): boolean {
    return [ScreenSize.Desktop].includes(this.layoutService.screenSize());
  }

  get isShownTotalYearText(): boolean {
    return ![
      FinancialSnapshotType.Assets,
      FinancialSnapshotType.Liabilities,
    ].includes(this.type);
  }

  isShown = true;

  readonly FINANCIAL_ICON_MAPPING = FINANCIAL_ICON_MAPPING;

  public toggle(): void {
    this.isShown = !this.isShown;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { noop } from 'rxjs';

import { ButtonComponent } from '@acorn/common-ui';
import { LayoutService, ScreenSize } from '@acorn/util';
import { environment } from '@env';

@Component({
  selector: 'acorn-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, RouterLink, RouterLinkActive, NgIf, MatMenuModule],
})
export class HeaderComponent {
  layoutService = inject(LayoutService);
  #router = inject(Router);

  @HostBinding('class.has-signed-in')
  @Input()
  hasSignedIn = false;

  @Output() signIn = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  get isMobile() {
    return this.layoutService.screenSize() === ScreenSize.Mobile;
  }

  onHandleSignOut(): void {
    this.signOut.emit();
  }

  getStarted() {
    if (this.hasSignedIn) {
      this.#router.navigate(['/']).then(noop);
      return;
    }

    this.#router.navigate(['/auth/sign-up']).then(noop);
  }

  backToHome(): void {
    //this.#router.navigate(['/home']).then(noop);
    window.location.href = environment.wordpressURL;
  }
}

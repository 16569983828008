<h2 mat-dialog-title>Wealth Coach Details</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup">
        <section class="change-strategy">
            <div class="form-field-wrapper mb-1">
                <mat-label>Wealth Coach Name</mat-label>
                <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
                    <input matInput formControlName="wealthCoachName" />
                    <mat-error *ngIf="formGroup.get('wealthCoachName')?.hasError('required') && formGroup.get('wealthCoachName')?.touched">
                        Wealth Coach Name is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-field-wrapper mb-1">
                <mat-label>AR Number</mat-label>
                <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
                    <input matInput formControlName="arNumber" />
                    <mat-error *ngIf="formGroup.get('arNumber')?.hasError('required') && formGroup.get('arNumber')?.touched">
                        AR Number is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-field-wrapper mb-1" *ngIf="this.dialogData.isShowCallofDate">
                <mat-label>Call of Date</mat-label>
                <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
                    <input matInput [matDatepicker]="pickerCallOfDate" formControlName="dateOfCall" required />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerCallOfDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerCallOfDate></mat-datepicker>
                    <mat-error *ngIf="formGroup.get('dateOfCall')?.hasError('required') && formGroup.get('dateOfCall')?.touched">
                        Call of Date is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-field-wrapper mb-1" *ngIf="dialogData.isShowPreviousSOA">
                <mat-label>Date of Previous SOA</mat-label>
                <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
                    <input matInput [matDatepicker]="pickerPreviousStatement" formControlName="dateOfPreviousSOA" required />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerPreviousStatement"></mat-datepicker-toggle>
                    <mat-datepicker #pickerPreviousStatement></mat-datepicker>
                    <mat-error *ngIf="formGroup.get('dateOfPreviousSOA')?.hasError('required') && formGroup.get('dateOfPreviousSOA')?.touched">
                        Date of Previous Statement of Advice is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
    </form>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
    <div class="footer">
        <button mat-stroked-button mat-dialog-close class="w-150">Cancel</button>

        <button mat-raised-button color="primary" class="w-150" (click)="confirm()">Confirm</button>
    </div>
</mat-dialog-actions>

import { BuildingBlockType, WealthHealthCategory } from '@acorn/util';

export interface WealthHealthScoreCategory {
  categoryScore: number;
  content: string;
  wealthHealthCategory: WealthHealthCategory;
  wealthHealthResponse: {
    buildingBlocks: BuildingBlockType[];
    responses: string[];
  };
}

export interface WealthHealthScore {
  overallWealthHealthScore: number;
  content: string;
  wealthHealthCategories: WealthHealthScoreCategory[];
}

<ng-container *ngIf="!isLoading(); else loadingTemp">
  <acorn-completed-successfully-profile
    *ngIf="isShowYouAreSetPage(); else wealthCoachStepTemplate"
    [currentUser]="currentUser"
    [recalculateAfterEdit]="currentUser.currentApplication?.recalculateAfterEdit!"
    [memberShip]="membershipCode!" [featureImageUrl]="membershipPlanEntry().featureImage"
    [actionList]="membershipPlanEntry().actionList"
    [isFileNotes]="isFileNotes"
  ></acorn-completed-successfully-profile>
</ng-container>

<ng-template #wealthCoachStepTemplate>
  <ng-container [ngSwitch]="currentStep()">
    <acorn-returning-customers
      *ngSwitchCase="wealthCoachStep.WelcomeBack"
      [currentUser]="currentUser"
      [recalculateAfterEdit]="currentUser.currentApplication?.recalculateAfterEdit!"
      [isFileNotes]="isFileNotes"
      [featureImageUrl]="membershipPlanEntry().featureImage"
      [memberShip]="membershipCode!"
    ></acorn-returning-customers>

    <acorn-returning-customers
      *ngSwitchCase="wealthCoachStep.WelcomeBackEditable"
      [currentUser]="currentUser"
      [recalculateAfterEdit]="currentUser.currentApplication?.recalculateAfterEdit!"
      [isFileNotes]="isFileNotes"
      [featureImageUrl]="membershipPlanEntry().featureImage"
      [welcomeBackEditable]="welcomeBackEditable"
      (backToGoalStep)="onBackToGoalStep()"
    ></acorn-returning-customers>

    <ng-container *ngSwitchDefault>
      <acorn-wealth-coach-schedule
        *ngIf="calendarLink$ | async as calendarLink"
        [countScheduleTime]="countScheduleTime"
        [scheduleTitle]="membershipPlanEntry().scheduleTitle"
        [calendarLink]="calendarLink"
        [isFileNotes]="isFileNotes"
      ></acorn-wealth-coach-schedule>
      <acorn-application-navigation *ngIf="isBooked"
        [primaryButtonEnabled]="true"
        (back)="onHandleBack()"
        (next)="onHandleBack()"
      ></acorn-application-navigation>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingTemp>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

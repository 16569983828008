import { ChangeDetectorRef, Component, computed, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import {
    Category,
    AboutYouQuestion,
    AnswerSelection,
    ApplicationUser,
    ApplicationStep,
    AnswerType,
    ApplicationAboutYouResponse,
    CategoryStatus,
    CustomerUser,
    CategoryCode,
    WEBHOOK_STATUS,
} from '@acorn/util';
import { ApplicationStepIndex, findLatestStepIndex, getUpdatedCategoryStatus } from '@acorn/feature-application';
import { NavigationComponent } from '@acorn/common-ui';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DependantsComponent, CommonAnswerComponent, LastReviewComponent } from '../../feature-about-you/ui';
import { WelcomeScreenComponent, VerticalStepperComponent, NestedVerticalStepperComponent } from '../../ui';
import { first, isNil, orderBy } from 'lodash';
import { CategoryService } from '@acorn/data-access';
import { TemplateType } from '../../feature-about-you/utils';
import { StepStatePipe } from '../utils/step-state.pipe';
import { RiskToleranceOutcomeComponent } from '../ui/risk-tolerance-outcome/risk-tolerance-outcome.component';

@Component({
    selector: 'acorn-risk-tolerance',
    standalone: true,
    imports: [
        CommonModule,
        WelcomeScreenComponent,
        DependantsComponent,
        CdkStepperModule,
        VerticalStepperComponent,
        NestedVerticalStepperComponent,
        NavigationComponent,
        CommonAnswerComponent,
        LastReviewComponent,
        RiskToleranceOutcomeComponent,
        StepStatePipe,
    ],
    templateUrl: './risk-tolerance.component.html',
    styleUrls: ['./risk-tolerance.component.scss'],
})
export class RiskToleranceComponent {
    @Output() back = new EventEmitter<void>();
    @Output() finishedStep = new EventEmitter<{
        isUpdateApplicationProgress: boolean;
    }>();
    @Output() triggerWebhook = new EventEmitter<string>();

    @Input() currentUser!: CustomerUser;
    @Input({ required: true }) categories: Category[] = [];
    @Input({ required: true }) questions: AboutYouQuestion[] = [];
    @Input({ required: true }) answerSelections: AnswerSelection[] = [];
    @Input() isFileNotes = false;
    @Input() isAppComplete = false;
    @Input({ required: true }) applicationId!: string;
    @Input({ required: true }) applicationUsers!: ApplicationUser[];
    @Input({ required: true }) latestApplicationStep!: ApplicationStep;
    @Input({ required: true }) dashboardLastStep!: ApplicationStep;
    @Input({ required: true }) set applicationAboutYou(value: ApplicationAboutYouResponse[]) {
        this.latestApplicationAboutYou.set(value);
    }

    #categoryService = inject(CategoryService);
    #cdr = inject(ChangeDetectorRef);
    #viewport = inject(ViewportScroller);

    protected readonly TemplateType = TemplateType;
    protected readonly CategoryStatus = CategoryStatus;
    public RISK_TOLERANCE_OUTCOME_INDEX = this.categories.length - 1;
    selectedStep = signal<number | undefined>(undefined);
    selectedChildStep = signal<number | undefined>(undefined);

    markAsSeen = signal(false);
    latestApplicationAboutYou = signal<ApplicationAboutYouResponse[]>([]);

    selectedParentCategory = computed(() => {
        const selectedStepIndex = this.selectedStep();

        if (selectedStepIndex === undefined) {
            return null;
        }

        return this.categories[selectedStepIndex];
    });

    selectedCategory = computed(() => {
        const parentCategory = this.selectedParentCategory();

        if (!parentCategory) return null;

        const skipChildCheck = parentCategory.categoryCode === 'RiskToleranceOutcome';

        if (skipChildCheck) {
            return parentCategory;
        }

        if (!parentCategory?.childItems?.length) {
            return null;
        }

        return parentCategory.childItems[this.selectedChildStep()!];
    });

    selectedQuestions = computed(() => {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return [];
        }

        return orderBy(this.questions, 'order').filter((question) => question.aboutYouCategory.id === selectedCategory.id);
    });

    selectedPrimaryQuestion = computed(() => {
        const primaryQuestions = this.selectedQuestions();

        return primaryQuestions[0];
    });

    selectedTemplateType = computed(() => {
        const selectedQuestions = this.selectedQuestions();

        if (!selectedQuestions?.length) {
            return null;
        }

        const firstQuestion = first(selectedQuestions)!;

        if (firstQuestion.isMultiAnswerQuestion) {
            return TemplateType.MultiAnswerQuestion;
        }

        if (firstQuestion.question.answerType === AnswerType.LastReviewDropDown) {
            return TemplateType.LastReview;
        }

        return TemplateType.Default;
    });

    ngOnInit() {
        const [stepIndex, childStepIndex] = findLatestStepIndex(this.categories);
        if (stepIndex === 0 && childStepIndex === 0) {
            this.markAsSeen.set(false);
        } else {
            this.markAsSeen.set(true);
            this.selectedStep.set(stepIndex);
            this.selectedChildStep.set(childStepIndex);
        }
    }

    onInitializeStep(): void {
        this.markAsSeen.set(true);
        this.selectedStep.set(0);
        this.selectedChildStep.set(0);
    }

    onSelectStep(index: number): void {
        const updatedCategory = this.categories[index];

        if (!updatedCategory) {
            return;
        }

        if (updatedCategory.status !== CategoryStatus.Complete) {
            this.selectedStep.set(index);
            return;
        }

        this.selectedStep.set(index);
        this.selectedChildStep.set(0);
    }

    onSelectChildStep(index: number): void {
        this.selectedChildStep.set(index);
        this.#scrollToTop();
    }

    onUpdateApplicationAboutYou(updatedApplicationAboutYou: ApplicationAboutYouResponse[]): void {
        this.latestApplicationAboutYou.set(updatedApplicationAboutYou);
    }

    onHandleBack(): void {
        const selectedCategory = this.selectedCategory();
        const selectedParentCategory = this.selectedParentCategory();

        if (!selectedCategory || !selectedParentCategory) {
            return;
        }

        if (selectedCategory.isFirstItem && selectedParentCategory.isFirstItem) {
            this.back.emit();
            return;
        }

        if (selectedCategory.isFirstItem) {
            const currentStepIndex = this.selectedStep()!;
            this.selectedStep.set(currentStepIndex - 1);

            const childStepIndex = this.categories[currentStepIndex - 1].childItems?.length!;
            this.selectedChildStep.set(childStepIndex - 1);

            return;
        }

        this.selectedChildStep.update((index) => index! - 1);
        this.#scrollToTop();
    }

    onBeforeHandleNext(): void {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return;
        }

        if (this.isFileNotes || this.isAppComplete) {
            this.onHandleNext();
            return;
        }

        if (selectedCategory.status !== CategoryStatus.Complete) {
            this.#markSelectedCategoryAsComplete();
        } else {
            this.onHandleNext();
        }
    }

    onHandleNext(): void {
        const selectedCategory = this.selectedCategory();

        if (!selectedCategory) {
            return;
        }

        if (this.selectedCategory()?.isLastItem) {
            if (this.selectedParentCategory()?.isLastItem) {
                if (this.selectedStep() === 0) {
                    this.selectedStep.set(1);
                }

                if (this.selectedStep() === 1) {
                    this.triggerWebhook.emit(WEBHOOK_STATUS.wealth_health)

                    const isUpdateApplicationProgress =
                        ApplicationStepIndex[this.dashboardLastStep] <= ApplicationStepIndex[ApplicationStep.RiskTolerance];

                    this.finishedStep.emit({ isUpdateApplicationProgress });
                }
            } else {
                this.selectedStep.update((step) => step! + 1);
                this.selectedChildStep.set(0);
            }
        } else {
            this.selectedChildStep.update((index) => index! + 1);
        }

        this.markAsSeen.set(true);
    }

    onBack() {
        this.selectedStep.set(0);
        this.selectedChildStep.set(5);
    }

    #scrollToTop(): void {
        this.#viewport.scrollToPosition([0, 0]);
    }

    #markSelectedCategoryAsComplete(): void {
        const selectedStepIndex = this.selectedStep();
        const selectedChildStepIndex = this.selectedChildStep();

        if (isNil(selectedStepIndex) || isNil(selectedChildStepIndex)) {
            return;
        }

        const updatedCategoryStatus = getUpdatedCategoryStatus(this.categories, selectedStepIndex, selectedChildStepIndex);

        if (!updatedCategoryStatus.length) {
            return;
        }

        // Update the status to In-Progress for Weath Health by Mu and Sasi
        updatedCategoryStatus.forEach((e) => {
            if (e.categoryCode === CategoryCode.FinancialBehaviourAndRiskAnalysis) {
                e.status = CategoryStatus.InProgress;
            }
        });

        this.#cdr.markForCheck();

        this.#categoryService.updateStatusCategory(this.applicationId, updatedCategoryStatus).subscribe(({ isSuccess }) => {
            if (!isSuccess) {
                return;
            }

            this.onHandleNext();
        });
    }

    isAllSubStepsComplete(): boolean {
        const selectedCategory = this.selectedCategory();
        if (!selectedCategory || !selectedCategory.childItems) {
            return false;
        }

        return selectedCategory.childItems.every((subStep) => subStep.status === CategoryStatus.Complete);
    }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { ConfirmFsgStore } from '../../store';

export const fsgGuard: CanActivateFn = () => {
  const router = inject(Router);

  return inject(ConfirmFsgStore).isConfirmFSG$.pipe(
    map((it) => (it ? true : router.createUrlTree(['/confirm-fsg'])))
  );
};

import { Document } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { Options } from './interfaces';

export function convertDataToHtmlString(
  data: Document,
  options?: Options
): string {
  return options
    ? documentToHtmlString(data, options)
    : documentToHtmlString(data);
}

<ng-container *ngIf="cards?.length">
  <div class="card" *ngFor="let card of cards">
    <img [src]="card.fields.image.fields.file.url" alt="" />

    <div class="card-information">
      <h3 *ngIf="orderEnabled">{{ card.fields.order }}.</h3>

      <h3>{{ card.fields.cardTitle }}</h3>

      <p>{{ card.fields.cardDescription }}</p>
    </div>
  </div>
</ng-container>

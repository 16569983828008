export enum ApplicationStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Complete = 'Complete',
}


export enum ApplicationAssumtionEnum {
  Age = "LifestyleAndBehaviour",
  InflationEscalationRates = "InflationEscalationRates",
  InvestmentReturns = "InvestmentReturns",
  Superannuation = "Superannuation",
  Debt = "Debt",
  OtherAssumptions = "OtherAssumptions",
  WealthStrategies = "WealthStrategiesAssetAllocationPreRetirement",
}
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-welcome-screen',
  standalone: true,
  imports: [CommonModule, IconSpriteModule],
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeScreenComponent {
  @Input() imageName = 'artboard.svg';
  @Input() title = 'Your Goals';
  @Input() estimatedTime = '10';
  @Input()
  description = `Understanding your income and expenses is important when building an effective wealth plan. Please provide details about your income, expenses, and any financial commitments so we can prepare wealth strategies that align with your financial reality. Estimates with around 80% accuracy will be sufficient. <br> At the end of this section you’ll receive a graphic summary of your financial situation.`;
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  CdkOverlayOrigin,
  FlexibleConnectedPositionStrategyOrigin,
  OverlayModule,
} from '@angular/cdk/overlay';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { ButtonComponent } from '@acorn/common-ui';
import { LayoutService, ScreenSize } from '@acorn/util';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'acorn-help-bubble',
  standalone: true,
  imports: [
    CommonModule,
    IconSpriteModule,
    OverlayModule,
    ButtonComponent,
    RouterLink,
  ],
  templateUrl: './help-bubble.component.html',
  styleUrls: ['./help-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpBubbleComponent {
  #layoutService = inject(LayoutService);

  @ViewChild('trigger', { read: CdkOverlayOrigin, static: true })
  buttonTrigger!: CdkOverlayOrigin;

  isOpen = false;

  flexibleOrigin = computed<FlexibleConnectedPositionStrategyOrigin>(() => {
    const screenSize = this.#layoutService.screenSize();

    return screenSize === ScreenSize.Mobile
      ? ({ x: 0, y: window.innerHeight } as any)
      : this.buttonTrigger;
  });

  overlayOffsetY = computed<number>(() =>
    this.#layoutService.screenSize() === ScreenSize.Mobile ? 0 : 44
  );
}

import {
    AboutYouService,
    ApplicationGoalService,
    ApplicationService,
    FinancialSituationService,
    LocalStorageService,
    UserService,
    UserSubscriptionPlanService,
} from '@acorn/data-access';
import {
    AboutYouQuestion,
    AnswerSelection,
    ApplicationAboutYouResponse,
    ApplicationGoalSettings,
    ApplicationStatus,
    ApplicationStep,
    ApplicationSubPartStep,
    ApplicationSubStep,
    ApplicationUser,
    Category,
    CategoryResponse,
    CustomerUser,
    FinancialCategoryType,
    FinancialQuestion,
    FinancialUserAnswer,
    GoalSettingPartsStep,
    GoalSettingStep,
    riskCategoryType,
    SubscriptionPlan,
    wealthCategoryType,
    WealthCoachStep,
    WealthStrategyOptionsStep,
} from '@acorn/util';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    computed,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { Router } from '@angular/router';
import { enUS } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep, findKey, groupBy, isEmpty, orderBy } from 'lodash';
import { combineLatest, noop } from 'rxjs';
import { FeatureFinancialSituationComponent } from './feature-financial-situation';
import { WealthCoachComponent } from './feature-wealth-coach/wealth-coach.component';
import { WealthHealthComponent } from './feature-wealth-health/wealth-health.component';

import { ApplicationStepperComponent } from './ui';
import { ApplicationStepIndex, isWealthCoachNotPlan } from './util';
import { FeatureFinancialProjectionComponent } from './feature-financial-projection/feature-financial-projection.component';
import { ProjectionPopupComponent } from './feature-financial-projection/ui/projection-popup/projection-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FeatureGoalsComponent } from './feature-goals';
import { STEP_STRUCTURE } from './feature-goals/ui/utlis/step-structure.const';
import { getInitialGoalStep } from './feature-your-goals/utils';
import { GoalCheckComponent } from './ui/goal-check/goal-check.component';
import { Helper } from './helpers/helper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FeatureWealthStrategyOptionsComponent } from './feature-wealth-strategy-options/feature-wealth-strategy-options.component';
import { RiskToleranceComponent } from './feature-risk-tolerance/risk-tolerance/risk-tolerance.component';
import { StrategyServiceHelper } from './helpers/strategy-service.helper';
import { WealthCalendlyService } from './ui/data-access/wealth-calendly.service';
import { SummaryComponent } from './financial-questionnaire/summary/summary.component';
import { AboutYouComponent } from './feature-about-you/about-you.component';

const DATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'dd/MM/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
    },
};

@Component({
    selector: 'acorn-feature-application',
    standalone: true,
    templateUrl: './feature-application.component.html',
    styleUrls: ['./feature-application.component.scss'],
    imports: [
        CommonModule,
        // eslint-disable-next-line @angular-eslint/no-forward-ref
        forwardRef(() => ApplicationStepperComponent),
        CdkStepperModule,
        FeatureGoalsComponent,
        AboutYouComponent,
        ApplicationStepperComponent,
        FeatureFinancialSituationComponent,
        WealthHealthComponent,
        WealthCoachComponent,
        FeatureFinancialProjectionComponent,
        FeatureWealthStrategyOptionsComponent,
        RiskToleranceComponent,
        SummaryComponent,
    ],
    providers: [
        Helper,
        StrategyServiceHelper,
        WealthCalendlyService,
        { provide: MAT_DATE_LOCALE, useValue: enUS },
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeatureApplicationComponent implements OnInit {
    #applicationGoalService = inject(ApplicationGoalService);
    #applicationService = inject(ApplicationService);
    #aboutYouService = inject(AboutYouService);
    #cdf = inject(ChangeDetectorRef);
    #financialSituationService = inject(FinancialSituationService);
    #userService = inject(UserService);
    #userSubscriptionPlanService = inject(UserSubscriptionPlanService);
    localStorageService = inject(LocalStorageService);
    #router = inject(Router);
    #toastrService = inject(ToastrService);
    #dialog = inject(MatDialog);
    currentStepIndex = 0;
    #dialogRef: any;
    #ngxLoader = inject(NgxUiLoaderService);

    currentSubStep = signal<WealthStrategyOptionsStep | null>(null);

    dashboardLastStep!: ApplicationStep | any;
    WealthCoachStep: any;
    isAppComplete = false;

    @Output() showDashboard = new EventEmitter<void>();
    @Output() backToTheQuotePage = new EventEmitter<void>();
    @Output() backToSummary = new EventEmitter<void>();

    @Input() onlyAboutYou = false;
    @Input() onlyWealthstrategyOptions = false;
    @Input() isCustomerApp = false;

    @Input({ required: true }) set currentUser(value: CustomerUser) {
        if (!value?.currentApplication || isEmpty(value?.currentApplication)) {
            if (value.currentApplication == null) {
                this.latestApplicationStep.set(ApplicationStep.AboutYou);
                this.applicationStep.set(ApplicationStep.AboutYou);
            }
            this.#currentUser = value;
            return;
        }

        this.#currentUser = value;

        this.isAppComplete = this.isCustomerApp && this.#currentUser.currentApplication?.status === ApplicationStatus.Complete;

        if (value.currentApplication?.isStepFromSummary) {
            if (value.currentApplication.currentLastStep === ApplicationStep.AboutYou) {
                this.latestApplicationStep.set(ApplicationStep.AboutYou);
                this.applicationStep.set(ApplicationStep.AboutYou);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.Goal) {
                this.latestApplicationStep.set(ApplicationStep.Goal);
                this.applicationStep.set(ApplicationStep.Goal);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.FinancialSnapshot) {
                this.latestApplicationStep.set(ApplicationStep.FinancialSnapshot);
                this.applicationStep.set(ApplicationStep.FinancialSnapshot);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.RiskTolerance) {
                this.latestApplicationStep.set(ApplicationStep.RiskTolerance);
                this.applicationStep.set(ApplicationStep.RiskTolerance);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.WealthHealth) {
                this.latestApplicationStep.set(ApplicationStep.WealthHealth);
                this.applicationStep.set(ApplicationStep.WealthHealth);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.FinancialProjection) {
                this.latestApplicationStep.set(ApplicationStep.FinancialProjection);
                this.applicationStep.set(ApplicationStep.FinancialProjection);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.WealthStrategyOptions) {
                this.latestApplicationStep.set(ApplicationStep.WealthStrategyOptions);
                this.applicationStep.set(ApplicationStep.WealthStrategyOptions);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            } else if (value.currentApplication.currentLastStep === ApplicationStep.WealthCoach) {
                this.latestApplicationStep.set(ApplicationStep.WealthCoach);
                this.applicationStep.set(ApplicationStep.WealthCoach);
                this.dashboardLastStep = value?.currentApplication?.currentStep;
            }

            this.#cdf.detectChanges();
            return;
        }

        // About you Dashboard
        if (this.onlyAboutYou) {
            this.latestApplicationStep.set(ApplicationStep.AboutYou);
            this.applicationStep.set(ApplicationStep.AboutYou);

            this.dashboardLastStep = value?.currentApplication?.currentStep;
            return;
        }

        // Adviser Edit or Customer Edit and strategy is not approved and recalculateAfterEdit
        if (
            (this.currentUser.currentApplication?.currentStep === ApplicationStep.WealthStrategyOptions ||
                this.currentUser.currentApplication?.currentStep === ApplicationStep.Dashboard) &&
            (this.#currentUser?.currentApplication?.status === ApplicationStatus.Active ||
                !value.currentApplication.isAllStrategiesApproved ||
                !value.currentApplication.goalApprovedDate ||
                value.currentApplication.recalculateAfterEdit)
        ) {
            this.latestApplicationStep.set(ApplicationStep.WealthCoach);
            this.applicationStep.set(ApplicationStep.WealthCoach);

            if (value.currentApplication.currentSubStep == null) {
                value.currentApplication.currentSubStep = WealthCoachStep.WelcomeBack;
            }
            return;
        }

        // Showing Wealth Strategy Option if navigated from Dashboard
        if (
            this.currentUser.currentApplication?.currentStep === ApplicationStep.Dashboard &&
            this.#currentUser?.currentApplication?.status === ApplicationStatus.Complete
        ) {
            if (value.currentApplication.recalculateAfterEdit) {
                this.latestApplicationStep.set(ApplicationStep.WealthCoach);
                this.applicationStep.set(ApplicationStep.WealthCoach);

                value.currentApplication.currentSubStep = WealthCoachStep.WelcomeBack;
                return;
            }

            if (
                (this.#router.url === '/?isdashBoard=true' &&
                    value.currentApplication.isAllStrategiesApproved === true &&
                    value.currentApplication.goalApprovedDate) ||
                value.currentApplication.isFromDashBoardApp
            ) {
                this.onlyWealthstrategyOptions = true;
            } else {
                const script = document.getElementById('zsiqscript');

                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }

                window.location.href = '/dashboard';
            }
        }

        if (this.onlyWealthstrategyOptions) {
            this.latestApplicationStep.set(ApplicationStep.WealthStrategyOptions);
            this.applicationStep.set(ApplicationStep.WealthStrategyOptions);
            this.currentSubStep.set(WealthStrategyOptionsStep.WealthStrategyOptions);
            this.dashboardLastStep = value?.currentApplication?.currentStep;
            return;
        }

        this.applicationStep.set(value?.currentApplication?.currentStep);
        this.latestApplicationStep.set(value?.currentApplication?.currentStep);
    }

    @Input() allowNavigateToHome = true;
    @Input() isDashboardApp = false;
    @Input() calendarLink: string | null = null;
    @Input() isFileNotes = false;
    @Input() isDisable = true;

    get currentUser(): CustomerUser {
        return this.#currentUser;
    }

    protected readonly ApplicationStep = ApplicationStep;
    protected readonly GoalStep = GoalSettingStep;
    protected readonly PartStep = GoalSettingPartsStep;
    protected readonly ApplicationStatus = ApplicationStatus;

    applicationStep = signal<ApplicationStep | null>(null);
    latestApplicationStep = signal<ApplicationStep | null>(null);
    partStep = signal<GoalSettingPartsStep | null>(null);
    goalStep = signal<GoalSettingStep | null>(null);

    // your goals
    applicationGoal = signal<ApplicationGoalSettings | null>(null);
    isReadyForGoal = computed(() => !!this.applicationGoal() && !!this.goalStep() && !!this.partStep());
    isWelcomeBackEditable = false;
    stepStructure: any[] = cloneDeep(STEP_STRUCTURE);

    // about you
    aboutYouCategories = signal<Category[]>([]);
    aboutYouQuestions = signal<AboutYouQuestion[]>([]);
    answerSelections = signal<AnswerSelection[]>([]);
    applicationAboutYou = signal<ApplicationAboutYouResponse[]>([]);
    isReadyForAboutYou = computed(() => this.aboutYouCategories().length);

    // financialProjection
    financialProjection = signal<Category[]>([]);

    // risk tolerance
    riskToleranceCategories = signal<Category[]>([]);
    riskToleranceQuestions = signal<AboutYouQuestion[]>([]);
    riskToleranceAnswerSelections = signal<AnswerSelection[]>([]);
    applicationRiskTolerance = signal<ApplicationAboutYouResponse[]>([]);
    isReadyForRisk = computed(() => this.riskToleranceCategories().length);

    // wealthScore
    wealthCategories = signal<Category[]>([]);
    wealthQuestions = signal<AboutYouQuestion[]>([]);
    wealthAnswerSelections = signal<AnswerSelection[]>([]);
    applicationWealth = signal<ApplicationAboutYouResponse[]>([]);
    isReadyForWealth = computed(() => this.wealthCategories().length);

    financialSituation = signal<{
        categories: Category[];
        questions: FinancialQuestion[];
        answerSelections: AnswerSelection[];
        answers: FinancialUserAnswer[];
        data: boolean;
    } | null>(null);

    applicationStepIndex = computed(() => {
        const applicationStep = this.applicationStep();

        if (!applicationStep) {
            return -1;
        }

        return ApplicationStepIndex[applicationStep];
    });

    subscriptionPlan = toSignal<SubscriptionPlan | undefined>(this.#userSubscriptionPlanService.userSubscriptionPlan$);

    isShowWealthCoach = computed(() =>
        this.isDashboardApp
            ? this.applicationStep() === ApplicationStep.WealthCoach
            : this.applicationStep() === ApplicationStep.WealthCoach && Boolean(this.subscriptionPlan())
    );

    #currentUser!: CustomerUser;

    ngOnInit() {
        if (
            this.#currentUser?.currentApplication?.status === ApplicationStatus.Active &&
            !this.isDashboardApp &&
            this.#currentUser.currentApplication?.isLatestGoalBeingApplied !== true &&
            !this.isApplicationDateValid(this.#currentUser.currentApplication?.createdDate)
        ) {
            this.stepperChanged(this.applicationStepIndex());
            this.latestApplicationStep.set(this.applicationStep());
            this.checkStep(this.applicationStepIndex());
            return;
        }

        this.latestApplicationStep.set(this.applicationStep());
        this.#handleWealthCoachProfileWithoutPlan();
        this.stepperChanged(this.applicationStepIndex());
    }

    checkStep(index: number) {
        if (index !== this.applicationStepIndex()) {
            const selectedApplicationStep = findKey(ApplicationStepIndex, (item) => item === index);

            if (selectedApplicationStep) {
                this.applicationStep.set(selectedApplicationStep as ApplicationStep);
            }
        }

        const stepsRequiringBackToGoal = [
            ApplicationStepIndex[ApplicationStep.Goal],
            ApplicationStepIndex[ApplicationStep.AboutYou],
            ApplicationStepIndex[ApplicationStep.FinancialSnapshot],
            ApplicationStepIndex[ApplicationStep.WealthCoach],
            ApplicationStepIndex[ApplicationStep.WealthHealth],
        ];

        if (stepsRequiringBackToGoal.includes(index)) {
            this.backToGoal();
        }
    }

    backToGoal() {
        const model = this.#dialog.open(GoalCheckComponent, {
            width: '400px',
            height: '300px',
            disableClose: true,
            hasBackdrop: true,
        });
        model.componentInstance.close.subscribe(() => {
            model.close();
            this.goalStep.set(GoalSettingStep.GoalSetting);
            this.partStep.set(GoalSettingPartsStep.Part1);
            this.applicationStep.set(ApplicationStep.Goal);
            this.latestApplicationStep.set(ApplicationStep.Goal);
        });
    }

    isApplicationDateValid(applicationCreatedDate: any) {
        const createdDate = new Date(applicationCreatedDate + 'Z');

        const releasedDate = new Date(Date.UTC(2024, 8, 30, 12, 0, 0));

        if (createdDate >= releasedDate) {
            return true;
        }

        return false;
    }

    stepperChanged(index: number, data: boolean = false, goalChange: boolean = false, isfromStep: boolean = false): void {
        if (
            !this.subscriptionPlan() &&
            (index === ApplicationStepIndex[ApplicationStep.FinancialProjection] ||
                index === ApplicationStepIndex[ApplicationStep.WealthCoach] ||
                index === ApplicationStepIndex[ApplicationStep.WealthStrategyOptions])
        ) {
            return;
        }

        if (index === 0 && isfromStep) {
            localStorage.removeItem('financialProjectionProgress');
        }

        if (index !== this.currentStepIndex) {
            this.currentStepIndex = index;
            const selectedApplicationStep = findKey(ApplicationStepIndex, (item) => item === index);

            if (selectedApplicationStep) {
                this.applicationStep.set(selectedApplicationStep as ApplicationStep);
            }

            switch (index) {
                case ApplicationStepIndex[ApplicationStep.Goal]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                    }

                    this.#applicationGoalService.getCurrentApplicationGoal().subscribe(({ data }) => {
                        this.applicationGoal.set(data);
                        this.#calculateCurrentPartStep(goalChange);

                        if (this.isFileNotes) {
                            this.#ngxLoader.stop();
                        }
                    });

                    break;
                }

                case ApplicationStepIndex[ApplicationStep.AboutYou]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                        this.#getAboutYouStructure();
                        this.#ngxLoader.stop();
                        break;
                    } else this.#getAboutYouStructure();
                    break;
                }

                case ApplicationStepIndex[ApplicationStep.RiskTolerance]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                        this.#getRiskToleranceStructure();
                        this.#ngxLoader.stop();
                        break;
                    } else this.#getRiskToleranceStructure();
                    break;
                }

                case ApplicationStepIndex[ApplicationStep.FinancialSnapshot]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                        this.#getFinancialSituationStructure(data, isfromStep);
                        this.#ngxLoader.stop();
                        break;
                    } else this.#getFinancialSituationStructure(data, isfromStep);
                    break;
                }

                case ApplicationStepIndex[ApplicationStep.WealthHealth]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                        this.#getWealthStructure();
                        this.#ngxLoader.stop();
                        break;
                    } else this.#getWealthStructure();
                    break;
                }

                case ApplicationStepIndex[ApplicationStep.FinancialProjection]: {
                    if (this.isFileNotes) {
                        this.#ngxLoader.start();
                        if (!this.currentUser.currentApplication?.isManualFlow) {
                            this.showFinancialProjection();
                        }
                        this.#ngxLoader.stop();
                        break;
                    }

                    if (!this.currentUser.currentApplication?.isManualFlow) {
                        this.showFinancialProjection();
                    }
                    break;
                }
            }
        }
    }

    onBackToGoal(): void {
        this.applicationStep.set(ApplicationStep.Goal);
        this.isWelcomeBackEditable = true;
    }

    updateProgress(
        isUpdateApplicationProgress: boolean,
        updatedStep: ApplicationStep,
        updatedSubStep: ApplicationSubStep,
        updatedPartStep?: ApplicationSubPartStep,
        data: boolean = false,
        goalChange: boolean = false,
        isfromBack: boolean = false
    ): void {
        if (updatedStep === ApplicationStep.WealthStrategyOptions) {
            updatedStep =
                this.currentUser?.currentApplication?.status === ApplicationStatus.Complete &&
                this.currentUser?.currentApplication?.isAllStrategiesApproved === true &&
                this.currentUser?.currentApplication?.goalApprovedDate
                    ? ApplicationStep.WealthStrategyOptions
                    : ApplicationStep.WealthCoach;

            if (this.currentUser?.currentApplication?.recalculateAfterEdit === true) {
                this.stepperChanged(ApplicationStepIndex[ApplicationStep.WealthCoach]);
            } else {
                this.stepperChanged(ApplicationStepIndex[updatedStep]);
            }
        }

        if (!isUpdateApplicationProgress) {
            if (isfromBack && updatedStep === ApplicationStep.WealthHealth) {
                this.applicationStep.set(updatedStep);
                this.latestApplicationStep.set(updatedStep);
                this.stepperChanged(ApplicationStepIndex[updatedStep], data, goalChange);
                return
            }

            if (
                updatedStep === ApplicationStep.WealthCoach ||
                updatedStep === ApplicationStep.FinancialProjection ||
                updatedStep === ApplicationStep.WealthStrategyOptions
            ) {
                this.applicationStep.set(updatedStep);
                this.latestApplicationStep.set(updatedStep);
            } else if (data) {
                this.applicationStep.set(updatedStep);
                this.latestApplicationStep.set(updatedStep);
                this.dashboardLastStep = this.currentUser?.currentApplication?.currentStep;
                this.stepperChanged(ApplicationStepIndex[updatedStep], data, goalChange);
            } else if (goalChange) {
                this.applicationStep.set(updatedStep);
                this.latestApplicationStep.set(updatedStep);
                this.dashboardLastStep = this.currentUser?.currentApplication?.currentStep;
                this.stepperChanged(ApplicationStepIndex[updatedStep], data, goalChange);
            } else {
                window.location.href = '';
            }
            return;
        } else if (updatedStep === ApplicationStep.Goal && updatedSubStep === GoalSettingStep.GoalSetting) {
            this.partStep.set(STEP_STRUCTURE[0].parts[0].id);
            const applicationGoalTmp = this.applicationGoal();

            this.applicationGoal.set(applicationGoalTmp);
        }

        if (isWealthCoachNotPlan(updatedStep, this.subscriptionPlan())) {
            if (this.isDashboardApp) {
                this.#toastrService.error('This user has not paid yet!');
            } else {
                this.#router.navigate(['pricing']).then(noop);
            }
            return;
        }

        if (this.isAppComplete && updatedStep !== ApplicationStep.FinancialProjection) {
            return;
        }

        this.#applicationService.updateCurrentProgress(updatedStep, updatedSubStep, updatedPartStep).subscribe(({ data }) => {
            if (this.isFileNotes) {
                return;
            }

            if (this.#dialogRef) {
                this.#dialogRef.close();
            }

            if (!updatedSubStep || Object.values(GoalSettingStep as unknown as string).includes(updatedSubStep)) {
                this.goalStep.set(<GoalSettingStep | null>updatedSubStep);
            }

            if (
                updatedStep === ApplicationStep.WealthCoach ||
                updatedStep === ApplicationStep.FinancialProjection ||
                updatedStep === ApplicationStep.WealthStrategyOptions
            ) {
                if (updatedStep === ApplicationStep.WealthCoach) {
                    localStorage.setItem('financialProjectionProgress', 'true');
                }

                window.dispatchEvent(new CustomEvent('yourEventType', { detail: true }));
                this.applicationStep.set(updatedStep);
                this.latestApplicationStep.set(updatedStep);
                this.stepperChanged(ApplicationStepIndex[updatedStep]);
            } else {
                window.location.href = '';
            }
        });
    }

    public backToSummaryPage() {
        window.location.reload();
        // this.backToSummary.emit();
    }

    public updateCurrentProgress(value: any): void {
        if (this.currentUser.currentApplication) {
            this.currentUser.currentApplication.currentSubStep = value.roundStep;
            this.currentUser.currentApplication.currentPartStep = value.partStep;
        }
    }

    onSyncApplicationUsers(applicationUsers: ApplicationUser[]): void {
        if (!this.currentUser.currentApplication) {
            return;
        }

        this.currentUser.currentApplication.applicationUsers = applicationUsers || [];
        this.#clearPartnerProfileIfUserHasOnlyOneApplicationUser(applicationUsers);
    }

    #calculateCurrentPartStep(goalChange: boolean = false): void {
        if (
            this.#currentUser.currentApplication?.isLatestGoalBeingApplied !== true &&
            this.#currentUser.currentApplication?.status === ApplicationStatus.Active
        ) {
            this.partStep.set(GoalSettingPartsStep.Part1);
            this.goalStep.set(GoalSettingStep.GoalSetting);
            return;
        }

        const applicationGoal = this.applicationGoal();

        if (!applicationGoal) {
            return;
        }

        const currentSubStepValue: string | null = this.currentUser.currentApplication?.currentSubStep || null;

        const goalSteps = <string[]>Object.values(GoalSettingStep);

        let currentSubStep: GoalSettingStep | null = null;
        if (currentSubStepValue && goalSteps.includes(currentSubStepValue)) {
            currentSubStep = <GoalSettingStep>currentSubStepValue;
        }

        if (goalChange) {
            this.goalStep.set(GoalSettingStep.RoundVerify);
        } else {
            this.goalStep.set(currentSubStep ? currentSubStep : getInitialGoalStep(applicationGoal));
        }

        const currentGoalStep = this.goalStep();

        if (!currentGoalStep) {
            return;
        }

        const currentPartStep = this.currentUser.currentApplication?.currentPartStep as GoalSettingPartsStep;

        this.partStep.set(currentPartStep);
    }

    #getAboutYouStructure(): void {
        this.aboutYouCategories.set([]);

        combineLatest([
            this.#aboutYouService.getCategories(),
            this.#aboutYouService.getQuestions(),
            this.#aboutYouService.getAnswerSelection(),
            this.#aboutYouService.getAnswers(),
        ]).subscribe(([categoriesResponse, questionsResponse, answerSelectionResponse, answersResponse]) => {
            this.aboutYouCategories.set(this.#getAboutYouCategories(categoriesResponse?.data || []));
            this.financialProjection.set(this.#getNestedCategories(categoriesResponse?.data || []));
            this.aboutYouQuestions.set(questionsResponse?.data || []);
            this.answerSelections.set(orderBy(answerSelectionResponse?.data || [], 'order'));
            this.applicationAboutYou.set(answersResponse?.data || []);
        });
    }

    #getRiskToleranceStructure(): void {
        this.riskToleranceCategories.set([]);

        combineLatest([
            this.#aboutYouService.getCategories(),
            this.#aboutYouService.getQuestions(),
            this.#aboutYouService.getAnswerSelection(),
            this.#aboutYouService.getAnswers(),
        ]).subscribe(([categoriesResponse, questionsResponse, answerSelectionResponse, answersResponse]) => {
            this.riskToleranceCategories.set(this.#getRiskToleranceCategories(categoriesResponse?.data || []));

            this.financialProjection.set(this.#getNestedCategories(categoriesResponse?.data || []));

            this.riskToleranceQuestions.set(questionsResponse?.data || []);
            this.riskToleranceAnswerSelections.set(orderBy(answerSelectionResponse?.data || [], 'order'));
            this.applicationRiskTolerance.set(answersResponse?.data || []);
        });
    }

    #getWealthStructure(): void {
        this.wealthCategories.set([]);

        combineLatest([
            this.#aboutYouService.getCategories(),
            this.#aboutYouService.getQuestions(),
            this.#aboutYouService.getAnswerSelection(),
            this.#aboutYouService.getAnswers(),
        ]).subscribe(([categoriesResponse, questionsResponse, answerSelectionResponse, answersResponse]) => {
            this.wealthCategories.set(this.#getWealthCategories(categoriesResponse?.data || []));

            this.financialProjection.set(this.#getNestedCategories(categoriesResponse?.data || []));

            this.wealthQuestions.set(questionsResponse?.data || []);
            this.wealthAnswerSelections.set(orderBy(answerSelectionResponse?.data || [], 'order'));
            this.applicationWealth.set(answersResponse?.data || []);
        });
    }

    #getFinancialSituationStructure(data: boolean = false, isfromStep: boolean = false): void {
        this.financialSituation.set(null);

        combineLatest([
            this.#financialSituationService.getCategories(),
            this.#financialSituationService.getQuestions(this.#currentUser.currentApplication?.id),
            this.#financialSituationService.getAnswerSelection(),
            this.#financialSituationService.getAnswers(),
        ]).subscribe(([categoriesResponse, questionsResponse, answerSelectionResponse, answersResponse]) => {
            const financialCategoriesBasedOnType = this.#getNestedCategories(categoriesResponse?.data || [], data);

            let a;

            if (this.currentUser.currentApplication?.financialCategoryType === FinancialCategoryType.Liabilities) {
                a = financialCategoriesBasedOnType.filter(
                    (v) => v.name === this.currentUser.currentApplication?.financialCategoryType || v.name === 'Financial Snapshot'
                );
                a.forEach((item, index) => {
                    item.isFirstItem = index === 0; // True only for the first item
                });
            } else {
                a = financialCategoriesBasedOnType.filter((v) => v.name === this.currentUser.currentApplication?.financialCategoryType);
                a.forEach((item, index) => {
                    item.isFirstItem = index === 0; // True only for the first item
                });
            }

            this.financialSituation.set({
                categories: data ? financialCategoriesBasedOnType : isfromStep ? financialCategoriesBasedOnType : a,
                questions: questionsResponse?.data || [],
                answerSelections: answerSelectionResponse?.data || [],
                answers: answersResponse?.data || [],
                data: data,
            });

            this.#cdf.detectChanges();
        });
    }

    navigateToTheQuotePage(): void {
        this.backToTheQuotePage.emit();
    }

    #getAboutYouCategories(categoriesResponse: CategoryResponse[], data: boolean = false): Category[] {
        const sortedByOrder = orderBy(categoriesResponse, 'displayOrder');

        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) => parentCategory.type === 'AboutYou' && parentCategory.name === 'Personal profile & relationship'
        );

        return aboutYouParents.map((parentCategory, parentIndex) => ({
            ...parentCategory,
            name: parentCategory.name === 'Personal profile & relationship' ? 'About You' : parentCategory.name,
            isFirstItem: parentIndex === 0,
            isLastItem: parentIndex === aboutYouParents.length - 1,
            data: data,
            childItems: groupByParentId[parentCategory.id]?.map((category, index) => ({
                ...category,
                isFirstItem: index === 0,
                isLastItem: index === groupByParentId[parentCategory.id].length - 1,
            })),
        }));
    }

    #getRiskToleranceCategories(categoriesResponse: any[], data: boolean = false): Category[] {
        const riskCategoryCodes = Object.values(riskCategoryType);
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) =>
                parentCategory.type === 'AboutYou' &&
                (parentCategory.name === 'Financial behaviour and risk analysis' || parentCategory.name === 'Risk Tolerance Outcome')
        );

        return aboutYouParents.map((parentCategory, parentIndex) => {
            // Check if all child items have status "Complete"
            const childCategories = groupByParentId[parentCategory.id]?.filter((category) =>
                riskCategoryCodes.includes(category.categoryCode as unknown as riskCategoryType)
            );

            // Check if all child items have status "Complete" (ignore "Pending")
            const allChildrenComplete = childCategories?.every((category) => category.status === 'Complete');
            return {
                ...parentCategory,
                name: parentCategory.name === 'Financial behaviour and risk analysis' ? 'Risk Tolerance' : parentCategory.name,
                isFirstItem: parentIndex === 0,
                isLastItem: parentIndex === aboutYouParents.length - 1,
                data: data,
                status: allChildrenComplete ? 'Complete' : parentCategory.status, // Set parent status to 'Complete' if all children are complete
                childItems: childCategories?.map((category, index) => {
                    const isLastItem = category.categoryCode === 'InvestingPreference' || index === childCategories.length - 1;
                    return {
                        ...category,
                        isFirstItem: index === 0,
                        isLastItem: isLastItem,
                    };
                }),
            };
        });
    }

    #getWealthCategories(categoriesResponse: any[], data: boolean = false): Category[] {
        const riskCategoryCodes = Object.values(wealthCategoryType);
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) => parentCategory.type === 'AboutYou' && parentCategory.name === 'Financial behaviour and risk analysis'
        );

        return aboutYouParents.map((parentCategory, parentIndex) => {
            // Check if all child items have status "Complete"
            const childCategories = groupByParentId[parentCategory.id]?.filter((category) =>
                riskCategoryCodes.includes(category.categoryCode as unknown as wealthCategoryType)
            );

            // Check if all child items have status "Complete" (ignore "Pending")
            const allChildrenComplete = childCategories?.every((category) => category.status === 'Complete');
            return {
                ...parentCategory,
                name: parentCategory.name === 'Financial behaviour and risk analysis' ? 'Wealth Health Score' : parentCategory.name,
                isFirstItem: parentIndex === 0,
                isLastItem: parentIndex === aboutYouParents.length - 1,
                data: data,
                status: allChildrenComplete ? 'Complete' : parentCategory.status, // Set parent status to 'Complete' if all children are complete
                childItems: childCategories?.map((category, index) => ({
                    ...category,
                    isFirstItem: index === 0,
                    isLastItem: index === childCategories.length - 1,
                })),
            };
        });
    }

    #getNestedCategories(categoriesResponse: CategoryResponse[], data: boolean = false): Category[] {
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');
        const emptyParentId = groupByParentId['null'];

        return emptyParentId.map((parentCategory, parentIndex) => ({
            ...parentCategory,
            isFirstItem: parentIndex === 0,
            isLastItem: parentIndex === emptyParentId.length - 1,
            data: data,
            childItems: groupByParentId[parentCategory.id]?.map((category, index) => ({
                ...category,
                isFirstItem: index === 0,
                isLastItem: index === groupByParentId[parentCategory.id].length - 1,
            })),
        }));
    }

    #clearPartnerProfileIfUserHasOnlyOneApplicationUser(applicationUsers: ApplicationUser[]): void {
        if (applicationUsers.length < 2 && !this.isDashboardApp) {
            this.#userService.clearCurrentPartnerProfile().subscribe();
        }
    }

    #handleWealthCoachProfileWithoutPlan(): void {
        if (isWealthCoachNotPlan(this.applicationStep(), this.subscriptionPlan())) {
            this.applicationStep.set(ApplicationStep.WealthHealth);
        }
    }

    isCalculationStopped($event: boolean) {
        if ($event) {
            this.#dialogRef.close();
        } else {
            this.showFinancialProjection();
        }
    }

    public showFinancialProjection() {
        this.#dialogRef = this.#dialog.open(ProjectionPopupComponent, {
            width: '450px',
            height: '450px',
            disableClose: true,
            hasBackdrop: true,
            panelClass: 'financial-popup-container',
        });
    }

    movetoPrevious(): void {
        const currentIndex = this.applicationStepIndex();
        if (currentIndex > 0) {
            const previousStep = findKey(ApplicationStepIndex, (item) => item === currentIndex - 1);
            if (previousStep) {
                this.applicationStep.set(previousStep as ApplicationStep);
                this.stepperChanged(currentIndex - 1);
            }
        }
    }

    public triggerWebhook(status: any) {
        this.#applicationService
            .triggerWebhook(this.currentUser.currentApplication?.applicationUsers[0]?.firstName || '', '', status, this.currentUser.email)
            .subscribe(
                () => {
                    console.log('Webhook Status: Success');
                },
                (err) => {
                    console.log('Webhook Status: Error', err);
                }
            );
    }

    public dashboardEmit() {
        this.showDashboard.emit();
    }

    showApplication(event: { isShowApplication: boolean; step: ApplicationStep | any; subStep: any }) {
        this.dashboardLastStep = this.currentUser?.currentApplication?.currentStep;

        if (this.currentUser.currentApplication) {
            this.currentUser.currentApplication.isStepFromSummary = true;
        }

        if (this.currentUser.currentApplication) {
            if (event.step === ApplicationStep.AboutYou) {
                this.latestApplicationStep.set(ApplicationStep.AboutYou);
                this.applicationStep.set(ApplicationStep.AboutYou);
                return;
            } else if (event.step === ApplicationStep.Goal) {
                this.latestApplicationStep.set(ApplicationStep.Goal);
                this.applicationStep.set(ApplicationStep.Goal);
                return;
            } else if (event.step === ApplicationStep.FinancialSnapshot) {
                this.currentUser.currentApplication.financialCategoryType = event.subStep;
                this.latestApplicationStep.set(ApplicationStep.FinancialSnapshot);
                this.applicationStep.set(ApplicationStep.FinancialSnapshot);
                return;
            } else if (event.step === ApplicationStep.RiskTolerance) {
                this.latestApplicationStep.set(ApplicationStep.RiskTolerance);
                this.applicationStep.set(ApplicationStep.RiskTolerance);
                return;
            } else if (event.step === ApplicationStep.WealthHealth) {
                this.latestApplicationStep.set(ApplicationStep.WealthHealth);
                this.applicationStep.set(ApplicationStep.WealthHealth);
                return;
            }
        }

        this.stepperChanged(ApplicationStepIndex[event.step as ApplicationStep]);
        // if (event.subStep === FinancialCategoryType.Income) {
        //     data.currentApplication.financialCategoryType = FinancialCategoryType.Income;
        // } else if (event.subStep === FinancialCategoryType.Assets) {
        //     data.currentApplication.financialCategoryType = FinancialCategoryType.Assets;
        // } else if (event.subStep === FinancialCategoryType.Liabilities) {
        //     data.currentApplication.financialCategoryType = FinancialCategoryType.Liabilities;
        // } else if (event.subStep === FinancialCategoryType.Expenses) {
        //     data.currentApplication.financialCategoryType = FinancialCategoryType.Expenses;
        // }

        this.#cdf.detectChanges();
    }
}

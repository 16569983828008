import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WealthCalendlyService } from '../data-access/wealth-calendly.service';
import { NativeDateAdapter } from '@angular/material/core';

const MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };
@Component({
    selector: 'acorn-wealth-coach-popup',
    standalone: true,
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: NativeDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatIconModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './wealth-coach-popup.component.html',
    styleUrls: ['./wealth-coach-popup.component.scss'],
})
export class WealthCoachPopupComponent {
    @Output() added = new EventEmitter<void>();

    dialogData = inject(MAT_DIALOG_DATA);
    #toastrService = inject(ToastrService);
    #strategyActionPlanSvc = inject(WealthCalendlyService);
    #ngxLoader = inject(NgxUiLoaderService);
    #dialogRef = inject(MatDialogRef<WealthCoachPopupComponent>);

    public formGroup: FormGroup;
    formData: any;

    constructor() {
        this.formGroup = new FormGroup({
            arNumber: new FormControl({ value: '', disabled: false }, Validators.required),
            wealthCoachName: new FormControl({ value: '', disabled: false }, Validators.required),
        });

        if (this.dialogData.isShowPreviousSOA) {
            this.formGroup.addControl('dateOfPreviousSOA', new FormControl({ value: '', disabled: false }, Validators.required));
        }

        if (this.dialogData.isShowCallofDate) {
            this.formGroup.addControl('dateOfCall', new FormControl({ value: '', disabled: false }, Validators.required));
        }

        this.getWealthCoachData();
    }

    public confirm() {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.#ngxLoader.start();

        const formValues = this.formGroup.value;

        if(this.dialogData.isShowCallofDate){
            const dt = new Date(formValues.dateOfCall);
            formValues.dateOfCall = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
        }

        if(this.dialogData.isShowPreviousSOA){
            const dp = new Date(formValues.dateOfPreviousSOA);
            formValues.dateOfPreviousSOA = dp.getFullYear() + '-' + (dp.getMonth() + 1) + '-' + dp.getDate();
        }

        if (!this.dialogData?.isShowCallofDate) {
            formValues.dateOfCall = this.formatDateOrNull(this.formData?.dateOfCall);
        }

        if (!this.dialogData?.isShowPreviousSOA) {
            formValues.dateOfPreviousSOA = this.formatDateOrNull(this.formData?.dateOfPreviousSOA);
        }

        const data = {
            applicationId: this.dialogData?.applicationId,
            ...formValues,
        };

        this.#strategyActionPlanSvc.SaveWealthCoachData(data, this.dialogData?.applicationId).subscribe(
            (res: any) => {
                this.#toastrService.success('Wealth coach details added successfully', 'Success');

                this.added.emit();

                this.#dialogRef.close();
            },
            (err: any) => {
                this.#dialogRef.close();
                this.#ngxLoader.stop();
                this.#toastrService.error(err.error.Message, 'Error');
            }
        );
    }

    getWealthCoachData() {
        this.#strategyActionPlanSvc.GetWealthCoachData(this.dialogData.applicationId).subscribe(
            (res: any) => {
                this.formData = res?.data || {};

                const patchableData = Object.keys(this.formData).reduce((acc, key) => {
                    if (this.formGroup.contains(key)) {
                        acc[key] = this.formData[key];
                    }
                    return acc;
                }, {} as any);

                this.formGroup.patchValue(patchableData);
            },
            (err: any) => {
                console.error('Error fetching strategies calculation:', err);
            }
        );
    }

    private formatDateOrNull(date: any): string | null {
        if (date) {
            const dt = new Date(date);
            return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
        }
        return null;
    }
}

<img
  *ngIf="url; else defaultTemplate"
  [ngSrc]="url"
  alt=""
  fill=""
/>

<ng-template #defaultTemplate>
  <mat-icon>account_circle_outline</mat-icon>
</ng-template>

import { MembershipPlanEntry, MembershipPlanEntryResponse } from '@acorn/util';

import { ActionListMapper } from './action-list.mapper';

export function mappingMembershipPlan(
  dataEntry: MembershipPlanEntryResponse
): MembershipPlanEntry {
  return <MembershipPlanEntry>{
    id: dataEntry.id,
    name: dataEntry.name,
    description: dataEntry.description,
    actionList: ActionListMapper.fromNestedModelToSingleModel(
      dataEntry.actionList
    ),
    featureImage: dataEntry.featureImage.fields.file.url,
    scheduleTitle: dataEntry.scheduleTitle,
  };
}

import { ApplicationStep, WealthHealthStep } from '@acorn/util';

export const ApplicationStepIndex = {
    [ApplicationStep.GettingStarted]: 0,
    [ApplicationStep.AboutYou]: 1,
    [ApplicationStep.Goal]: 2,
    [ApplicationStep.FinancialSnapshot]: 3,
    [ApplicationStep.RiskTolerance]: 4,
    [ApplicationStep.WealthHealth]: 5,
    [ApplicationStep.FinancialProjection]: 6,
    [ApplicationStep.WealthStrategyOptions]: 7,
    [ApplicationStep.WealthCoach]: 8,
    [ApplicationStep.Dashboard]: 9,
};

export const WealthHealthStepIndex = {
  [WealthHealthStep.WealthBuildingBlock]: 0,
  [WealthHealthStep.WealthScore]: 1,
  [WealthHealthStep.WealthHappy]: 2,
};

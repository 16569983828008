import { BaseContentFul, ImageContent } from './base-contentful.interface';

export interface HomePageContent {
  customerQuoteTitle: string;
  finePrintDescription: string;
  finePrintTitle: string;
  headerDescription: string;
  headerTitle: string;
  internalName: string;
  keyFeaturesTitle: string;
  keyPointsTitle: string;
  learnMoreTitle: string;
  learnMoreUrl: string;
  howItWorksTitle: string;
  summaryDescription: string;
  summaryImages: ImageContent[];
  summaryTitle: string;
  keyPoints?: KeyPoint[];
  keyFeatures?: BaseContentFul[];
  customerQuotes?: CustomerQuote[];
}

export interface KeyPoint extends BaseContentFul {
  images: ImageContent[];
}

export interface CustomerQuote {
  author: string;
  caption: string;
}

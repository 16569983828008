<ng-container *ngIf="answers?.length || markAsSeen(); else welcomeTemplate">
  <acorn-vertical-stepper
    [selectedIndex]="selectedStep()!"
    (selectedIndexChange)="onSelectStep($event)"
  >
    <cdk-step
      *ngFor="let category of categories"
      [label]="category.name"
      [state]="category.status | displayStepState"
    >
      <acorn-nested-vertical-stepper
        *ngIf="category.id === selectedParentCategory()?.id!"
        [parentId]="category.id"
        [activeParentId]="selectedParentCategory()?.id!"
        [selectedIndex]="selectedChildStep()!"
        (selectedIndexChange)="onSelectChildStep($event)"
      >
        <cdk-step
          *ngFor="let subStep of category.childItems!"
          [label]="subStep.name"
          [state]="subStep.status | displayStepState"
        ></cdk-step>
      </acorn-nested-vertical-stepper>
    </cdk-step>
  </acorn-vertical-stepper>

  <div class="step__body">
    <mat-card [class.dual-respondent]="isDualRespondent()">
      <mat-card-content>
        <h4>{{ selectedParentCategory()?.name }}</h4>

        <h1>{{ selectedCategory()?.name }}</h1>

        <p *ngIf="selectedCategory()?.description" class="description">
          {{ selectedCategory()?.description }}
        </p>

        <ng-container
          *ngIf="
            !isProcessFinancialSnapshot();
            else snapshotDescriptionTemplate
          "
        >
          <acorn-alert *ngIf="errorMessage()" type="error" class="common-error">
            <b>{{ errorMessage() }}</b>
          </acorn-alert>

          <div
            class="accordion-wrapper"
            [class.dual-respondent]="isDualRespondent()"
          >
            <mat-accordion
              *ngFor="
                let applicationUser of formRecord().controls
                  | keyvalue : applicationUserOrder
              "
            >
              <mat-expansion-panel
                [expanded]="
                  layoutService.screenSize() !== ScreenSize.Mobile ||
                  (layoutService.screenSize() === ScreenSize.Mobile &&
                    !isDualRespondent())
                "
                [disabled]="
                  layoutService.screenSize() !== ScreenSize.Mobile &&
                  isDualRespondent()
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ formTemplate[applicationUser.key].applicationUserName }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="questions">
                  <ng-container *ngFor="let item of selectedQuestions()">
                    <ng-container
                      *ngIf="
                        formTemplate[applicationUser.key].questions[
                          item.id
                        ] as questionTemplateType
                      "
                    >
                      <ng-container [ngSwitch]="questionTemplateType">
                        <acorn-group-question
                          *ngSwitchCase="FinancialTemplateType.GroupQuestion"
                          [question]="item"
                          [formRecord]="applicationUser.value.controls[item.id]"
                          [applicationKey]="applicationUser.key"
                          [warningMessage]="warningMessages().get(applicationUser.key)"
                          [isDualRespondent]="isDualRespondent()"
                          [hasDivider]="hasDivider()"
                          [isSubmitted]="isSubmitted()"
                          [applicationUsers]="applicationUsers"
                          [isFileNotes]="isFileNotes"
                          [isExpand]="
                            null | isSingleQuestionExpand : selectedCategory() : item.question.questionType
                          "
                          [errorMessage]="this.errorMessages().get(applicationUser.key)"
                          (removeWarningMessage)="removeWarningMessage($event)"
                        ></acorn-group-question>

                        <acorn-nested-group-question
                          *ngSwitchCase="
                            FinancialTemplateType.NestedGroupQuestion
                          "
                          [applicationUsers]="applicationUsers"
                          [question]="item"
                          [formArray]="applicationUser.value.controls[item.id]"
                          [isDualRespondent]="isDualRespondent()"
                          [hasDivider]="hasDivider()"
                          [isSubmitted]="isSubmitted()"
                          [isFileNotes]="isFileNotes"
                          [isExpand]="
                            item
                              | isExpandedExpansionPanel
                                : answers
                                : applicationUser.key
                          "
                          [errorMessage]="
                            this.errorMessages().get(applicationUser.key)
                          "
                        >
                        </acorn-nested-group-question>

                        <acorn-array-question
                        [isFileNotes]="isFileNotes"
                          *ngSwitchCase="FinancialTemplateType.ArrayQuestion"
                          [question]="item"
                          [isDualRespondent]="isDualRespondent()"
                          [formArray]="applicationUser.value.controls[item.id]"
                          [maxControl]="
                            item.question.maxChildNumber
                              ? item.question.maxChildNumber
                              : null
                          "
                        ></acorn-array-question>

                        <acorn-single-question
                        [isFileNotes]="isFileNotes"
                          *ngSwitchDefault
                          [formGroup]="applicationUser.value.controls[item.id]"
                          [question]="item"
                          [applicationKey]="applicationUser.key"
                          [isDualRespondent]="isDualRespondent()"
                          [isSubmitted]="isSubmitted()"
                          [isExpand]="
                            getSuperannuation(item, applicationUser.value)
                              | isSingleQuestionExpand
                                : selectedCategory()
                                : item.question.questionType
                          "
                          [warningMessage]="
                            this.warningMessages()
                              .get(applicationUser.key)
                              ?.get(item.id)
                          "
                          (removeWarningMessage)="removeWarningMessage($event)"
                        ></acorn-single-question>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-container>

        <ng-template #snapshotDescriptionTemplate>
          <p class="description no-margin">
            Below is a brief summary of your personal financial situation.
            You’ve made a good start, and taking actions from the Wealth
            Maximiser will help you leap ahead.
          </p>
        </ng-template>
      </mat-card-content>
    </mat-card>

    <acorn-financial-snapshot
      *ngIf="isProcessFinancialSnapshot()"
      [applicationId]="applicationId"
      [snapshotCategoryStatus]="selectedParentCategory()?.status"
     [isFileNotes]="isFileNotes"
    ></acorn-financial-snapshot>

    <acorn-application-navigation
      [isLoading]="isLoading()"
      (back)="onHandleBack()"
      (next)="onValidation()"
    ></acorn-application-navigation>
  </div>

  <div></div>
</ng-container>

<ng-template #welcomeTemplate>
  <div></div>

  <acorn-welcome-screen
    imageName="financial-situation.svg"
    title="Financial Situation"
    description="Understanding your income and expenses is important when building an effective wealth plan. Please provide details about your income, expenses, and any financial commitments so we can prepare wealth strategies that align with your financial reality. Estimates with around 80% accuracy will be sufficient. <br><br> At the end of this section you’ll receive a graphic summary of your financial situation."
    estimatedTime="30"
  >
    <mat-card class="mat-card--small">
      <mat-card-content>
        <b>You’ll need to have the following information at hand:</b>

        <ul>
          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Monthly income (including salary, rental income, dividends etc.)
          </li>

          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Monthly expenses (including groceries, utilities, health &
            insurance, subscriptions etc.)
          </li>

          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Amount held in savings accounts
          </li>

          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Amount held in investment portfolio
          </li>

          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Amount currently in Superannuation and amount contributed yearly
            from salary
          </li>

          <li>
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#check-fill'"
              [width]="'24px'"
            ></svg-icon-sprite>

            Amount owed on any loans/debt, interest rate, term length and
            whether it is a P&I loan or interest only
          </li>
        </ul>
      </mat-card-content>
    </mat-card>

    <acorn-application-navigation
      (back)="back.emit()"
      (next)="markAsSeen.set(true)"
    ></acorn-application-navigation>
  </acorn-welcome-screen>

  <div></div>
</ng-template>

<ng-container
  *ngIf="selectedStep() !== undefined || markAsSeen(); else welcomeTemplate"
>
  <acorn-vertical-stepper
    [selectedIndex]="selectedStep()!"
    (selectedIndexChange)="onSelectStep($event)"
  >
    <cdk-step
      *ngFor="let category of categories"
      [label]="category.name"
      [state]="category.status | displayStepState"
    >
      <acorn-nested-vertical-stepper
        *ngIf="category.id === selectedParentCategory()?.id!"
        [parentId]="category.id"
        [activeParentId]="selectedParentCategory()?.id!"
        [selectedIndex]="selectedChildStep()!"
        (selectedIndexChange)="onSelectChildStep($event)"
      >
        <cdk-step
          *ngFor="let subStep of category.childItems!"
          [label]="subStep.name"
          [state]="subStep.status === CategoryStatus.Complete ? 'done' : ''"
        ></cdk-step>
      </acorn-nested-vertical-stepper>
    </cdk-step>
  </acorn-vertical-stepper>

  <div class="step__body" [ngSwitch]="selectedTemplateType()">
    <ng-container *ngSwitchCase="TemplateType.MultiAnswerQuestion">
      <acorn-dependants
        [questions]="selectedQuestions()"
        [parentCategory]="selectedParentCategory()!"
        [currentCategory]="selectedCategory()!"
        [applicationId]="applicationId"
        [applicationUsers]="applicationUsers"
        [isFileNotes]="isFileNotes"
        [applicationAboutYou]="latestApplicationAboutYou()"
        (async)="onUpdateApplicationAboutYou($event)"
        (back)="onHandleBack()"
        (next)="onBeforeHandleNext()"
      ></acorn-dependants>
    </ng-container>

    <ng-container *ngSwitchCase="TemplateType.LastReview">
      <acorn-last-review
        [questions]="selectedQuestions()"
        [parentCategory]="selectedParentCategory()!"
        [currentCategory]="selectedCategory()!"
        [answerSelections]="answerSelections"
        [applicationId]="applicationId"
        [applicationUsers]="applicationUsers"
        [isFileNotes]="isFileNotes"
        [applicationAboutYou]="latestApplicationAboutYou()"
        (async)="onUpdateApplicationAboutYou($event)"
        (back)="onHandleBack()"
        (next)="onBeforeHandleNext()"
      ></acorn-last-review>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <acorn-common-answer
        [answerSelections]="answerSelections"
        [questions]="selectedQuestions()"
        [primaryQuestion]="selectedPrimaryQuestion()"
        [parentCategory]="selectedParentCategory()!"
        [currentCategory]="selectedCategory()!"
        [applicationId]="applicationId"
        [applicationUsers]="applicationUsers"
        [isFileNotes]="isFileNotes"
        [applicationAboutYou]="latestApplicationAboutYou()"
        (async)="onUpdateApplicationAboutYou($event)"
        (back)="onHandleBack()"
        (next)="onBeforeHandleNext()"
      ></acorn-common-answer>
    </ng-container>
  </div>

  <div></div>
</ng-container>

<ng-template #welcomeTemplate>
  <div></div>

  <acorn-welcome-screen
    imageName="about-you.svg"
    title="About You"
    description="Now we know what your goals are, it's time to understand your current lifestyle, financial situation, behaviours and preferences. We'll use these insights to better define the timeline and specifics of your wealth strategy options."
    estimatedTime="5"
  >
    <acorn-application-navigation
      (back)="back.emit()"
      (next)="onInitializeStep()"
    >
    </acorn-application-navigation>
  </acorn-welcome-screen>

  <div></div>
</ng-template>

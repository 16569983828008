import { ApplicationService } from "@acorn/data-access";
import { inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class Helper {
  applicationSvc = inject(ApplicationService);

  updateStatus(applicationId: any) {
      this.applicationSvc.updateStatus(applicationId).subscribe(
        () => {},
        (error: any) => {
          console.error('Error updating status', error);
        }
      );
  }
}

<header>
  <div class="user-circle-wrapper">
    <div class="user-circle">
      <img class="user-border" src="assets/images/color-wheel.svg" alt="" />

      <img
        class="user-avatar"
        [src]="
          isDualUser()
            ? 'assets/images/dual-user-circle.svg'
            : 'assets/images/user-circle.svg'
        "
        alt=""
      />

      <span class="user-name">
        {{
          isDualUser()
            ? firstUserFinance()?.firstName +
              ' & ' +
              secondUserFinance()?.firstName
            : firstUserFinance()?.firstName
        }}
      </span>

      <span id="income-connector" class="point"></span>
      <span id="expenses-connector" class="point"></span>
      <span id="liabilities-connector" class="point"></span>
      <span id="assets-connector" class="point"></span>
    </div>
  </div>
  <div class="headline">
    <h3>Net assets today</h3>

    <h1>
      {{
        (isDualUser()
          ? firstUserFinance()?.financialValue?.netAssetsToday! +
            secondUserFinance()?.financialValue?.netAssetsToday!
          : firstUserFinance()?.financialValue?.netAssetsToday!
        )
          | currency : 'USD' : 'symbol' : '.0'
          | acornConvertZeroToDash
      }}
    </h1>
  </div>
</header>

<div class="panel">
  <div class="single-column">
    <acorn-information-panel
      [type]="FinancialSnapshotType.Income"
      [total]="
        isDualUser()
          ? firstUserFinance()?.financialValue?.totalIncome! +
            secondUserFinance()?.financialValue?.totalIncome!
          : firstUserFinance()?.financialValue?.totalIncome || 0
      "
    >
      <div>
        <div class="card-item">
          <div class="card-title">Employment (excl. Super)</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.employment! +
                  secondUserFinance()?.financialValue?.employment!
                : firstUserFinance()?.financialValue?.employment
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.employment
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.employment
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>

        <div class="card-item">
          <div class="card-title">Rental Income</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.rentalIncome! +
                  secondUserFinance()?.financialValue?.rentalIncome!
                : firstUserFinance()?.financialValue?.rentalIncome
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.rentalIncome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.rentalIncome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="card-item">
          <div class="card-title">Other Income</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.otherIncome! +
                  secondUserFinance()?.financialValue?.otherIncome!
                : firstUserFinance()?.financialValue?.otherIncome
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.otherIncome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.otherIncome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>
    </acorn-information-panel>
    <acorn-information-panel
      [type]="FinancialSnapshotType.Expenses"
      [total]="
        isDualUser()
          ? firstUserFinance()?.financialValue?.totalExpenses! +
            secondUserFinance()?.financialValue?.totalExpenses!
          : firstUserFinance()?.financialValue?.totalExpenses || 0
      "
    >
      <div>
        <div class="card-item">
          <div class="card-title">Non-discretionary Expenses</div>

          <div class="card-sub-title">excl. Insurance</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.nonTaxExpenses! +
                  secondUserFinance()?.financialValue?.nonTaxExpenses!
                : firstUserFinance()?.financialValue?.nonTaxExpenses
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.nonTaxExpenses
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.nonTaxExpenses
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Discretionary Expenses</div>

          <div class="card-sub-title">excl. Insurance</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.taxExpenses! +
                  secondUserFinance()?.financialValue?.taxExpenses!
                : firstUserFinance()?.financialValue?.taxExpenses
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.taxExpenses
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.taxExpenses
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="card-item">
          <div class="card-title">Insurance Premiums</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.insurancePremiums! +
                  secondUserFinance()?.financialValue?.insurancePremiums!
                : firstUserFinance()?.financialValue?.insurancePremiums
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.insurancePremiums
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.insurancePremiums
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>
    </acorn-information-panel>
  </div>
  <div class="single-column">
    <acorn-information-panel
      [type]="FinancialSnapshotType.Assets"
      [total]="
        isDualUser()
          ? firstUserFinance()?.financialValue?.totalAssets! +
            secondUserFinance()?.financialValue?.totalAssets!
          : firstUserFinance()?.financialValue?.totalAssets || 0
      "
    >
      <div>
        <div class="card-item">
          <div class="card-title">Emergency Fund</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.emergencyFund! +
                  secondUserFinance()?.financialValue?.emergencyFund!
                : firstUserFinance()?.financialValue?.emergencyFund
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.emergencyFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.emergencyFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Savings</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.savings! +
                  secondUserFinance()?.financialValue?.savings!
                : firstUserFinance()?.financialValue?.savings
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.savings
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.savings
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Owner-Occupied Home</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.ownerOccupiedHome! +
                  secondUserFinance()?.financialValue?.ownerOccupiedHome!
                : firstUserFinance()?.financialValue?.ownerOccupiedHome
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.ownerOccupiedHome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.ownerOccupiedHome
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Home Contents/ Miscellaneous</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.homeContents! +
                  secondUserFinance()?.financialValue?.homeContents!
                : firstUserFinance()?.financialValue?.homeContents
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.homeContents
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.homeContents
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Superannuation</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.superannuation! +
                  secondUserFinance()?.financialValue?.superannuation!
                : firstUserFinance()?.financialValue?.superannuation
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.superannuation
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.superannuation
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="card-item">
          <div class="card-title">Motor Vehicles</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.motorVehicles! +
                  secondUserFinance()?.financialValue?.motorVehicles!
                : firstUserFinance()?.financialValue?.motorVehicles
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.motorVehicles
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.motorVehicles
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Investment Property</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.investmentProperty! +
                  secondUserFinance()?.financialValue?.investmentProperty!
                : firstUserFinance()?.financialValue?.investmentProperty
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.investmentProperty
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.investmentProperty
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Investment Portfolio (non-property)</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.investmentNonProperty! +
                  secondUserFinance()?.financialValue?.investmentNonProperty!
                : firstUserFinance()?.financialValue?.investmentNonProperty
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.investmentNonProperty
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.investmentNonProperty
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Funeral Fund</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.funeralFund! +
                  secondUserFinance()?.financialValue?.funeralFund!
                : firstUserFinance()?.financialValue?.funeralFund
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.funeralFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.funeralFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Education Fund</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.educationFund! +
                  secondUserFinance()?.financialValue?.educationFund!
                : firstUserFinance()?.financialValue?.educationFund
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.educationFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.educationFund
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>
    </acorn-information-panel>

    <acorn-information-panel
      [type]="FinancialSnapshotType.Liabilities"
      [total]="
        isDualUser()
          ? firstUserFinance()?.financialValue?.totalLiabilities! +
            secondUserFinance()?.financialValue?.totalLiabilities!
          : firstUserFinance()?.financialValue?.totalLiabilities || 0
      "
    >
      <div>
        <div class="card-item">
          <div class="card-title">Mortgage</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.mortgage! +
                  secondUserFinance()?.financialValue?.mortgage!
                : firstUserFinance()?.financialValue?.mortgage
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.mortgage
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.mortgage
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Student Loans</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.studentLoans! +
                  secondUserFinance()?.financialValue?.studentLoans!
                : firstUserFinance()?.financialValue?.studentLoans
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.studentLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.studentLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Credit Card & Other Loans</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.creditCardAndOtherLoans! +
                  secondUserFinance()?.financialValue?.creditCardAndOtherLoans!
                : firstUserFinance()?.financialValue?.creditCardAndOtherLoans
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.creditCardAndOtherLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.creditCardAndOtherLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="card-item">
          <div class="card-title">Motor Vehicle Leases</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.motorVehicleLeases! +
                  secondUserFinance()?.financialValue?.motorVehicleLeases!
                : firstUserFinance()?.financialValue?.motorVehicleLeases
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.motorVehicleLeases
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.motorVehicleLeases
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-title">Investment Property Loans</div>

          <div class="total">
            {{
              (isDualUser()
                ? firstUserFinance()?.financialValue?.investmentPropertyLoans! +
                  secondUserFinance()?.financialValue?.investmentPropertyLoans!
                : firstUserFinance()?.financialValue?.investmentPropertyLoans
              )
                | currency : 'USD' : 'symbol' : '.0'
                | acornConvertZeroToDash
            }}
          </div>

          <div class="detail-item" *ngIf="isDualUser()">
            <div class="detail-name">{{ firstUserFinance()?.firstName }}</div>
            <p>
              {{
                firstUserFinance()?.financialValue?.investmentPropertyLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
            <div class="detail-name">{{ secondUserFinance()?.firstName }}</div>
            <p>
              {{
                secondUserFinance()?.financialValue?.investmentPropertyLoans
                  | currency : 'USD' : 'symbol' : '.0'
                  | acornConvertZeroToDash
              }}
            </p>
          </div>
        </div>
      </div>
    </acorn-information-panel>
  </div>
</div>

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../../../../../../apps/customer-portal/src/app/common/ui';

@Component({
  selector: 'acorn-information',
  standalone: true,
  imports: [CommonModule, FooterComponent],
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent {}

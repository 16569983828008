export enum AnswerType {
  Text = 'Text',
  Number = 'Number',
  Year = 'Year',
  Amount = 'Amount',
  Percent = 'Percent',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  MatrixTable = 'MatrixTable',
  AdultAge = 'AdultAge',
  ChildAge = 'ChildAge',
  Age = 'Age',
  LastReviewDropDown = 'LastReviewDropDown',
  CheckboxMax3 = 'CheckboxMax3',
  Group = 'Group',
  TabChoice = 'TabChoice',
  YearDropdown = 'YearDropdown',
  PercentDropdown = 'PercentDropdown',
  InterestRate = 'InterestRate',
  RepaymentStructure = 'RepaymentStructure',
}

export enum QuestionType {
  PrimaryIncome = 'PrimaryIncome',
  CommissionsBonus = 'CommissionsBonus',
  EmployerSuperContributions = 'EmployerSuperContributions',
  SecondaryIncome = 'SecondaryIncome',
  BaseSalary = 'BaseSalary',
  EmployerSuperannuationContributions = 'EmployerSuperannuationContributions',
  ConcessionalSuperContributions = 'ConcessionalSuperContributions',
  SalarySacrifice = 'SalarySacrifice',
  InvestmentProperty = 'InvestmentProperty',
  OwnBusiness = 'OwnBusiness',
  SharesReitsAndOther = 'SharesReitsAndOther',
  InterestIncome = 'InterestIncome',
  SupplementaryRentingOutPartOfHouse = 'SupplementaryRentingOutPartOfHouse',
  MiscellaneousIncome = 'MiscellaneousIncome',
  EmploymentRelatedExpenses = 'EmploymentRelatedExpenses',
  HomeOfficeRelatedExpenses = 'HomeOfficeRelatedExpenses',
  InvestmentPropertyExpenses = 'InvestmentPropertyExpenses',
  InterestExpense = 'InterestExpense',
  OtherInvestmentPropertyExpenses = 'OtherInvestmentPropertyExpenses',
  SharesAndOtherExpenses = 'SharesAndOtherExpenses',
  CharitableDonations = 'CharitableDonations',
  MortgageRepayments = 'MortgageRepayments',
  RentExpense = 'RentExpense',
  Groceries = 'Groceries',
  InternetAndPhone = 'InternetAndPhone',
  Utilities = 'Utilities',
  Insurance = 'Insurance',
  ComputerAndElectronics = 'ComputerAndElectronics',
  HealthAndMedicine = 'HealthAndMedicine',
  InsuranceLifeHealth = 'InsuranceLifeHealth',
  RestauranceEntertainment = 'RestauranceEntertainment',
  MotorVehicle = 'MotorVehicle',
  Education = 'Education',
  Subscriptions = 'Subscriptions',
  BankAccountSavings = 'BankAccountSavings',
  EmergencyFund = 'EmergencyFund',
  OwnerOccupiedProperty = 'OwnerOccupiedProperty',
  PropertyValue = 'PropertyValue',
  InvestmentPortfolio = 'InvestmentPortfolio',
  SharesAndEtfs = 'SharesAndEtfs',
  Reits = 'Reits',
  Bonds = 'Bonds',
  AlternativeAssets = 'AlternativeAssets',
  HomeContentsMiscellaneous = 'HomeContentsMiscellaneous',
  EducationFund = 'EducationFund',
  FuneralFund = 'FuneralFund',
  Superannuation = 'Superannuation',
  OwnerOccupiedPropertyLoan = 'OwnerOccupiedPropertyLoan',
  PrincipleDebtOutstanding = 'PrincipleDebtOutstanding',
  InterestRate = 'InterestRate',
  RemainingTermYears = 'RemainingTermYears',
  InterestType = 'InterestType',
  OwnershipBalance = 'OwnershipBalance',
  Respondent = 'Respondent',
  StudentLoans = 'StudentLoans',
  CreditCards = 'CreditCards',
  OtherLoans = 'OtherLoans',
  Liabilities = 'Liabilities',
  RepaymentStructure = 'RepaymentStructure',
}

export enum KOQuestionType {
  Equal = 'Equal',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  OneOf = 'OneOf',
  OutRange = 'OutRange',
}

export enum FinancialSuperannuation {
  Included = 'Included',
  Excluded = 'Excluded',
}

export enum FinancialTaxStatus {
  PreTax = 'PreTax',
  PostTax = 'PostTax',
}

export enum FinancialFrequency {
  Annually = 'Annually',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export enum DisplayMode {
  All = 'All',
  Single = 'Single',
  Merged = 'Merged',
  Dual = 'Dual',
}

export enum RepaymentStructure {
  ['P&I'] = 'P&I',
  InterestOnly = 'InterestOnly',
}

import {
  AnswerSelection,
  AnswerType,
  CategoryResponse,
  FinancialFrequency,
  FinancialSuperannuation,
  FinancialTaxStatus,
  KOQuestionType,
  DisplayMode,
  QuestionType,
} from '@acorn/util';

export interface FinancialQuestion {
  questionId: string;
  question: Question;
  isRequired: boolean;
  isRequiredToInterestRate: boolean;
  isRequiredToRepaymentStructure: boolean;
  order: number;
  category: CategoryResponse;
  isSuperannuationIncluded: boolean;
  isTaxStatusIncluded: boolean;
  isFrequencyIncluded: boolean;
  isMultiAnswer: boolean;
  id: string;
  readonly: boolean;
  displaymode: DisplayMode;
  childrenQuestions?: FinancialQuestion[];
  parent?: FinancialQuestion;
  populateValueFrom: string;
  readonlyWhen: string;
  label?: string;
  listenFieldChanged: string;
  conditionPopulated: string;
  regressionWarnings: RegressionWarning[];
  syncNumberOfItemFrom: string;
}

export interface FinancialUserAnswer {
  applicationUserId: string;
  answers: FinancialAnswer[];
}

export interface FinancialAnswer {
  answerContent: string;
  financialQuestionId: string;
  order: number;
  superannuation?: FinancialSuperannuation;
  taxStatus?: FinancialTaxStatus;
  frequency?: FinancialFrequency;
  questionType?: QuestionType;
  isMultiAnswerQuestion?: boolean;
  multiAnswerItems?: {
    order: number;
    content: string;
  }[];
}

export interface FinancialSnapshot {
  applicationUserId: string;
  firstName: string;
  financialValue: {
    employment: number;
    rentalIncome: number;
    otherIncome: number;
    totalIncome: number;
    nonTaxExpenses: number;
    taxExpenses: number;
    insurancePremiums: number;
    totalExpenses: number;
    mortgage: number;
    studentLoans: number;
    creditCardAndOtherLoans: number;
    motorVehicleLeases: number;
    totalLiabilities: number;
    investmentPropertyLoans: number;
    emergencyFund: number;
    savings: number;
    ownerOccupiedHome: number;
    homeContents: number;
    superannuation: number;
    investmentProperty: number;
    investmentNonProperty: number;
    motorVehicles: number;
    funeralFund: number;
    totalAssets: number;
    netAssetsToday: number;
    educationFund: number;
  };
}
export interface Question {
  content: string;
  description: string;
  questionType: QuestionType;
  answerType: AnswerType;
  isNumberOfItemQuestion: boolean;
  isKOQuestion: boolean;
  koQuestionType: KOQuestionType;
  koQuestionValue: unknown;
  answerSelections: AnswerSelection[];
  childQuestions: null;
  id: string;
  warningDescription?: string;
  maxChildNumber?: number;
}

export interface RegressionWarning {
  createdBy: string;
  createdDate: string;
  financialQuestionId: string;
  formula: string;
  id: string;
  isDeleted: boolean;
  modifiedBy: string;
  modifiedDate: string;
  warningMessage: string;
  isErrorMessage: boolean;
  isValidationMessage: boolean;
}

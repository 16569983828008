import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart } from 'chart.js';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acorn-chart-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule
  ],
  templateUrl: './chart-layout.component.html',
  styleUrls: ['./chart-layout.component.scss'],
})
export class ChartLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('barCanvas') private barCanvas!: ElementRef;
  @ViewChild('player') videoElement!: ElementRef;

  #dialogRef = inject(MatDialogRef<ChartLayoutComponent>);
  dialogData = inject(MAT_DIALOG_DATA);

  barChart: any;
  isMobile: boolean = false;
  isLandscape: boolean = false;
  expanded: boolean = false;

  constructor() { }

  ngAfterViewInit(): void {
    if(this.dialogData){
      this.generateBarChart();
    }
  }

  ngOnInit(): void {
  }

  onClosePopup() {
    this.#dialogRef.close();
  }

  generateBarChart(): void {
    if (this.barCanvas) {
      this.barChart = new Chart(this.barCanvas.nativeElement, {
        data: {
          labels: this.dialogData.chartLabels,
          datasets:this.dialogData.datasets
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              maxWidth: 250,
              labels: {
                padding: 10,
                boxWidth: 10,
                usePointStyle: true,
                font: {
                  size: 3,
                  weight: 'bold',
                },
              },
            },
          },
          
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: 'Age of youngest respondent',
                font: {
                  size: 15,
                  weight: 'bold',
                },
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return Number(value) / 1000000 + 'M';
                }
              },
              title: {
                display: true,
                text: '$AUD',
                font: {
                  size: 15,
                  weight: 'bold',
                },
              },
            },
          },
        },
      });
    }
    this.updateLegendPosition();
  }

  private updateLegendPosition(): void {
    const fontSize = window.innerWidth <= 768 ? 11 : 13;
    if (this.barChart.options.scales && this.barChart?.options.scales.x && this.barChart.options.scales.x.title) {
      this.barChart.options.scales.x.title.font = {
        size: fontSize,
        weight: 'bold'
      };
    }

    if (this.barChart.options.scales && this.barChart?.options.scales.y && this.barChart.options.scales.y.title) {
      this.barChart.options.scales.y.title.font = {
        size: fontSize,
        weight: 'bold'
      };
    }

    if (this.barChart?.options.plugins.legend.labels) {
      this.barChart.options.plugins.legend.labels.font = {
        size: fontSize,
        weight: 'bold'
      };
    }

    this.barChart.update();
  }

}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { noop } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'acorn-unauthorized-page',
  templateUrl: 'unauthorized-page.component.html',
  styleUrls: ['unauthorized-page.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule],
})
export class UnauthorizedPageComponent {
  #router = inject(Router);

  onBackToSignIn(): void {
    this.#router.navigate(['/auth/sign-in']).then(noop);
  }
}

import { GoalSettingPartsStep, GoalSettingStep } from '@acorn/util';
import {
  GoalPart,
  GoalStepStructure,
} from '../../../util';
import { STEP_STRUCTURE } from './step-structure.const';

export const bindingStepStructureStatus = (
  stepStructure: GoalStepStructure,
  totalSelectedIds: number,
  partStep: GoalSettingPartsStep | null,
  currentStepStructure: GoalStepStructure | null
): void => {
  if (!stepStructure.parts) {
    return;
  }

  stepStructure.state =
    totalSelectedIds >= stepStructure.minimumRequiredGoals &&
    totalSelectedIds <= stepStructure.maximumRequiredGoals
      ? 'done'
      : 'number';

  const currentPartStructure = stepStructure.parts.find(
    (part) => part.id === partStep
  );

  if (
    partStep === 'Part4' &&
    stepStructure.id === GoalSettingStep.GoalSetting
  ) {
    stepStructure.state = 'done';

    stepStructure.parts.forEach((part) => {
      if (partStep === 'Part4') {
        part.state = 'done';
      }
    });
  }

  stepStructure.parts.forEach((part) => {
    if (
      currentStepStructure?.id === stepStructure.id &&
      currentPartStructure &&
      part.order <= currentPartStructure.order
    ) {
      // part.state = 'done';
    } else if (
      currentStepStructure &&
      currentStepStructure.order > stepStructure.order
    ) {
      part.state = 'done';
    } else if (
      currentStepStructure &&
      currentStepStructure.order < stepStructure.order
    ) {
      part.state = 'number';
    }
  });
};

export const isNeedToUpdateCurrentProcess = (
  currentProcess: {
    roundStep: GoalSettingStep;
    partStep: GoalSettingPartsStep;
  },
  currentStepStructure: GoalStepStructure,
  currentPartStructure: GoalPart,
  isWelcomeBackEdit: boolean
): boolean => {
  if (isWelcomeBackEdit) {
    return true;
  }

  if (currentProcess) {
    const applicationRoundStepStructure = STEP_STRUCTURE.find(
      (it) => it.id === currentProcess!.roundStep
    );
    if (currentStepStructure.order > applicationRoundStepStructure!.order) {
      return true;
    } else if (
      currentStepStructure.order === applicationRoundStepStructure!.order
    ) {
      const applicationPartStepStructure =
        applicationRoundStepStructure!.parts.find(
          (it) => it.id === currentProcess!.partStep
        );
      return currentPartStructure!.order > applicationPartStepStructure!.order;
    } else {
      return true;
    }
  }

  return true;
};

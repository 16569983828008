<span
  *ngIf="isSuccessType"
  class="mdi mdi-check-circle-outline toast-icon"
></span>

<span
  *ngIf="isErrorType"
  class="mdi mdi-alert-circle-outline toast-icon"
></span>

<div class="toast-title">{{ title }}</div>

<div class="toast-message">
  {{ message }}
</div>

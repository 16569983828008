export const ACORN_TOOLTIP = {
  GETTING_STARTED: {
    SOMEONE_ELSE:
      'You may prefer to involve someone else, such as your life partner or cohabiting partner in collaboratively developing this wealth plan, particularly when your finances and goals are shared.',
  },
  WEALTH_HEALTH: {
    GOAL: {
      TITLE: 'Goals',
      CONTENT: 'Achieve your most important financial goals',
    },
    TAX: {
      TITLE: 'Tax & Structuring',
      CONTENT:
        'Optimising financial structures and tax planning can significantly impact wealth accumulation. Proper structuring of assets and investments, along with strategic tax planning, provides a foundation that can minimise tax liabilities and maximise tax benefits, leading to enhanced wealth preservation and growth.',
    },
    ESTATE: {
      TITLE: 'Estate Planning',
      CONTENT:
        'Estate planning allows individuals to have control over the distribution of their assets and minimise taxation or disputes. Creating wills, establishing trusts, and considering future wealth transfer are vital for preserving wealth and ensuring a smooth transition for future generations.',
    },
    RETIREMENT: {
      TITLE: 'Retirement Planning',
      CONTENT:
        'Planning for retirement helps ensure financial independence and a comfortable lifestyle in later years. Setting retirement goals, estimating expenses, and implementing a suitable retirement savings strategy are essential for achieving a secure and worry-free retirement.',
    },
    STOCKS: {
      TITLE: 'Shares & Other Investments',
      CONTENT:
        'Investing in shares, ETFs, bonds, and other financial instruments provides opportunities for capital growth and income generation, especially in the long term. Diversifying investments across various asset classes helps mitigate risk and maximise returns over the medium to long term.',
    },
    SUPER: {
      TITLE: 'Superannuation',
      CONTENT:
        'Superannuation, the Australian retirement savings system, is crucial for long-term financial security. Contributing regularly to a superannuation account and maximizing employer contributions can help individuals build a substantial retirement nest egg and enjoy a comfortable retirement lifestyle.  It is a tax effective structure to accumulate wealth.',
    },
    PROPERTY: {
      TITLE: 'Property',
      CONTENT:
        'Real estate investments offer potential income, capital appreciation, and diversification. Investing in properties allows individuals to generate rental income, benefit from long-term property value appreciation, and build a tangible asset portfolio.  The benefits of negative gearing may also be realised.',
    },
    INCOME: {
      TITLE: 'Income',
      CONTENT:
        'Income is the foundation of wealth creation. It provides the means to save, invest, and build wealth over time. Maximising and protecting income sources increases financial stability and the ability to pursue wealth-building opportunities.',
    },
    SAVINGS: {
      TITLE: 'Savings, Budgeting & Debt',
      CONTENT:
        'This building block focus on managing personal finances effectively. Saving a portion of income, creating budgets, and managing debt are vital for long-term financial security. They enable individuals to live within their means, avoid excessive debt, and build a foundation of savings.',
    },
    WEALTH_PROTECTION: {
      TITLE: 'Wealth Protection (including insurance)',
      CONTENT:
        'Insurance coverage is essential for protecting accumulated wealth and mitigating financial risks. Adequate life insurance, health insurance, and income protection insurance safeguard against unforeseen events that could otherwise deplete wealth and disrupt financial stability.',
    },
    WEALTH_BUILDING_BLOCKS: {
      TITLE: 'Building Blocks',
      CONTENT:
        'The Wealth Maximiser Building Blocks refer to the fundamental components that contribute to maximising wealth and achieving your key goals.',
    },
    INDEPENDENCE_AND_LEGACY: {
      CONTENT: 'Attaining financial freedom and creating a lasting impact',
    },
    WEALTH_ACCUMULATION: {
      CONTENT:
        'The ongoing process of increasing financial assets and resources',
    },
    WEALTH_FOUNDATION: {
      CONTENT: 'The building blocks and essentials for financial well-being',
    },
  },
};

<div class="assumption-content">
    <div class="assumption-container d-flex" style="justify-content: space-between;">
        <div class="d-flex" style="flex-direction: column;">
            <h3 class="assumption-header">Key Assumptions <span *ngIf="!this.isMobile">- Projected Financial Position</span></h3>
            <p class="mt-1 assumptions-notes" *ngIf="!this.isMobile">We have used the following assumptions to project
                your future financial
                position</p>
        </div>
        <button class="icon-button" (click)="onClose()">
            X
        </button>
    </div>
    <mat-divider *ngIf="this.assumptions"></mat-divider>

    <div class="scroll">
        <div class="content" [ngClass]="{'content-padding': assumptions?.length > 0}">
            <div class="table table1">
                <div class="row minH-60" *ngFor="let assumption of this.assumptionsPart1">
                  <div class="cell h">{{assumption.name}}</div>
                  <div class="cell">{{formatValue(assumption.value, assumption.measure)}}</div>
                </div>
              </div>
              <div class="table table2">
                <div class="row minH-60" *ngFor="let assumption of this.assumptionsPart2">
                  <div class="cell h">{{assumption.name}}</div>
                  <div class="cell">{{formatValue(assumption.value, assumption.measure)}}</div>
                </div>
              </div>
              <div class="table table3">
                <div class="row minH-60" *ngFor="let assumption of this.assumptionsPart3">
                  <div class="cell h">{{assumption.name}}</div>
                  <div class="cell">{{formatValue(assumption.value, assumption.measure)}}</div>
                </div>
              </div>
        </div>
        <mat-divider *ngIf="this.assumptions?.length !== 0"></mat-divider>
        <div class="assumption-body pa-15">
          <div class="d-flex assumption-para">
            <div class="assumption-n" style="flex: 1; padding-right: 20px;">
                <p>1. The projections calculate indicative, estimated personal income taxes for individuals who are
                    Australian Residents for tax purposes (applicable for FY2024-25).</p>
                <p>2. Medicare Levy Rates are assumed to operate at 2% irrespective of salary in the projection
                    model.
                    Note that this is not the case for low income earners.</p>
                <p>3. Tax Thresholds change from time to time. The model assumes tax thresholds to remain consistent
                    with current thresholds (FY2024-25), adjusted for inflation from FY2026.</p>
                <p>4. Superannuation modelling is indicative and does not include certain rules such as the ability
                    to
                    bring-forward concessional and non-concessional contributions (subject to super balance caps).
                    For
                    simplicity, it has been assumed super contributions are made annually (at end of year).</p>
                <p>5. Superannuation thresholds/caps have been adjusted for inflation from FY2026 (inclusive).</p>
                <p>6. It is assumed investment property loans remain on foot throughout projected period, remain
                    interest-only and are not paid down at any point during the projected period.</p>
                <p>7. The potential impact of Capital Gains Tax (CGT) is not factored into the projections.
                    Therefore,
                    where asset sales have occurred, CGT liability has not been deducted from the cashflows.</p>
            </div>
            <div class="assumption-n" style="flex: 1; padding-left: 20px;">
                <p>8. The Respondent's expense base from today is used as the basis to project go-forward expenses
                    (rising with inflation). Major uplifts in expenses or changes in lifestyle are not explicitly
                    assumed in these projections, with the exception of the rules set out above and outlined in
                    these
                    notes.</p>
                <p>9. Where the Respondent's initial expenses (excluding rent) are lower than estimated Australian
                    national averages in aggregate, the expense base at the start of the projection period has been
                    revised to meet the estimated national average.</p>
                <p>10. As outlined above, an assumption pertaining to additional expenses pertaining to children (or
                    financial dependants) has been applied until the age of the youngest child reaches 18. This
                    assumption only applies where the Respondent(s) already have children at the time of the
                    questionnaire.</p>
                <p>11. No incremental purchase of home contents and other miscellaneous items are factored into the
                    projections.</p>
                <p>12. The projections do not include any potential pay-out from insurance policies.</p>
                <p>13. In the event that a life trigger event occurs, it is important the Respondent reviews their
                    strategies and projections with a Wealth Coach as this document may no longer be relevant or
                    appropriate.</p>
                <p>14. The projection is mapped against the age of the youngest respondent.</p>
            </div>
        </div>
        </div>
    </div>
</div>

import { CategoryCode } from '@acorn/util';

const currentYear = new Date().getFullYear();

export const YEAR_OPTIONS = [...new Array(101)].map((value, index) => ({
  value: index.toString(),
  viewValue: index.toString(),
}));

export const PERCENT_OPTIONS = [...new Array(21)].map((value, index) => ({
  value: (index * 5).toString(),
  viewValue: `${index * 5}%`,
}));

export const MONTH_OPTIONS = [...new Array(12)].map((value, index) => ({
  value: (index + 1).toString(),
  viewValue: (index + 1).toString(),
}));

export const FUTURE_YEAR_OPTIONS = [...new Array(51)].map((value, index) => ({
  value: (currentYear + index).toString(),
  viewValue: (currentYear + index).toString(),
}));

export const FIXED_RATE_TERM_OPTIONS = [...new Array(120)].map(
  (value, index) => ({
    value: (index + 1).toString(),
    viewValue: (index + 1).toString(),
  })
);

export const NO_DIVIDER_CATEGORIES = [CategoryCode.RealEstate];

export const LISTEN_FIELD_CHANGED_SPLIT_SEPARATOR = ',';

<img [class]="imgClass" [src]="data.image" alt="" [height]="imgHeight" [width]="imgWidth" />
<div class="card-content">
  <h3>
    {{ data.title }}
  </h3>
  <acorn-category-box [category]="data.tags"></acorn-category-box>
  <p class="des">
    {{ data.subTitle }}
  </p>
  <p class="time" *ngIf="isShowTime">{{ data.createdDate | date : 'dd MMM yyyy' }}</p>
</div>

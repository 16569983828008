import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acorn-calendarpopup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './calendarpopup.component.html',
  styleUrls: ['./calendarpopup.component.scss'],
})
export class CalendarpopupComponent {
  #dialogRef = inject(MatDialogRef<CalendarpopupComponent>);

  onClose(){
    this.#dialogRef.close();
  }
}

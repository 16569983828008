<ul *ngIf="!hasSignedIn" class="quick_links">
  <li><a href="https://wealthmaximiser.com.au/">Home</a></li>
  <li><a href="https://wealthmaximiser.com.au/about-us">About Us</a></li>
  <li><a href="https://wealthmaximiser.com.au/pricing">Pricing</a></li>
  <li><a href="https://wealthmaximiser.com.au/media">Media</a></li>
</ul>

<img src="assets/images/logo.svg" alt="" (click)="backToHome()" />

<div *ngIf="!hasSignedIn" class="actions">
  <button acorn-button routerLink="/auth/sign-in">Login</button>

  <button acorn-button color="primary" (click)="getStarted()">
    Get Started
  </button>
</div>

<div
  class="menu-button"
  [style.display]="hasSignedIn ? 'flex' : ''"
  [matMenuTriggerFor]="menu"
>
  <span class="mdi mdi-menu text-base"></span>
</div>

<mat-menu #menu="matMenu" class="menu-panel" [hasBackdrop]="!isMobile">
  <div class="menu-panel-content-wrapper quick_links">
    <button mat-menu-item><a href="https://wealthmaximiser.com.au/">Home</a></button>

    <button mat-menu-item><a href="https://wealthmaximiser.com.au/about-us">About Us</a></button>

    <button mat-menu-item routerLink="/pricing">Pricing</button>

    <button mat-menu-item><a href="https://wealthmaximiser.com.au/media">Media</a></button>

    <ng-container *ngIf="hasSignedIn; else notSignedInTemplate">
      <button mat-menu-item routerLink="/user-profile">
        <div class="button-content-wrapper">
          <img src="assets/images/user-profile.svg" alt="" />

          <span>Your Profile</span>
        </div>
      </button>

      <button acorn-button (click)="onHandleSignOut()">Logout</button>
    </ng-container>

    <ng-template #notSignedInTemplate>
      <button acorn-button routerLink="/auth/sign-in">Login</button>

      <button acorn-button color="primary" (click)="getStarted()">
        Get Started
      </button>
    </ng-template>
  </div>
</mat-menu>

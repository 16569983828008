<button cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen">
  <span>Need help?</span>

  <svg-icon-sprite
    [src]="'assets/sprites/sprite.svg#question-line'"
  ></svg-icon-sprite>
</button>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="transparent-desktop-backdrop"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="flexibleOrigin()"
  [cdkConnectedOverlayOffsetY]="overlayOffsetY()"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayLockPosition]="true"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="isOpen = false"
>
  <div class="help-bubble-panel">
    <div class="information">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#question-sign'"
        [width]="'28px'"
      ></svg-icon-sprite>

      <h1>Need help?</h1>

      <p>
        If you have a question or need assistance with Wealth Maximiser, book a session with one of our Support Team.
      </p>

      <p>Please note, doing so will conclude your current session.</p>
    </div>

    <div class="actions">
      <button acorn-button color="primary" routerLink="/contact-wealth-coach">
        Book Support Team
      </button>

      <button acorn-button (click)="isOpen = false">Cancel</button>
    </div>
  </div>
</ng-template>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import {
  CategoryResponse,
  CommonResponse,
} from '@acorn/util';
import { catchError, map, of } from 'rxjs';

@Injectable()
export class FinancialProjectionService {
  #httpClient = inject(HttpClient);

  getFinancialProjection(applicationId: any) {
    return this.#httpClient.get<CommonResponse<CategoryResponse[]>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/chart-data`
    );
  }

  getApplications(applicationId: string) {
    return this.#httpClient.get<CommonResponse<boolean>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/assumptions`
    ).pipe(
      map((response: any) => {
        if (response?.isSuccess) {
          return response.data;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return of([]);
      })
    );
  }

  getApplicationsCalculations(id: string) {
    return this.#httpClient.get(`${environment.apiUrls.calculation}calculations/calculate?applicationId=${id}`);
  }

  reCalculate(applicationId: string, id: string) {
    return this.#httpClient.get(`${environment.apiUrls.calculation}calculations/calculate/status?applicationId=${applicationId}&id=${id}`);
  }
}
import { ChangeDetectorRef, Component, Inject, inject, OnInit, signal } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FooterComponent } from '../common/ui';
import { FinancialProjectionComponent } from './ui/financial-projection/financial-projection.component';
import { ActionsEmptyComponent } from './ui/actions-empty/actions-empty.component';
import { AboutYouComponent } from './ui/about-you/about-you.component';
import { InformationComponent } from './ui/information/information.component';
import { ApplicationGoalSettings, ApplicationStatus, ApplicationStep, ContentTypeIds, CustomerProfile, CustomerUser, QuoteContent } from '@acorn/util';
import { ApplicationGoalService, ApplicationService, BaseContentfulService, UserService } from '@acorn/data-access';
import { catchError, combineLatest, map, Observable, of } from 'rxjs';
import { FeatureApplicationComponent } from '@acorn/feature-application';
import { ActivatedRoute, Router } from '@angular/router';
import { WealthStrategyOptionPopupComponent } from 'libs/feature-application/src/lib/feature-wealth-strategy-options/ui/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { StrategyActionPlanService } from './data-access/strategy-action-plan.service';
import { FinancialProjectionService } from 'libs/feature-application/src/lib/feature-financial-projection/data-access-financial-projection';
import { LearningComponent } from './ui/learning-hub/learning-hub.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'acorn-dashboard',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        FooterComponent,
        FinancialProjectionComponent,
        ActionsEmptyComponent,
        AboutYouComponent,
        InformationComponent,
        FeatureApplicationComponent,
        LearningComponent,
    ],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    public selectedTabIndex = 0;
    public showUserInfo = false;
    public previousTabIndex = 0;

    #userService = inject(UserService);
    #contentFullService = inject(BaseContentfulService);
    #dialog = inject(MatDialog);
    #financialProjectionService = inject(FinancialProjectionService);
    #applicationGoalSvc = inject(ApplicationGoalService);
    #toastrService = inject(ToastrService);

    applicationStep = signal<ApplicationStep | null>(null);
    latestApplicationStep = signal<ApplicationStep | null>(null);
    #viewport = inject(ViewportScroller);
    applicationGoal = signal<ApplicationGoalSettings | null>(null);
    currentUser = signal<CustomerUser | null>(null);
    currentProfile = signal<CustomerProfile | null>(null);
    #strategyActionPlanSvc = inject(StrategyActionPlanService);
    cd = inject(ChangeDetectorRef);
    showLearningHub = false;
    isSecondYear = false;
    #applicationSvc = inject(ApplicationService);
    tabs = [
        {
            label: 'Your Dashboard',
            icon: '/assets/images/d-dashboard.svg',
        },
        {
            label: 'Your Strategy Options',
            icon: '/assets/images/d-strategy.svg',
        },
        {
            label: 'Wealth Health',
            icon: '/assets/images/d-wealth-health.svg',
        },
        {
            label: 'About You',
            icon: '/assets/images/d-about-you.svg',
        },
        {
            label: 'Financial Projection',
            icon: '/assets/images/d-financial-projection.svg',
        },
    ];

    wealthStrategyOptions = false;
    quoteContent = signal<QuoteContent | null>(null);
    isShownQuoteContent = signal<boolean>(false);
    strategy: any;
    applicationId!: any;
    selectedStrategy: any;
    chartData: any;
    respondentInfo !: Array<any>;

    constructor(private route: ActivatedRoute, private router: Router) {}

    selectTab(index: number) {
        this.previousTabIndex = this.selectedTabIndex;
        this.selectedTabIndex = index;

        this.showLearningHub = false;
        this.showUserInfo = false;

        // window.location.hash = this.selectedTabIndex.toString()

        if (this.selectedTabIndex === 1) {
            if (this.tabs[1].label === 'Download Strategy') {
                this.selectedTabIndex = this.previousTabIndex;
                this.openStrategyPopup();
            } else {
                this.router.navigateByUrl('/?isdashBoard=true');
                // this.applicationStep.set(ApplicationStep.WealthStrategyOptions);
                // this.latestApplicationStep.set(
                //     ApplicationStep.WealthStrategyOptions
                // );
                // this.wealthStrategyOptions = true;
            }
        }
    }
    public get getRespondentInfo(){
        return  this.respondentInfo.map(item => item.name).join(" & ");
    }

    public userInfo() {
        this.#viewport.scrollToPosition([0, 0]);
        this.showUserInfo = true;
        this.showLearningHub = false;
        this.selectedTabIndex = 5;
        window.location.hash = '5'
    }

    public setLearningHub() {
        window.location.hash = '2'
        this.#viewport.scrollToPosition([0, 0]);
        this.showLearningHub = true;
        this.showUserInfo = false;
        this.selectedTabIndex = 2;
    }

    public showAboutYou() {
        window.location.hash = "3"
        this.showLearningHub = false;
        this.showUserInfo = false;
        this.selectedTabIndex = 3;
        this.#viewport.scrollToPosition([0, 0]);
    }

    public getApplication() {
        this.#applicationSvc.getApplicationRespondentInfo(this.applicationId).subscribe((res:any)=>{
           this.respondentInfo = res.data;
        })
    }
    isExpanded = true;

    toggleText() {
      this.isExpanded = !this.isExpanded;
    }

    public getCurrentUser() {
        combineLatest([
            this.#userService.getCurrentUser(),
            this.#contentFullService.getContentful(ContentTypeIds.InspirationQuotePage),
            this.#userService.getCurrentProfile(),
        ]).subscribe(([{ data }, [quoteContent], profile]) => {
            this.currentUser.set(data);
            this.currentProfile.set(profile.data);

            if(!data.currentApplication?.goalApprovedDate || !data.currentApplication.isAllStrategiesApproved){
                this.router.navigateByUrl('/');
                return
            }

            this.applicationId = data.currentApplication?.id;

            this.getStrategyActionPlan();
            this.getApplication();
            this.quoteContent.set(quoteContent.fields as any);
            if (!this.currentUser()?.currentApplication) {
                this.isShownQuoteContent.set(true);
            }
        });
    }

    public showTheQuoteContent(): void {
        this.isShownQuoteContent.set(true);
        this.#viewport.scrollToPosition([0, 0]);
    }

    ngOnInit(): void {
        this.getCurrentUser();
        window.addEventListener('hashchange', (e:any)=>{
         if(window.location.hash === "" || window.location.hash === "#0"){
            this.selectTab(0);
         }
        });
    }

    clearQueryParams(): void {
        this.router.navigate([], { queryParams: {}, relativeTo: this.route });
    }

    hasStrategy(hasStrategyNull: boolean, strategy: any) {
        this.strategy = strategy;
        if (hasStrategyNull) {
            this.tabs[1].label = 'Your Strategy Options';
        } else {
            this.tabs[1].label = 'Download Strategy';
        }
    }

    openStrategyPopup() {
        const modifiedStrategy = {
            applicationId: this.strategy.applicationId,
            strategyId: this.strategy.strategyId,
            strategyOption: this.strategy.strategyOption,
            name: this.strategy.strategy.name,
            description: this.strategy.strategy.description,
            longDescription: this.strategy.strategy.longDescription,
            riskVsReturnProfile: this.strategy.strategy.riskVsReturnProfile,
            riskDescription: this.strategy.strategy.riskDescription,
            selectedByCustomerDate: this.strategy.selectedByCustomerDate,
            benefits: [
                this.strategy.strategy.benefits1,
                this.strategy.strategy.benefits2,
                this.strategy.strategy.benefits3,
                this.strategy.strategy.benefits4,
                this.strategy.strategy.benefits5,
            ],
            exampleActions: [
                this.strategy.strategy.exampleAction1,
                this.strategy.strategy.exampleAction2,
                this.strategy.strategy.exampleAction3,
                this.strategy.strategy.exampleAction4,
                this.strategy.strategy.exampleAction5,
            ].filter(Boolean),
            primaryBBs: [
                this.strategy.strategy.primaryBB1,
                this.strategy.strategy.primaryBB2,
                this.strategy.strategy.primaryBB3,
                this.strategy.strategy.primaryBB4,
                this.strategy.strategy.primaryBB5,
            ].filter(Boolean),
            risks: [
                this.strategy.strategy.risk1,
                this.strategy.strategy.risk2,
                this.strategy.strategy.risk3,
                this.strategy.strategy.risk4,
                this.strategy.strategy.risk5,
            ].filter(Boolean),
            portfolioType: this.strategy.strategy.portfolioType,
            risk: this.strategy.strategy.risk,
            goals: this.selectedStrategy.goals,
        };

        this.#dialog.open(WealthStrategyOptionPopupComponent, {
            width: '880px',
            height: '80vh',
            disableClose: true,
            hasBackdrop: true,
            data: {
                strategy: modifiedStrategy,
                isDashboard: true,
            },
        });
    }

    public getStrategyActionPlan() {
        this.#strategyActionPlanSvc.GetStrategyActionPlan(this.applicationId).subscribe({
            next: (res: any) => {
                const strategy = res.data;
                this.selectedStrategy = {
                    strategyId: strategy?.strategyId || null,
                    name: strategy?.strategy?.name || null,
                    selectedByAdminDate: strategy?.selectedByAdminDate || null,
                    goals: [],
                };

                if(strategy?.selectedByAdminDate !== null) {
                  this.isSecondYear = true;
                }


                // moved to top as per the client requirment refer  devops -> 7682
                this.getChartData();

                if (strategy?.strategyId && strategy?.strategyId !== '00000000-0000-0000-0000-000000000000') {
                    this.getGoals(strategy.strategyId).subscribe((goals: any) => {
                        this.selectedStrategy.goals = goals;
                        this.cd.markForCheck();
                    });
                }

                this.cd.markForCheck();
            },
            error: (error: any) => {
                this.#toastrService.error(
                    error.error.Message,
                    'Error'
                );
            },
        });
    }

    public goToWealthHealth() {
        this.selectedTabIndex = 0;
        setTimeout(() => {
            const wealthMain = document.getElementById('wealth-main');
            if (wealthMain) {
                wealthMain.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 0);
    }

    public getChartData() {
        this.#financialProjectionService.getFinancialProjection(this.applicationId).subscribe((res: any) => {
            this.chartData = res.data;
            this.cd.markForCheck();
        },
        (err:any)=>{
            this.#toastrService.error(
                err.error.Message,
                'Error'
            );
        });
    }

    public getGoals(strategyId: string): Observable<any> {
        return this.#applicationGoalSvc.getApplicationGoal(this.applicationId, strategyId).pipe(
            map((res: any) => res.data),
            catchError((err: any) => {
                this.#toastrService.error(
                    err.error.Message,
                    'Error'
                );
                return of([]);
            })
        );
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acornConvertZeroToDash',
  standalone: true
})
export class ConvertZeroToDashPipe implements PipeTransform {

  transform(value: string | null): string | number | null {
    return value === '$0' ? '$--' : value;
  }

}

<div class="card">
    <div class="header">
        <h4>{{ parentCategory?.name }}</h4>
        <h1>{{ currentCategory?.name }}</h1>
        <p
            acornTooltip
            [tooltipMessage]="tooltip"
            [innerHTML]="currentCategory?.description || secondaryQuestion[0]?.question?.content"
        ></p>
    </div>

    <div class="card-content">
        <mat-accordion>
            <mat-expansion-panel [expanded]="true" [disabled]="layoutService.screenSize() !== ScreenSize.Mobile">
                <ng-container *ngIf="primaryQuestion">
                    <ng-container [ngSwitch]="primaryQuestion.question.answerType">
                        <ng-container *ngSwitchCase="AnswerType.Radio">
                            <mat-radio-group
                                [disabled]="isFileNotes || isAppComplete"
                                class="radio-group"
                                color="primary"
                                [formControl]="formRecord.controls[this.applicationUserId ]"
                                (change)="onRadioChanged($event, this.applicationUserId )"
                                [ngClass]="{ 'disabled-select': isFileNotes || isAppComplete }"
                            >
                                <mat-radio-button
                                    class="radio-button"
                                    *ngFor="let selection of selections()"
                                    [value]="selection.id"
                                    [disabled]="isFileNotes || isAppComplete"
                                >
                                    {{ selection.content }}
                                </mat-radio-button>
                            </mat-radio-group>
                            <p *ngIf="isSubmitted() && formRecord.controls[this.applicationUserId ].invalid" class="error-message">
                                {{ ERROR_MESSAGE.MAKE_A_SELECTION }}
                            </p>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- Secondary question (if applicable) -->
                <ng-container *ngIf="formRecord.controls[this.applicationUserId  + '-secondary']">
                    <p acornTooltip [tooltipMessage]="tooltip" [innerHTML]="secondaryQuestion[1]?.question?.content" class="sec-question"></p>
                    <ng-container [ngSwitch]="secondaryQuestion[1].question.answerType">
                        <ng-container *ngSwitchCase="AnswerType.Radio">
                            <mat-radio-group
                                [disabled]="isFileNotes || isAppComplete"
                                class="radio-group"
                                color="primary"
                                [formControl]="formRecord.controls[this.applicationUserId  + '-secondary']"
                                [ngClass]="{ 'disabled-select': isFileNotes || isAppComplete }"
                            >
                                <mat-radio-button
                                    class="radio-button"
                                    *ngFor="let selection of secSelections()"
                                    [value]="selection.id"
                                    [disabled]="isFileNotes || isAppComplete"
                                >
                                    {{ selection.content }}
                                </mat-radio-button>
                            </mat-radio-group>

                            <p *ngIf="isSubmitted() && formRecord.controls[this.applicationUserId  + '-secondary'].invalid" class="error-message">
                                {{ ERROR_MESSAGE.MAKE_A_SELECTION }}
                            </p>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <acorn-application-navigation [isLoading]="isLoading()" (back)="back.emit()" (next)="onSubmit()"></acorn-application-navigation>
</div>

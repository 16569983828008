import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

import {
  CdkStepper,
  CdkStepperModule,
  STEP_STATE,
  StepState,
} from '@angular/cdk/stepper';

import { NumberInput } from '@angular/cdk/coercion';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-nested-vertical-stepper',
  templateUrl: 'nested-vertical-stepper.component.html',
  styleUrls: ['nested-vertical-stepper.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    [{ provide: CdkStepper, useExisting: NestedVerticalStepperComponent }],
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    MatButtonModule,
    NgTemplateOutlet,
    IconSpriteModule,
    CdkStepperModule,
    MatIconModule,
  ],
})
export class NestedVerticalStepperComponent extends CdkStepper {
  @Input() parentId = '';
  @Input() activeParentId = '';
  @Input() disabled = false;

  @Input()
  override set selectedIndex(index: NumberInput) {
    if (this.parentId !== this.activeParentId) {
      return;
    }

    super.selectedIndex = index;
  }

  override get selectedIndex(): number {
    return super.selectedIndex;
  }

  protected readonly STEP_STATE = STEP_STATE;

  selectStepByIndex(index: number, state: StepState): void {
    if (this.disabled || state !== 'done') {
      return;
    }

    this.selectedIndex = index;
  }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ApplicationGoalSettings,
    ApplicationStep,
    Category,
    CategoryResponse,
    CustomerUser,
    FinancialCategoryType,
    GoalMetricsQuestion,
    riskCategoryType,
    wealthCategoryType,
} from '@acorn/util';
import { AboutYouService, ApplicationGoalService, FinancialSituationService, UserService } from '@acorn/data-access';
import { StepDetail } from '../util/summary-step-structure';
import { first, groupBy, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, noop } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'acorn-summary',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnInit {
    @Output() showApplication = new EventEmitter<{
        isShowApplication: boolean;
        step: ApplicationStep | FinancialCategoryType;
        subStep: any;
    }>();

    @Input() isFileNotes = false;
    @Input() isDashboardApp = false;
    @Input() customerUser!: CustomerUser;

    public visibleStates: boolean[] = [false, false, false, false, false];
    public readonly ApplicationStep = ApplicationStep;
    public readonly FinancialCategoryType = FinancialCategoryType;

    #financialSituationService = inject(FinancialSituationService);
    #aboutYouService = inject(AboutYouService);
    #cd = inject(ChangeDetectorRef);
    #userService = inject(UserService);
    #router = inject(Router);

    // about you
    aboutYouCategories = signal<Category[]>([]);

    // goals
    #applicationGoalService = inject(ApplicationGoalService);
    applicationGoal!: ApplicationGoalSettings;
    goalQuestionsData!: GoalMetricsQuestion[];

    // Financial situation
    financialSituation = signal<{
        categories: Category[];
        data: boolean;
    } | null>(null);

    // risk tolerance
    riskToleranceCategories = signal<Category[]>([]);

    // wealthScore
    wealthCategories = signal<Category[]>([]);

    public steps!: any[];
    public stepsSubject = new BehaviorSubject<any[]>(StepDetail);

    currentUser!: CustomerUser;
    isAllStepsCompleted = false;

    public userName = signal('');
    public text!: string;

    ngOnInit(): void {
        this.steps = StepDetail;
        this.refreshData();
    }

    public refreshData() {
        setTimeout(() => {
            if ((this.isFileNotes || this.isDashboardApp) && this.customerUser) {
                this.currentUser = this.customerUser;
                this.#getApplicationUserId();
            } else {
                this.#getCurrentUser();
            }
        }, 0);
    }

    start(step: ApplicationStep | FinancialCategoryType, subStep: any = '') {
        this.showApplication.emit({ isShowApplication: true, step, subStep });
    }

    toggleVisibility(index: number): void {
        this.visibleStates[index] = !this.visibleStates[index];
    }

    #getCurrentUser() {
        combineLatest([this.#userService.getCurrentUser()]).subscribe(([{ data }]) => {
            this.currentUser = data;

            const users = this.currentUser?.currentApplication?.applicationUsers ?? [];

            if (this.currentUser.currentApplication === null) {
                this.text = `Your journey to financial success begins here. <br><br>
                To begin, fill out your profile by completing the sections below.<br> Whether you’re defining your goals
                or adding financial information,<br> each section helps pave the way to building your financial plan.`;
            }

            this.#cd.detectChanges();

            if (users?.length > 0) {
                this.userName.set(users[0]?.firstName ?? '');
            }

            this.#getApplicationUserId();
        });
    }

    #getApplicationUserId(): void {
        const applicationUserId = first(this.currentUser?.currentApplication?.applicationUsers)?.id;

        if (!applicationUserId) {
            return;
        }

        if (this.currentUser?.currentApplication?.currentStep) {
            const step = this.currentUser.currentApplication.currentStep;

            this.updateSteps(step);
        }
    }

    updateSteps(currentStep: ApplicationStep) {
        const stepMapping: any = {
            [ApplicationStep.GettingStarted]: [],
            [ApplicationStep.AboutYou]: ['GettingStarted'],
            [ApplicationStep.Goal]: ['GettingStarted', 'AboutYou'],
            [ApplicationStep.FinancialSnapshot]: ['GettingStarted', 'AboutYou', 'Goal'],
            [ApplicationStep.RiskTolerance]: ['GettingStarted', 'AboutYou', 'Goal', 'FinancialSituation'],
            [ApplicationStep.WealthHealth]: ['GettingStarted', 'AboutYou', 'Goal', 'FinancialSituation', 'RiskTolerance', 'WealthHealth'],
            [ApplicationStep.FinancialProjection]: [
                'GettingStarted',
                'AboutYou',
                'Goal',
                'FinancialSituation',
                'RiskTolerance',
                'WealthHealth',
                'FinancialProjection',
            ],
            [ApplicationStep.WealthStrategyOptions]: [
                'GettingStarted',
                'AboutYou',
                'Goal',
                'FinancialSituation',
                'RiskTolerance',
                'WealthHealth',
                'FinancialProjection',
                'WealthStrategyOption',
                'WealthCoach',
            ],
            [ApplicationStep.WealthCoach]: [
                'GettingStarted',
                'AboutYou',
                'Goal',
                'FinancialSituation',
                'RiskTolerance',
                'WealthHealth',
                'FinancialProjection',
                'WealthStrategyOption',
                'WealthCoach',
            ],
            [ApplicationStep.Dashboard]: [
                'GettingStarted',
                'AboutYou',
                'Goal',
                'FinancialSituation',
                'RiskTolerance',
                'WealthHealth',
                'FinancialProjection',
                'WealthStrategyOption',
                'WealthCoach',
                'Dashboard',
            ],
        };

        const completedSteps = stepMapping[currentStep] || [];

        const defaultText = 'Continue completing your profile to get your Wealth Health Score';
        StepDetail.forEach((step) => {
            if (completedSteps.includes(step.stepTitle)) {
                step.percentage = 100;
                step.status = 'completed';
                step.buttonText = 'Review';
                this.#cd.markForCheck();
            } else if (step.stepTitle === currentStep) {
                step.status = 'in-process';
            } else {
                step.status = 'in-complete';
            }

            if (
                completedSteps.includes(ApplicationStep.WealthHealth) ||
                (completedSteps.includes(ApplicationStep.RiskTolerance) && step.stepTitle === ApplicationStep.FinancialSnapshot)
            ) {
                step.children.forEach((child) => {
                    child.status = 'completed';
                    child.buttonText = 'Review';
                    child.percentage = 100;
                });
            }

            this.#cd.detectChanges();
        });

        this.text = defaultText;

        if (currentStep === ApplicationStep.GettingStarted) {
            this.#getAboutYouStructure();
        }

        if (completedSteps.includes(ApplicationStep.AboutYou)) {
            this.#getAboutYouStructure(false);
        }

        if (completedSteps.includes(ApplicationStep.Goal)) {
            const shouldUpdatePercentage = currentStep === ApplicationStep.FinancialSnapshot;
            this.#getGoalData(shouldUpdatePercentage);

            setTimeout(() => {
                this.#getFinancialSituationStructure(false, true);
            }, 500);
        }
        if (completedSteps.includes(ApplicationStep.FinancialSnapshot)) {
            this.#getFinancialSituationStructure(false, false);
            setTimeout(() => {
                this.#getRiskToleranceStructure(true);
            }, 1000);
        }
        if (completedSteps.includes(ApplicationStep.RiskTolerance)) {
            this.#getWealthStructure(true);

            setTimeout(() => {
                this.#getRiskToleranceStructure(false);
            }, 1000);
        }
        if (completedSteps.includes(ApplicationStep.WealthHealth)) {
            const shouldUpdatePercentage = currentStep === ApplicationStep.FinancialProjection;
            this.#getWealthStructure(shouldUpdatePercentage);
        }

        setTimeout(() => {
            this.areAllStepsCompleted(this.steps);
        }, 2000);

        this.#cd.detectChanges();
    }

    // About you

    #getAboutYouStructure(updatePercentage: boolean = true): void {
        this.aboutYouCategories.set([]);

        combineLatest([this.#aboutYouService.getCategories()]).subscribe(([categoriesResponse]) => {
            this.aboutYouCategories.set(this.#getAboutYouCategories(categoriesResponse?.data || []));

            if (this.aboutYouCategories() && updatePercentage) {
                this.#updateStepPercentage(ApplicationStep.AboutYou, this.aboutYouCategories());
            }
        });
    }

    #getAboutYouCategories(categoriesResponse: CategoryResponse[], data: boolean = false): Category[] {
        const sortedByOrder = orderBy(categoriesResponse, 'order');

        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) => parentCategory.type === 'AboutYou' && parentCategory.name === 'Personal profile & relationship'
        );

        return aboutYouParents.map((parentCategory, parentIndex) => ({
            ...parentCategory,
            name: parentCategory.name === 'Personal profile & relationship' ? 'About You' : parentCategory.name,
            isFirstItem: parentIndex === 0,
            isLastItem: parentIndex === aboutYouParents.length - 1,
            data: data,
            childItems: groupByParentId[parentCategory.id]?.map((category, index) => ({
                ...category,
                isFirstItem: index === 0,
                isLastItem: index === groupByParentId[parentCategory.id].length - 1,
            })),
        }));
    }

    #getCompletionPercentage(category: any, stepIndex: number = 0): number {
        let childItems = category[stepIndex].childItems || [];

        if (stepIndex === 3) {
            const childItemsForIndex3 = category[3]?.childItems || [];
            const childItemsForIndex4 = category[4]?.childItems || [];
            childItems = [...childItemsForIndex3, ...childItemsForIndex4];
        }

        const totalItems = childItems.length;

        if (totalItems === 0) {
            return 0;
        }

        const completedItems = childItems.filter((item: { status: string }) => item.status === 'Complete').length;
        const percentage = (completedItems / totalItems) * 100;

        return Math.round(percentage);
    }

    #getRiskCompletionPercentage(categories: any[], index: number = 0): number {
        const category = categories[index];
        let completedCount = 0;

        if (category.categoryCode === 'RiskToleranceOutcome') {
            if (category.status === 'Complete') {
                return 100;
            } else {
                return 0;
            }
        }
        const totalCount = category.childItems?.length || 0;

        category.childItems?.forEach((child: { status: string }) => {
            if (child.status === 'Complete') {
                completedCount++;
            }
        });

        const percentage = (completedCount / totalCount) * 100;
        return isNaN(percentage) ? 0 : percentage;
    }

    #updateStepPercentage(stepTitle: ApplicationStep, categories: any[] | null) {
        if (!categories) return;

        const stepIndex = this.steps.findIndex((s) => s.stepTitle === stepTitle);

        if (stepIndex !== -1 && stepTitle !== ApplicationStep.RiskTolerance && stepTitle !== ApplicationStep.FinancialSnapshot) {
            const percentage = this.#getCompletionPercentage(categories);
            this.steps[stepIndex].percentage = percentage;
            this.steps[stepIndex].status = '';
            this.steps[stepIndex].buttonText = '';
            if (percentage === 100) {
                this.steps[stepIndex].status = 'completed';
                this.steps[stepIndex].buttonText = 'Review';
                if (this.steps[stepIndex + 1]) {
                    this.steps[stepIndex + 1].status = 'in-process';
                }
            } else if (percentage > 0) {
                this.steps[stepIndex].status = 'in-process';
                this.steps[stepIndex].buttonText = 'Continue';
            } else if (percentage === 0) {
                this.steps[stepIndex].status = 'in-process';
                this.steps[stepIndex].buttonText = 'Start';
            }

            this.#cd.detectChanges();

            if (stepIndex === 4) {
                return;
            }
        }
        this.#cd.detectChanges();
    }

    #updateFinancialPercentage(stepTitle: ApplicationStep, categories: any[] | null) {
        if (!categories) return;

        const stepIndex = this.steps.findIndex((s) => s.stepTitle === stepTitle);

        if (stepTitle === ApplicationStep.FinancialSnapshot) {
            let totalPercentage = 0;
            let completedChildrenCount = 0;
            for (let i = 0; i < categories.length - 1; i++) {
                const category = categories[i];

                const stepChildIndex = this.steps[stepIndex]?.children?.findIndex(
                    (s: { stepTitle: FinancialCategoryType }) => s.stepTitle === category.name
                );

                if (stepChildIndex !== -1) {
                    const percentage = this.#getCompletionPercentage(categories, stepChildIndex);
                    this.steps[stepIndex].children[stepChildIndex].percentage = percentage;

                    if (percentage === 100) {
                        this.steps[stepIndex].children[stepChildIndex].status = 'completed';
                        this.steps[stepIndex].children[stepChildIndex].buttonText = 'Review';

                        if (this.steps[stepIndex].children[stepChildIndex + 1]) {
                            this.steps[stepIndex].children[stepChildIndex + 1].status = 'in-process';
                        }

                        completedChildrenCount++;

                        this.#cd.detectChanges();
                    } else if (percentage > 0) {
                        this.steps[stepIndex].children[stepChildIndex].status = 'in-process';
                        this.steps[stepIndex].children[stepChildIndex].buttonText = 'Continue';
                    } else if (percentage === 0) {
                        this.steps[stepIndex].children[stepChildIndex].status = 'in-process';
                        this.steps[stepIndex].children[stepChildIndex].buttonText = 'Start';
                        break
                    }

                    totalPercentage += percentage;
                    this.#cd.detectChanges();
                }
            }

            const childCount = this.steps[stepIndex]?.children?.length || 0;
            const parentPercentage = childCount > 0 ? totalPercentage / childCount : 0;

            this.steps[stepIndex].percentage = parentPercentage;
            this.steps[stepIndex].buttonText = '';

            if (completedChildrenCount === childCount) {
                this.steps[stepIndex].status = 'completed';
                this.steps[stepIndex].buttonText = 'Review';
                this.steps[stepIndex + 1].status = 'in-process';
            } else if (completedChildrenCount > 0) {
                this.steps[stepIndex].status = 'in-process';
                this.steps[stepIndex].buttonText = 'Continue';
            } else if (completedChildrenCount === 0) {
                this.steps[stepIndex].status = 'in-complete';
                this.steps[stepIndex].buttonText = 'Start';
            }

            this.#cd.detectChanges();
        }
    }

    updateRiskPercentage(stepTitle: ApplicationStep, categories: any[] | null) {
        if (!categories) return;

        const stepIndex = this.steps.findIndex((s) => s.stepTitle === stepTitle);

        if (stepTitle === ApplicationStep.RiskTolerance) {
            if (categories.length > 1) {
                const firstCategoryRawCompletion = this.#getRiskCompletionPercentage(categories, 0);
                const secondCategoryRawCompletion = this.#getRiskCompletionPercentage(categories, 1);

                const firstCategoryCompletion = (firstCategoryRawCompletion / 100) * 50;
                const secondCategoryCompletion = (secondCategoryRawCompletion / 100) * 50;

                const percentage = firstCategoryCompletion + secondCategoryCompletion;
                this.steps[stepIndex].percentage = percentage;
                this.steps[stepIndex].buttonText = '';
                this.steps[stepIndex].status = '';

                if (percentage === 100) {
                    this.steps[stepIndex].status = 'completed';
                    this.steps[stepIndex].buttonText = 'Review';

                    if (stepIndex === 4) {
                        return;
                    }

                    if (this.steps[stepIndex + 1]) {
                        this.steps[stepIndex + 1].status = 'in-process';
                    }

                    this.#cd.detectChanges();
                } else if (percentage > 0) {
                    this.steps[stepIndex].buttonText = 'Continue';
                    this.steps[stepIndex].status = 'in-process';
                } else {
                    this.steps[stepIndex].status = 'in-process';
                    this.steps[stepIndex].buttonText = 'Start';
                }
            } else {
                const percentage = this.#getRiskCompletionPercentage(categories);
                this.steps[stepIndex].percentage = percentage;

                if (percentage === 100) {
                    this.steps[stepIndex].status = 'completed';
                    this.steps[stepIndex].buttonText = 'Review';

                    if (this.steps[stepIndex + 1]) {
                        this.steps[stepIndex + 1].status = 'in-process';
                    }
                } else {
                    this.steps[stepIndex].buttonText = 'Continue';
                }
                this.#cd.detectChanges();
            }
        }

        this.#cd.detectChanges();
    }

    // Goal
    #getGoalData(updatePercentage: boolean = true) {
        combineLatest([
            this.#applicationGoalService.getCurrentApplicationGoal(),
            this.#applicationGoalService.getCurrentQuestions(),
        ]).subscribe(([goalsResponse, questionsResponse]) => {
            this.applicationGoal = goalsResponse.data;
            this.goalQuestionsData = questionsResponse.data;

            if (updatePercentage) {
                this.#updateGoalProgress();
            }
        });
    }

    #updateGoalProgress() {
        let completedPercentage = 0;
        const totalConditions = 3;

        const roundVerifyCompleted =
            this.applicationGoal.applicationGoals.RoundVerify && this.applicationGoal.applicationGoals.RoundVerify.length > 0;
        if (roundVerifyCompleted) {
            completedPercentage += 1;
        }

        if (this.goalQuestionsData.length > 0) {
            completedPercentage += 1;
        }

        const stepsAfterGoal = [
            ApplicationStep.FinancialSnapshot,
            ApplicationStep.RiskTolerance,
            ApplicationStep.WealthHealth,
            ApplicationStep.FinancialProjection,
            ApplicationStep.WealthStrategyOptions,
            ApplicationStep.WealthCoach,
            ApplicationStep.Dashboard,
        ];

        if (this.currentUser.currentApplication?.currentStep && stepsAfterGoal.includes(this.currentUser.currentApplication.currentStep)) {
            completedPercentage += 1;
        }

        const finalPercentage = (completedPercentage / totalConditions) * 100;

        this.#updateStepCompletion(finalPercentage, this.currentUser.currentApplication?.currentStep === ApplicationStep.FinancialSnapshot);
    }

    #updateStepCompletion(completedPercentage: number, isFinancialSnapshot: boolean = false) {
        const goalStepIndex = this.steps.findIndex((s) => s.stepTitle === ApplicationStep.Goal);

        if (goalStepIndex === -1) return;

        this.steps[goalStepIndex].percentage = completedPercentage;

        if (this.steps[goalStepIndex + 1].children.length > 0 && isFinancialSnapshot) {
            this.steps[goalStepIndex + 1].children.forEach((child: { status: string }, index: number) => {
                child.status = index === 0 ? 'in-process' : 'in-complete';
            });
        }

        if (completedPercentage === 100) {
            this.steps[goalStepIndex].status = 'completed';
            this.steps[goalStepIndex].buttonText = 'Review';

            const nextStepIndex = goalStepIndex + 1;
            if (nextStepIndex < this.steps.length) {
                this.steps[nextStepIndex].status = 'in-process';
                this.steps[nextStepIndex].children[0].status = 'in-process';
            }
        } else if (completedPercentage > 0) {
            this.steps[goalStepIndex].status = 'in-process';
            this.steps[goalStepIndex].buttonText = 'Continue';
        } else {
            this.steps[goalStepIndex].status = 'in-process';
            this.steps[goalStepIndex].buttonText = 'Start';
        }

        this.#cd.markForCheck();
    }

    // Financial situation

    #getFinancialSituationStructure(data: boolean = false, updatePercentage: boolean = true): void {
        this.financialSituation.set(null);

        combineLatest([this.#financialSituationService.getCategories()]).subscribe(([categoriesResponse]) => {
            const categories = this.#getNestedCategories(categoriesResponse?.data || [], data);

            if (categories && updatePercentage) {
                this.#updateFinancialPercentage(ApplicationStep.FinancialSnapshot, categories);
            }

            this.financialSituation.set({
                categories: this.#getNestedCategories(categoriesResponse?.data || [], data),
                data: data,
            });
        });
    }

    #getNestedCategories(categoriesResponse: CategoryResponse[], data: boolean = false): Category[] {
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');
        const emptyParentId = groupByParentId['null'];

        return emptyParentId.map((parentCategory, parentIndex) => ({
            ...parentCategory,
            isFirstItem: parentIndex === 0,
            isLastItem: parentIndex === emptyParentId.length - 1,
            data: data,
            childItems: groupByParentId[parentCategory.id]?.map((category, index) => ({
                ...category,
                isFirstItem: index === 0,
                isLastItem: index === groupByParentId[parentCategory.id].length - 1,
            })),
        }));
    }

    // Risk tolerance

    #getRiskToleranceStructure(updatePercentage: boolean = true): void {
        this.riskToleranceCategories.set([]);

        combineLatest([this.#aboutYouService.getCategories()]).subscribe(([categoriesResponse]) => {
            this.riskToleranceCategories.set(this.#getRiskToleranceCategories(categoriesResponse?.data || []));

            if (this.riskToleranceCategories() && updatePercentage) {
                this.updateRiskPercentage(ApplicationStep.RiskTolerance, this.riskToleranceCategories());
            }
        });
    }

    #getRiskToleranceCategories(categoriesResponse: any[], data: boolean = false): Category[] {
        const riskCategoryCodes = Object.values(riskCategoryType);
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) =>
                parentCategory.type === 'AboutYou' &&
                (parentCategory.name === 'Financial behaviour and risk analysis' || parentCategory.name === 'Risk Tolerance Outcome')
        );

        return aboutYouParents.map((parentCategory, parentIndex) => {
            const childCategories = groupByParentId[parentCategory.id]?.filter((category) =>
                riskCategoryCodes.includes(category.categoryCode as unknown as riskCategoryType)
            );

            const allChildrenComplete = childCategories?.every((category) => category.status === 'Complete');
            return {
                ...parentCategory,
                name: parentCategory.name === 'Financial behaviour and risk analysis' ? 'Risk Tolerance' : parentCategory.name,
                isFirstItem: parentIndex === 0,
                isLastItem: parentIndex === aboutYouParents.length - 1,
                data: data,
                status: allChildrenComplete ? 'Complete' : parentCategory.status,
                childItems: childCategories?.map((category, index) => {
                    const isLastItem = category.categoryCode === 'InvestingPreference' || index === childCategories.length - 1;
                    return {
                        ...category,
                        isFirstItem: index === 0,
                        isLastItem: isLastItem,
                    };
                }),
            };
        });
    }

    //weathHealthScore
    #getWealthStructure(updatePercentage: boolean = true): void {
        this.wealthCategories.set([]);

        combineLatest([this.#aboutYouService.getCategories()]).subscribe(([categoriesResponse]) => {
            this.wealthCategories.set(this.#getWealthCategories(categoriesResponse?.data || []));

            if (this.wealthCategories() && updatePercentage) {
                this.#updateStepPercentage(ApplicationStep.WealthHealth, this.wealthCategories());
            }
        });
    }

    #getWealthCategories(categoriesResponse: any[], data: boolean = false): Category[] {
        const riskCategoryCodes = Object.values(wealthCategoryType);
        const sortedByOrder = orderBy(categoriesResponse, 'order');
        const groupByParentId = groupBy(sortedByOrder, 'parentId');

        const aboutYouParents = groupByParentId['null']?.filter(
            (parentCategory) => parentCategory.type === 'AboutYou' && parentCategory.name === 'Financial behaviour and risk analysis'
        );

        return aboutYouParents.map((parentCategory, parentIndex) => {
            const childCategories = groupByParentId[parentCategory.id]?.filter((category) =>
                riskCategoryCodes.includes(category.categoryCode as unknown as wealthCategoryType)
            );

            const allChildrenComplete = childCategories?.every((category) => category.status === 'Complete');
            return {
                ...parentCategory,
                name: parentCategory.name === 'Financial behaviour and risk analysis' ? 'Wealth Health Score' : parentCategory.name,
                isFirstItem: parentIndex === 0,
                isLastItem: parentIndex === aboutYouParents.length - 1,
                data: data,
                status: allChildrenComplete ? 'Complete' : parentCategory.status,
                childItems: childCategories?.map((category, index) => ({
                    ...category,
                    isFirstItem: index === 0,
                    isLastItem: index === childCategories.length - 1,
                })),
            };
        });
    }

    getDynamicClasses(step: any): { [key: string]: boolean } {
        return {
            completed: step.percentage === 100,
            [step.status]: step.percentage !== 100 && step.status !== 'in-complete',
        };
    }

    public areAllStepsCompleted(steps: any[]): boolean {
        const allSteps: any[] = [];
        steps.forEach((step) => {
            allSteps.push(step);
            if (step.children && step.children.length > 0) {
                allSteps.push(...step.children);
            }
        });
        return allSteps.every((step) => step.status === 'fully-completed' || step.status === 'completed');
    }

    public getMyPlan() {
        this.#router.navigate(['pricing']).then(noop);
    }

    isFullyCompleted(i: number, step: any): boolean {
        return (i === 0 || i === 1) && step.status === 'completed';
    }

    isFinancialFullyCompleted(i: number, step: any): boolean {
        return i === 2 && step.status === 'completed';
    }

    isWealthandRiskCompleted(i: number, step: any): boolean {
        return (i === 3 || i === 4) && step.status === 'completed';
    }

    showStep(step: ApplicationStep): boolean {
        const currentStep = this.currentUser?.currentApplication?.currentStep;
        const hasSubscription = !!this.currentUser?.currentSubscription;

        if (!hasSubscription) {
            return false;
        }

        const stepOrder = [
            ApplicationStep.FinancialProjection,
            ApplicationStep.WealthStrategyOptions,
            ApplicationStep.WealthCoach,
            ApplicationStep.Dashboard,
        ];

        if (currentStep) {
            const currentStepIndex = stepOrder.indexOf(currentStep);
            const stepIndex = stepOrder.indexOf(step);

            return stepIndex <= currentStepIndex;
        }

        return false;
    }
}

<img src="assets/images/{{ imageName }}" alt="" />

<h1>{{ title }}</h1>

<div class="estimated-time-section">
  <svg-icon-sprite
    [width]="'24px'"
    [src]="'assets/sprites/sprite.svg#timer-line'"
  ></svg-icon-sprite>

  <span>
    Estimated time to complete this section: <b>{{ estimatedTime }} minutes</b>
  </span>
</div>

<p class="description" [innerHTML]="description"></p>

<ng-content></ng-content>

import { WritableSignal } from '@angular/core';

export function deleteWarningMessageRecord(
  messageMap: WritableSignal<Map<string, Map<string, string>>>,
  appUserId: string,
  questionId: string
): void {
  const hasMessageKey = messageMap().get(appUserId)?.has(questionId);
  if (hasMessageKey) {
    messageMap().get(appUserId)?.delete(questionId);
  }

  if (messageMap().get(appUserId)?.size === 0) {
    messageMap()?.delete(appUserId);
  }
}

export function addWarningMessageRecord(
  messageMap: WritableSignal<Map<string, Map<string, string>>>,
  appUserId: string,
  questionId: string,
  warningMessage: string
): void {
  const result = createWarningMessageByAppUserIfNotExist(
    messageMap(),
    appUserId
  );
  result.get(appUserId)?.set(questionId, warningMessage);
}

export function createWarningMessageByAppUserIfNotExist(
  messageMap: Map<string, Map<string, string>>,
  appUserId: string
): Map<string, Map<string, string>> {
  if (!messageMap.get(appUserId)?.size) {
    messageMap.set(appUserId, new Map<string, string>(new Map([])));
  }
  return messageMap;
}

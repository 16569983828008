import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

import { BaseTooltipDirective } from './base-tooltip.directive';

@Directive({ selector: '[acornTooltip]', standalone: true })
export class TooltipDirective extends BaseTooltipDirective implements OnInit {
  #elementRef = inject(ElementRef);
  #renderer = inject(Renderer2);
  #phoneResponsive = 768;

  @Input() isDisplayIconShowTooltip = true;

  iconTooltipRef: HTMLElement | null = null;

  override ngOnInit() {
    super.ngOnInit();

    if (this.isDisplayIconShowTooltip && this.tooltipMessage) {
      this.#addIconMobileViewport();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.show();
  }

  @HostListener('mouseleave')
  onMouseOut() {
    this.hide();
  }

  @HostBinding('class')
  get className() {
    if (!this.tooltipMessage) {
      return '';
    }

    return this.isActive() ? 'acorn-tooltip active' : 'acorn-tooltip';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.iconTooltipRef) {
      this.#handleDisplayIconTooltip(
        (event.target as Window).innerWidth,
        this.iconTooltipRef
      );
    }
  }

  #addIconMobileViewport(): void {
    const currentElement = this.#elementRef.nativeElement;
    const iconElement = this.#renderer.createElement('img');
    this.iconTooltipRef = iconElement;
    this.#renderer.setAttribute(
      iconElement,
      'src',
      'assets/icons/question-line.svg'
    );
    this.#renderer.setAttribute(iconElement, 'width', '20px');
    this.#renderer.setAttribute(iconElement, 'height', '20px');
    this.#renderer.setAttribute(iconElement, 'class', 'tooltipIcon');
    this.#handleDisplayIconTooltip(window.innerWidth, iconElement);

    this.#renderer.listen(iconElement, 'click', (event) => {
      this.show();
      event.stopPropagation();
    });

    this.#renderer.insertBefore(
      currentElement.parentNode,
      iconElement,
      currentElement.nextSibling
    );
  }

  #handleDisplayIconTooltip(width: number, element: HTMLElement): void {
    if (width < this.#phoneResponsive) {
      this.#renderer.setStyle(element, 'display', 'inline');
    } else {
      this.#renderer.setStyle(element, 'display', 'none');
    }
  }
}

import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { InformationComponent } from '../information/information.component';
import { ActionsComponent } from '../actions/actions.component';
import { FormsModule } from '@angular/forms';
import { WealthHealthScore } from '@acorn/util';
import { WealthHealthComponent } from '../wealth-health/wealth-health.component';

@Component({
    selector: 'acorn-actions-empty',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        ProgressBarComponent,
        InformationComponent,
        ActionsComponent,
        FormsModule,
        WealthHealthComponent,
    ],
    templateUrl: './actions-empty.component.html',
    styleUrls: ['./actions-empty.component.scss'],
})
export class ActionsEmptyComponent implements AfterViewInit {
    @Output() userInfo = new EventEmitter<void>();
    @Output() aboutYou = new EventEmitter<void>();
    @Output() hasStrategy = new EventEmitter<{
        hasStrategyNull: boolean;
        strategy: any;
    }>();

    @Output() learningHub = new EventEmitter<boolean>();

    @Input() applicationId!: string;
    @Input() selectedStrategy!: any;

    slidesToShow = 3;
    currentIndex = 0;

    goals = [
        {
            goalId: 1,
            goalName: 'Start or expand a share portfolio',
            selectedGoal: true,
        },
        {
            goalId: 2,
            goalName: 'Prepare for a major celebration (e.g. wedding)',
            selectedGoal: false,
        },
        {
            goalId: 3,
            goalName: 'Increase savings over time',
            selectedGoal: false,
        },
        {
            goalId: 4,
            goalName: 'Invest in an investment property',
            selectedGoal: false,
        },
        { goalId: 5, goalName: 'Pay off mortgage', selectedGoal: false },
    ];
    wealthHealthScore!: WealthHealthScore;

    public update() {
        const navLinks: NodeListOf<Element> = document.querySelectorAll('.nav-link');
        const ulElement: HTMLElement | null = document.querySelector('.custom-nav-header');
        const dots: NodeListOf<Element> = document.querySelectorAll('.dot');

        if (!ulElement) return;

        const totalTabs = navLinks.length;

        const updateView = () => {
            const slideWidth = ulElement.offsetWidth / this.slidesToShow;
            const scrollPosition = this.currentIndex * slideWidth;

            ulElement.style.transform = `translateX(-${scrollPosition}px)`;

            dots.forEach((dot, index) => {
                dot.classList.remove('active');
                if (index === Math.floor(this.currentIndex / this.slidesToShow)) {
                    dot.classList.add('active');
                }
            });
        };

        dots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                this.currentIndex = index * this.slidesToShow;

                if (this.currentIndex >= totalTabs) {
                    this.currentIndex = totalTabs - this.slidesToShow;
                } else if (this.currentIndex < 0) {
                    this.currentIndex = 0;
                }

                updateView();
            });
        });

        updateView();
    }

    ngAfterViewInit(): void {
        this.update();
    }

    toggleAboutYou() {
        this.aboutYou.emit();
    }

    toggleUsefulInfo() {
        this.userInfo.emit();
    }

    hasStrategyEmit(hasStrategyNull: boolean, strategy: any) {
        this.hasStrategy.emit({
            hasStrategyNull: hasStrategyNull,
            strategy: strategy,
        });
    }

    public setLearningHub() {
        this.learningHub.emit();
    }
}

import { BillingInterval, MembershipType } from '@acorn/util';

export interface Subscription {
  id: string;
  createdDate: Date;
  startDate: Date;
  endDate: Date;
  status: string;
  requestCancelDate: Date;
  countScheduleTime: number;
  subscriptionPlan: SubscriptionPlan;
}

export interface SubscriptionPlan {
  id: string;
  externalProductId: string;
  membershipCode: MembershipType;
  billingInterval: BillingInterval;
}

export interface ProductList {
  productId: string;
  name: string;
  description: string;
  price: number;
  originalPrice: number;
  percentOff: number;
  amountOff: number;
  currency: string;
  interval: 'year' | 'month';
  image: string;
  membershipCodeType: string;
  featureList: string[];
}

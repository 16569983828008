import { CommonResponse } from "@acorn/util";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@env";

@Injectable()

export class WealthCalendlyService {
  #httpClient = inject(HttpClient);

  SaveWealthCoachData(data: any ,applicationId:any) {
    return this.#httpClient.post(
      `${environment.apiUrls.calculation}application/${applicationId}/calendly`,
      data
    );
  }

  GetWealthCoachData(applicationId: any) {
    return this.#httpClient.get<CommonResponse<any>>(
      `${environment.apiUrls.calculation}application/${applicationId}/calendly`
    );
  }
}

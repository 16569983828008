<mat-card>
  <mat-card-content>
    <div (click)="!isDesktop && toggle()" class="header">
      <h1>
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#' + FINANCIAL_ICON_MAPPING[type]"
          [width]="'24px'"
          [height]="'24px'"
        ></svg-icon-sprite>

        <span>{{ type }}</span>
      </h1>

      <p>
        {{ type === 'Total Income' ? 'Total after tax:' : 'Total:'}}
        {{ total | currency : 'USD' : 'symbol' : '.0' | acornConvertZeroToDash
        }}<span *ngIf="isShownTotalYearText"> /yr</span>
      </p>
      <div [class.hidden]="!(isShown || isDesktop)" class="toggle">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#chevron-down'"
          [width]="'24px'"
          [height]="'24px'"
        ></svg-icon-sprite>
      </div>
    </div>

    <div
      [@expand]="isShown || isDesktop ? 'visible' : 'hidden'"
      *ngIf="isShown || isDesktop"
      class="cards"
    >
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>

export enum FinancialTemplateType {
  SingleQuestion = 'SingleQuestion',
  GroupQuestion = 'GroupQuestion',
  NestedGroupQuestion = 'NestedGroupQuestion',
  ArrayQuestion = 'ArrayQuestion'
}

export enum FinancialSnapshotType {
  Income = 'Total Income',
  Expenses = 'Expenses',
  Liabilities = 'Liabilities',
  Assets = 'Assets'
}


import {
  AnswerType,
  CategoryCode,
  ERROR_KEY,
  FinancialQuestion,
  QuestionType,
  RepaymentStructure,
} from '@acorn/util';
import { FormGroup, FormRecord } from '@angular/forms';
import { isEqual } from 'lodash';

import { AmountForm } from '../form.interface';

const interestRateDefaultValue = {
  fixedPerVariable: 0,
  fixedInterestRate: '',
  remainingFixedRateTermMonth: '',
};

export function getRequiredToOtherQuestionsByKeys(
  keys: (keyof FinancialQuestion)[],
  childrenQuestions: FinancialQuestion[]
): FinancialQuestion[] {
  return childrenQuestions.filter((it) =>
    keys.some(
      (key) =>
        it[key] === true ||
        it.question.questionType === QuestionType.RemainingTermYears
    )
  );
}

export function handleLiabilityRealEstateChange(
  childrenQuestions: FinancialQuestion[],
  requiredToOtherQuestions: FinancialQuestion[],
  data: Partial<{ [p: string]: any }>,
  formRecord: FormRecord
): void {
  const questions = getListQuestionToExecuteValidate(childrenQuestions);
  let hasError = false;

  requiredToOtherQuestions.forEach((requiredField) => {
    const requiredVal: string | null = data[requiredField.id].answerContent;
    let hasInterestRateError = false;
    let interestRateValue: string | null = '';
    let hasRepaymentError = false;
    let repaymentValue: string | null = '';
    let hasRemainingTermYearsError = false;
    let remainingTermValue: string | null = '';
    questions.forEach((it) => {
      const amountFormCtrl = <FormGroup<AmountForm>>formRecord.get(it.id);
      const answerFormCtrl = amountFormCtrl.controls.answerContent;
      if (it.question.questionType === QuestionType.InterestRate) {
        hasInterestRateError = !isInterestRateValid(answerFormCtrl.value);
        interestRateValue = answerFormCtrl.value;
      } else if (it.question.questionType === QuestionType.RepaymentStructure) {
        hasRepaymentError = !isRepaymentStructureValid(answerFormCtrl.value);
        repaymentValue = answerFormCtrl.value;
      } else if (it.question.questionType === QuestionType.RemainingTermYears) {
        hasRemainingTermYearsError = !answerFormCtrl.value;
        remainingTermValue = answerFormCtrl.value;
      }
    });

    if (
      requiredVal &&
      (hasInterestRateError || hasRepaymentError || hasRemainingTermYearsError)
    ) {
      hasError = true;
    } else if (!requiredVal) {
      const isEqualInterestRateDefault = interestRateValue
        ? isEqual(JSON.parse(interestRateValue), interestRateDefaultValue)
        : false;
      const isEqualRepaymentDefault = Object.values(
        repaymentValue ? JSON.parse(repaymentValue) : {}
      ).every((it) => it === null);
      const isEqualRemainingTermYearsDefault = remainingTermValue === '';

      if (
        ((hasInterestRateError ||
          hasRepaymentError ||
          hasRemainingTermYearsError) &&
          !(
            isEqualInterestRateDefault &&
            isEqualRepaymentDefault &&
            isEqualRemainingTermYearsDefault
          )) ||
        (!hasInterestRateError &&
          !hasRepaymentError &&
          !hasRemainingTermYearsError)
      ) {
        hasError = true;
      }
    }
  });

  if (hasError) {
    formRecord.setErrors({ [ERROR_KEY.ALL_FIELDS_REQUIRED]: true });
  }
}

export function isInterestRateValid(answerContent: string | null): boolean {
  if (!answerContent) return false;

  const data = { ...JSON.parse(answerContent) };
  delete data.fixedPerVariable;

  return Object.values(data).every((val) => val !== '' && !isNaN(Number(val)));
}

function isRepaymentStructureValid(answerContent: string | null): boolean {
  if (!answerContent) return false;

  const data = { ...JSON.parse(answerContent) };
  if (!data.repaymentStructure) {
    return false;
  }

  if (data.repaymentStructure === RepaymentStructure['P&I']) {
    return true;
  }

  return Object.values(data).every(Boolean);
}

function getListQuestionToExecuteValidate(
  childrenQuestions: FinancialQuestion[]
): FinancialQuestion[] {
  const isInvestmentQuestions = childrenQuestions.every(
    (it) => it.parent?.question.questionType === QuestionType.InvestmentProperty
  );

  if (isInvestmentQuestions) {
    return childrenQuestions.filter((it) => !it.readonly);
  }

  const interestRateQuestions: FinancialQuestion[] = childrenQuestions.filter(
    (it) =>
      it.category.categoryCode === CategoryCode.RealEstate &&
      it.question.answerType === AnswerType.InterestRate
  );

  const repaymentQuestions: FinancialQuestion[] = childrenQuestions.filter(
    (it) =>
      it.category.categoryCode === CategoryCode.RealEstate &&
      it.question.answerType === AnswerType.RepaymentStructure
  );

  return [...interestRateQuestions, ...repaymentQuestions];
}

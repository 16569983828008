import {
  GoalSettingPartsStep,
  GoalSettingPartStep,
  GoalSettingStep,
} from '@acorn/util';
import { GoalStepStructure, GoalStepStructure1 } from '../../../util';

export const STEP_STRUCTURE1: GoalStepStructure1[] = [
  {
    id: GoalSettingPartStep.BuildWealth,
    order: 1,
    state: 'number',
    label: 'Build wealth',
    icon: '/assets/svg/build-wealth.svg',
    Description:
      'Build wealth goals are focused on maximising your financial position through effective saving, investing, and income strategies. These goals aim to optimise your capital and income growth for greater success.',
    maximumRequiredGoals: 25,
    minimumRequiredGoals: 5,
    isLastStep: false,
    selected: []
  },
  {
    id: GoalSettingPartStep.FinancialSecurity,
    order: 2,
    state: 'number',
    label: 'Financial security',
    icon: '/assets/svg/financial-security.svg',
    Description:
      'Financial security goals aim to establish a stable and secure financial foundation by managing expenses, savings, wealth protection and planning for retirement. These goals help you build a safety net, improve financial stability, and help ensure a comfortable and secure future.',
    maximumRequiredGoals: 20,
    minimumRequiredGoals: 5,
    isLastStep: false,
    selected: []
  },
  {
    id: GoalSettingPartStep.LifestyleDevelopment,
    order: 3,
    state: 'number',
    label: 'Lifestyle & development',
    icon: '/assets/svg/lifestyle-development.svg',
    Description:
      'Lifestyle and development goals focus on enriching your life through personal growth, new experiences, and achieving significant milestones. These goals help you pursue your passions, improve your skills, and enjoy a fulfilling and balanced life.',
    maximumRequiredGoals: 15,
    minimumRequiredGoals: 5,
    isLastStep: false,
    selected: []
  },
  {
    id: GoalSettingPartStep.FamilyCommunity,
    order: 4,
    state: 'number',
    label: 'Family & community',
    icon: '/assets/svg/family-community.svg',
    Description:
      'Family and community goals focus on strengthening your connections with loved ones and making a positive impact. These goals help you provide support, build a legacy, and contribute to the well-being of your family and community.',
    maximumRequiredGoals: 10,
    minimumRequiredGoals: 5,
    isLastStep: true,
    selected: []
  },
];

export const STEP_STRUCTURE: GoalStepStructure[] = [
  {
    id: GoalSettingStep.GoalSetting,
    order: 1,
    state: 'number',
    maximumRequiredGoals: 10,
    minimumRequiredGoals: 5,
    label: 'Goal Setting',
    parts: [
      {
        id: GoalSettingPartsStep.ChooseYourGoals,
        label: 'Choose Your Goals',
        order: 1,
        state: 'number',
        description: 'Choose Your Goal',
        isLastPart: true,
      },
    ],
  },
  {
    id: GoalSettingStep.RoundVerify,
    order: 2,
    state: 'number',
    maximumRequiredGoals: 10,
    minimumRequiredGoals: 5,
    label: 'Confirm & Prioritize',
    parts: [
      {
        id: GoalSettingPartsStep.YourTop5,
        label: 'Your Top 5',
        order: 1,
        state: 'number',
        isLastPart: true,
        description:
          'Congratulations on identifying your top 5 goals. Keep lower priority goals in mind as your priorities evolve. Review and rearrange your top 5 goals if necessary.',
      },
    ],
  },
  {
    id: GoalSettingStep.GoalMetrics,
    order: 3,
    maximumRequiredGoals: 0,
    minimumRequiredGoals: 0,
    state: 'number',
    label: 'Goal Details',
    parts: [
      {
        id: GoalSettingPartsStep.DefineYourMetrics,
        order: 1,
        state: 'number',
        label: 'Define Your Metrics',
        isLastPart: true,
        description:
          "Now that we've prioritised your goals, let's make them actionable! Please provide the specific details and timelines for each goal below to create a clear plan for achieving them. Keep in mind that your financial plan is based on the Australian financial year (July 1 - June 30) so as you enter target years below, please include the financial year instead of the calendar year. If you're unsure, a reasonable estimate will work just fine!",
      },
    ],
  },
];

<div class="popup-container">
  <div class="popup-header">
    <div class="header-content">
      <h3>Reflection activity (3 mins)</h3>
      <h1>Why do values matter?</h1>
    </div>
    <button class="close-button" aria-label="Close" (click)="closePopup()"> &#x2715;</button>
  </div>
  <div class="popup-content">
    <p>
      Reflecting on what matters most to you is the first step in defining your financial goals.
      Whether it's family, creativity, or self-reliance, understanding your values will guide your Wealth Maximiser Journey.
    </p>
    <p class="mt-1">
      When thinking about your goals, it's beneficial to consider your values as aligning them will help you live a happier life.
      If you choose goals that don’t align to your values you may feel unsatisfied.
    </p>
    <h4><i>To understand your values, take a moment to ask yourself these three questions:</i></h4>
    <ul class="mt-1">
      <li>What are the moments in life that bring you the greatest joy and fulfillment?</li>
      <li>When faced with a difficult decision, what principles guide your choices?</li>
      <li>Which aspects of your life would you never compromise, and why?</li>
    </ul>
    <hr>
    <h4><i>Some common values that might impact your financial goals:</i></h4>
    <div class="values-tags">
      <span class="tag" *ngFor="let value of values">{{ value }}</span>
    </div>
  </div>
  <div class="popup-footer">
    <button class="close-button" (click)="closePopup()">Close</button>
  </div>
</div>

import { GoalStepStructure } from '@acorn/feature-application';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { NgForOf, NgIf, ViewportScroller } from '@angular/common';

import { MatDividerModule } from '@angular/material/divider';

import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

import { MatButtonModule } from '@angular/material/button';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { chunk } from 'lodash';

import { Goal, GoalSettingPartStep, GoalSettingStep, GoalStep, NextStepAction } from '@acorn/util';
import { ApplicationGoalService } from '@acorn/data-access';
import { AlertComponent, NavigationComponent } from '@acorn/common-ui';
import { STEP_STRUCTURE } from '../utlis/step-structure.const';

@Component({
  selector: 'acorn-confirm-and-prioritise',
  templateUrl: 'confirm-and-prioritise.component.html',
  styleUrls: ['confirm-and-prioritise.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDividerModule,
    CdkDropList,
    NgForOf,
    CdkDrag,
    IconSpriteModule,
    MatButtonModule,
    NavigationComponent,
    NgIf,
    AlertComponent,
  ],
})
export class ConfirmAndPrioritiseComponent {
  #applicationGoalService = inject(ApplicationGoalService);
  #goalStepVerifyStructure: GoalStepStructure = STEP_STRUCTURE.filter(
    (it) => it.id === GoalSettingStep.RoundVerify
  )[0]!;
  #viewport = inject(ViewportScroller);
  isMobile = window.innerWidth <= 768;

  @Input() set goals(value: Goal[]) {
    if (value?.length < this.#goalStepVerifyStructure.minimumRequiredGoals) {
      this.top5GoalList.set([]);
      this.next5GoalList.set([]);

      return;
    }

    const [top5GoalList, next5GoalList] = chunk(value, 5);
    this.top5GoalList.set(top5GoalList);
    this.next5GoalList.set(next5GoalList || []);
  }

  @Input() isFileNotes: boolean = false;

  @Output() nextClicked = new EventEmitter<NextStepAction>();
  @Output() backClicked = new EventEmitter<void>();

  top5GoalList = signal<Goal[]>([]);
  next5GoalList = signal<Goal[]>([]);
  isLoading = signal<boolean>(false);
  errorMessage = signal<string>('');

  drop(event: CdkDragDrop<Goal[]>, dropTo: 'top' | 'next') {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (dropTo === 'next') {
        transferArrayItem(
          event.container.data,
          event.previousContainer.data,
          0,
          5
        );
      } else {
        transferArrayItem(
          event.container.data,
          event.previousContainer.data,
          5,
          0
        );
      }
    }
  }

  onNext(): void {
    if(this.isFileNotes){
      this.nextClicked.emit();
      return
    }

    const top5GoalIds = this.top5GoalList().map((goal) => goal.id);
    const next5GoalIds = this.next5GoalList().map((goal) => goal.id);
    const goalIds = [...top5GoalIds, ...next5GoalIds];

    this.isLoading.set(true);
    this.#applicationGoalService
      .setCurrentGoal(
        GoalStep.RoundVerify,
        goalIds.map((id, index) => ({
          id,
          order: index,
        }))
      )
      .subscribe((result) => {
        this.isLoading.set(false);
        if (!result.isSuccess && result.message) {
          this.errorMessage.set(result.message);
          this.#viewport.scrollToPosition([0, 0]);
          return;
        }
        this.nextClicked.emit({
          applicationGoals: result.data.applicationGoals,
        });
      });
  }
}

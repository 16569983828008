import {
  CommonResponse,
  CustomerUser,
  PricingCheckout,
  PricingPackage,
  PricingResponse,
  ProductList,
  UpComingInvoice,
  UpdateSubscription,
} from '@acorn/util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { map, Observable } from 'rxjs';

@Injectable()
export class PricingService {
  #httpClient = inject(HttpClient);

  getPricingPackages(coupon?: string): Observable<PricingPackage[]> {
    return this.#getProductionList(coupon).pipe(
      map((res) => {
        if (res.isSuccess && res.data) {
          return this.#mappingPricingPackages(res.data);
        }
        throw new Error(res.message);
      })
    );
  }

  getUpComingInvoice(
    payload: UpdateSubscription
  ): Observable<CommonResponse<UpComingInvoice>> {
    const params = new HttpParams({
      fromObject: { ...(payload && payload) },
    });

    return this.#httpClient.get<CommonResponse<UpComingInvoice>>(
      `${environment.apiUrls.customer}/api/Membership/get-subscription-upcoming-invoice`,
      { params }
    );
  }

  getCurrentUser() {
    return this.#httpClient.get<CommonResponse<CustomerUser>>(
      `${environment.apiUrls.customer}/api/User/Current`
    );
  }

  checkout(
    payload: PricingCheckout
  ): Observable<CommonResponse<PricingResponse>> {
    return this.#httpClient
      .post<CommonResponse<string>>(
        `${environment.apiUrls.customer}/api/Membership/product-checkout`,
        payload
      )
      .pipe(map((res) => ({ ...res, data: { sessionId: res.data } })));
  }

  update(
    payload: UpdateSubscription
  ): Observable<CommonResponse<PricingResponse>> {
    return this.#httpClient.post<CommonResponse<PricingResponse>>(
      `${environment.apiUrls.customer}/api/Membership/subscription-update`,
      payload
    );
  }

  #getProductionList(
    coupon?: string
  ): Observable<CommonResponse<ProductList[]>> {
    const params = new HttpParams({
      fromObject: { ...(coupon && { coupon }) },
    });
    return this.#httpClient.get<CommonResponse<ProductList[]>>(
      `${environment.apiUrls.customer}/api/Membership/get-product-list`,
      { params }
    );
  }

  #mappingPricingPackages(products: ProductList[]): PricingPackage[] {
    return products
      .map(
        (it) =>
          ({
            id: it.productId,
            name: it.name,
            packageUrlBadge: it.image,
            description: it.description,
            interval: it.interval,
            price: it.price,
            originalPrice: it.originalPrice,
            percentOff: it.percentOff,
            amountOff: it.amountOff,
            currency: it.currency,
            validBenefits: it.featureList
              .filter((item) => item.includes('[FEATURE]'))
              .map((it) => it.replace('[FEATURE]', '')),
            invalidBenefits: it.featureList
              .filter((item) => item.includes('[NO-FEATURE]'))
              .map((it) => it.replace('[NO-FEATURE]', '')),
            tag: it.featureList
              .filter((item) => item.includes('[TAG]'))
              .map((it) => it.replace('[TAG]', '')),
          } as PricingPackage)
      )
      .reverse();
  }
}

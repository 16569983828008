import { RepaymentStructure } from '@acorn/util';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { FUTURE_YEAR_OPTIONS, MONTH_OPTIONS } from '../../utils';

@Component({
  selector: 'acorn-repayment-structure',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './repayment-structure.component.html',
  styleUrls: ['./repayment-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => RepaymentStructureComponent),
      multi: true,
    },
  ],
})
export class RepaymentStructureComponent
  implements ControlValueAccessor, OnInit
{
  protected readonly RepaymentStructure = RepaymentStructure;
  protected readonly MONTH_OPTIONS = MONTH_OPTIONS;
  protected readonly FUTURE_YEAR_OPTIONS = FUTURE_YEAR_OPTIONS;


  formGroup = new FormGroup({
    repaymentStructure: new FormControl<RepaymentStructure | null>(null),
    repaymentExpireMonth: new FormControl<string | null>(null),
    repaymentExpireYear: new FormControl<string | null>(null),
  });

  @Input() isFileNotes : boolean = false;

  ngOnInit() {

    this.formGroup.controls.repaymentStructure.valueChanges.subscribe(
      (value: RepaymentStructure | null) => {
        if (!value) {
          return;
        }

        if (value === RepaymentStructure['P&I']) {
          this.formGroup.patchValue({
            repaymentExpireMonth: null,
            repaymentExpireYear: null,
          });
        }
      }
    );

    this.formGroup.valueChanges.subscribe((value) => {
      this.onChange(JSON.stringify(value));
    });
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  writeValue(value: string): void {
    if (!value) {
      return;
    }

    try {
      const formValue = JSON.parse(value);
      this.formGroup.patchValue(formValue);

      this.onChange(JSON.stringify(formValue));
    } catch (e) {
      console.log(e);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (!isDisabled) {
      return;
    }

    this.formGroup.disable();
  }

  onRepaymentStructureChanged(
    event: Event,
    repaymentStructure: RepaymentStructure
  ): void {
    if (this.isFileNotes) {
      return;
    }

    event.preventDefault();

    if (
      this.formGroup.controls.repaymentStructure.value === repaymentStructure
    ) {
      this.formGroup.controls.repaymentStructure.setValue(null);
    } else {
      this.formGroup.controls.repaymentStructure.setValue(repaymentStructure);
    }
  }
}

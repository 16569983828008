<div class="information">
    <div class="container">
        <h3>Useful information</h3>
        <div class="information-inner">
            <div class="info-link">
                <a target="_blank" href="https://www.wealthmaximiser.com.au/financial-services-guide/">
                    <img src="/assets/icons/document.svg" alt="document" />
                    <P> Financial Services Guide</P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://acornstorageuat.blob.core.windows.net/assets/Key-Assumptions-PDF.pdf">
                    <img src="/assets/icons/virtual.svg" alt="virtual" />
                    <P> Key Assumptions for Status Quo Financial Projection
                    </P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://www.wealthmaximiser.com.au/pricing/#accordion">
                    <img src="/assets/icons/information.svg" alt="information" />
                    <P> FAQs</P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://acornstorageuat.blob.core.windows.net/assets/Wealth-Philosophy-PDF.pdf">
                    <img src="/assets/icons/smoothing.svg" alt="smoothing" />
                    <P> Our wealth philosophy </P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://acornstorageuat.blob.core.windows.net/assets/Wealth-Building-Blocks-PDF.pdf">
                    <img src="/assets/icons/warning.svg" alt="warning" />
                    <P> Wealth Building Blocks </P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://acornstorageuat.blob.core.windows.net/assets/Glossary-PDF.pdf">
                    <img src="/assets/icons/book.svg" alt="book" />
                    <P> Glossary </P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://www.wealthmaximiser.com.au/terms-and-conditions/">
                    <img src="/assets/icons/terms.svg" alt="terms" />
                    <P> Terms & Conditions</P>
                </a>
            </div>
            <div class="info-link">
                <a target="_blank" href="https://www.wealthmaximiser.com.au/pricing/">
                    <img src="/assets/icons/currency.svg" alt="currency" />
                    <P> Pricing plans</P>
                </a>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="formGroup" [ngClass]="{'disabled-select': isFileNotes}">
  <mat-slider disableRipple="true" [disabled]="isFileNotes">
    <input matSliderThumb formControlName="fixedPerVariable" />
  </mat-slider>

  <div class="fixed-variable-information">
    <div class="form-field-wrapper">
      <mat-label>Fixed</mat-label>

      <mat-form-field
        appearance="outline"
        class="small w-full"
        subscriptSizing="dynamic"
      >
        <input
          type="text"
          matInput
          mask="000"
          suffix="%"
          [validation]="false"
          [ngModel]="fixedRateControl.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onFixedRateChanged($event)"
          
        />
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-label>Variable</mat-label>

      <mat-form-field
        appearance="outline"
        class="small w-full"
        subscriptSizing="dynamic"  
      >
        <input
          type="text"
          matInput
          mask="000"
          suffix="%"
          [validation]="false"
          [ngModel]="variableRateControl.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onVariableRateChanged($event)"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="form-field-wrapper">
    <mat-label>Fixed Interest rate</mat-label>

    <mat-form-field
      appearance="outline"
      class="small"
      subscriptSizing="dynamic"
      [class.readonly]="formGroup.controls.fixedInterestRate.disabled"
    >
      <span matPrefix>%</span>

      <input
        type="text"
        matInput
        mask="percent.2"
        placeholder="0"
        formControlName="fixedInterestRate"
        [readOnly]="formGroup.controls.fixedInterestRate.disabled"
        [disabled]="isFileNotes"
      />
    </mat-form-field>
  </div>

  <div class="form-field-wrapper">
    <mat-label>Remaining fixed rate term (months)</mat-label>

    <mat-form-field
      appearance="outline"
      class="small"
      subscriptSizing="dynamic"
      [class.readonly]="formGroup.controls.fixedInterestRate.disabled"
    
    >
      <mat-icon
        matSuffix
        class="suffix-select-icon"
        [class.triggered]="selectElement.panelOpen"
        [disbled]="isFileNotes"
      >
        keyboard_arrow_down
      </mat-icon>

      <mat-select
        #selectElement
        formControlName="remainingFixedRateTermMonth"
        [hideSingleSelectionIndicator]="true"
        [disabled]="formGroup.controls.fixedInterestRate.disabled || isFileNotes"
      >
        <mat-option
          *ngFor="let option of FIXED_RATE_TERM_OPTIONS"
          [value]="option.value"
          [disabled]="isFileNotes"
        >
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper">
    <mat-label>Variable Interest rate</mat-label>

    <mat-form-field
      appearance="outline"
      class="small"
      subscriptSizing="dynamic"
      [class.readonly]="formGroup.controls.variableInterestRate.disabled" 
    >
      <span matPrefix >%</span>

      <input
        type="text"
        matInput
        mask="percent.2"
        placeholder="0"
        formControlName="variableInterestRate"
        [readOnly]="formGroup.controls.variableInterestRate.disabled"
        [disabled]="isFileNotes"
      />
    </mat-form-field>
  </div>
</form>

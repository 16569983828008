import { ImageContent } from 'apps/customer-portal/src/app/common/utils';
import { Document } from '@contentful/rich-text-types';

import { MediaCategories } from '../enum';

export interface MediaEntryQuery {
  title: string;
  subTitle: string;
  featuredTitle: string;
  featureDescription: string;
  featuredHeading: string;
  featuredList: FeaturedListMediaEntry[];
}

export interface MediaEntry {
  title: string;
  subTitle: string;
  featuredTitle: string;
  featureDescription: string;
  featuredHeading: string;
  featuredList: FeaturedMedia[];
}

export interface FeaturedListMediaEntry {
  fields: {
    author: string;
    createdDate: string;
    image: ImageContent;
    subTitle: string;
    timeRead: number;
    content: Document;
    articleType: string;
    title: string;
    userName: string;
    tags: MediaCategories[];
  };
  sys: {
    id: string;
  };
}

export interface FeaturedMedia {
  author: string;
  createdDate: string;
  image: string;
  subTitle: string;
  timeRead: number;
  content: string;
  articleType: string;
  title: string;
  userName: string;
  tags: string;
  id: string;
}

export interface FeatureArticles {
  latest: FeaturedMedia[];
  video: FeaturedMedia[];
  media: FeaturedMedia[];
  blog: FeaturedMedia[];
  other: FeaturedMedia[];
}

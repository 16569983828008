<div class="information">
    <div class="container">
        <div class="information-inner">
            <div class="info-link" *ngFor="let item of learningHub | slice:0:itemsToShow">
             <div class="link-ref" (click)="open(item)">
                <div class="image-container">
                    <img [src]="item.imgae" alt="Card image" />
                </div>
                <div class="content learning">
                    <h4>{{ item.title }}</h4>
                    <button>{{ item.type }}</button>
                    <p class="hub-text"> {{ item.description }}</p>
                </div>
            </div>
            </div>
        </div>
        <div class="load-btn"> 
        <button class="load-more" *ngIf="itemsToShow < learningHub.length" (click)="showMore()">Load more resources</button>
    </div>
    </div>
</div>

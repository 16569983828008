<mat-slider [disableRipple]="true" [disabled]="isFileNotes || isAppComplete">
  <input matSliderThumb [min]="min" [max]="max" [formControl]="sliderControl"/>
</mat-slider>
<div class="form-group" [ngClass]="{'disabled-select': isFileNotes || isAppComplete}">
  <div class="form-field-wrapper">
    <mat-label>{{ respondent1 || 'Respondent 1' }}</mat-label>

    <mat-form-field
      appearance="outline"
      class="small w-full"
      subscriptSizing="dynamic"
    >
      <input
        #inputRespondent1Element
        type="text"
        matInput
        mask="000"
        suffix="%"
        [validation]="false"
        [ngModel]="form.get('respondent1')!.value"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onRespondent1Changed($event)"
      />
    </mat-form-field>
  </div>

  <div class="form-field-wrapper">
    <mat-label>{{ respondent2 || 'Respondent 2' }}</mat-label>

    <mat-form-field
      appearance="outline"
      class="small w-full"
      subscriptSizing="dynamic"
    >
      <input
        #inputRespondent2Element
        type="text"
        matInput
        mask="000"
        suffix="%"
        [validation]="false"
        [ngModel]="form.get('respondent2')!.value"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onRespondent2Changed($event)"
      />
    </mat-form-field>
  </div>
</div>

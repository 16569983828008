<button
  mat-flat-button
  color="primary"
  [style.pointer-events]="disabled ? 'none' : ''"
  (click)="onClick()"
>
  <ng-container [ngSwitch]="loadingStatus">
    <ng-container *ngSwitchCase="'loading'">
      {{ loadingLabel }}
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      {{ successLabel }}
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-content></ng-content>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!loadingStatus">
    <ng-content select="[icon-button]"></ng-content>
  </ng-container>

  <mat-icon iconPositionEnd *ngIf="loadingStatus">
    <mat-spinner
      diameter="24"
      *ngIf="loadingStatus === 'loading'"
    ></mat-spinner>

    <ng-container *ngIf="loadingStatus === 'success'">check</ng-container>
  </mat-icon>
</button>

import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, noop, switchMap, throwError } from 'rxjs';

import { LocalStorageService } from '@acorn/data-access';

import { environment } from '@env';

import { SecurityService } from '../data-access';

import { STORAGE_KEY } from './constants';

import { AuthInfo } from './interfaces';

export const securityInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const securityService = inject(SecurityService);

  const authInfo = localStorageService.getData<AuthInfo>(STORAGE_KEY.authInfo);

  if (!authInfo) {
    router.navigate(['/unauthorized']).then(noop);

    return throwError(null);
  }

  const { token } = authInfo;

  const modifiedReq = req.clone({
    withCredentials: true,
    headers: req.headers
      .set('Authorization', `Bearer ${token}`)
      .set('Ocp-Apim-Subscription-Key', environment.subscriptionKey),
  });

  return next(modifiedReq).pipe(
    catchError((response) => {
      if (response.status === 401) {
        return securityService.refreshToken(token, authInfo.refreshToken).pipe(
          switchMap((refreshTokenResponse) => {
            localStorageService.saveData(
              STORAGE_KEY.authInfo,
              JSON.stringify({
                token: refreshTokenResponse.data.token,
                refreshToken: refreshTokenResponse.data.refreshToken,
              })
            );

            const modifiedReq = req.clone({
              withCredentials: true,
              headers: req.headers
                .set(
                  'Authorization',
                  `Bearer ${refreshTokenResponse.data.token}`
                )
                .set('Ocp-Apim-Subscription-Key', environment.subscriptionKey),
            });

            return next(modifiedReq);
          }),
          catchError((error) => {
            securityService.handleRemoveToken();
            securityService.isAuthenticated.set(false);
            router.navigate(['/auth/sign-in']).then(noop);

            return throwError(error);
          })
        );
      }

      if (response.status === 403) {
        securityService.handleRemoveToken();
        securityService.isAuthenticated.set(false);
        router.navigate(['/unauthorized']).then(noop);
      }

      return throwError(response);
    })
  );
};

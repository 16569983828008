import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { NavigationComponent } from '@acorn/common-ui';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { GoalPrioritiserExerciseComponent } from '../../../feature-your-goals/ui';
import {
  VerticalStepperComponent,
  NestedVerticalStepperComponent,
  WelcomeScreenComponent,
} from '../../../ui';
import { GetPartStructurePipe } from '../../../util/pipes/get-part-structure.pipe';
import { ConfirmAndPrioritiseComponent } from '../confirm-and-prioritise';
import { GoalMetricsComponent } from '../goal-metrics';
import {
  Application,
  ApplicationGoalSettings,
  ApplicationStatus,
  ApplicationStep,
  ApplicationUser,
  Goal,
  GoalSettingPartsStep,
  GoalSettingStep,
  NextGoalStepAction,
  WEBHOOK_STATUS,
} from '@acorn/util';
import { GoalPart, GoalStepStructure } from '../../../util';
import { STEP_STRUCTURE } from '../utlis/step-structure.const';
import { cloneDeep, first } from 'lodash';
import { GoalSettingsComponent } from '../goal-settings/goal-settings.component';
import {
  bindingStepStructureStatus,
  isNeedToUpdateCurrentProcess,
} from '../utlis/helper';
import {
  ApplicationAnswerService,
  ApplicationGoalService,
  ApplicationService,
} from '@acorn/data-access';
import { da } from 'date-fns/locale';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'acorn-your-goals',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => VerticalStepperComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => NestedVerticalStepperComponent),
    CdkStepperModule,
    GoalPrioritiserExerciseComponent,
    ConfirmAndPrioritiseComponent,
    IconSpriteModule,
    VerticalStepperComponent,
    GoalMetricsComponent,
    NavigationComponent,
    WelcomeScreenComponent,
    GetPartStructurePipe,
    GoalSettingsComponent,
  ],
  templateUrl: './your-goals.component.html',
  styleUrls: ['./your-goals.component.scss'],
})
export class YourGoalsComponent implements OnInit {
  readonly GoalStep = GoalSettingStep;
  @Output() backClicked = new EventEmitter<void>();
  @Output() triggerWebhook = new EventEmitter<string>();

  #applicationService = inject(ApplicationService);
  #applicationGoalService = inject(ApplicationGoalService);
  #viewport = inject(ViewportScroller);
  #applicationAnswerService = inject(ApplicationAnswerService);

  @ViewChild('cdkStepper', { static: true })
  cdkStepper!: CdkStepper;
  @ViewChild(NestedVerticalStepperComponent, { static: true })
  cdkSubStepper!: CdkStepper;

  @Input() isWelcomeBackEditable: boolean = false;

  stepStructure: any[] = cloneDeep(STEP_STRUCTURE);

  stepStructures = signal<GoalStepStructure[]>(cloneDeep(STEP_STRUCTURE));
  currentStepStructure = signal<GoalStepStructure | null>(null);
  currentPartStructure = signal<GoalPart | null>(null);

  goalsTemplate = signal<Goal[]>([]);
  selectedGoals = signal<string[]>([]);
  #currentProgress:
    | {
        roundStep: GoalSettingStep;
        partStep: GoalSettingPartsStep;
      }
    | undefined;
  #currentApplicationUserId: any;

  @Input({ required: true }) currentApplication!: Application;

  @Input() isFileNotes: boolean = false;

  @Input({ required: true }) set applicationGoal(
    value: ApplicationGoalSettings
  ) {
    this.#applicationGoal = value;

    if(this.currentApplication?.isLatestGoalBeingApplied && this.currentApplication?.status ===
      ApplicationStatus.Active || this.currentApplication?.status ===
      ApplicationStatus.Complete){
      this.#bindingApplicationStatus();
    }else{
      if (this.goalStep === GoalSettingStep.GoalSetting  && this.currentApplication?.isLatestGoalBeingApplied == null) {
        const initialIndex = this.stepStructure.findIndex(
          (step) => step.id === GoalSettingStep.GoalSetting
        );
        if (initialIndex !== -1) {
          this.cdkStepper.selectedIndex = initialIndex;
          this.currentStepStructure.set(this.stepStructure[initialIndex]);
          this.currentPartStructure.set(
            this.stepStructure[initialIndex]?.parts[0] || null
          );
        }
      }
    }
  }

  @Input() applicationUsers: ApplicationUser[] = [];
  @Input() isDashboardApp: boolean = false;

  @Output() finishedStep = new EventEmitter<{
    isUpdateApplicationProgress: boolean;
  }>();

  @Input({ required: true }) goalStep: GoalSettingStep =
    GoalSettingStep.GoalSetting;

  @Input({ required: true }) set partStep(value: GoalSettingPartsStep | null) {
    this.currentPartStep = value;
    this.goalCurrentPartstep.set(value);
    this.#bindingCurrentStructure();
  }

  currentPartStep!: GoalSettingPartsStep | null;
  #applicationGoal!: ApplicationGoalSettings;

  goalCurrentPartstep = signal<GoalSettingPartsStep | null>(null);

  @Output() updatedProgress = new EventEmitter<{
    roundStep: GoalSettingStep;
    partStep: GoalSettingPartsStep;
  }>();

  @Output() updatedGoalProgress = new EventEmitter<{
    roundStep: GoalSettingStep;
    partStep: GoalSettingPartsStep;
  }>();

  get applicationGoal() {
    return this.#applicationGoal;
  }

  onSelectPartChanged(
    selectedIndex: number,
    parentStepId: GoalSettingStep
  ): void {
    if (parentStepId !== this.currentStepStructure()?.id) {
      return;
    }

    const updatedPart = this.currentStepStructure()!.parts[selectedIndex];

    this.currentPartStructure.set(updatedPart);
    this.calculateGoals();
  }

  onSelectStepChanged(selectedIndex: number): void {
    const updatedStep = this.stepStructure[selectedIndex];

    if (updatedStep) {
      this.currentStepStructure.set(updatedStep);
      this.currentPartStructure.set(updatedStep.parts[0] || null);
    } else {
      this.currentStepStructure.set(null);
      this.currentPartStructure.set(null);
    }
    this.calculateGoals();
  }

  onHandleNext(value: any): void {
    this.applicationGoal.applicationGoals = value?.applicationGoals;

    const currentStep = this.currentStepStructure()?.id;

    if (currentStep === GoalSettingStep.RoundVerify) {
      if (this.currentPartStructure()?.isLastPart) {
        const roundVerifyStepIndex = this.stepStructure.findIndex(
          (step) => step.id === GoalSettingStep.RoundVerify
        );

        if (roundVerifyStepIndex !== -1) {
          this.stepStructure[roundVerifyStepIndex].state = 'done';
        }
      }

      const goalMetricsStepIndex = this.stepStructure.findIndex(
        (step) => step.id === GoalSettingStep.GoalMetrics
      );

      if (goalMetricsStepIndex !== -1) {
        this.cdkStepper.selectedIndex = goalMetricsStepIndex;
        this.currentStepStructure.set(this.stepStructure[goalMetricsStepIndex]);
        this.currentPartStructure.set(
          this.stepStructure[goalMetricsStepIndex].parts[0] || null
        );
      }
    }
    this.calculateGoals();

    this.stepStructures.set(cloneDeep(this.stepStructure));
    this.#updateGoalStep(
      GoalSettingStep.GoalMetrics,
      GoalSettingPartsStep.DefineYourMetrics
    );

    this.#bindingApplicationStatus();
  }

  onFinishStep(): void {
    this.triggerWebhook.emit(WEBHOOK_STATUS.about_you_and_financial_situation);
    this.#bindingApplicationStatus();
    this.finishedStep.emit({ isUpdateApplicationProgress: true });
  }

  onHandleBack() {
    const currentStep = this.currentStepStructure()?.id;

    if (currentStep === GoalSettingStep.RoundVerify) {
      const goalSettingStepIndex = this.stepStructure.findIndex(
        (step) => step.id === GoalSettingStep.GoalSetting
      );

      if (goalSettingStepIndex !== -1) {
        this.cdkStepper.selectedIndex = goalSettingStepIndex;
        this.currentStepStructure.set(this.stepStructure[goalSettingStepIndex]);
        this.currentPartStructure.set(
          this.stepStructure[goalSettingStepIndex].parts[0] || null
        );
      }
    } else if (currentStep === GoalSettingStep.GoalMetrics) {
      const roundVerifyStepIndex = this.stepStructure.findIndex(
        (step) => step.id === GoalSettingStep.RoundVerify
      );
      if (roundVerifyStepIndex !== -1) {
        this.cdkStepper.selectedIndex = roundVerifyStepIndex;
        this.currentStepStructure.set(this.stepStructure[roundVerifyStepIndex]);
        this.currentPartStructure.set(
          this.stepStructure[roundVerifyStepIndex].parts[0] || null
        );
      }
    }
    this.calculateGoals();
  }

  onFinishGoalSettings(value: NextGoalStepAction) {
    this.applicationGoal.applicationGoals = value?.applicationGoals;

    if (this.currentPartStructure()?.isLastPart) {
      this.stepStructure[this.currentStepStructure()!.order - 1].state = 'done';

      let updatedStepStructure =
        this.stepStructure[this.currentStepStructure()!.order];
      if (value.nextGoalStep) {
        updatedStepStructure = this.stepStructure.find(
          (it) => it.id === value.nextGoalStep
        )!;
      }

      this.currentStepStructure.set(updatedStepStructure);
      this.currentPartStructure.set(updatedStepStructure.parts[0]);
      this.calculateGoals();
      this.#updateGoalStep(
        GoalSettingStep.RoundVerify,
        GoalSettingPartsStep.YourTop5
      );
      this.#bindingApplicationStatus();
      return;
    }

    if (this.currentStepStructure() && this.currentPartStructure()) {
      this.stepStructure[this.currentStepStructure()!.order - 1].parts[
        this.currentPartStructure()!.order - 1
      ].state = 'done';

      const updatedPart =
        this.currentStepStructure()!.parts[this.currentPartStructure()!.order];

      this.stepStructures.set(cloneDeep(this.stepStructure));
      this.currentPartStructure.set(updatedPart);
      this.#bindingApplicationStatus();
    }
    this.calculateGoals();
  }

  calculateGoals() {
    this.#scrollToTop();

    if (this.currentStepStructure()?.id === GoalSettingStep.RoundVerify) {
      const verifiedGoals =
        this.applicationGoal?.applicationGoals[GoalSettingStep.RoundVerify];
      const notVerifiedGoals =
        this.applicationGoal?.applicationGoals[GoalSettingStep.GoalSetting];

      const selectedGoals =
        verifiedGoals?.length >=
        this.currentStepStructure()!.minimumRequiredGoals
          ? verifiedGoals
          : notVerifiedGoals;

      const goals = selectedGoals
        ?.map((id) =>
          this.applicationGoal?.originalGoals.find((goal) => goal?.id === id)
        )
        .filter((goal): goal is Goal => goal !== undefined && goal !== null);

      this.goalsTemplate.set(goals);

      return;
    }
  }

  ngOnInit(): void {
    if(this.currentApplication?.isLatestGoalBeingApplied && this.currentApplication?.status ===
      ApplicationStatus.Active || this.currentApplication?.status ===
      ApplicationStatus.Complete){
      this.#bindingApplicationStatus();
    }

    this.#getApplicationUserId();

    if (this.isWelcomeBackEditable) {
      this.onSelectStepChanged(0);
    }

    if (this.goalStep === GoalSettingStep.GoalSetting) {
      const initialIndex = this.stepStructure.findIndex(
        (step) => step.id === GoalSettingStep.GoalSetting
      );
      if (initialIndex !== -1) {
        this.cdkStepper.selectedIndex = initialIndex;
        this.currentStepStructure.set(this.stepStructure[initialIndex]);
        this.currentPartStructure.set(
          this.stepStructure[initialIndex]?.parts[0] || null
        );
      }
    }
  }

  #bindingApplicationStatus(): void {
    this.#getApplicationUserId();
    for (const step of this.stepStructure) {
      const selectedGoalIds =
        this.applicationGoal?.applicationGoals[step.id as GoalSettingStep];

      const stepStructure = this.stepStructure.find((s) => s.id === step.id)!;

      if (stepStructure?.id === GoalSettingStep.GoalMetrics) {
        if (this.#currentApplicationUserId) {
          combineLatest([
            this.#applicationGoalService.getCurrentQuestions(),
            this.#applicationAnswerService.getApplicationAnswer(
              this.#currentApplicationUserId
            ),
          ]).subscribe(([questionsResponse, answersResponse]) => {
            const answerGoalIds = answersResponse.data.map(
              (answer: { goalId: any }) => answer.goalId
            );

            const relevantGoalIds = questionsResponse.data
              .filter((item: { questions: any[] }) => item.questions.length > 0)
              .map((item: { goalId: any }) => item.goalId);

            const result = relevantGoalIds.map((goalId: any) => {
              return answerGoalIds.includes(goalId);
            });

            if (result.length > 0) {
              const allTrue = result.every((value: boolean) => value === true);

              if (allTrue) {
                stepStructure.state = 'done';
              } else {
                stepStructure.state = '';
              }

              const defineYourMetricsPart = stepStructure.parts.find(
                (part: { id: GoalSettingPartsStep }) =>
                  part.id === GoalSettingPartsStep.DefineYourMetrics
              );

              if (defineYourMetricsPart) {
                defineYourMetricsPart.state = allTrue ? 'done' : '';
              }
            }

            this.stepStructure.forEach((step: GoalStepStructure) => {
              const selectedGoalIds =
                this.applicationGoal?.applicationGoals[
                  step.id as GoalSettingStep
                ];
              if (selectedGoalIds) {
                bindingStepStructureStatus(
                  step,
                  selectedGoalIds.length,
                  this.currentPartStep,
                  this.currentStepStructure()
                );
              }
            });

            this.calculateGoals();
            this.stepStructures.set(cloneDeep(this.stepStructure));
          });
          return;
        }
      }

      // if (selectedGoalIds) {
      //   bindingStepStructureStatus(
      //     step,
      //     selectedGoalIds.length,
      //     this.currentPartStep,
      //     this.currentStepStructure()
      //   );
      // }
    }

    this.calculateGoals();
    this.stepStructures.set(cloneDeep(this.stepStructure));
  }

  #bindingCurrentStructure(): void {
    this.calculateGoals();

    const currentStepStructure = this.stepStructure.find(
      (step) => step.id === this.goalStep
    );

    if (!currentStepStructure) {
      return;
    }

    this.currentStepStructure.set(currentStepStructure);
    const currentPartStructure = this.currentStepStructure()?.parts.find(
      (part) => part.id === this.currentPartStep
    );

    if (!currentPartStructure) {
      return;
    }

    this.currentPartStructure.set(currentPartStructure);

    this.calculateGoals();
    this.#bindingApplicationStatus();
  }

  onBack(): void {
    this.backClicked.emit();
    this.#scrollToTop();
  }

  #updateGoalStep(
    goalStep: GoalSettingStep,
    partStep: GoalSettingPartsStep
  ): void {
    if(this.isFileNotes){
      return
    }

    const currentRoundStep = goalStep;
    const currentPartStep = partStep;

    if (
      !isNeedToUpdateCurrentProcess(
        this.#currentProgress!,
        this.currentStepStructure()!,
        this.currentPartStructure()!,
        this.isWelcomeBackEditable
      )
    ) {
      return;
    }

    this.#applicationService
      .updateCurrentProgress(
        ApplicationStep.Goal,
        currentRoundStep,
        currentPartStep
      )
      .subscribe({
        next: (value) => {
          const data = value.data;
          this.#currentProgress = {
            roundStep: <GoalSettingStep>data.currentSubStep,
            partStep: data.currentPartStep,
          };
          this.updatedProgress.emit(this.#currentProgress);
        },
      });
  }

  #scrollToTop(): void {
    this.#viewport.scrollToPosition([0, 0]);
  }

  #getApplicationUserId(): void {
    const applicationUserId = first(this.applicationUsers)?.id;

    if (!applicationUserId) {
      return;
    }

    this.#currentApplicationUserId = applicationUserId;
  }

  updateCurrentProgress($event: any) {
    this.updatedProgress.emit($event);
    //   this.#applicationService
    //   .updateCurrentProgress(
    //     ApplicationStep.Goal,
    //     $event.roundStep,
    //     $event.partStep
    //   )
    //   .subscribe({
    //     next: (value) => {
    //       const data = value.data;
    //       this.#currentProgress = {
    //         roundStep: <GoalSettingStep>data.currentSubStep,
    //         partStep: data.currentPartStep,
    //       };
    //       this.updatedProgress.emit(this.#currentProgress);
    //     },
    //   });
  }
}

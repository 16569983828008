<div class="container is-fluid gradient-bg pd-40">
  <div class="container">
    <div class="row">
      <div class="columns">
        <div class="col-25"></div>
        <div class="col-50">
          <h3>Welcome back</h3>
          <p>
            Based on your Wealth Maximiser profile answers, your risk tolerance
            appears to be the following, consider this as you review your Wealth
            Strategy Options on the next screen:
          </p>
          <div class="riskprofile">
            <div class="title" *ngIf="value">{{ value }}</div>
            <h3 *ngIf="!this.value">Loading...</h3>
            <div class="progress-pills" *ngIf="value">
              <ul>
                <li [ngClass]="{'fill': getRiskLevel(value) >= 1}"></li>
                <li [ngClass]="{'fill': getRiskLevel(value) >= 2}"></li>
                <li [ngClass]="{'fill': getRiskLevel(value) >= 3}"></li>
                <li></li>
              </ul>
            </div>
            <div>
              <p>{{ selectedRiskDescription }}</p>
            </div>
          </div>
        </div>
        <div class="col-25"></div>
      </div>
    </div>
  </div>
</div>
<div class="container is-fluid white-bg pd-40">
  <div class="container">
    <div class="row">
      <div class="columns">
        <div class="col-25"></div>
        <div class="col-50">
          <div class="wealth-strategies">
            <h3>Indicative risk return profile of wealth strategy options</h3>
            <p>
              Different wealth strategies focus on investing in one or different
              combinations of financial assets. Therefore each strategy includes
              a level of potential risk, depending on which assets it focuses
              on.
              <br /><br />
              Here's a chart showing the seven overall investment strategies
              that Wealth Maximiser considers before presenting you with three
              relevant options based on your goals and risk profile. This
              visualisation is indicative only and places each strategy on a
              risk-return spectrum, allowing you to see at a glance where each
              one stands in terms of its potential risk and corresponding
              return. You should consider your risk profile, the risk profile of
              different assets and the potential risk of the strategy overall
              when selecting your wealth strategy,
            </p>
            <img src="assets/images/chart.png" />
          </div>
        </div>
        <div class="col-25"></div>
      </div>
    </div>
  </div>
</div>

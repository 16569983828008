import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-reflection-activity-dialog',
  templateUrl: 'reflection-activity-dialog.component.html',
  styleUrls: ['reflection-activity-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    IconSpriteModule,
    MatDialogModule,
    MatDividerModule,
  ],
})
export class ReflectionActivityDialogComponent {
  @Input() timer: number = 3;

  #dialogRef = inject(MatDialogRef<ReflectionActivityDialogComponent>);

  chips: string[] = [
    'Creativity',
    'Environment',
    'Family',
    'Friends',
    'Fun',
    'Growth',
    'Mindfulness',
    'Open-mindedness',
    'Safety',
    'Self-reliance',
    'Status',
    'Truth-seeking',
    'Wealth',
    'Health',
    'Independence',
    'Legacy',
    'Minimalism',
  ];

  close(): void {
    this.#dialogRef.close();
  }
}

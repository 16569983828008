import { MembershipType } from '@acorn/util';

export interface MembershipPlanEntryResponse {
  id: string;
  name: MembershipType;
  description: string;
  actionList: ActionMembershipPlanEntry[];
  featureImage: {
    fields: {
      file: {
        url: string;
      };
    };
  };
  scheduleTitle: string;
}

export interface MembershipPlanEntry {
  id: string;
  name: MembershipType;
  description: string;
  actionList: ActionMembershipPlan[];
  featureImage: string;
  scheduleTitle: string;
}

export interface ActionMembershipPlanEntry {
  fields: {
    title: string;
    description: string;
  };
}

export interface ActionMembershipPlan {
  title: string;
  description: string;
}

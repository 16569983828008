<div class="profile-wrap">
    <div class="container-main">
        <div class="profile">
            <h1>Your profile</h1>
            <div class="top-profile d-flex">
                <div class="profile-text">
                    <h2>Welcome {{ userName() }}</h2>
                    <p *ngIf="!areAllStepsCompleted(steps)" [innerHTML]="this.text"></p>
                </div>
                <div class="profile-img" *ngIf="!areAllStepsCompleted(steps)">
                    <img
                        class="img-profile"
                        *ngIf="this.currentUser?.currentApplication !== null"
                        src="assets/fqimages/user-profile.png"
                        alt="profile"
                    />
                    <img *ngIf="this.currentUser?.currentApplication === null" src="assets/fqimages/user-profile.svg" alt="profile" />
                </div>
            </div>

            <div class="profile-section">
                <div class="profile-tab" *ngFor="let step of steps; let i = index">
                    <div
                        class="profile-box d-flex"
                        [class.profile-box-full]="step.children.length > 0"
                        [ngClass]="{
                            'fully-completed': (visibleStates[i] && isFullyCompleted(i, step)) || (visibleStates[i] && isFinancialFullyCompleted(i, step)) || (visibleStates[i] && isWealthandRiskCompleted(i,step)),
                        }"
                    >
                        <div
                            class="profile-box-main d-flex"
                            [ngClass]="step.status"
                            (click)="toggleVisibility(i)"
                            [class.hide-content]="visibleStates[i]"
                        >
                            <div class="line-bar" *ngIf="step.children.length <= 0">
                                <div class="line-percentange" [ngStyle]="{ width: step.percentage + '%' }"></div>
                            </div>
                            <div class="profile-inner" *ngIf="step.children.length <= 0">
                                <img [src]="'assets/fqimages/' + step.imagePath" alt="{{ step.stepTitle }}" />
                            </div>
                            <div class="prof-box-info" *ngIf="step.children.length <= 0">
                                <p class="time">{{ step.minute }} mins</p>
                                <h3>{{ step.title }}</h3>
                                <p>{{ step.description }}</p>
                                <a class="link-btn" [ngClass]="step.status" (click)="start(step.stepTitle)">{{ step.buttonText }}</a>
                            </div>
                            <div class="lock-num" *ngIf="step.children.length <= 0">
                                <h5>{{ i + 1 }}</h5>
                            </div>
                            <div class="line-bar" *ngIf="step.children.length > 0">
                                <div class="line-percentange" [ngStyle]="{ width: step.percentage + '%' }"></div>
                            </div>
                            <div class="profile-inner" *ngIf="step.children.length > 0">
                                <img src="assets/fqimages/financial-snapshot.svg" alt="about-you" />
                            </div>
                            <div class="prof-main-inner" *ngIf="step.children.length > 0">
                                <div class="prof-box-info">
                                    <p class="time">{{ step.minute }} mins</p>
                                    <h3>{{ step.title }}</h3>
                                    <p>{{ step.description }}</p>
                                </div>
                                <div
                                    class="financial-col"
                                    [ngClass]="{
                                    'remove-flex': (visibleStates[i] && isFullyCompleted(i, step)) || (visibleStates[i] && isFinancialFullyCompleted(i, step)) || (visibleStates[i] && isWealthandRiskCompleted(i,step)),
                                }"
                                >
                                    <div
                                        class="financial-inner d-flex"
                                        *ngFor="let child of step.children"
                                        [ngClass]="{
                                        'remove-bottom': (visibleStates[i] && isFullyCompleted(i, step)) || (visibleStates[i] && isFinancialFullyCompleted(i, step)) || (visibleStates[i] && isWealthandRiskCompleted(i,step)),
                                    }"
                                    >
                                        <div class="profile-box-col d-flex overlay-dark" [ngClass]="child.status">
                                            <div class="line-bar">
                                                <div class="line-percentange" [ngStyle]="{ width: child.percentage + '%' }"></div>
                                            </div>
                                            <div class="prof-main-inner">
                                                <div class="prof-box-info">
                                                    <div class="income-text d-flex">
                                                        <h3>{{ child.title }}</h3>
                                                        <p class="time">{{ child.minute }} mins</p>
                                                    </div>
                                                    <a
                                                        class="link-btn btn-sm"
                                                        [ngClass]="child.status"
                                                        (click)="start(step.stepTitle, child.stepTitle)"
                                                        >{{ child.buttonText }}</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="review-col">
                                            <a class="review-btn" (click)="start(step.stepTitle, child.stepTitle)">
                                                Review {{ child.title }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lock-num" *ngIf="step.children.length > 0">
                                <h5>{{ i + 1 }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-tab" style="width: 100% !important; margin-top: 20px !important">
                <div class="profile-box profile-box-last">
                    <div class="profile-box-main d-flex">
                        <div class="profile-inner">
                            <img src="assets/fqimages/action.svg" alt="about-you" />
                        </div>
                        <div class="prof-box-info">
                            <p>Next step</p>
                            <h3>Get your action plan</h3>
                            <ul class="list-text">
                                <li>3 personalised financial strategies to choose from</li>
                                <li>Book a session with a Wealth Coach</li>
                                <li>Recieve your personalised 12 month action plan</li>
                            </ul>
                            <div class="plan-btn-wrap">
                                <a
                                    class="link-btn btn-sm get-my-plan"
                                    *ngIf="
                                    (isAllStepsCompleted || areAllStepsCompleted(steps)) &&
                                    !(isFileNotes && isDashboardApp) &&
                                    this.currentUser?.currentSubscription === null
                                "
                                    (click)="getMyPlan()"
                                    >Get my plan! <img src="assets/fqimages/arrow-right.svg"
                                /></a>
                            </div>

                            <div class="btn-grp">
                                <button
                                    *ngIf="showStep(ApplicationStep.FinancialProjection)"
                                    class="step-btn btn-sm"
                                    (click)="start(ApplicationStep.FinancialProjection, null)"
                                >
                                    Financial Projection
                                </button>
                                <button
                                    *ngIf="showStep(ApplicationStep.WealthStrategyOptions)"
                                    class="step-btn btn-sm"
                                    (click)="start(ApplicationStep.WealthStrategyOptions)"
                                >
                                    Wealth Strategy Options
                                </button>
                                <button
                                    *ngIf="showStep(ApplicationStep.WealthCoach)"
                                    class="step-btn btn-sm"
                                    (click)="start(ApplicationStep.WealthCoach)"
                                >
                                    Wealth Coach
                                </button>
                                <button
                                    *ngIf="showStep(ApplicationStep.Dashboard)"
                                    class="step-btn btn-sm"
                                    (click)="start(ApplicationStep.Dashboard)"
                                >
                                    Dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

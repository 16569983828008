import { CustomToastComponent } from '@acorn/common-ui';
import {
  LocalStorageService,
  UserService,
  UserSubscriptionPlanService,
} from '@acorn/data-access';
import { provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { provideEffects } from '@ngrx/effects';

import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideToastr } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { appRoutes } from './app.routes';
import { SecurityService } from './common/data-access';
import { appInitializerFactory } from './common/utils/factories/app-initializer.factory';
import { appFeature, ConfirmFsgStore, SecurityEffect } from './store';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        SecurityService,
        LocalStorageService,
        UserService,
        ConfirmFsgStore,
        UserSubscriptionPlanService,
      ],
      useFactory:
        (
          securityService: SecurityService,
          localStorageService: LocalStorageService,
          userService: UserService,
          confirmFsgStore: ConfirmFsgStore,
          userSubscriptionPlanService: UserSubscriptionPlanService
        ) =>
        (): void | Observable<unknown> =>
          appInitializerFactory(
            securityService,
            localStorageService,
            userService,
            confirmFsgStore,
            userSubscriptionPlanService
          ),
    },
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      IconSpriteModule.forRoot({
        path: 'assets/sprites/sprite.svg',
      }),
      BrowserAnimationsModule
    ),
    provideAnimations(),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideStore(),
    provideHttpClient(),
    provideEffects([SecurityEffect]),
    provideState(appFeature),
    provideToastr({
      toastComponent: CustomToastComponent,
    }),
    provideStoreDevtools(),
  ],
};

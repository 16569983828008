import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedMedia } from '@acorn/util';

import { CategoryBoxComponent } from '../category-box';

@Component({
  selector: 'acorn-media-card',
  standalone: true,
  imports: [CommonModule, CategoryBoxComponent],
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCardComponent {
  @Input() data!: FeaturedMedia;
  @Input() isShowTime = true;
  @Input() imgClass?: string;
  @Input() imgHeight?: number;
  @Input() imgWidth?: number;
}

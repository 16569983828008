import { createFeature, createReducer, on } from '@ngrx/store';

import { SecurityActions } from './security.actions';

interface State {
  isAuthenticated: boolean;
  userId: string | null;
  userProfile: unknown;
}

const initialState: State = {
  isAuthenticated: false,
  userId: null,
  userProfile: null,
};

export const appFeature = createFeature({
  name: 'app',
  reducer: createReducer(
    initialState,
    on(SecurityActions.signInSuccess, (state) => ({
      ...state,
      isAuthenticated: true,
    }))
  ),
});

export const { selectIsAuthenticated } = appFeature;

<div class="header">
  <ng-container *ngIf="!countScheduleTime">
    <h1>Book your Wealth Coach</h1>
    <p>{{ scheduleTitle }}</p>
  </ng-container>

  <ng-container *ngIf="countScheduleTime">
    <h1>Welcome back!</h1>
    <p>It’s time to schedule your next Wealth Coach check-in</p>
  </ng-container>
</div>

<div #calendarElem class="widget" [ngClass]="{'disabled-select': isFileNotes}"></div>

<button [matMenuTriggerFor]="!isMobile ? menu : null" (click)="shareIOS()">
  <span>Share</span>

  <svg-icon-sprite
    [src]="'assets/sprites/sprite.svg#share'"
    [width]="'24px'"
  ></svg-icon-sprite>
</button>

<mat-menu #menu="matMenu" class="social-menu" [hasBackdrop]="true">
  <div class="menu-panel-content-wrapper">
    <button mat-menu-item [cdkCopyToClipboard]="location.href">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#link'"
        [width]="'24px'"
      ></svg-icon-sprite>

      Copy link
    </button>

    <a
      mat-menu-item
      [href]="
        'http://twitter.com/share?text=Im Sharing on Twitter&url=' +
        location.href
      "
      target="_blank"
    >
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#twitter'"
        [width]="'24px'"
      ></svg-icon-sprite>

      Share on X
    </a>

    <a
      mat-menu-item
      [href]="'https://www.facebook.com/sharer/sharer.php?u=' + location.href"
      target="_blank"
    >
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#facebook'"
        [width]="'24px'"
      ></svg-icon-sprite>

      Share on Facebook
    </a>

    <a
      mat-menu-item
      [href]="
        'https://www.linkedin.com/sharing/share-offsite/?url=https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/'
      "
      target="_blank"
    >
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#linkedin'"
        [width]="'24px'"
      ></svg-icon-sprite>

      Share on LinkedIn
    </a>
  </div>
</mat-menu>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import { CategoryStatusInputModel, CommonResponse } from '@acorn/util';

@Injectable()
export class CategoryService {
  #httpClient = inject(HttpClient);

  updateStatusCategory(
    applicationId: string,
    categoryStatusInputModels: CategoryStatusInputModel[]
  ) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.customer}/api/Category/status`,
      {
        applicationId,
        updateCategoryStatusInputModels: categoryStatusInputModels,
      }
    );
  }
}

import { AboutYouQuestionType } from '@acorn/util';
import {
  KNOCKOUT_MESSAGES_QUESTION_TYPE_MAP,
  KNOCKOUT_MESSAGES_QUESTION_TYPE_MAP_FOR_DUAL_RESPONDENTS,
  WARNING_MESSAGE_QUESTION_TYPE_MAP,
} from './constants';

import { ModalType } from './enum/modal-type.enum';

export function getMessageBaseOnQuestionType(
  type: AboutYouQuestionType,
  modalType: ModalType,
  isDualRespondent = false
): string | undefined {
  if (isDualRespondent) {
    return modalType === ModalType.Warning
      ? WARNING_MESSAGE_QUESTION_TYPE_MAP.get(type)
      : KNOCKOUT_MESSAGES_QUESTION_TYPE_MAP_FOR_DUAL_RESPONDENTS.get(type);
  } else {
    return modalType === ModalType.Warning
      ? WARNING_MESSAGE_QUESTION_TYPE_MAP.get(type)
      : KNOCKOUT_MESSAGES_QUESTION_TYPE_MAP.get(type);
  }
}

<div
  class="goal-setting-container"
  *ngIf="!goalStep()!"
  (window:beforeunload)="onBeforeUnload($event)"
>
  <div class="d-flex align-center choose-wrap">
    <h2>Choose your goals</h2>
    <div class="chat-btn d-flex align-center" (click)="chatWithWealthCoach()">
      <img src="assets/images/support-icon.svg" />
      <span>Chat with a Wealth Coach</span>
    </div>
  </div>
  <p>
    Choose a minimum of 5 goals across our 4 wealth focus areas. For best
    results, we recommend including a mix of short and long term goals.
  </p>

  <div class="progress-bar-container goal-m-30">
    <div class="progress-bar-content">
      <div class="goals-selected"><p>Goals Selected</p></div>
      <div class="progress-bar">
        <div class="progress-bar-steps">
          <div
            *ngFor="let step of Goals; let i = index"
            class="progress-bar-step"
            [ngClass]="{
              active: i < selectedGoals,
              'last-active': i === selectedGoals - 1 && selectedGoals > 0
            }"
          >
            {{ step }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <acorn-alert
    *ngIf="errorMessage()"
    type="error"
    [ngClass]="{ 'mb-2': errorMessage() }"
  >
    <b>{{ errorMessage() }}</b>
  </acorn-alert>

  <div class="wealth-type-container">
    <div
      *ngFor="let step of WealthType; let i = index"
      [class.active]="i === currentStepIndex"
      (click)="currentStepIndex = i"
      class="wealth-type"
      [ngClass]="{
        'lifestyle-development': step.label === 'Lifestyle & development'
      }"
    >
      <img
        [src]="step.icon"
        alt="{{ step.label }}"
        width="70px"
        height="70px"
      />
      <span>{{ step.label }}</span>
    </div>
  </div>

  <ng-container *ngIf="WealthType[currentStepIndex]">
    <acorn-goals-step
      [step]="WealthType[currentStepIndex]"
      (selectionChange)="selectionChange()"
      [goalLength]="selectedGoals"
      [isFileNotes]="isFileNotes"
    ></acorn-goals-step>
  </ng-container>

  <div class="tooltip-container" [class.show]="showTooltip">
    <div class="tooltip-content">
      <div class="d-flex tooltip-note">
        <span class="icon"><img src="/assets/svg/warning.svg" /></span>
        <span class="tooltip-text"
          >You are required to choose a minimum of 5 goals to create your
          financial plan. Please choose additional goals to proceed to the next
          stage.
        </span>
      </div>
      <div class="d-flex image-center">
        <button class="close-btn" (click)="this.showTooltip = false">
          Close
        </button>
      </div>
    </div>
  </div>

  <div class="goal-note">
    <p
      class="goal-m-15"
      style="cursor: pointer"
      (click)="openReflectionPopup()"
    >
      Discover more about your core values and help realise your goals here.
    </p>
  </div>

  <div class="navigation-buttons">
    <acorn-application-navigation
      [primaryButtonLabel]="
        WealthType[currentStepIndex].isLastStep ? 'Finish' : 'Next'
      "
      (next)="
        WealthType[currentStepIndex].isLastStep ? onFinish() : onHandleNext()
      "
      (back)="onHandleBack()"
      [isLoading]="isLoading()"
      [customBackClass]="'goal-back'"
      [customNextClass]="'goal-next'"
    ></acorn-application-navigation>
  </div>
</div>

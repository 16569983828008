import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, noop, of, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import {
  LocalStorageService,
  UserSubscriptionPlanService,
} from '@acorn/data-access';

import { SecurityActions } from './security.actions';

import { SecurityService } from '../common/data-access';

import { AuthInfo, STORAGE_KEY } from '../common/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SecurityEffect {
  #actions = inject(Actions);

  #securityService = inject(SecurityService);
  #router = inject(Router);
  #ngxLoader = inject(NgxUiLoaderService);
  #localStorageService = inject(LocalStorageService);
  #userSubscriptionPlanService = inject(UserSubscriptionPlanService);

  signInSuccess$ = createEffect(() =>
    this.#actions.pipe(
      ofType(SecurityActions.signInSuccess),
      tap(({ token, refreshToken }) => {
        this.#localStorageService.saveData(
          STORAGE_KEY.authInfo,
          JSON.stringify({ token, refreshToken })
        );

        return;
      })
    )
  );

  signInFailure$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(SecurityActions.signInFailure),
        tap(() => {
          this.#router.navigate(['/unauthorized']).then(noop);
        })
      ),
    { dispatch: false }
  );

  signOut$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(SecurityActions.signOut),
        tap(() => {
          this.#ngxLoader.start();

          const authInfo = this.#localStorageService.getData<AuthInfo>(
            STORAGE_KEY.authInfo
          );

          if (!authInfo?.token) {
            this.#router.navigate(['/auth/sign-in']).then(noop);
            return;
          }

          this.#securityService
            .signOut(authInfo.token)
            .pipe(
              map(() => {
                this.#securityService.handleRemoveToken();
                this.#userSubscriptionPlanService.reset();
                this.#router.navigate(['/auth/sign-in']).then(noop);
              }),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  this.#userSubscriptionPlanService.reset();
                  this.#securityService.handleRemoveToken();
                  this.#router.navigate(['/auth/sign-in']).then(noop);
                }

                return of(SecurityActions.signOutFailure());
              })
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );
}

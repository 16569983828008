<div class="goal-metrics">
  <div class="header">
    <h4>{{ round.label }}</h4>

    <h1>{{ round?.parts?.[0]?.label }}</h1>

    <p>
      {{ round?.parts?.[0]?.description }}
    </p>
  </div>

  <acorn-alert *ngIf="formRecord.invalid && isSubmitted()" type="error">
    <b>To proceed, {{ errorMessages().length }} changes need to be made</b>

    <ul *ngFor="let message of errorMessages()">
      <li>{{ message }}</li>
    </ul>
  </acorn-alert>

  <acorn-alert *ngIf="serverErrorMessage()" type="error">
    <b>{{ serverErrorMessage() }}</b>
  </acorn-alert>

  <form
    *ngIf="formRecord && renderedForm() && goalMetricsQuestions().length"
    class="goal-questions"
    [formGroup]="formRecord"
  >
    <div
      *ngFor="let goal of goalMetricsQuestions(); let i = index"
      class="goal-card"
    >
      <div class="title">
        <div class="number">{{ i + 1 }}</div>
        <div class="explainer-wrap">
          <h4>
            {{ goal.goalName }}
          </h4>
          <svg-icon-sprite
            *ngIf="goal.explainer"
            acornClickableTooltip
            [tooltipMessage]="goal.explainer"
            [src]="'assets/sprites/sprite.svg#information-line'"
            [width]="'20px'"
          ></svg-icon-sprite>
        </div>
      </div>

      <div *ngIf="goal.questions.length" class="content">
        <div class="question" *ngFor="let question of goal.questions">
          <ng-container
            *ngIf="
              question.isNumberOfItemQuestion && question.totalGroups;
              else singleControl
            "
          >
            <ng-container
              *ngFor="
                let group of question.totalGroups;
                index as index;
                last as last
              "
            >
              <div class="sub-question">
                <span>{{ question.content }} {{ index + 1 }}</span>

                <button
                  *ngIf="index !== 0"
                  type="button"
                  mat-stroked-button
                  class="remove-button"
                  (click)="onDeleteSubQuestion(goal.goalId, question, index)"
                >
                  <div class="button-content-wrapper">
                    Remove

                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#delete-bin'"
                      [width]="'24px'"
                    ></svg-icon-sprite>
                  </div>
                </button>
              </div>

              <ng-container
                *ngFor="let subQuestion of question.childrenQuestions"
              >
                <div>{{ subQuestion.content }}</div>

                <ng-container [ngSwitch]="subQuestion.answerType">
                  <ng-container *ngSwitchCase="AnswerType.Amount">
                    <mat-form-field
                      class="small"
                      appearance="outline"
                      subscriptSizing="dynamic"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                    >
                      <span matPrefix>$</span>

                      <input
                        type="text"
                        matInput
                        mask="separator"
                        thousandSeparator=","
                        placeholder="Amount"
                        [errorStateMatcher]="matcher"
                        [formControl]="
                          formRecord.controls[goal.goalId].controls[
                            subQuestion.id + '-' + index
                          ]
                        "
                      />

                      <mat-error>
                        <ng-container
                          *ngTemplateOutlet="
                            requiredError;
                            context: { $implicit: 'Amount' }
                          "
                        ></ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="AnswerType.Number">
                    <mat-form-field
                      class="small"
                      appearance="outline"
                      subscriptSizing="dynamic"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                    >
                      <input
                        type="text"
                        matInput
                        mask="0*"
                        placeholder="Number"
                        [errorStateMatcher]="matcher"
                        [disabled]="isFileNotes"
                        [formControl]="
                          formRecord.controls[goal.goalId].controls[
                            subQuestion.id + '-' + index
                          ]
                        "
                      />

                      <mat-error>
                        <ng-container
                          *ngTemplateOutlet="
                            requiredError;
                            context: { $implicit: 'Number' }
                          "
                        ></ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="AnswerType.Percent">
                    <mat-form-field
                      class="small"
                      appearance="outline"
                      subscriptSizing="dynamic"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                    >
                      <span matPrefix>%</span>

                      <input
                        type="text"
                        matInput
                        mask="0*"
                        placeholder="Percent"
                        [errorStateMatcher]="matcher"
                        [disabled]="isFileNotes"
                        [formControl]="
                          formRecord.controls[goal.goalId].controls[
                            subQuestion.id + '-' + index
                          ]
                        "
                      />

                      <mat-error>
                        <ng-container
                          *ngTemplateOutlet="
                            requiredError;
                            context: { $implicit: 'Percent' }
                          "
                        ></ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="AnswerType.Year">
                    <mat-form-field
                      class="small"
                      appearance="outline"
                      subscriptSizing="dynamic"
                      [ngClass]="{ 'disabled-select': isFileNotes }"
                    >
                      <input
                        matInput
                        placeholder="Year"
                        mask="0000"
                        [errorStateMatcher]="matcher"
                        [disabled]="isFileNotes"
                        [formControl]="
                          formRecord.controls[goal.goalId].controls[
                            subQuestion.id + '-' + index
                          ]
                        "
                      />

                      <mat-error>
                        <ng-container
                          *ngTemplateOutlet="
                            yearError;
                            context: {
                              goal,
                              controlId: subQuestion.id + '-' + index
                            }
                          "
                        ></ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>

              <mat-divider *ngIf="!last" class="question-divider"></mat-divider>
            </ng-container>

            <div class="add-button">
              <mat-divider></mat-divider>

              <button
                mat-stroked-button
                type="button"
                [disabled]="question.totalGroups.length >= 3"
                (click)="
                  addMoreQuestion(formRecord.controls[goal.goalId], question)
                "
              >
                Add {{ question.content }}
                <mat-icon iconPositionEnd>add</mat-icon>
              </button>
            </div>
          </ng-container>

          <!-- single control template -->
          <ng-template #singleControl>
            <div>
              {{ question.content }}
            </div>

            <ng-container [ngSwitch]="question.answerType">
              <ng-container *ngSwitchCase="AnswerType.Amount">
                <mat-form-field
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{ 'disabled-select': isFileNotes }"
                >
                  <span matPrefix>$</span>

                  <input
                    type="text"
                    matInput
                    mask="separator"
                    thousandSeparator=","
                    placeholder="Amount"
                    required
                    [errorStateMatcher]="matcher"
                    [disabled]="isFileNotes"
                    [formControl]="
                      formRecord.controls[goal.goalId].controls[question.id]
                    "
                  />

                  <mat-error>
                    <ng-container
                      *ngTemplateOutlet="
                        requiredError;
                        context: { $implicit: 'Amount' }
                      "
                    ></ng-container>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="AnswerType.Number">
                <mat-form-field
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{ 'disabled-select': isFileNotes }"
                >
                  <input
                    type="text"
                    matInput
                    mask="0*"
                    placeholder="Number"
                    [errorStateMatcher]="matcher"
                    [disabled]="isFileNotes"
                    [formControl]="
                      formRecord.controls[goal.goalId].controls[question.id]
                    "
                  />

                  <mat-error>
                    <ng-container
                      *ngTemplateOutlet="
                        requiredError;
                        context: { $implicit: 'Number' }
                      "
                    ></ng-container>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="AnswerType.Percent">
                <mat-form-field
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{ 'disabled-select': isFileNotes }"
                >
                  <span matPrefix>%</span>

                  <input
                    type="text"
                    matInput
                    mask="0*"
                    placeholder="Percent"
                    [errorStateMatcher]="matcher"
                    [disabled]="isFileNotes"
                    [formControl]="
                      formRecord.controls[goal.goalId].controls[question.id]
                    "
                  />

                  <mat-error>
                    <ng-container
                      *ngTemplateOutlet="
                        requiredError;
                        context: { $implicit: 'Percent' }
                      "
                    ></ng-container>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="AnswerType.Year">
                <mat-form-field
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{ 'disabled-select': isFileNotes }"
                >
                  <input
                    matInput
                    type="text"
                    placeholder="Year"
                    mask="0000"
                    [validation]="false"
                    [errorStateMatcher]="matcher"
                    [disabled]="isFileNotes"
                    [formControl]="
                      formRecord.controls[goal.goalId].controls[question.id]
                    "
                  />

                  <mat-error class="year-error">
                    <ng-container
                      *ngTemplateOutlet="
                        yearError;
                        context: { goal, controlId: question.id }
                      "
                    ></ng-container>
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </form>
</div>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="backClicked.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>

<ng-template #yearError let-goal="goal" let-controlId="controlId">
  <svg-icon-sprite
    [src]="'assets/sprites/sprite.svg#exclamation-solid'"
    [width]="'24px'"
  ></svg-icon-sprite>

  <ng-container
    *ngIf="
      formRecord.controls[goal.goalId].controls[controlId].hasError('required')
    "
  >
    Year is required
  </ng-container>

  <ng-container
    *ngIf="
    formRecord.controls[goal.goalId].controls[controlId].hasError('invalidYear') &&
    formRecord.controls[goal.goalId].controls[controlId].errors?.['invalidYear']
  "
  >
    {{ formRecord.controls[goal.goalId].controls[controlId].errors?.['invalidYear'].message }}
  </ng-container>

  <ng-container
    *ngIf="
      formRecord.controls[goal.goalId].controls[controlId].hasError('pastYear')
    "
  >
    Year must be not in the past</ng-container
  >
</ng-template>

<ng-template #requiredError let-requiredLabel>
  <svg-icon-sprite
    [src]="'assets/sprites/sprite.svg#exclamation-solid'"
    [width]="'24px'"
  ></svg-icon-sprite>

  {{ requiredLabel }} is required
</ng-template>

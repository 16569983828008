import { CommonResponse, Feedback } from '@acorn/util';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { map, Observable } from 'rxjs';

@Injectable()
export class ExternalApplicationService {
  #httpClient = inject(HttpClient);

  getFeeFoStat(): Observable<number> {
    return this.#httpClient
      .get<CommonResponse<{ rating: { rating: number } }>>(
        `${environment.apiUrls.customer}/api/FeeFo/Summary`,
        {
          headers: {
            'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
          },
        }
      )
      .pipe(map((res) => res.data.rating.rating));
  }

  getFeeFoFeedbacks(): Observable<Feedback[]> {
    return this.#httpClient
      .get<CommonResponse<{ reviews: Feedback[] }>>(
        `${environment.apiUrls.customer}/api/FeeFo`,
        {
          headers: {
            'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
          },
        }
      )
      .pipe(map((res) => res.data.reviews));
  }
}

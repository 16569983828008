<section class="header">
  <img
    ngSrc="assets/images/wealth-coach-success.svg"
    alt=""
    height="241"
    width="320"
  />

  <h1>You’re all set!</h1>

  <div>
    <p>Thanks for taking the time to complete your profile.</p>
    <p>Here’s what happens next: </p>
  </div>
</section>

<section class="point">
  <!--<ul class="content" *ngFor="let point of actionList; index as index">
    <div class="order">{{ index + 1 }}</div>
    <li>
      <h3>{{ point.title }}</h3>
      <p>{{ point.description }}</p>
    </li>
  </ul>-->
  <ul class="content" >
    <div class="order">1</div>
    <li>
      <h3>Receive your strategies</h3>
      <p>Within 5 business days you will receive your Top 3 Wealth Strategies to choose from - 
        this will be available online and via email. Look out for an email from us when this is ready</p>
    </li>
  </ul>

  <ul class="content" >
    <div class="order">2</div>
    <li>
      <h3>Enjoy your Wealth Coach strategy session</h3>
      <p>Attend your Wealth Coach strategy session to decide which strategy to follow and understand your action plan</p>
    </li>
  </ul>

  <ul class="content" >
    <div class="order">3</div>
    <li>
      <h3>Receive your action plan</h3>
      <p>Receive your chosen strategy Statement of Advice including year long plan and first 90 days breakdown</p>
    </li>
  </ul>

  <ul class="content" >
    <div class="order">4</div>
    <li>
      <h3>Take action</h3>
      <p>Follow your action plan to maximise your Wealth and achieve your goals!</p>
    </li>
  </ul>

  <ul class="content" >
    <div class="order">5</div>
    <li>
      <h3>Check-in with your Wealth Coach</h3>
      <p>We will email you when it’s time to schedule your next Wealth Coach check-in.</p>
    </li>
  </ul>
</section>

<section *ngIf="isAbleToUpgrade" class="access">
  <img src="assets/images/glossy.png" alt="" />
  <div>
    <h3>Wealth Coach access</h3>
    <p>Talk through strategies and action plan with a Wealth Coach by upgrading your plan</p>
  </div>

  <button
    mat-flat-button
    color="primary"
    (click)="gotoPricingPage()"
  >
    Upgrade my plan
  </button>
</section>

<section class="watch">
  <iframe
    *ngIf="videoUrl()"
    width="180px"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    mozallowfullscreen
    webkitallowfullscreen
    allowfullscreen
    [src]="videoUrl() | safe"
  >
  </iframe>

  <p>WATCH: Meet the Wealth Coaches</p>
</section>

<!--<section class="feature feature-done">
  <img [src]="featureImageUrl" alt="feature" />
</section>-->

<section class="alert">
  <p>
    At Wealth Maximiser, we hold a unique wealth philosophy 
    that serves as our guide when shaping the wealth strategy 
    options we present to you for consideration.
  </p>

  <button
    mat-flat-button
    class="fit-content"
    (click)="goToWealthPhilosophy()"
    [disabled]="isFileNotes"
  >
    <div class="button-content-wrapper">
      <span>Learn more about our Wealth Philosophy</span>

      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#arrow-right-up-line'"
        [width]="'24px'"
      ></svg-icon-sprite>
    </div>
  </button>
</section>
<acorn-application-navigation
    *ngIf="isFileNotes === false && (this.currentUser?.currentApplication?.status === ApplicationStatus.Complete && this.currentUser?.currentApplication?.isAllStrategiesApproved === true && this.currentUser?.currentApplication?.goalApprovedDate && !recalculateAfterEdit)"
    class="acron-navigation white-bg"
    [backButtonEnabled]="false"
    (next)="gotoDashboard()"
></acorn-application-navigation>

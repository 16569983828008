import {
  Category,
  CategoryCode,
  FinancialSuperannuation,
  QuestionType,
} from '@acorn/util';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSingleQuestionExpand',
  standalone: true,
})
export class IsSingleQuestionExpandPipe implements PipeTransform {
  transform(
    value: unknown,
    selectedCategory: Category | null,
    questionType?: QuestionType | undefined
  ): boolean {
    return (
      value === FinancialSuperannuation.Included ||
      !!(
        selectedCategory &&
        [
          CategoryCode.PrimaryIncome,
          CategoryCode.Savings,
          CategoryCode.Property,
          CategoryCode.FinancialInvestments,
          CategoryCode.RealEstate,
        ].includes(selectedCategory.categoryCode)
      ) ||
      !!(
        questionType &&
        [QuestionType.Superannuation, QuestionType.MotorVehicle].includes(
          questionType
        )
      )
    );
  }
}

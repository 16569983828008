<h1>Risk Tolerance outcome</h1>
<p>
    Based on your Wealth Maximiser profile answers, your risk tolerance appears to be the following, consider this as you start to think
    about your strategy options to build wealth moving forward.
</p>
<div class="riskprofile">
    <div class="title" *ngIf="value">{{ value }}</div>
    <div *ngIf="!value"><p>Risk not found</p></div>
    <h3 *ngIf="this.loading">Loading...</h3>
    <div class="progress-pills" *ngIf="value">
        <ul>
            <li [ngClass]="{ fill: getRiskLevel(value) >= 1 }"></li>
            <li [ngClass]="{ fill: getRiskLevel(value) >= 2 }"></li>
            <li [ngClass]="{ fill: getRiskLevel(value) >= 3 }"></li>
            <li></li>
        </ul>
    </div>
    <div>
        <p>{{ selectedRiskDescription }}</p>
        <p class="mt-2">If you would like to understand more about this speak to a Wealth Coach.</p>
    </div>
</div>

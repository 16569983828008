import { FormControl } from '@angular/forms';
import {
  FinancialFrequency,
  FinancialSuperannuation,
  FinancialTaxStatus,
} from '@acorn/util';
import { FinancialTemplateType } from './template-type.enum';
import { ApplicationUserFormTemplate } from '@acorn/feature-application';

export interface FinancialAnswerForm {
  financialQuestionId: FormControl<string>;
}

export interface AmountForm extends FinancialAnswerForm {
  answerContent: FormControl<string | null>;
  superannuation: FormControl<FinancialSuperannuation | null>;
  taxStatus: FormControl<FinancialTaxStatus | null>;
  frequency: FormControl<FinancialFrequency | null>;
}

export interface FormTemplate extends ApplicationUserFormTemplate {
  questions: {
    [key: string]: FinancialTemplateType;
  };
}

export interface PopulatedField {
  parentQuestionId?: string;
  questionId: string;
  listenFieldChanged: string;
  conditionPopulated: string;
  populateValueFrom: string;
}

export interface ReadonlyField {
  parentQuestionId?: string;
  questionId: string;
  condition: string;
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconSpriteModule],
})
export class AlertComponent implements OnInit {
  @Input() type: 'warning' | 'error' = 'warning';

  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesService {
  private hasUnsavedChangesSubject = new BehaviorSubject<boolean>(false);
  hasUnsavedChanges$ = this.hasUnsavedChangesSubject.asObservable();

  setUnsavedChanges(status: boolean): void {
    this.hasUnsavedChangesSubject.next(status);
  }

  get hasUnsavedChanges(): boolean {
    return this.hasUnsavedChangesSubject.value;
  }
}

import {
  AnswerSelection,
  AnswerType,
  ApplicationUser,
  DisplayMode,
  DisplayUsersNamePipe,
  ERROR_KEY,
  ERROR_MESSAGE,
  FinancialQuestion,
  ScreenSize,
} from '@acorn/util';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormRecord, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { omit, values } from 'lodash';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { debounceTime } from 'rxjs';

import {
  getRequiredToOtherQuestionsByKeys,
  handleLiabilityRealEstateChange,
} from '../../utils';
import { SingleQuestionComponent } from '../single-question';

@Component({
  selector: 'acorn-group-question',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    SingleQuestionComponent,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    IconSpriteModule,
    DisplayUsersNamePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
  templateUrl: './group-question.component.html',
  styleUrls: ['./group-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupQuestionComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #cdRef = inject(ChangeDetectorRef);

  @HostBinding('class.dual-respondent')
  @Input()
  isDualRespondent? = false;

  @Input()
  hasDivider = true;

  @Input()
  index = 0;

  @HostBinding('class.disabled-expansion')
  get isDisabledExpansion() {
    return this.index > 0;
  }

  @Input({ required: true }) question!: FinancialQuestion;
  @Input({ required: true }) formRecord!: FormRecord<any>;
  @Input() warningMessage: Map<string, string> | undefined;
  @Input() answerSelections: AnswerSelection[] = [];
  @Input() isSubmitted = false;
  @Input() applicationUsers: ApplicationUser[] = [];
  @Input() applicationKey!: string;
  @Input() isExpand = false;
  @Input() errorMessage: Map<string, string> | undefined;
  @Input() isFileNotes : boolean =  false;
  @Input() isAppComplete = false;

  @Output() removeClicked = new EventEmitter<void>();
  @Output() removeWarningMessage = new EventEmitter<{
    applicationKey: string;
    id: string;
  }>();

  protected readonly ScreenSize = ScreenSize;
  protected readonly AnswerType = AnswerType;
  protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

  get isShownErrorMessage(): boolean {
    if (this.question.childrenQuestions?.length && this.errorMessage?.size) {
      const hasError = this.question.childrenQuestions.some((it) =>
        this.errorMessage?.has(it.id)
      );
      if (hasError) {
        return true;
      }
    }

    return (
      this.isSubmitted &&
      this.formRecord.hasError(ERROR_KEY.ALL_FIELDS_REQUIRED)
    );
  }

  ngOnInit(): void {
    this.#expandPanelWhenEnteredValue();
    this.#handleListenToRealEstateChange();
  }

  get isDisplayedMergedQuestion(): boolean {
    return (
      this.question.displaymode === DisplayMode.Merged &&
      this.applicationUsers?.length === 2
    );
  }

  public onRemoveWarningMessage(value: {
    applicationKey: string;
    id: string;
  }): void {
    this.removeWarningMessage.emit(value);
  }

  #expandPanelWhenEnteredValue(): void {
    const formValue = this.formRecord.value;
    const countEnteredValue = Object.values(formValue)
      .map((it) => values(omit(it, ['financialQuestionId'])).filter(Boolean))
      .reduce((prev, next) => prev + next.length, 0);
    if (countEnteredValue) {
      this.isExpand = true;
    }
  }

  #handleListenToRealEstateChange(): void {
    this.formRecord.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.#destroyRef))
      .subscribe((dataChange) => {
        if (!this.question.childrenQuestions?.length) return;

        const requiredToOtherRealEstateQuestions =
          getRequiredToOtherQuestionsByKeys(
            ['isRequiredToInterestRate', 'isRequiredToRepaymentStructure'],
            this.question.childrenQuestions
          );

        if (requiredToOtherRealEstateQuestions.length) {
          handleLiabilityRealEstateChange(
            this.question.childrenQuestions,
            requiredToOtherRealEstateQuestions,
            dataChange,
            this.formRecord
          );
        }
        this.#cdRef.detectChanges();
      });
  }
}

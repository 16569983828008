<div class="container">
  <h4>401</h4>

  <h1>Unauthorized</h1>

  <p class="message">Your account is pending. Contact to customer to activate.</p>

  <button mat-flat-button color="primary" (click)="onBackToSignIn()">
    Go back sign-in
  </button>
</div>

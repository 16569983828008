import { Pipe, PipeTransform } from '@angular/core';

import { CitizenshipStatus } from '../enum';

@Pipe({
  name: 'citizenshipStatus',
  standalone: true,
})
export class CitizenshipStatusPipe implements PipeTransform {
  transform(value: CitizenshipStatus) {
    switch (value) {
      case CitizenshipStatus.PermanentResidentWithAllFinancial: {
        return 'Australian citizen/permanent resident with all financial assets in Australia';
      }

      case CitizenshipStatus.PermanentResidentWithSomeFinancial: {
        return 'Australian citizen/permanent resident with some financial assets in another country';
      }

      case CitizenshipStatus.TemporaryVisaHolder: {
        return 'Temporary visa holder';
      }

      default: {
        return '';
      }
    }
  }
}

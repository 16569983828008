import { AnswerType, KOQuestionType, AboutYouQuestionType } from '../enum';

export interface AboutYouQuestion {
  id: string;
  questionId: string;
  isMultiAnswerQuestion: boolean;
  isRequired: boolean;
  multiAnswerQuestionTitle: string;
  order: number;
  defaultValue: string;
  aboutYouCategory: {
    childItems: unknown[];
    description: string;
    id: string;
    isDualRespondent: boolean;
    name: string;
    order: number;
    parentId: string;
  };
  question: {
    answerType: AnswerType;
    childQuestions: unknown[];
    subContent?: string;
    content: string;
    description: string;
    id: string;
    isKOQuestion: boolean;
    koQuestionType: KOQuestionType;
    koQuestionValue: string;
    isNumberOfItemQuestion: boolean;
    parentId: string;
    questionAnswerSelections: unknown;
    questionType: AboutYouQuestionType;
  };
}

export interface AboutYouUserAnswer {
  applicationUserId: string;
  answers: AboutYouAnswer[];
  isKOQuestion?: boolean;
  isShowWarning?: boolean;
}

export interface AboutYouAnswer {
  order: number;
  content: string;
  questionId: string;
  isRequired: boolean;
  isMultiAnswerQuestion?: boolean;
  multiAnswerItems?: {
    order: number;
    content: string;
  }[];
}

export interface ApplicationAboutYouResponse {
  applicationUserId: string;
  answers: AboutYouAnswerResponse[];
}

export interface AboutYouAnswerResponse {
  questionId: string;
  answerContent: string;
  applicationAnswerId: string;
  order: number;
  isMultiAnswerQuestion?: boolean;
  multiAnswerItems?: {
    order: number;
    content: string;
  }[];
}

<div class="card">
  <div class="header">
    <h4>{{ parentCategory.name }}</h4>

    <h1>{{ currentCategory.name }}</h1>

    <p>Add any dependants you have (optional)</p>
  </div>

  <acorn-alert *ngIf="formRecord.invalid && isSubmitted()" type="error">
    <p class="alert-title">
      To proceed, {{ displayedTotalMessages() }} to be made
    </p>

    <ul *ngFor="let message of errorMessages()" class="alert-messages">
      <li>{{ message }}</li>
    </ul>
  </acorn-alert>

  <acorn-alert *ngIf="serverErrorMessage()" type="error">
    <b>{{ serverErrorMessage() }}</b>
  </acorn-alert>

  <div class="content">
    <mat-accordion
      *ngFor="let item of formRecord.controls | keyvalue : applicationUserOrder"
      multi
    >
      <mat-expansion-panel
        [expanded]="true"
        [disabled]="layoutService.screenSize() !== ScreenSize.Mobile"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ formTemplate[item.key].applicationUserName }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          *ngFor="let formArray of item.value.controls | keyvalue: dependantOrder"
          class="sub-card"
        >
          <p
            class="label"
            acornTooltip
            [tooltipMessage]="
              formTemplate[item.key].questions[formArray.key].description
            "
          >
            {{
              formTemplate
                | acornDisplayDependantTitle : item.key : formArray.key!
            }}
          </p>

          <div
            *ngFor="
              let formControl of formArray.value.controls;
              let index = index
            "
            class="question"
          >
            <div class="title">
              <p>
                {{
                  formTemplate
                    | acornDisplayDependantName : item.key : formArray.key!
                }}
                {{ index + 1 }}
              </p>

              <button
                mat-stroked-button
                class="remove-button"
                (click)="onRemove(item.key, formArray.key!, index)"
                [disabled]="isFileNotes || isAppComplete"
              >
                Remove
                <svg-icon-sprite
                  iconPositionEnd
                  [src]="'assets/sprites/sprite.svg#delete-bin'"
                  [width]="'24px'"
                ></svg-icon-sprite>
              </button>
            </div>

            <div class="input">
              <p>Age</p>

              <ng-container
                [ngSwitch]="
                  formTemplate[item.key].questions[formArray.key].answerType
                "
              >
                <mat-form-field
                  *ngSwitchCase="AnswerType.AdultAge"
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{'disabled-select': isFileNotes || isAppComplete}"
                 (mousedown)="isFileNotes || isAppComplete ? $event.preventDefault() : null"
                 [disabled]="isFileNotes || isAppComplete"
                >
                  <input
                    matInput
                    mask="0*"
                    type="text"
                    placeholder="Enter age"
                    [formControl]="formControl"
                    [disabled]="isFileNotes || isAppComplete"
                  />

                  <mat-error *ngIf="
                        isSubmitted() && formControl.hasError('invalidAge')
                      ">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#exclamation-solid'"
                      [width]="'24px'"
                    ></svg-icon-sprite>

                    Age is greater than or equal to 18 years
                  </mat-error>
                </mat-form-field>

                <mat-form-field
                  *ngSwitchCase="AnswerType.Age"
                  class="small"
                  appearance="outline"
                  subscriptSizing="dynamic"
                  [ngClass]="{'disabled-select': isFileNotes || isAppComplete}"
                  (mousedown)="isFileNotes || isAppComplete ? $event.preventDefault() : null"
                >
                  <input
                    type="text"
                    mask="0*"
                    placeholder="Enter age"
                    matInput
                    [formControl]="formControl"
                  />
                </mat-form-field>

                <ng-container *ngSwitchDefault>
                  <mat-form-field
                    class="small"
                    appearance="outline"
                    subscriptSizing="dynamic"
                    [ngClass]="{'disabled-select': isFileNotes || isAppComplete}"
                    (mousedown)="isFileNotes || isAppComplete ? $event.preventDefault() : null"
                  >
                    <mat-select
                      [hideSingleSelectionIndicator]="true"
                      [formControl]="formControl"
                      (selectionChange)="$event.source.close()"
                      [disabled]="isFileNotes || isAppComplete"
                    >
                      <mat-option
                        *ngFor="let age of AgeList"
                        [value]="age"
                        class="answer-option"
                        [disabled]="isFileNotes || isAppComplete"
                      >
                        {{ age }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </ng-container>
            </div>

            <mat-divider></mat-divider>
          </div>

          <button
            mat-stroked-button
            class="add-button"
            (click)="addMore(item.key, formArray.key!)"
            [disabled]="isFileNotes || isAppComplete"
          >
            Add
            <mat-icon iconPositionEnd>add</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>

import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { WealthStrategyOptionPopupComponent } from '../popup/popup.component';
import { assetMapping, StrategyImages } from '../../utils/wealth-helper';

@Component({
  selector: 'acorn-strategy-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './strategy-option.component.html',
  styleUrls: ['./strategy-option.component.scss'],
})
export class StrategyOptionComponent {
  @Input() public strategies: any;

  #dialog = inject(MatDialog);

  openPopup(strategy: any) {
    this.#dialog.open(WealthStrategyOptionPopupComponent, {
      width: '880px',
      height: '80vh',
      disableClose: true,
      hasBackdrop: true,
      data: {
        strategy: strategy
      },
    });
  }

  getImagePath(bb: string): string {
    const match = assetMapping.find((item) => item.title === bb);
    return match ? match.imagePath : '';
  }

  getStrategyImage(strategyName: string): string {
    const strategy = StrategyImages.find(s => s.title === strategyName);
    return strategy ? strategy.imagePath : 'assets/images/default-portfolio.svg';
  }

  formatStrategyOption(option: string): string {
    return option.replace(/(\d+)/, ' $1');
  }

  public getGoals(goals:Array<any>,value:boolean):Array<any>{
    return goals.filter(e=> e.isLikely === value);
    // return goals.sort((a, b) => b.isLikely - a.isLikely);
  }
}

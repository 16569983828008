<div mat-dialog-content>
  <div class="image-title">
    <div class="image-wrapper">
      <img
        src="assets/images/image-title.png"
        alt=""
      />
    </div>
  </div>

  <div class="content">
    <div class="text">
      <p>
        Reflecting on what matters most to you is the first step in defining
        your financial goals. Whether it's family, creativity, or self-reliance,
        understanding your values will guide your Wealth Maximiser Journey.
      </p>

      <p>
        When thinking about your goals, it's beneficial to consider your values
        as aligning them will help you live a happier life. If you choose goals
        that don’t align to your values you may feel unsatisfied.
      </p>
    </div>

    <div class="value">
      <div class="questions">
        <p>
          To understand your values, take a moment to ask yourself these three
          questions:
        </p>

        <p>
          What are the moments in life that bring you the greatest joy and
          fulfillment?
        </p>

        <p>
          When faced with a difficult decision, what principles guide your
          choices?
        </p>

        <p>Which aspects of your life would you never compromise, and why?</p>
      </div>

      <mat-divider></mat-divider>

      <p class="caption">
        Some common values that might impact your financial goals:
      </p>

      <div class="chip-list">
        <div class="chip" *ngFor="let chip of chips">{{ chip }}</div>
      </div>
    </div>
    <button mat-stroked-button (click)="close()">Close</button>
  </div>
</div>

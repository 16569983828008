<div class="container is-fluid popup pd-20">
    <div class="close-btn">
    <button class="icon-button close" (click)="onClose()">X</button>
</div>

    <div class="title">
        <img src="assets/images/diversified-portfolio.svg" />
        <h2>
            <span *ngIf="dialogData.isDashboard !== true">{{
                formatStrategyOption(
                    dialogData.strategy?.strategyOption ??
                        dialogData.strategyOption
                )
            }}</span
            >{{ dialogData.strategy.name }}
        </h2>
    </div>
    <div class="row">
        <div *ngIf="dialogData.isDashboard === true">
            <div class="columns actions">
                <button class="edit-button" (click)="downloadChosenSOA()" [disabled]="loading">
                    <ng-container *ngIf="!loading">Download a PDF of your Statement of Advice</ng-container>
                    <ng-container *ngIf="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </ng-container>
                  </button>
            </div>
        </div>
        <div class="columns">
            <div class="col-50">
                <p class="desktop-view">
                    {{ dialogData.strategy.longDescription }}
                </p>
                <p class="mobile-view">{{ dialogData.strategy.description }}</p>
            </div>
            <div class="col-50">
                <h5>Key Aspects</h5>
                <div class="key-list">
                    <ul>
                        <li>
                            Portfolio Type
                            <span class="tag">{{
                                dialogData.strategy.portfolioType
                            }}</span>
                        </li>
                        <li>
                            Likely Goals
                            <ul class="green-fill">
                                <li *ngFor="let goal of  getGoals(this.dialogData.strategy.goals,true)"  class="fill"  ></li>
                                <li *ngFor="let goal of  getGoals(this.dialogData.strategy.goals,false)" [class.fill]="goal.isLikely"></li>
                            </ul>
                        </li>
                        <li>
                            Potential Risk
                            <ul class="orang-fill">
                                <li
                                    [class.fill]="
                                        dialogData.strategy.risk >= 0.25
                                    "
                                ></li>
                                <li
                                    [class.fill]="
                                        dialogData.strategy.risk >= 0.5
                                    "
                                ></li>
                                <li
                                    [class.fill]="
                                        dialogData.strategy.risk >= 0.75
                                    "
                                ></li>
                                <li
                                    [class.fill]="dialogData.strategy.risk >= 1"
                                ></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-50 wealth-blocks">
                <h5>Primary Wealth Building Blocks</h5>

                <div class="blocks">
                    <div *ngFor="let bb of dialogData.strategy.primaryBBs">
                        <div>
                            <img [src]="getImagePath(bb)" alt="{{ bb }}" />
                        </div>
                        <div>{{ bb }}</div>
                    </div>
                </div>
            </div>
            <div class="col-50 goals-blocks">
                <h5>Goals</h5>
                <ul>
                    <li *ngFor="let goal of dialogData.strategy.goals" [ngClass]="goal.isLikely ? 'likely' : 'unlikely'">
                        {{ goal.goalName }}
                    </li>
                </ul>
            </div>
            <div class="col-50 actions">
                <h5 *ngIf="dialogData.isDashboard !== true">Example Actions</h5>
                <h5 *ngIf="dialogData.isDashboard === true">Example</h5>
                <p *ngIf="dialogData.isDashboard !== true">
                    By following this strategy, these are the typical actions
                    that you will likely complete at some point. They may or may
                    not be in your first 12 month action plan depending on your
                    current situation.
                </p>
                <ul *ngFor="let action of dialogData.strategy.exampleActions">
                    <li class="example">{{ action }}</li>
                </ul>
            </div>
            <div class="col-50 actions" *ngIf="dialogData.isDashboard !== true">
                <h5>Benefits</h5>
                <p>
                    This strategy can be effective when well implemented for the
                    following reasons.
                </p>
                <ul *ngFor="let benefit of dialogData.strategy.benefits">
                    <li
                        *ngIf="benefit.trim() !== ''"
                        [innerHTML]="benefit"
                    ></li>
                </ul>
            </div>
            <div class="col-50 actions" *ngIf="dialogData.isDashboard === true">
                <h5>Considerations About [x]</h5>
                <ul class="underline">
                    <li>What and Why</li>
                    <li>Definition</li>
                    <li>Key Risks</li>
                    <li>Considerations</li>
                    <li>Potential Costs</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row risk-row">
        <div class="columns actions risk-col">
            <div class="col-100">
                <h3 *ngIf="dialogData.isDashboard !== true">
                    Risk vs return profile
                </h3>
                <h3 *ngIf="dialogData.isDashboard === true">Risk</h3>
                <p *ngIf="dialogData.isDashboard !== true">
                    {{ dialogData.strategy.riskVsReturnProfile }}
                </p>
                <br />
                <h5 *ngIf="dialogData.isDashboard !== true">
                    {{ dialogData.strategy.riskDescription }}
                </h5>
            </div>
            <div class="col-5"></div>
            <div class="col-45 chart">
                <img
                    *ngIf="dialogData.strategy.riskDescription === 'Low'"
                    src="assets/images/chart.svg"
                    alt="Chart 1"
                />
                <img
                    *ngIf="dialogData.strategy.riskDescription === 'Medium'"
                    src="assets/images/chart2.svg"
                    alt="Chart 2"
                />
                <img
                    *ngIf="dialogData.strategy.riskDescription === 'High'"
                    src="assets/images/chart3.svg"
                    alt="Chart 3"
                />
            </div>
            <div class="col-5"></div>
            <div class="col-45">
                <ul
                    class="warning"
                    *ngFor="let risk of dialogData.strategy.risks"
                >
                    <li *ngIf="risk.trim() !== ''" [innerHTML]="risk"></li>
                </ul>
            </div>
        </div>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { StepState } from '@angular/cdk/stepper';

import { CategoryStatus } from '@acorn/util';

@Pipe({
  name: 'displayStepState',
  standalone: true,
})
export class StepStatePipe implements PipeTransform {
  transform(value: CategoryStatus): StepState {
    switch (value) {
      case CategoryStatus.Complete: {
        return 'done'
      }

      case CategoryStatus.InProgress: {
        return 'edit';
      }

      default: {
        return '';
      }
    }
  }
}

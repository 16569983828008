import { GoalStep, PartStep } from '@acorn/util';
import { StepStructure } from '../../util';

export const STEP_STRUCTURE: StepStructure[] = [
  {
    id: GoalStep.Round1,
    order: 1,
    state: 'number',
    label: 'Round 1',
    maximumRequiredGoals: 25,
    minimumRequiredGoals: 5,
    parts: [
      {
        id: PartStep.Part1,
        label: 'Part 1 – Goal Prioritiser',
        stepperLabel: 'Part 1',
        order: 1,
        state: 'number',
        isFirstPart: true,
        description:
          'Completing this Goal Prioritiser exercise is key to identifying your top five most important goals, forming the foundation of your Wealth Maximiser strategy and actions. This process involves multiple rounds of prioritisation. Dedicate a few minutes to each prioritisation stage, deeply contemplating what matters to you and your loved ones. This opportunity to reflect is rare, so please make the most out of it! Select the most appealing goals from each round. You can select up to 5 goals on each page.',
      },
      {
        id: PartStep.Part2,
        label: 'Part 2 – Goal Prioritiser',
        stepperLabel: 'Part 2',
        state: 'number',
        order: 2,
        description:
          'Continue selecting the most appealing goals from the list below.',
      },
      {
        id: PartStep.Part3,
        label: 'Part 3 – Goal Prioritiser',
        stepperLabel: 'Part 3',
        state: 'number',
        order: 3,
        description:
          'Continue selecting the most appealing goals from the list below.',
      },
      {
        id: PartStep.Part4,
        label: 'Part 4 – Goal Prioritiser',
        stepperLabel: 'Part 4',
        order: 4,
        state: 'number',
        description:
          'Good job, you have nearly finished round 1. Continue selecting the most appealing goals from the list below.',
      },
      {
        id: PartStep.Part5,
        label: 'Part 5 – Goal Prioritiser',
        stepperLabel: 'Part 5',
        state: 'number',
        order: 5,
        isLastPart: true,
        description:
          'Good job, you have nearly finished round 1. Continue selecting the most appealing goals from the list below.',
      },
    ],
  },
  {
    id: GoalStep.Round2,
    label: 'Round 2',
    order: 2,
    maximumRequiredGoals: 15,
    minimumRequiredGoals: 5,
    state: 'number',
    parts: [
      {
        id: PartStep.Part1,
        label: 'Part 1',
        state: 'number',
        isFirstPart: true,
        order: 1,
        description: `Now you have identified your top goals, it's time to begin trading them off. Select the most appealing from this shortlist`,
      },
      {
        id: PartStep.Part2,
        label: 'Part 2',
        state: 'number',
        order: 2,
        description: `Now you have identified your top goals, it's time to begin trading them off. Select the most appealing from this shortlist`,
      },
      {
        id: PartStep.Part3,
        label: 'Part 3',
        state: 'number',
        order: 3,
        isLastPart: true,
        description: `Now you have identified your top goals, it's time to begin trading them off. Select the most appealing from this shortlist`,
      },
    ],
  },
  {
    id: GoalStep.Round3,
    order: 3,
    state: 'number',
    label: 'Round 3',
    maximumRequiredGoals: 10,
    minimumRequiredGoals: 5,
    parts: [
      {
        id: PartStep.Part1,
        label: 'Part 1',
        state: 'number',
        order: 1,
        isFirstPart: true,
        description: `Now you have identified your top goals, it's time to identify your top goals. Select the 5 most appealing from this shortlist`,
      },
      {
        id: PartStep.Part2,
        label: 'Part 2',
        order: 2,
        isLastPart: true,
        state: 'number',
        description: `Now you have identified your top 15 goals, it's time to identify your top goals. Select the 5 most appealing from this shortlist`,
      },
    ],
  },
  {
    id: GoalStep.RoundVerify,
    order: 4,
    state: 'number',
    maximumRequiredGoals: 10,
    minimumRequiredGoals: 5,
    label: 'Confirm & Prioritise',
    parts: [
      {
        id: PartStep.YourTop5,
        label: 'Your top 5',
        order: 1,
        state: 'number',
        isFirstPart: true,
        isLastPart: true,
        description:
          'Congratulations on identifying your top 5 goals. Keep lower priority goals in mind as your priorities evolve. Review and rearrange your top 5 goals if necessary.',
      },
    ],
  },
  {
    id: GoalStep.GoalMetrics,
    order: 5,
    maximumRequiredGoals: 0,
    minimumRequiredGoals: 0,
    state: 'number',
    label: 'Goal Details',
    parts: [
      {
        id: PartStep.DefineYourMetrics,
        order: 1,
        label: 'Define your metrics',
        isLastPart: true,
        state: 'number',
        isFirstPart: true,
        description:
          "Now that your goals have been prioritised, let's make them actionable! Please provide specific details for each goal to create a clear roadmap toward success. If you are unsure, just include a reasonable estimate.",
      },
    ],
  },
];

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export function atLeastSelectedValidator(
  atLeast: number,
  maximum?: number
): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value?.length < atLeast) {
      return { invalidAtLeast: true };
    }

    if (!isNil(maximum) && control.value?.length > maximum) {
      return { invalidAtLeast: true };
    }

    return null;
  };
}

import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

@Pipe({
  name: 'headerTitle',
  standalone: true,
})
export class HeaderTitlePipe implements PipeTransform {
  transform(value: string) {
    return startCase(value);
  }
}

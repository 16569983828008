import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { STEP_STRUCTURE1 } from '../utlis/step-structure.const';
import { AlertComponent, NavigationComponent } from '@acorn/common-ui';
import { GoalsStepComponent } from '../goals-step/goals-step.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import {
  Application,
  ApplicationGoalSettings,
  ApplicationStatus,
  ApplicationStep,
  ApplicationUser,
  GoalPartStep,
  GoalSettingPartsStep,
  GoalSettingPartStep,
  GoalSettingStep,
  GoalStep,
  NextGoalStepAction,
} from '@acorn/util';
import { YourGoalsComponent } from '../yourGoals/your-goals.component';
import { MatDialog } from '@angular/material/dialog';
import { ReflectionPopupComponent } from '../ReflectionPopup/reflection-popup.component';
import { ApplicationGoalService, ApplicationService } from '@acorn/data-access';
import { CalendarpopupComponent } from '../Calendarpopup/calendarpopup.component';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UnsavedChangesService } from '../../data-access/unsaved-changes.service';
import { GoalStepStructure1 } from '../../../util';
import { GoalMaxCheckComponent } from '../goal-max-check/goal-max-check.component';
@Component({
  selector: 'acorn-goal-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    NavigationComponent,
    GoalsStepComponent,
    IconSpriteModule,
    YourGoalsComponent,
    ReflectionPopupComponent,
    AlertComponent,
  ],
  templateUrl: './goal-settings.component.html',
  styleUrls: ['./goal-settings.component.scss'],
})
export class GoalSettingsComponent implements OnInit, OnDestroy {
  @Output() nextClicked = new EventEmitter<boolean>();
  @Output() backClicked = new EventEmitter<boolean>();
  @Output() finish = new EventEmitter<NextGoalStepAction>();
  @Output() ChooseYourGoal = new EventEmitter<boolean>();

  #applicationGoalService = inject(ApplicationGoalService);
  #applicationService = inject(ApplicationService);

  GoalSettingStep = GoalSettingStep;
  Goals: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  filteredGoals: number[] = [];
  #viewport = inject(ViewportScroller);
  #dialog = inject(MatDialog);
  #unsavedChangesSvc = inject(UnsavedChangesService);

  totalGoals: number = this.Goals.length;
  WealthType = STEP_STRUCTURE1;
  currentStepIndex = 0;
  selectedGoals: number = 0;
  disable: boolean = false;
  showTooltip = false;
  goalPartStep: any;

  private _currentApplication!: Application;
  private isMobile: boolean = false;

  @Input() isAppComplete = false;

  @Input({ required: true }) set currentApplication(value: Application) {
    this._currentApplication = value;
  }

  @Input({ required: true }) set partStep(value: any) {
    if (
      this._currentApplication?.status === ApplicationStatus.Active &&
      this._currentApplication?.isLatestGoalBeingApplied !== true
    ) {
      this.currentStepIndex = 0;
      return;
    }

    // Setting Pat4 if we came back from Your Top 5
    if (
      (this._currentApplication?.currentStep !== ApplicationStep.Goal &&
        !value) ||
      this._currentApplication?.currentStep !== ApplicationStep.Goal ||
      this._currentApplication?.currentSubStep ===
        GoalSettingStep.RoundVerify ||
      this._currentApplication?.currentSubStep === GoalSettingStep.GoalMetrics
    ) {
      this.currentStepIndex = 3;
      this.goalPartStep = GoalSettingPartsStep.Part4;
      return;
    }

    if (value) {
      this.goalPartStep = value;

      switch (this.goalPartStep) {
        case GoalSettingPartsStep.Part1:
          this.currentStepIndex = 0;
          break;
        case GoalSettingPartsStep.Part2:
          this.currentStepIndex = 1;
          break;
        case GoalSettingPartsStep.Part3:
          this.currentStepIndex = 2;
          break;
        case GoalSettingPartsStep.Part4:
          this.currentStepIndex = 3;
          break;
        default:
          this.currentStepIndex = 0;
      }
    }
  }

  goalStep = signal<GoalSettingStep | null>(null);
  #currentProgress:
    | {
        roundStep: GoalSettingStep;
        partStep: GoalSettingPartsStep;
      }
    | undefined;

  public navigationSubscription!: Subscription;
  public hasNewGoals: boolean = false;
  public hasRemovedGoals: boolean = false;

  @Input({ required: true }) set applicationGoal(
    value: ApplicationGoalSettings
  ) {
    this.#applicationGoal = value;
    this.filterGoals();
  }

  @Output() updatedProgress = new EventEmitter<{
    roundStep: GoalSettingStep;
    partStep: GoalSettingPartsStep;
  }>();

  @Input() isFromStepper: boolean = false;

  @Input() applicationUsers: ApplicationUser[] = [];

  @Input() isFileNotes: boolean = false;

  get progress() {
    return (this.selectedGoals / this.totalGoals) * 100;
  }

  isLoading = signal<boolean>(false);
  errorMessage = signal<string>('');

  #applicationGoal!: ApplicationGoalSettings;

  get applicationGoal() {
    return this.#applicationGoal;
  }

  constructor(private router: Router) {
    this.isMobile = window.innerWidth <= 768;

    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url !== '/auth/sign-in') {
          if (this.hasNewGoals || this.hasRemovedGoals) {
            const confirmLeave = confirm(
              'Your changes have not been saved. Discard changes?'
            );
            if (!confirmLeave) {
              this.router.navigate([this.router.url]);
            }
          }
        }
      }
    });
  }

  hasUnsavedChanges(): boolean {
    return this.hasNewGoals || this.hasRemovedGoals;
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    this.#unsavedChangesSvc.setUnsavedChanges(false);
  }

  onBeforeUnload($event: any) {
    if (this.hasNewGoals || this.hasRemovedGoals) {
      return ($event.returnValue =
        'Your changes have not been saved. Discard changes?');
    } else {
      return true;
    }
  }

  filterGoals() {
    const goals = this.WealthType.filter(
      (type) => type.selected && type.selected.length > 0
    )
      .map((type) => type.selected)
      .flat();

    this.selectedGoals = goals.length;

    const selectedGoals = this.applicationGoal.applicationGoals.Round1;

    const processStepGoals = (
      step: GoalStepStructure1,
      selectedGoals: string | any[]
    ) => {
      if (
        [
          GoalSettingPartStep.BuildWealth,
          GoalSettingPartStep.FinancialSecurity,
          GoalSettingPartStep.LifestyleDevelopment,
          GoalSettingPartStep.FamilyCommunity,
        ].includes(step.id)
      ) {
        step.goals = this.applicationGoal?.originalGoals.filter(
          (e) => e.goalCategory === step.id
        );

        if (this.isMobile) {
          step.goals.sort((a, b) => {
            const orderA = a.order % 3;
            const orderB = b.order % 3;

            if (a.goalCategory === GoalSettingPartStep.FamilyCommunity) {
              return orderA === orderB ? a.order - b.order : orderB - orderA;
            }
            return orderA === orderB ? a.order - b.order : orderA - orderB;
          });
        } else {
          step.selected = step.goals
            .filter((e) => selectedGoals.includes(e.id))
            .map((e) => ({ id: e.id, order: e.order }));
        }
      } else {
        step.goals = [];
        step.selected = [];
      }

      return step;
    };

    this.WealthType = STEP_STRUCTURE1.map((step) =>
      processStepGoals(step, selectedGoals)
    );
  }

  onHandleNext() {
    if (this.selectedGoals > 0) {
      this.saveGoal(undefined, () => {
        if (this.errorMessage()) {
          return;
        }

        this.goNext();

        const currentPartStep = this.mapStepIndexToPartStep(
          this.currentStepIndex
        );

        if (currentPartStep) {
          this.#updateGoalStep(GoalSettingStep.GoalSetting, currentPartStep);
        }

        this.nextClicked.emit(true);
        this.#scrollToTop();
      });
    } else {
      this.goNext();
    }
  }

  goNext() {
    if (this.currentStepIndex < this.WealthType.length - 1) {
      this.currentStepIndex++;
    }
  }

  private mapStepIndexToPartStep(index: number): GoalSettingPartsStep | null {
    switch (index) {
      case 0:
        return GoalSettingPartsStep.Part1;
      case 1:
        return GoalSettingPartsStep.Part2;
      case 2:
        return GoalSettingPartsStep.Part3;
      case 3:
        return GoalSettingPartsStep.Part4;
      default:
        return null;
    }
  }

  onHandleBack() {
    if (this._currentApplication?.status === ApplicationStatus.Complete) {
      this.goback();
      return;
    }

    if (this.selectedGoals > 0) {
      this.saveGoal(
        undefined,
        () => {
          this.goback();
          const currentPartStep = this.mapStepIndexToPartStep(
            this.currentStepIndex
          );

          if (currentPartStep) {
            this.#updateGoalStep(GoalSettingStep.GoalSetting, currentPartStep);
          }
        },
        false
      );
    } else {
      this.goback();
    }
  }

  goback() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    } else {
      this.backClicked.emit(true);
    }
    this.#scrollToTop();
  }

  onFinish(): any {
    if (this.selectedGoals < 5) {
      this.showTooltip = true;
      return (this.disable = true);
    } else {
      this.disable = false;
      this.saveGoal(true);
    }
  }

  #scrollToTop(): void {
    this.#viewport.scrollToPosition([0, 0]);
  }

  selectionChange() {
    const goals = this.WealthType.filter(
      (type) => type.selected && type.selected.length > 0
    )
      .map((type) => type.selected)
      .flat();

    this.selectedGoals = goals.length;

    const currentlySelectedGoalIds = new Set(goals.map((goal) => goal.id));

    const round1Goals = this.applicationGoal?.applicationGoals?.Round1 || [];
    const round1GoalIds = new Set(round1Goals);

    this.hasNewGoals = Array.from(currentlySelectedGoalIds).some(
      (id) => !round1GoalIds.has(id)
    );

    this.hasRemovedGoals = Array.from(round1GoalIds).some(
      (id) => !currentlySelectedGoalIds.has(id)
    );

    if (this.selectedGoals > 10) {
      this.disable = true;
    } else {
      this.disable = false;
    }

    this.#unsavedChangesSvc.setUnsavedChanges(
      this.hasNewGoals || this.hasRemovedGoals
    );
  }

  ngOnInit(): void {
    this.filterGoals();
    // this.checkLastPartStep();
  }

  public checkLastPartStep() {
    const goalParts = Object.values(GoalPartStep);
    if (
      this.applicationGoal.applicationGoals.Round1.length >= 5 &&
      !goalParts.some((part) => this.goalPartStep?.includes(part))
    ) {
      this.currentStepIndex = 3;
    }
  }

  public openReflectionPopup() {
    this.#dialog.open(ReflectionPopupComponent, {
      width: '800px',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  private saveGoal(
    isLastPart: boolean = false,
    onSuccess: any = undefined,
    isLoading: boolean = true
  ) {

    if(this.isFileNotes || this.isAppComplete){
      if (isLastPart) {
        if (onSuccess) {
          onSuccess();
        }
        this.finish.emit({
          applicationGoals: this.applicationGoal.applicationGoals,
          nextGoalStep: GoalSettingStep.RoundVerify,
        });
      }

      if (onSuccess) {
        onSuccess();
      }
      this.isLoading.set(false);
      return
    }

    this.isLoading.set(isLoading);

    const selected = this.WealthType.filter(
      (type) => type.selected && type.selected.length > 0
    )
      .map((type) => type.selected)
      .flat();

    if (selected.length === 0) {
      return;
    }

    if (isLastPart && selected.length > 10) {
      const model = this.#dialog.open(GoalMaxCheckComponent, {
        width: '400px',
        height: '220px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          isGoalMaximum: true,
        },
      });

      model.componentInstance.close.subscribe(() => {
        model.close();
        this.#updateGoalStep(
          GoalSettingStep.GoalSetting,
          GoalSettingPartsStep.Part4
        );
      });

      this.isLoading.set(false);
      return;
    }

    this.#applicationGoalService
      .setGoals(
        GoalStep.Round1,
        selected,
        this.WealthType[0].id === GoalSettingPartStep.FamilyCommunity
      )
      .subscribe((result) => {
        if (!result.isSuccess && result.message) {
          this.errorMessage.set(result.message);
          this.isLoading.set(false);
          this.#viewport.scrollToPosition([0, 0]);
        }

        if (!result.data) return;

        this.#resetState();

        this.applicationGoal.applicationGoals.Round1 =
          result.data?.applicationGoals?.Round1;

        if (isLastPart) {
          this.finish.emit({
            applicationGoals: result.data?.applicationGoals,
            nextGoalStep: GoalSettingStep.RoundVerify,
          });
        }

        this.filterGoals();
        this.#scrollToTop();

        if (onSuccess) {
          onSuccess();
        }
      });
  }

  chatWithWealthCoach() {
    this.#dialog.open(CalendarpopupComponent, {
      width: '1200px',
      height: '600px',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  #updateGoalStep(
    goalStep: GoalSettingStep,
    partStep: GoalSettingPartsStep
  ): void {
    if(this.isFileNotes){
      return
    }

    const currentRoundStep = goalStep;
    const currentPartStep = partStep;

    this.#applicationService
      .updateCurrentProgress(
        ApplicationStep.Goal,
        currentRoundStep,
        currentPartStep
      )
      .subscribe({
        next: (value) => {
          const data = value.data;
          this.#currentProgress = {
            roundStep: <GoalSettingStep>data?.currentSubStep,
            partStep: data?.currentPartStep,
          };

          this.updatedProgress.emit(this.#currentProgress);
        },
      });
  }

  #resetState(): void {
    this.isLoading.set(false);
    this.errorMessage.set('');
  }
}

import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';

import { CdkStepper, STEP_STATE, StepState } from '@angular/cdk/stepper';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-vertical-stepper',
  templateUrl: 'vertical-stepper.component.html',
  styleUrls: ['vertical-stepper.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [[{ provide: CdkStepper, useExisting: VerticalStepperComponent }]],
  imports: [
    CommonModule,
    MatStepperModule,
    MatButtonModule,
    NgTemplateOutlet,
    IconSpriteModule,
    MatIconModule,
  ],
})
export class VerticalStepperComponent extends CdkStepper {
  readonly STEP_STATE = STEP_STATE;

  selectStepByIndex(index: number, state: StepState): void {
    if (state !== 'done' && state !== 'edit') {
      return;
    }

    this.selectedIndex = index;
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import { Answer, CommonResponse } from '@acorn/util';

@Injectable()
export class ApplicationAnswerService {
  #httpClient = inject(HttpClient);

  getApplicationAnswer(applicationUserId: string) {
    return this.#httpClient.get<CommonResponse<Answer[]>>(
      `${environment.apiUrls.customer}/api/ApplicationGoal/Answer/${applicationUserId}`
    );
  }

  saveApplicationAnswer(answers: Answer[], applicationId: string = '') {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.customer}/api/ApplicationGoal/Answer/Save?applicationId=${applicationId}`,
      answers
    );
  }
}

import { ActionMembershipPlan, ActionMembershipPlanEntry } from '@acorn/util';

export class ActionListMapper {
  static fromNestedModelToSingleModel(
    list: ActionMembershipPlanEntry[]
  ): ActionMembershipPlan[] {
    return list.map((i) => ({
      title: i.fields.title,
      description: i.fields.description,
    }));
  }
}

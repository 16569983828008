import { Component, HostBinding, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'acorn-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class TooltipComponent {
  @HostBinding('class')
  @Input()
  position = '';

  @Input() label? = '';
  @Input() text? = '';
}

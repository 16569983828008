import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyDashboardActionPlanService } from '../../../data-access/strategy-action-plan.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'acorn-request-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './request-popup.component.html',
  styleUrls: ['./request-popup.component.scss'],
})
export class RequestPopupComponent {
  @Output() close = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  dialogData = inject(MAT_DIALOG_DATA);

  #toastrService = inject(ToastrService);
  #strategyActionPlanSvc = inject(StrategyDashboardActionPlanService);

  onConfirm(){
    this.#strategyActionPlanSvc.ReCalculateAfterEdit(this.dialogData?.applicationId).subscribe(
        (res:any)=>{
            this.confirm.emit();
        },
        (err:any)=>{
            this.#toastrService.error(
                err.error.Message,
                'Error'
            );
        }
    )
  }

  onCancel(){
    this.close.emit();
  }

}

<div class="goal-container">
  <div class="d-flex">
    <img
      [src]="this._step.icon"
      alt="{{ this._step.label }}"
      width="48px"
      height="48px"
    />
    <h3 class="d-flex" [ngClass]="{ 'highlight-step': _step.order === 3 ||  _step.order === 4 }">
      {{ this._step.label }}
      <span class="mobile-only">({{ this._step.order }} /4)</span>
    </h3>
  </div>

  <div class="description goal-m-15">
    <p>{{ this._step.Description }}</p>
  </div>
  <div
    class="tooltip-container"
    [ngStyle]="{ position: 'absolute', 'min-width': '300px' }"
    [class.show]="showWarning"
    [style.left.px]="pageX"
    [style.top.px]="pageY"
  >
    <div class="tooltip-content">
      <div class="d-flex tooltip-note">
        <span class="icon"><img src="/assets/svg/warning.svg" /></span>
        <span class="tooltip-text"
          >You have chosen the maximum number of goals. If you wish to select
          other goals, please review your current choices and amend as
          necessary.
        </span>
      </div>
      <div class="d-flex image-center">
        <button class="close-btn" (click)="this.showWarning = false">
          Close
        </button>
      </div>
    </div>
  </div>
  <div class="group-goal-container">
    <div
      *ngFor="let goal of this._step.goals"
      class="goal desktop-layout"
      [class.selected]="selectedGoalsControl.value.includes(goal.id)"
      (click)="
        toggleGoal(
          $event,
          goal.id,
          selectedGoalsControl.value.includes(goal.id)
        )
      "
      [class.disabled-select]="isFileNotes || isAppComplete"
    >
      <mat-checkbox
        color="primary"
        [checked]="goalsForm?.get(goal.id)?.value"
        [disabled]="isFileNotes || isAppComplete"
        [ngClass]="{'disabled-select': isFileNotes || isAppComplete}"
        (change)="checkLength($event)"
      ></mat-checkbox>

      <p>{{ goal.content }}</p>

      <svg-icon-sprite
        acornClickableTooltip
        [tooltipMessage]="goal.description"
        [src]="'assets/sprites/sprite.svg#information-line'"
        [width]="'20px'"
      ></svg-icon-sprite>
    </div>
    <div
      *ngFor="let goal of this._step.goals"
      class="goal mobile-layout"
      [class.selected]="selectedGoalsControl.value.includes(goal.id)"
      (click)="
        toggleGoal(
          $event,
          goal.id,
          selectedGoalsControl.value.includes(goal.id)
        )
      "
    >
      <mat-checkbox
        color="primary"
        [checked]="goalsForm?.get(goal.id)?.value"
        [disabled]="isGoalDisabled(goal.id)"
        [disabled]="isAppComplete || isGoalDisabled(goal.id) || isFileNotes"
        [ngClass]="{'disabled-select': isFileNotes || isAppComplete}"
        (change)="checkLength($event)"
      ></mat-checkbox>
      <p>{{ goal.content }}</p>

      <svg-icon-sprite
        acornClickableTooltip
        [tooltipMessage]="goal.description"
        [src]="'assets/sprites/sprite.svg#information-line'"
        [width]="'20px'"
      ></svg-icon-sprite>
    </div>
  </div>
</div>

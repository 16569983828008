<div class="strategy-col">
  <div *ngFor="let strategy of strategies" class="strategy-block">
    <div class="strategy-chart">
      <div class="description">
        <div class="title option-container">
          <img [src]="getStrategyImage(strategy.name)" [alt]="strategy.name" [class]="strategy.name"/>
          <h2>
            <span>{{ formatStrategyOption(strategy.strategyOption) }}</span
            >{{ strategy.name }}
          </h2>
        </div>
        <p class="desktop-view">{{ strategy.description }}</p>
        <p class="mobile-view">{{ strategy.description }}</p>
      </div>
    </div>
    <div class="key-aspects">
      <div class="title">
        <h4>Key Aspects</h4>
      </div>
      <div class="key-list">
        <ul>
          <li>
            Portfolio Type <span class="tag">{{ strategy.portfolioType }}</span>
          </li>
          <li>
            Likely Goals
            <ul class="green-fill">
                <li *ngFor="let goal of getGoals(strategy.goals,true)" class="fill"></li>
                <li *ngFor="let goal of getGoals(strategy.goals,false)" ></li>
            </ul>
          </li>
          <li>
            Potential Risk
            <ul class="orang-fill">
              <li [class.fill]="strategy.risk >= 0.25"></li>
              <li [class.fill]="strategy.risk >= 0.5"></li>
              <li [class.fill]="strategy.risk >= 0.75"></li>
              <li [class.fill]="strategy.risk >= 1"></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="chart">
        <img *ngIf="strategy.riskDescription === 'Low'" src="assets/images/chart.svg" alt="Chart 1" />
        <img *ngIf="strategy.riskDescription === 'Medium'" src="assets/images/chart2.svg" alt="Chart 2" />
        <img *ngIf="strategy.riskDescription === 'High'" src="assets/images/chart3.svg" alt="Chart 3" />
      </div>
      <div class="wealth-blocks">
        <div class="title">
          <h4>Primary Wealth Building blocks</h4>
        </div>
        <div class="blocks">
          <div *ngFor="let bb of strategy.primaryBBs">
            <div>
              <img [src]="getImagePath(bb)" alt="{{ bb }}" />
            </div>
            <div>{{ bb }}</div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button (click)="openPopup(strategy)">Find Out More</button>
      </div>
    </div>
  </div>
</div>

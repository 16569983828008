import { Pipe, PipeTransform } from '@angular/core';
import { MediaCategories } from '../enum';

@Pipe({
  name: 'safeCategory',
  standalone: true,
})
export class SafeCategoryPipe implements PipeTransform {
  transform(category: string | undefined): string {
    return category || MediaCategories.Other;
  }
}

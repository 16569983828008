import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';

import { SecurityService } from '../../data-access';

@Component({
  selector: 'acorn-application-footer',
  templateUrl: 'application-footer.component.html',
  styleUrls: ['application-footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatListModule, RouterLink, NgIf],
})
export class ApplicationFooterComponent {
  securityService = inject(SecurityService);

  readonly currentYear: number = new Date().getFullYear();

  @HostBinding('class.filled')
  @Input()
  filled = false;
}

import { LoadingStatus } from '@acorn/util';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'acorn-button',
  templateUrl: 'acorn-button.component.html',
  styleUrls: ['acorn-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
})
export class AcornButtonComponent {
  @Input() disabled = false;
  @Input() loadingStatus!: LoadingStatus;
  @Input() loadingLabel = 'Loading...';
  @Input() successLabel = 'Saved';

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  onClick(): void {
    this.clickEvent.emit();
  }
}

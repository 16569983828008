import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyOptionComponent } from '../strategy-option/strategy-option.component';
import { FeatureWealthStrategyOptionsService } from '../../data-access/feature-wealth-strategy-options.service';
import { CustomerUser } from '@acorn/util';
import { FormsModule } from '@angular/forms';
import { StrategyImages } from '../../utils/wealth-helper';

@Component({
    selector: 'acorn-whats-next',
    standalone: true,
    imports: [CommonModule, StrategyOptionComponent, FormsModule],
    templateUrl: './whats-next.component.html',
    styleUrls: ['./whats-next.component.scss'],
})
export class WhatsNextComponent implements OnInit {
    @Input() public strategies: any;
    @Input() currentUser!: CustomerUser;
    @Input() applicationId: any;
    @Input() userId: any;
    @Input() onlyWealthstrategyOptions!: boolean;

    #wealthStrategyOptionsSvc = inject(FeatureWealthStrategyOptionsService);

    public selectedStrategies: string[] = [];
    public showError = '';

    ngOnInit(): void {
        this.strategies?.forEach(
            (strategy: { selected: boolean; selectedByCustomerDate: any }) => {
                strategy.selected = !!strategy.selectedByCustomerDate;
            }
        );
    }

    getStrategyImage(strategyName: string): string {
        const strategy = StrategyImages.find((s) => s.title === strategyName);
        return strategy
            ? strategy.imagePath
            : 'assets/images/default-portfolio.svg';
    }

    onCheckboxChange() {
        this.saveStrategies();
    }

    saveStrategies() {
        this.selectedStrategies = this.strategies
            .filter((strategy: any) => strategy.selected)
            .map((strategy: any) => strategy.strategyId);

        if (this.selectedStrategies.length > 0) {
            this.#wealthStrategyOptionsSvc
                .SaveStrategies(
                    this.selectedStrategies,
                    this.applicationId,
                    this.userId
                )
                .subscribe({
                    next: (res: any) => {
                        console.log('Saved successfully:', res);
                    },
                    error: () => {
                        this.showError = 'Oops, something went wrong.';
                    },
                });
        }
    }
}

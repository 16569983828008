import { CommonResponse } from '@acorn/util';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StrategyDashboardActionPlanService {
  #httpClient = inject(HttpClient);

  constructor() {}

  GetStrategyActionPlan(applicationId: string) {
    return this.#httpClient
      .get<CommonResponse<boolean>>(
        `${environment.apiUrls.calculation}applications/${applicationId}/twelve-month-plan/action-detail`
      );
  }

  DeleteActionDetail(applicationId: string, startegyId: string ,actionId:string ,actionDetailsId:any) {
    return this.#httpClient
      .delete<CommonResponse<boolean>>(
        `${environment.apiUrls.calculation}applications/${applicationId}/strategies/${startegyId}/actions/${actionId}/action-details/${actionDetailsId}`
      );
  }

  SaveActionDetail(applicationId: string, startegyId: string ,actionId:any,actionDetailsId:any) {
    return this.#httpClient
      .post<CommonResponse<boolean>>(
        `${environment.apiUrls.calculation}applications/${applicationId}/strategies/${startegyId}/actions/${actionId}/action-details/${actionDetailsId}`,''
      );
  }

  GetApplicationStatus(applicationId:any){
    return this.#httpClient.get(`${environment.apiUrls.calculation}applications/${applicationId}`);
  }

  GoalSave(data:any , applicationId:string){
    return this.#httpClient.get(`${environment.apiUrls.calculation}applications/${applicationId}` , data);
  }

  GetGoalLikelyStatus(applicationId:string , startegyId:string){
    return  this.#httpClient.get<CommonResponse<unknown>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/strategies/${startegyId}/goals-likelihood`
    );
  }

  GetRelavantActions(applicationId:string , startegyId:string ,actionId:string ,isActionOriginal:boolean){
    return this.#httpClient.get(`${environment.apiUrls.calculation}applications/${applicationId}/strategies/${startegyId}/actions/${actionId}/action-details?isActionOriginal=${isActionOriginal}`);
  }

  ReCalculateAfterEdit(applicationId:any){
    return this.#httpClient.put<CommonResponse<unknown>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/recalculate-after-edit`,
      null
    );
  }
}

import { FinancialQuestion, FinancialUserAnswer } from '@acorn/util';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isExpandedExpansionPanel',
  standalone: true,
})
export class IsExpandedExpansionPanelPipe implements PipeTransform {
  transform(
    question: FinancialQuestion,
    answers: FinancialUserAnswer[],
    applicationUserId: string
  ): boolean {
    if (!question.question.questionType) return false;

    const applicationUserAnswer = answers.find(
      (item) => item.applicationUserId === applicationUserId
    )?.answers;

    const answersRelatedToQuestion = (applicationUserAnswer || []).filter(
      (it) =>
        it.questionType &&
        it.questionType.startsWith(question.question.questionType) &&
        it.answerContent
    );

    return answersRelatedToQuestion.length > 0;
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart, ChartDataset } from 'chart.js/auto';
import { AgeData, FinancialData } from '../feature-financial-projection/feature-financial-projection.component';
@Component({
  selector: 'acorn-default-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './default-chart.component.html',
  styleUrls: ['./default-chart.component.scss'],
})
export class DefaultChartComponent implements OnInit, AfterViewInit {
  @ViewChild('barCanvas') private barCanvas!: ElementRef;

  @Input() public chartId!: string;

  @Input() public set data(value: any) {
    if (Array.isArray(value)) {
      this.processedData = this.processFinancialData(value);
      setTimeout(() => {
        if (this.processedData) {
          this.generateBarChart();
        }
      }, 0);
    }
    // else {
    //   this.createChart(this.defaultLabels, this.defaultDatasets);
    // }
  }

  @Input() customeClass:string=""

  public barThickness: number = 20;

  public defaultLabels: any = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
  ];

  public defaultDatasets: any = [
    {
      type: 'line',
      label: 'Net Asset Position',
      data: [
        5000000, 9000000, 12000000, 15000000, 14000000, 11000000, 8000000,
        5000000, 2000000,
      ],
      borderColor: 'black',
      backgroundColor: 'black',
      borderWidth: 3,
      fill: false,
      pointStyle: 'line',
      pointRadius: 0,
    },
    {
      type: 'bar',
      label: 'Other Loans',
      data: [
        3000000, 5000000, 7000000, 9000000, 10000000, 9000000, 8000000, 7000000,
        6000000,
      ],
      backgroundColor: '#f79f64',
      barThickness: this.barThickness,
      borderColor: 'black',
      borderWidth: 0,
      stack: 'Stack 0',
      pointStyle: 'circle',
    },
    {
      type: 'bar',
      label: 'Superannuation',
      data: [
        2000000, 3000000, 5000000, 6000000, 5000000, 4000000, 3000000, 2000000,
        1000000,
      ],
      backgroundColor: '#c0da53',
      barThickness: this.barThickness,
      borderColor: 'black',
      borderWidth: 0,
      stack: 'Stack 0',
      pointStyle: 'circle',
    },
    {
      type: 'bar',
      label: 'Investment Property Portfolio',
      data: [
        4000000, 6000000, 8000000, 10000000, 10000000, 9000000, 8000000,
        7000000, 6000000,
      ],
      backgroundColor: '#3a8baf',
      barThickness: this.barThickness,
      borderColor: 'black',
      borderWidth: 0,
      stack: 'Stack 0',
      pointStyle: 'circle',
    },
    {
      type: 'bar',
      label: 'Education Fund',
      data: [
        -5000000, -7000000, -9000000, -11000000, -12000000, -13000000,
        -14000000, -15000000, -16000000,
      ],
      backgroundColor: '#de99b7',
      barThickness: this.barThickness,
      borderColor: 'black',
      borderWidth: 0,
      stack: 'Stack 0',
      pointStyle: 'circle',
    },
    {
      type: 'bar',
      label: 'Cash (incl.Emergency & Funeral Funds)',
      data: [
        1000000, 2000000, 3000000, 4000000, 4000000, 4000000, 4000000, 4000000,
        4000000,
      ],
      backgroundColor: '#264b6a',
      barThickness: this.barThickness,
      borderColor: 'black',
      borderWidth: 0,
      stack: 'Stack 0',
      pointStyle: 'circle',
    },
  ];

  processedData: any;
  barChart: any;
  datasets: ChartDataset<'bar' | 'line', number[]>[] = [];
  chartLabels: string[] = [];


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.barThickness = window.innerWidth <= 768 ? 10 : 20
    if (this.barChart) {
      this.updateLegendPosition();
      this.barChart.update();
    }
  }

  ngAfterViewInit(): void {
    this.createChart(this.defaultLabels, this.defaultDatasets);
  }
  ngOnInit(): void {
    // this.createChart(this.defaultLabels, this.defaultDatasets);
  }

  processFinancialData(data: FinancialData[]): AgeData[] {
    if (!Array.isArray(data)) {
      return [];
    }

    const ageDataMap: Map<number, AgeData> = new Map();
    const filteredData = data?.filter(
      (item) =>
        item.name !== 'Check' && item.name !== 'Present Value of Net Assets'
    );
    filteredData.forEach((item) => {
      const year = item.financialYear;
      let ageData = ageDataMap.get(year);

      if (!ageData) {
        ageData = { age: 0, data: {} };
        ageDataMap.set(year, ageData);
      }

      if (item.name === 'Age') {
        ageData.age = item.value;
      } else {
        ageData.data[item.name] = Math.round(item.value);
      }
    });

    return Array.from(ageDataMap.values());
  }

  private generateBarChart() {
    if (this.processedData && this.processedData.length > 0) {
      this.chartLabels = this.processedData.map((entry: any) =>
        entry.age.toString()
      );
      this.datasets = [
        {
          type: 'line',
          label: 'Net Asset Position',
          data: this.processedData.map(
            (entry: any) => entry.data['Net Asset Position']
          ),
          borderColor: 'black',
          backgroundColor: '323E48',
          borderWidth: 3,
          fill: false,
          pointStyle: 'line',
          pointRadius: 0,
        },
      ];

      const financialCategories = Object.keys(
        this.processedData[0].data
      ).filter((key) => key !== 'Net Asset Position');

      const categoryColors = {
        'Cash (incl. Emergency & Funeral Funds)': '#264b6a',
        'Education Fund': '#de99b7',
        'Owner-Occupied Property': '#da627d',
        'Investment Property Portfolio': '#3a8baf',
        'Other Assets (incl. Motor Vehicles)': '#2ec4b6',
        Superannuation: '#c0da53',
        'Home Loan': '#84a98c',
        'Investment Loans': '#a167a5',
        'Other Loans': '#f79f64',
      };

      financialCategories.forEach((category, index) => {
        this.datasets.push({
          type: 'bar',
          label: category,
          data: this.processedData.map((entry: any) => entry.data[category]),
          border: 'none',
          backgroundColor:
            categoryColors[category as keyof typeof categoryColors] ||
            '#000000', // Assert type of category
          fill: false,
          pointStyle: 'circle',
          pointRadius: 0,
        } as ChartDataset<'bar', number[]>);
      });

      this.createChart(this.chartLabels, this.datasets);
    }
  }


  public createChart(chartLabels: any, datasets: any) {
    if (this.barChart) {
      this.barChart.destroy();
    }

    const canvas = document.getElementById(this.chartId) as HTMLCanvasElement;

    if (canvas) {
      this.barChart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: datasets,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              maxWidth: 350,
              labels: {
                padding: 20,
                boxWidth: 10,
                usePointStyle: true,
                font: {
                  family: 'Gotham',
                },
              },
            },
            tooltip: {
              callbacks: {
                title: function (tooltipItems) {
                  return `Age: ${tooltipItems[0].label}`;
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: 'Age of youngest respondent',
                font: {
                  size: 14,
                  weight: 'bold',
                },
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return Number(value) / 1000000 + 'M';
                },
              },
              title: {
                display: true,
                text: '$AUD',
                font: {
                  size: 14,
                  weight: 'bold',
                },
              },
            },
          },
        },
      });
    }

    this.updateLegendPosition();
  }

  private updateLegendPosition(): void {
    const legendPosition = window.innerWidth <= 768 ? 'bottom' : 'right';
    const fontSize = window.innerWidth <= 768 ? 8 : 12;
    if (
      this.barChart?.options.scales &&
      this.barChart?.options.scales.x &&
      this.barChart?.options.scales.x.title
    ) {
      this.barChart.options.scales.x.title.font = {
        size: fontSize,
        weight: '400',
        family: 'Gotham',
      };
    }

    if (
      this.barChart &&
      this.barChart?.options &&
      this.barChart?.options.plugins &&
      this.barChart?.options.plugins.legend
    ) {
      this.barChart.options.plugins.legend.position = legendPosition;
    }

    if (
      this.barChart?.options.scales &&
      this.barChart?.options.scales.y &&
      this.barChart?.options.scales.y.title
    ) {
      this.barChart.options.scales.y.title.font = {
        size: fontSize,
        weight: '400',
        family: 'Gotham',
      };
    }

    if (this.barChart?.options.plugins.legend.labels) {
      this.barChart.options.plugins.legend.labels.font = {
        size: fontSize,
        weight: '400',
      };
    }

    this.barChart?.update();
  }
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskDirective } from 'ngx-mask';

interface OwnerShipBalance {
  respondent1: AbstractControl<number>;
  respondent2: AbstractControl<number>;
}

@Component({
  selector: 'acorn-ownership-balance',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSliderModule,
    NgxMaskDirective,
    FormsModule,
  ],
  templateUrl: './ownership-balance.component.html',
  styleUrls: ['./ownership-balance.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => OwnershipBalanceComponent),
      multi: true,
    },
  ],
})
export class OwnershipBalanceComponent implements ControlValueAccessor, OnInit {
  #onChanged = new Array<(value: string) => void>();
  #onTouched = new Array<() => void>();

  readonly form!: FormGroup;
  readonly sliderControl!: FormControl;

  isDisabled!: boolean;
  #value!: string;

  @Input() min = 0;
  @Input() max = 100;
  @Input() respondent1: string | undefined;
  @Input() respondent2: string | undefined;
  @Input() isFileNotes: boolean  = false;
  @Input() isAppComplete  = false;

  @ViewChild('inputRespondent1Element', { static: true })
  inputRespondent1Element!: ElementRef<HTMLInputElement>;
  @ViewChild('inputRespondent2Element', { static: true })
  inputRespondent2Element!: ElementRef<HTMLInputElement>;

  get value(): string {
    return this.#value;
  }

  constructor(protected changeDetector: ChangeDetectorRef) {
    this.form = new FormGroup<OwnerShipBalance>({
      respondent1: new FormControl<number>(50, { nonNullable: true }),
      respondent2: new FormControl<number>(50, { nonNullable: true }),
    });
    this.sliderControl = new FormControl(50);
  }

  ngOnInit(): void {
    this.form.get('respondent1')?.valueChanges.subscribe({
      next: (value) => this.updateValue(String(value)),
    });

    this.sliderControl.valueChanges.subscribe({
      next: (value) => this.onRespondent1Changed(value, false),
    });
  }

  registerOnChange(fn: () => void): void {
    this.#onChanged.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.#onTouched.push(fn);
  }

  writeValue(value: string): void {
    this.#value = value;
    this.onRespondent1Changed(value);
    this.changeDetector.detectChanges();
  }

  updateValue(value: string): void {
    if (this.value !== value) {
      this.#value = value;
      this.#onChanged.forEach((it) => it(value));
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetector.detectChanges();
  }

  onRespondent1Changed(rawValue: string, isNeededSliderUpdated = true): void {
    let value = Number(rawValue);
    if (value < this.min) {
      value = this.min;
    } else if (value > this.max) {
      value = this.max;
    }
    this.form.patchValue({
      respondent1: value,
      respondent2: this.max - value,
    });

    if (isNeededSliderUpdated) {
      this.sliderControl.setValue(value);
    }
  }

  onRespondent2Changed(rawValue: string): void {
    let value = Number(rawValue);
    if (value < this.min) {
      value = this.min;
    } else if (value > this.max) {
      value = this.max;
    }
    this.form.patchValue({
      respondent1: this.max - value,
      respondent2: value,
    });
  }
}

import { PartStructure, StepStructure } from '@acorn/feature-application';
import { ApplicationGoal, ApplicationGoalSettings, Goal, GoalSettingStep, GoalStep, PartStep } from '@acorn/util';
import { cloneDeep } from 'lodash';

import { STEP_STRUCTURE } from './step-structure.const';

export const bindingStepStructureStatus = (
  stepStructure: StepStructure,
  totalSelectedIds: number,
  partStep: PartStep | null,
  currentStepStructure: StepStructure | null
) => {
  stepStructure.state =
    totalSelectedIds >= stepStructure.minimumRequiredGoals &&
    totalSelectedIds <= stepStructure.maximumRequiredGoals
      ? 'done'
      : 'number';

  let currentPartStructure: PartStructure;
  if (stepStructure.id === currentStepStructure?.id) {
    currentPartStructure = stepStructure.parts.find(
      (it) => it.id === partStep
    )!;
  }

  stepStructure.parts.forEach((part) => {
    if (
      currentStepStructure?.id === stepStructure.id &&
      currentPartStructure &&
      part.order <= currentPartStructure.order
    ) {
      part.state = 'done';
    } else if (
      currentStepStructure &&
      currentStepStructure?.order > stepStructure.order
    ) {
      part.state = 'done';
    } else if (
      currentStepStructure &&
      currentStepStructure?.order < stepStructure.order
    ) {
      part.state = 'number';
    }
  });
};

export const isNeedToUpdateCurrentProcess = (
  currentProcess: {
    roundStep: GoalStep;
    partStep: PartStep;
  },
  currentStepStructure: StepStructure,
  currentPartStructure: PartStructure,
  isWelcomeBackEdit: boolean
): boolean => {
  if (isWelcomeBackEdit) {
    return true;
  }

  if (currentProcess) {
    const applicationRoundStepStructure = STEP_STRUCTURE.find(
      (it) => it.id === currentProcess!.roundStep
    );
    if (currentStepStructure.order > applicationRoundStepStructure!.order) {
      return true;
    } else if (
      currentStepStructure.order === applicationRoundStepStructure!.order
    ) {
      const applicationPartStepStructure =
        applicationRoundStepStructure!.parts.find(
          (it) => it.id === currentProcess!.partStep
        );
      return currentPartStructure!.order > applicationPartStepStructure!.order;
    } else {
      return true;
    }
  }

  return true;
};

export const getInitialGoalStep = (
  applicationGoal: ApplicationGoalSettings
): GoalSettingStep => {

  if (applicationGoal.applicationGoals[GoalSettingStep.RoundVerify].length) {
    return GoalSettingStep.GoalMetrics;
  }

  if (applicationGoal.applicationGoals[GoalSettingStep.GoalSetting].length === 10) {
    return GoalSettingStep.RoundVerify;
  }

  // if (
  //   (applicationGoal.applicationGoals[GoalStep.Round3].length > 0 &&
  //     applicationGoal.applicationGoals[GoalStep.Round3].length < 10) ||
  //   applicationGoal.applicationGoals[GoalStep.Round2].length === 15
  // ) {
  //   return GoalStep.Round3;
  // }

  // if (
  //   (applicationGoal.applicationGoals[GoalStep.Round2].length > 0 &&
  //     applicationGoal.applicationGoals[GoalStep.Round2].length < 15) ||
  //   applicationGoal.applicationGoals[GoalStep.Round1].length === 25
  // ) {
  //   return GoalStep.Round2;
  // }

  return GoalSettingStep.GoalSetting;
};

export function getStepStructureBaseOnRound2(
  stepStructure: StepStructure[],
  availableGoals: Goal[],
  goalPerPart: number
): StepStructure[] {
  if (availableGoals.length <= goalPerPart) {
    stepStructure[1].parts[0].isLastPart = true;
    stepStructure[1].parts[1].isLastPart = false;
    stepStructure[1].parts[1].isHidden = true;
    stepStructure[1].parts[2].isHidden = true;
    stepStructure[1].parts[2].isLastPart = false;
  } else if (availableGoals.length <= goalPerPart * 2) {
    stepStructure[1].parts[0].isLastPart = false;
    stepStructure[1].parts[1].isLastPart = true;
    stepStructure[1].parts[1].isHidden = false;
    stepStructure[1].parts[2].isHidden = true;
    stepStructure[1].parts[2].isLastPart = false;
  } else if (availableGoals.length > goalPerPart * 2) {
    stepStructure[1].parts[0].isLastPart = false;
    stepStructure[1].parts[1].isLastPart = false;
    stepStructure[1].parts[1].isHidden = false;
    stepStructure[1].parts[2].isHidden = false;
    stepStructure[1].parts[2].isLastPart = true;
  } else {
    stepStructure[1] = cloneDeep(STEP_STRUCTURE[1]);
  }

  return stepStructure;
}

export function getStepStructureBaseOnRound3(
  stepStructure: StepStructure[],
  availableGoals: Goal[],
  goalPerPart: number
): StepStructure[] {
  if (availableGoals.length <= goalPerPart) {
    stepStructure[2].parts[0].isLastPart = true;
    stepStructure[2].parts[1].isHidden = true;
    stepStructure[2].parts[1].isLastPart = false;
  } else if (availableGoals.length > goalPerPart) {
    stepStructure[2].parts[0].isLastPart = false;
    stepStructure[2].parts[1].isHidden = false;
    stepStructure[2].parts[1].isLastPart = true;
  } else {
    stepStructure[2] = cloneDeep(STEP_STRUCTURE[2]);
  }

  return stepStructure;
}

export function getGoalsBasedOnCurrentPart(
  currentPartStructure: PartStructure,
  availableGoals: Goal[],
  goalPerPart: number
): Goal[] {
  let updatedGoals = [];
  if (currentPartStructure.order === 1) {
    updatedGoals = availableGoals.slice(0, goalPerPart);
  } else {
    const end = (currentPartStructure.order - 1) * goalPerPart + goalPerPart;
    updatedGoals = availableGoals.slice(end - goalPerPart, end);
  }

  return updatedGoals;
}

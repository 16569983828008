import {
  AboutYouService,
  ApplicationAnswerService,
  ApplicationGoalService,
  ApplicationService,
  ApplicationUserService,
  BaseContentfulService,
  CategoryService,
  FinancialSituationService,
  PricingService,
  UserService,
  WealthHealthService,
} from '@acorn/data-access';
import { APPLICATION_ID } from '@acorn/util';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { Route } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UnauthorizedPageComponent } from './common/ui';
import {
  authGuard,
  fsgGuard,
  securityInterceptor,
  tokenGuard,
} from './common/utils';
import { authInterceptor } from './common/utils/auth.interceptor';
import { FinancialProjectionService } from 'libs/feature-application/src/lib/feature-financial-projection/data-access-financial-projection';
import { NotFoundComponent } from './common/ui/not-found/not-found.component';
import { Helper } from 'libs/feature-application/src/lib/helpers/helper';
import { StrategyActionPlanService } from '../../../../libs/feature-application/src/lib/dashboard';
import { FeatureWealthStrategyOptionsService } from 'libs/feature-application/src/lib/feature-wealth-strategy-options/data-access/feature-wealth-strategy-options.service';
import { StrategyServiceHelper } from 'libs/feature-application/src/lib/helpers/strategy-service.helper';
import { WealthCalendlyService } from 'libs/feature-application/src/lib/ui/data-access/wealth-calendly.service';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [authGuard, fsgGuard],
    providers: [
      ApplicationGoalService,
      ApplicationService,
      ApplicationUserService,
      ApplicationAnswerService,
      UserService,
      AboutYouService,
      CategoryService,
      FinancialSituationService,
      BaseContentfulService,
      WealthHealthService,
      FinancialProjectionService,
      FeatureWealthStrategyOptionsService,
      Helper,
      StrategyServiceHelper,
      WealthCalendlyService,
      { provide: APPLICATION_ID, useValue: new BehaviorSubject('') },
      provideHttpClient(withInterceptors([securityInterceptor])),
    ],
    loadChildren: () =>
      import('./customer-application/customer-application.routes').then(
        (c) => c.routes
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.routes').then((r) => r.routes),
    canActivate: [tokenGuard],
    providers:[ApplicationService ,  provideHttpClient(withInterceptors([securityInterceptor])), { provide: APPLICATION_ID, useValue:     new BehaviorSubject('')}]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent,
  },
  {
    path: 'contact-wealth-coach',
    loadComponent: () =>
      import('./wealth-coach-page').then((c) => c.WealthCoachPageComponent),
  },
  {
    path: 'confirm-fsg',
    providers: [
      provideHttpClient(withInterceptors([securityInterceptor])),
      UserService,
    ],
    loadComponent: () =>
      import('./confirm-fsg-page/confirm-fsg-page.component').then(
        (c) => c.ConfirmFsgPageComponent
      ),
  },
  {
    path: 'pricing',
    providers: [
      provideHttpClient(withInterceptors([authInterceptor])),
      PricingService,
    ],
    loadComponent: () =>
      import('./pricing-page/pricing-page.component').then(
        (c) => c.PricingPageComponent
      ),
  },
  {
    path: 'payment/callback',
    canActivate: [authGuard],
    providers: [
      provideHttpClient(withInterceptors([authInterceptor])),
      ApplicationService,
      { provide: APPLICATION_ID, useValue: new BehaviorSubject('') },
    ],
    loadComponent: () =>
      import('./payment-callback/payment-callback.component').then(
        (c) => c.PaymentCallbackComponent
      ),
  },
  {
    path: 'user-profile',
    canActivate: [authGuard],
    providers: [
      provideHttpClient(withInterceptors([securityInterceptor])),
      UserService,
    ],
    loadComponent: () =>
      import('./user-profile/user-profile.component').then(
        (c) => c.UserProfileComponent
      ),
  },
  {
    path: 'article/:id',
    loadComponent: () =>
      import('./article-details/article-details.component').then(
        (c) => c.ArticleDetailsComponent
      ),
  },
  {
    path: 'page/:id',
    loadComponent: () =>
      import('./static-page/static-page.component').then(
        (c) => c.StaticPageComponent
      ),
  },
  {
    path: 'dashboard',
    providers: [
      ApplicationGoalService,
      ApplicationService,
      ApplicationUserService,
      ApplicationAnswerService,
      UserService,
      AboutYouService,
      CategoryService,
      FinancialSituationService,
      BaseContentfulService,
      WealthHealthService,
      FinancialProjectionService,
      Helper,
      StrategyActionPlanService,
      StrategyServiceHelper ,
      FeatureWealthStrategyOptionsService,
      WealthCalendlyService,
      { provide: APPLICATION_ID, useValue: new BehaviorSubject('') },
      provideHttpClient(withInterceptors([securityInterceptor])),
    ],
    loadComponent: () =>
      import('../../../../libs/feature-application/src/lib/dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
  },
  {
    path: 'actions',
    providers: [
      ApplicationGoalService,
      ApplicationService,
      ApplicationUserService,
      ApplicationAnswerService,
      UserService,
      AboutYouService,
      CategoryService,
      FinancialSituationService,
      BaseContentfulService,
      WealthHealthService,
      FinancialProjectionService,
      Helper,
      StrategyActionPlanService,
      { provide: APPLICATION_ID, useValue: new BehaviorSubject('') },
      provideHttpClient(withInterceptors([securityInterceptor])),
    ],
    loadComponent: () =>
      import('../../../../libs/feature-application/src/lib/dashboard/ui/actions/actions.component').then(
        (c) => c.ActionsComponent
      ),
  },
  {
    path: 'information',
    loadComponent: () =>
      import('../../../../libs/feature-application/src/lib/dashboard/ui/information/information.component').then(
        (c) => c.InformationComponent
      ),
  },
  {
    path: 'strategy',
    loadComponent: () =>
      import('../../../../libs/feature-application/src/lib/dashboard/ui/strategy-action/strategy-action.component').then(
        (c) => c.StrategyActionComponent
      ),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];

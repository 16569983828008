import { CategoryService, FinancialSituationService } from '@acorn/data-access';
import { CategoryCode, CategoryStatus, FinancialSnapshot } from '@acorn/util';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';

import { MatCardModule } from '@angular/material/card';

import { ConvertZeroToDashPipe, FinancialSnapshotType } from '../../utils';

import { InformationPanelComponent } from '../information-panel';

@Component({
  selector: 'acorn-financial-snapshot',
  standalone: true,
  imports: [
    CommonModule,
    InformationPanelComponent,
    MatCardModule,
    ConvertZeroToDashPipe,
  ],
  templateUrl: './financial-snapshot.component.html',
  styleUrls: ['./financial-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialSnapshotComponent implements OnInit {
  #financialSituationService = inject(FinancialSituationService);
  #categoryService = inject(CategoryService);

  @Input({ required: true }) applicationId!: string;
  @Input({ required: true }) snapshotCategoryStatus?: CategoryStatus;
  @Input() isFileNotes: boolean = false;
  @Input() isAppComplete = false;

  protected readonly FinancialSnapshotType = FinancialSnapshotType;

  financialInfo = signal<FinancialSnapshot[]>([]);
  firstUserFinance = computed(() => {
    const finance = this.financialInfo();
    return finance.length > 0 ? finance[0] : null;
  });
  secondUserFinance = computed(() => {
    const finance = this.financialInfo();
    return finance.length > 0 ? finance[1] : null;
  });
  isDualUser = computed(
    () => this.firstUserFinance() && this.secondUserFinance()
  );

  ngOnInit() {
    if (this.snapshotCategoryStatus !== CategoryStatus.Complete) {
      this.#categoryService
        .updateStatusCategory(this.applicationId, [
          {
            categoryCode: CategoryCode.FinancialSnapshot,
            status: CategoryStatus.Complete,
          },
          {
            categoryCode: CategoryCode.YourSnapshot,
            status: CategoryStatus.Complete,
          },
        ])
        .subscribe();
    }

    this.#financialSituationService
      .getFinancialSnapshot()
      .subscribe(({ isSuccess, data }) => {
        if (!isSuccess) {
          return;
        }

        this.financialInfo.set(data);
      });
  }
}

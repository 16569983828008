import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeCategoryPipe } from '@acorn/util';

@Component({
  selector: 'acorn-category-box',
  standalone: true,
  imports: [CommonModule, SafeCategoryPipe],
  templateUrl: './category-box.component.html',
  styleUrls: ['./category-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryBoxComponent {
  @Input() category!: string;
}

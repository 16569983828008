<div style="rotate: 90deg;
                position: relative;
                top: 332px;
                height: 0px;
                width: 638px;
                left: 0px;">
    <div class="d-flex m-chart-layout" style="display: flex;
    justify-content: space-between;
    margin-bottom: 20px;">
        <p class="m-chart-p" style="margin-top: -50px;">
            <strong>Financial Projection</strong> <span class="m-chart-sub">&nbsp;If no significant changes to your
                wealth situation are made.</span>
        </p>

        <button style="font-size: 12px; margin-top: -80px;" class="icon-button d-flex" (click)="onClosePopup()">
            close &#x2715;
        </button>
    </div>
    <div class="m-chart-container" style="height: 280px;">
        <canvas #barCanvas class="rotated-chart" height="200" style="width:650px"></canvas>
    </div>
</div>
<span
  *ngFor="let item of [].constructor(5); let i = index"
  class="mdi mdi-star-outline"
  [style.font-size.px]="fontSize"
>
  <span
    *ngIf="Math.ceil(rate) >= i + 1"
    class="mdi mdi-star"
    [style.width.%]="Math.ceil(rate) === i + 1 ? getRatingPercent : ''"
  ></span>
</span>

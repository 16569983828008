import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonResponse, CustomerSignInResponse } from '@acorn/util';

import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class AccountService {
  #httpClient = inject(HttpClient);

  login(email: string) {
    return this.#httpClient.post<CommonResponse<CustomerSignInResponse>>(
      `${environment.apiUrls.identity}/api/Account/customer/login-sso`,
      { email }
    );
  }

  signUp(email: string, phoneNumber: string) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.identity}/api/Account/signup`,
      { email, phoneNumber }
    );
  }

  verifyOtp(email: string, otp: string) {
    return this.#httpClient.post<
      CommonResponse<{ token: string; refreshToken: string }>
    >(`${environment.apiUrls.identity}/api/Account/verify-otp`, { email, otp });
  }

  resendOtp(retryId: string, action: 'sign-in' | 'sign-up', email: string) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.identity}/api/Account/customer/${
        action === 'sign-up' ? 'signup-resend-otp' : 'resend-otp'
      }`,
      { id: retryId, email }
    );
  }
}

<div mat-dialog-content>
  <div class="content">
    <svg-icon-sprite
      classes="primary-color"
      [src]="'assets/sprites/sprite.svg#warning-sign'"
      [width]="'30px'"
    ></svg-icon-sprite>

    <h1>{{ dialogData.type === 'error' ? 'Error' : 'Warning' }} message</h1>

    <p *ngFor="let message of dialogData.messages">{{ message }}</p>
  </div>

  <div class="actions">
    <button mat-stroked-button (click)="close()">Close</button>

    <button
      *ngIf="dialogData.type !== 'error'"
      mat-stroked-button
      (click)="next()"
    >
      Ok
    </button>
  </div>
</div>

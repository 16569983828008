import { isNil, findLastIndex } from 'lodash';

import {
  ApplicationStep,
  Category,
  CategoryStatus,
  CategoryStatusInputModel,
  SubscriptionPlan,
} from '@acorn/util';

export const findLatestStepIndex = (
  categories: Category[],
  onlyAboutYou: boolean = false
): [number | undefined, number | undefined] => {
  const firstPendingCategoryIndex = categories.findIndex(
    (category) => category.status === CategoryStatus.Pending
  );
  if (firstPendingCategoryIndex === 0) {
    return [undefined, undefined];
  }

  const pendingCategoryIndex = categories.findIndex(
    (category) =>
      category.status === CategoryStatus.InProgress ||
      category.status === CategoryStatus.Pending
  );

  if (pendingCategoryIndex < 0) {
    // all categories have been completed
    if (onlyAboutYou) {
      const completedCategoryIndex = categories.findIndex(
        (category) => category.status === CategoryStatus.Complete
      );

      // Ensure that the completedCategoryIndex is valid and the category has childItems
      const completedSubCategoryIndex = categories[
        completedCategoryIndex < 0 ? 0 : completedCategoryIndex
      ]?.childItems?.findIndex(
        (subcategory) => subcategory.status === CategoryStatus.Complete
      ) ?? -1;

      return [
        completedCategoryIndex < 0 ? 0 : completedCategoryIndex,
        completedSubCategoryIndex < 0 ? 0 : completedSubCategoryIndex,
      ];
    }
    const completedCategoryIndex = findLastIndex(
      categories,
      (category) => category.status === CategoryStatus.Complete
    );

    const completedSubCategoryIndex = findLastIndex(
      categories[completedCategoryIndex < 0 ? 0 : completedCategoryIndex]
        .childItems,
      (category) => category.status === CategoryStatus.Complete
    );

    return [
      completedCategoryIndex < 0 ? 0 : completedCategoryIndex,
      completedSubCategoryIndex < 0 ? 0 : completedSubCategoryIndex,
    ];
  }

  const pendingSubCategoryIndex = categories[
    pendingCategoryIndex
  ].childItems?.findIndex(
    (subCategory) => subCategory.status === CategoryStatus.Pending
  );

  if (isNil(pendingSubCategoryIndex) || pendingSubCategoryIndex < 0) {
    return [0, 0];
  }

  return [pendingCategoryIndex, pendingSubCategoryIndex];
};

export const getUpdatedCategoryStatus = (
  categories: Category[],
  selectedParentIndex: number,
  selectedIndex: number
): CategoryStatusInputModel[] => {
  const categoryStatus: CategoryStatusInputModel[] = [];

  const currentParentCategory = categories[selectedParentIndex];

  if (!currentParentCategory?.childItems?.length) {
    return [];
  }

  const currentCategory = currentParentCategory.childItems[selectedIndex];

  if (!currentCategory) {
    return [];
  }

  currentCategory.status = CategoryStatus.Complete;
  categoryStatus.push({
    categoryCode: currentCategory.categoryCode,
    status: CategoryStatus.Complete,
  });

  if (currentCategory.isLastItem) {
    currentParentCategory.status = CategoryStatus.Complete;

    categoryStatus.push({
      categoryCode: currentParentCategory.categoryCode,
      status: CategoryStatus.Complete,
    });

    const nextParentCategory = categories[selectedParentIndex + 1];

    if (nextParentCategory?.status === CategoryStatus.Pending) {
      nextParentCategory.status = CategoryStatus.InProgress;

      categoryStatus.push({
        categoryCode: nextParentCategory.categoryCode,
        status: CategoryStatus.InProgress,
      });
    }
  } else if (currentParentCategory.status === CategoryStatus.Pending) {
    categoryStatus.push({
      categoryCode: currentParentCategory.categoryCode,
      status: CategoryStatus.InProgress,
    });
  }

  return categoryStatus;
};
export function isWealthCoachNotPlan(
  appStep: ApplicationStep | null,
  subscriptionPlan: SubscriptionPlan | undefined
): boolean {
  return (
    (appStep === ApplicationStep.FinancialProjection ||
      appStep === ApplicationStep.WealthCoach) &&
    !subscriptionPlan
  );
}

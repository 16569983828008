import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyAssumptionsPopupComponent } from '../../../feature-financial-projection/ui/key-assumptions-popup/key-assumptions-popup.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acorn-reflection-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './reflection-popup.component.html',
  styleUrls: ['./reflection-popup.component.scss'],
})
export class ReflectionPopupComponent {
  #dialogRef = inject(MatDialogRef<KeyAssumptionsPopupComponent>);

  values = [
    'Creativity', 'Environment', 'Family', 'Friends', 'Fun', 'Growth',
    'Mindfulness', 'Open-mindedness', 'Safety', 'Self-reliance', 'Status',
    'Truth-seeking', 'Wealth', 'Health', 'Independence', 'Legacy', 'Minimalism'
  ];

  closePopup() {
     this.#dialogRef.close();
  }
}

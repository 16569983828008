<div class="stepper">
  <div
    *ngFor="let step of steps; let i = index"
    class="stepper__item"
    [class.done]="step.state === STEP_STATE.DONE"
    [class.active]="selectedIndex === i"
    (click)="selectStepByIndex(i, step.state)"
  >
    <div class="relative">
      <ng-container
        *ngIf="step.state === STEP_STATE.DONE; else defaultIconTemplate"
      >
        <mat-icon>check</mat-icon>

        <div *ngIf="i < steps.length - 1" class="step-divider"></div>
      </ng-container>

      <ng-template #defaultIconTemplate>
        <div class="icon"></div>
      </ng-template>
    </div>

    <span class="label">{{ step.label }}</span>
  </div>
</div>

<div class="stepper__content">
  <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
</div>

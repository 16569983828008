export interface AnswerSelection {
  questionId: string;
  content: string;
  order: number;
  isNoneOfTheAbove: boolean;
  questionAnswerSelections: AnswerSelection[];
  id: string;
  isKOAnswer: boolean;
  isShowWarning: boolean;
}

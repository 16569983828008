import { Component, OnInit} from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'acron-wealth-health-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  providers: [
    { provide: MatDialogRef ,  useValue:{}},
],
  templateUrl: './wealth-health-popup.component.html',
  styleUrls: ['./wealth-health-popup.component.scss']
})
export class WealthHealthPopupComponent implements OnInit {
  public popup1 : boolean = true;
  public popup2 : boolean = true;
  
  ngOnInit(): void { 
  }

}
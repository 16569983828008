export enum MaritalStatus {
  Single = 'Single',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
  Separation = 'Separation',
  Married = 'Married',
  RelationshipWithSharedFinances = 'RelationshipWithSharedFinances',
  RelationshipWithSeparateFinances = 'RelationshipWithSeparateFinances',
}

export enum CitizenshipStatus {
  PermanentResidentWithAllFinancial = 'PermanentResidentWithAllFinancial',
  PermanentResidentWithSomeFinancial = 'PermanentResidentWithSomeFinancial',
  TemporaryVisaHolder = 'TemporaryVisaHolder',
}

export enum DependantEnum {
  Children = 'Children',
  ElderlyParent = 'ElderlyParent',
  LovedOneWithSpecialNeeds = 'LovedOneWithSpecialNeeds',
}

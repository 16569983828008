<acorn-vertical-stepper *ngIf="maskAsRead()">
  <cdk-step label="Set up">
    <acorn-nested-vertical-stepper
      [selectedIndex]="selectedIndex()"
      (selectedIndexChange)="onSelectIndexChanged($event)"
    >
      <cdk-step
        label="Who is this for?"
        [state]="applicationPlan ? 'done' : ''"
      ></cdk-step>

      <cdk-step
        label="Your details"
        [state]="applicationUsers?.length ? 'done' : ''"
      ></cdk-step>
    </acorn-nested-vertical-stepper>
  </cdk-step>
</acorn-vertical-stepper>

<div *ngIf="!maskAsRead()"></div>

<div class="content">
  <acorn-welcome-screen
    *ngIf="!maskAsRead(); else hasAnyPlanTemplate"
    imageName="welcome-to.svg"
    title="Welcome to Wealth Maximiser!"
    description=""
    estimatedTime="30-40"
  >
    <p>
      This survey is designed to give you a clearer view of your financial
      situation and most important goals.
    </p>

    <p>
      You'll be asked about your income, expenses and other financial details,
      so it's beneficial to keep essential information nearby.
    </p>

    <p>
      There is an auto-save feature after each page, allowing you to revisit it
      as needed.
    </p>

    <p>
      After you've finished, your Wealth Coach will guide you through the
      selected strategy to attain your key goals.
    </p>

    <p>Good luck!</p>

    <acorn-application-navigation
      [backButtonEnabled]="allowNavigateToHome"
      (next)="maskAsRead.set(true)"
      (back)="navigateToHome()"
    ></acorn-application-navigation>
  </acorn-welcome-screen>

  <ng-template #hasAnyPlanTemplate>
    <ng-container [ngSwitch]="selectedIndex()">
      <acorn-setup-plan
        *ngSwitchCase="0"
        [currentApplication]="currentApplication"
        [allowNavigateToHome]="allowNavigateToHome"
        [applicationPlan]="applicationPlan"
        [isFileNotes]="isFileNotes"
        (back)="onHandleBack()"
        (next)="onSubmitPlan($event)"
      ></acorn-setup-plan>

      <ng-container *ngSwitchCase="1">
        <acorn-setup-details
          *ngIf="applicationUsers && applicationPlan"
          [applicationPlan]="applicationPlan"
          [applicationUsers]="applicationUsers"
          [isFileNotes]="isFileNotes"
          (back)="onHandleBack()"
          (next)="
            onHandleNext($event.isUpdateApplications, $event.applicationUsers)
          "
        ></acorn-setup-details>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

<div></div>

<div class="card">
  <div class="header">
    <h4>{{ parentCategory.name }}</h4>

    <h1>{{ currentCategory.name }}</h1>

    <p *ngIf="primaryQuestion.question.subContent">
      {{ primaryQuestion.question.subContent }}
    </p>

    <p
      acornTooltip
      [tooltipMessage]="tooltip"
      [innerHTML]="
        currentCategory.description || primaryQuestion.question.content
      "
    ></p>

    <h5 *ngIf="isCheckboxMax3">
      {{ formRecord.controls[applicationUsers[0].id].value?.length }} of 3
      Selected
    </h5>
  </div>

  <acorn-alert *ngIf="formRecord.invalid && isSubmitted()" type="error">
    <p class="alert-title">
      To proceed, {{ displayedTotalMessages() }} to be made
    </p>

    <ul *ngFor="let message of errorMessages()" class="alert-messages">
      <li>{{ message }}</li>
    </ul>
  </acorn-alert>

  <acorn-alert *ngIf="serverErrorMessage()" type="error">
    <p class="alert-title">{{ serverErrorMessage() }}</p>
  </acorn-alert>

<div class="card-content">
    <mat-accordion
      *ngFor="
        let formControlItem of formRecord.controls
          | keyvalue : applicationUserOrder
      "
    >
      <mat-expansion-panel
        [expanded]="true"
        [disabled]="layoutService.screenSize() !== ScreenSize.Mobile"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ formTemplate[formControlItem.key].applicationUserName }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container
          *ngIf="primaryQuestion"
          [ngSwitch]="primaryQuestion.question.answerType"
        >
          <ng-container *ngSwitchCase="AnswerType.Radio">
            <mat-radio-group
            [disabled]="isFileNotes"
              class="radio-group"
              color="primary"
              [formControl]="formControlItem.value"
              (change)="onRadioChanged()"
              [ngClass]="{'disabled-select': isFileNotes}"
            >
              <mat-radio-button
                class="radio-button"
                *ngFor="let selection of selections()"
                [value]="selection.id"
                [disabled]="isFileNotes"
              >
                {{ selection.content }}
              </mat-radio-button>
            </mat-radio-group>

            <p
              *ngIf="isSubmitted() && formControlItem.value.invalid"
              class="error-message"
            >
              {{ ERROR_MESSAGE.MAKE_A_SELECTION }}
            </p>
          </ng-container>

          <ng-container
            *ngSwitchCase="
              [AnswerType.Checkbox, AnswerType.CheckboxMax3].includes(
                primaryQuestion.question.answerType
              )
                ? primaryQuestion.question.answerType
                : ''
            "
          >
            <div class="checkbox-question">
              <acorn-checkbox
                *ngFor="let selection of selections()"
                [label]="selection.content"
                [isFileNotes]="isFileNotes"
                [checked]="formControlItem.value.value?.includes(selection.id)"
                (changed)="
                  onCheckboxChanged(
                    formControlItem.value,
                    selection.id,
                    $event,
                    selection.isNoneOfTheAbove
                  )
                "
              ></acorn-checkbox>
            </div>

            <p
              *ngIf="isSubmitted() && formControlItem.value.invalid"
              class="error-message"
            >
              {{
                isCheckboxMax3
                  ? ERROR_MESSAGE.SELECT_UP_TO_3
                  : ERROR_MESSAGE.SELECT_MANY_OPTIONS
              }}
            </p>
          </ng-container>

          <mat-form-field
            *ngSwitchCase="AnswerType.Text"
            class="small"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input type="text" matInput [formControl]="formControlItem.value" />
          </mat-form-field>

          <mat-form-field
            *ngSwitchCase="AnswerType.AdultAge"
            class="small"
            appearance="outline"
            subscriptSizing="dynamic"
            [ngClass]="{'disabled-select': isFileNotes}"
          >
            <input
              type="text"
              mask="0*"
              placeholder="Enter age"
              matInput
              [formControl]="formControlItem.value"
              [readonly]="isFileNotes"
            />

            <mat-error
              *ngIf="
                isSubmitted() && formControlItem.value.hasError('invalidAge')
              "
            >
              <svg-icon-sprite
                [src]="'assets/sprites/sprite.svg#exclamation-solid'"
                [width]="'24px'"
              ></svg-icon-sprite>

              Age is greater than or equal to 18 years
            </mat-error>
          </mat-form-field>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<acorn-application-navigation
  [isLoading]="isLoading()"
  (back)="back.emit()"
  (next)="onSubmit()"
></acorn-application-navigation>

<div class="actions-container actions-container-main container">
    <h2>Actions</h2>
    <p class="pad-bot20 mobile-hide title" *ngIf="!this.hasStrategyNull">
        Complete and check off your actions
        <strong class="strategy-name"
            >{{ this.selectedStrategy?.name }} Strategy
        </strong>
        to work towards your goals, improve your Wealth Health score and improve
        your financial future.
    </p>
    <p class="pad-bot20 mobile-hide title" *ngIf="this.hasStrategyNull">
        Complete and check off your actions to work towards your goals, improve
        your Wealth Health score and improve your financial future.
    </p>
    <div class="actions-container container" *ngIf="!this.hasStrategyNull">
        <!--For Desktop-->

        <ng-container *ngIf="isSecondYear && !showPreviousYearTabs">
            <button class="see-year" mat-raised-button color="primary" (click)="toggleView()">
              See Previous Year
            </button>
          </ng-container>

        <p class="year-num" *ngIf="isSecondYear && !showPreviousYearTabs">Year-2</p>
        <p class="year-num" *ngIf="showPreviousYearTabs">Year-1</p>

          <ng-container *ngIf="showPreviousYearTabs">
            <button class="see-year"mat-raised-button color="primary" (click)="toggleView()">
              See Current Year
            </button>
          </ng-container>

        <mat-tab-group class="desktop-view">
            <mat-tab
                *ngFor="
                    let timeFrame of displayedTimeFrames;
                    let timeFrameIndex = index
                "
                [label]="timeFrame.displayName"
                class="d-flex"
            >
                <div class="prograss-main">
                    <div class="prograss-bar">
                        <div class="progress-text">Progress</div>
                        <div class="progress-col">
                        <div
                            class="circle-line"
                            *ngFor="
                                let action of timeFrame?.timeFrameList;
                                let i = index
                            "
                        >
                            <acorn-progress-bar
                                [number]="i + 1"
                                [filledSlices]="
                                    calculateProgress(action.actiondetails)
                                "
                                [slices]="action.actiondetails.length"
                            ></acorn-progress-bar>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="container action-inner">
                    <div
                        *ngFor="
                            let action of timeFrame.timeFrameList;
                            let i = index
                        "
                        class="action-box"
                    >
                        <div class="progress-count d-flex">
                            <div class="circle-line">
                                <acorn-progress-bar
                                    [number]="i + 1"
                                    [filledSlices]="
                                        calculateProgress(action.actiondetails)
                                    "
                                    [slices]="action.actiondetails.length"
                                ></acorn-progress-bar>
                            </div>
                            <div class="progress-text">
                                <h4>{{ action.topic }}</h4>
                            </div>
                        </div>
                        <div class="progress-content">
                          <div class="points no-padding" *ngIf="timeFrame.showDefault">
                            More details will be unlocked when you complete all previous actions, the previous {{ getDaysText(timeFrame.name) }} are finished or after you speak to a Wealth Coach
                          </div>
                        </div>
                        <div class="progress-content"  *ngIf="timeFrame.showDefault  === false ">
                            <div
                                (click)="toggleActive(timeFrameIndex, i)"
                                class="toggle d-flex" [ngClass]="getStatusMessage(action.actiondetails).statusClass"
                            >
                                <h3>{{ i + 1 }}</h3>
                                <p >
                                    {{ getStatusMessage(action.actiondetails).message }}
                                </p>

                            </div>

                            <div
                                class="toggle-content"
                                *ngFor="
                                    let detail of actiondetails(action?.actiondetails)
                                        | slice : 0 : 3;
                                    let actionDetailsIndex = index
                                "
                                [ngClass]="{ 'active': !activeStates[timeFrameIndex]?.[i] }"
                            >
                                <div class="toggle-text">
                                    <div class="points">
                                        <label
                                            ><b>{{ detail.name }} : </b>
                                            <span
                                                class="hidden-content"
                                                *ngIf="isContentVisible[timeFrameIndex]?.[i]?.[actionDetailsIndex]"
                                                >{{ detail?.description }}
                                                <a
                                                    href="#"
                                                    (click)="
                                                        toggleContentVisibility(
                                                            timeFrameIndex,
                                                            i,
                                                            actionDetailsIndex
                                                        );
                                                        $event.preventDefault()
                                                    "
                                                    >See Less</a
                                                >
                                            </span>
                                            <span
                                                class="see-more"
                                                *ngIf="!isContentVisible[timeFrameIndex]?.[i]?.[actionDetailsIndex]"
                                            >
                                                <a
                                                    href="#"
                                                    (click)="
                                                        toggleContentVisibility(
                                                            timeFrameIndex,
                                                            i,
                                                            actionDetailsIndex
                                                        );
                                                        $event.preventDefault()
                                                    "
                                                >
                                                    See more
                                                </a>
                                            </span>
                                            <input
                                                type="checkbox"
                                                [checked]="
                                                    detail.applicationActionDetails
                                                "
                                                (change)="
                                                    onCheckboxChange(
                                                        timeFrameIndex,
                                                        i,
                                                        detail.actionId,
                                                        detail.id,
                                                        $event
                                                    )
                                                "
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="read-more"
                                *ngIf="(action?.actiondetails?.length || 0) > 3 && !activeStates[timeFrameIndex]?.[i]"
                            >
                                <a (click)="seeActionDetails(action)">
                                    See Action details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <!--For Mobile-->

       <div class="mobile-view">
        <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
            <mat-icon matSuffix class="suffix-select-icon" [class.triggered]="newStrategyId.panelOpen">
              keyboard_arrow_down
            </mat-icon>

            <mat-select #newStrategyId [(value)]="selectedTabIndex" [hideSingleSelectionIndicator]="true">
              <mat-option *ngFor="let timeFrame of displayedTimeFrames; let timeFrameIndex = index" [value]="timeFrameIndex">
                {{ timeFrameIndex === 0 ? '0-90 days' : timeFrame.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="prograss-main">
            <div class="prograss-bar" *ngIf="selectedStrategy?.timeFrames">
                <div class="progress-text">Progress</div>
                <div class="progress-col">
                <div
                    class="circle-line"
                    *ngFor="
                       let action of selectedStrategy.timeFrames[selectedTabIndex]?.timeFrameList;
                        let i = index
                    "
                >
                    <acorn-progress-bar
                        [number]="i + 1"
                        [filledSlices]="
                            calculateProgress(action.actiondetails)
                        "
                        [slices]="this.action.actiondetails.length"
                    ></acorn-progress-bar>
                </div>
            </div>
            </div>
        </div>
        <div class="container action-inner" *ngIf="selectedStrategy?.timeFrames">
            <div *ngFor="let action of selectedStrategy.timeFrames[selectedTabIndex]?.timeFrameList; let i = index" class="action-box">
              <div class="progress-count d-flex">
                <div class="circle-line">
                  <acorn-progress-bar 
                  [slices]="this.action.actiondetails.length"
                  [number]="i + 1" [filledSlices]="calculateProgress(action.actiondetails)"></acorn-progress-bar>
                </div>
                <div class="progress-text">
                  <h4>{{ action.topic }}</h4>
                </div>
              </div>

              <div class="progress-content">
                <div class="points no-padding" *ngIf="selectedStrategy.timeFrames[selectedTabIndex]?.showDefault">
                  More details will be unlocked when you complete all previous actions, the previous 90 days are finished or after you speak to a Wealth Coach
                </div>
              </div>

              <div class="progress-content" *ngIf="selectedStrategy.timeFrames[selectedTabIndex]?.showDefault === false">
                <div (click)="toggleActive(selectedTabIndex, i)" class="toggle d-flex" [ngClass]="getStatusMessage(action.actiondetails).statusClass">
                  <h3>{{ i + 1 }}</h3>
                  <p>
                    {{ getStatusMessage(action.actiondetails).message }}
                </p>                </div>

                <div class="toggle-content" *ngFor="let detail of action?.actiondetails | slice : 0 : 3; let actionDetailsIndex = index" [ngClass]="{ 'active': !activeStates[selectedTabIndex]?.[i] }">
                  <div class="toggle-text">
                    <div class="points">
                      <label>
                        {{ detail.name }}
                        <span class="hidden-content" *ngIf="isContentVisible[selectedTabIndex]?.[i]?.[actionDetailsIndex]">
                          {{ detail?.description }}
                          <a href="#" (click)="toggleContentVisibility(selectedTabIndex, i, actionDetailsIndex); $event.preventDefault()">See Less</a>
                        </span>
                        <span class="see-more" *ngIf="!isContentVisible[selectedTabIndex]?.[i]?.[actionDetailsIndex]">
                          <a href="#" (click)="toggleContentVisibility(selectedTabIndex, i, actionDetailsIndex); $event.preventDefault()">See more</a>
                        </span>
                        <input type="checkbox" [checked]="detail.applicationActionDetails" (change)="onCheckboxChange(selectedTabIndex, i, detail.actionId, detail.id, $event)" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="read-more" *ngIf="(action?.actiondetails?.length || 0) > 3 && !activeStates[selectedTabIndex]?.[i]">
                  <a (click)="seeActionDetails(action)">See Action details</a>
                </div>
              </div>
            </div>
        </div>
       </div>
    </div>

    <div *ngIf="this.isLoading">
        <div class="c-spinner">
            <div class="c-spinner-inner"></div>
        </div>
    </div>

    <!--If strategy not selected-->

    <ng-container *ngIf="this.hasStrategyNull">
        <main class="tab-main">
            <ng-container *ngIf="!isMobile; else mobileView">
                <mat-tab-group>
                    <mat-tab *ngFor="let timeFrame of timeFramesList" [label]="timeFrame.displayName">
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-template #mobileView>
                <mat-form-field appearance="outline" class="small" subscriptSizing="dynamic">
                    <mat-icon matSuffix class="suffix-select-icon" [class.triggered]="newStrategyId.panelOpen">
                      keyboard_arrow_down
                    </mat-icon>

                    <mat-select #newStrategyId [(value)]="selectedEmptyTabIndex" [hideSingleSelectionIndicator]="true">
                      <mat-option *ngFor="let timeFrame of this.timeFramesList; let timeFrameIndex = index" [value]="timeFrameIndex">
                        {{ isMobile && timeFrameIndex === 0 ? '0-90 days' : timeFrame.displayName }}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
            </ng-template>
        </main>

        <div class="prograss-main">
            <div class="prograss-bar">
                <div class="progress-text">Progress</div>
                <div class="circle-line">
                    <acorn-progress-bar [number]="1" [slices]="10"></acorn-progress-bar>
                    <acorn-progress-bar [number]="2" [slices]="10"></acorn-progress-bar>
                    <acorn-progress-bar [number]="3" [slices]="10"></acorn-progress-bar>
                    <acorn-progress-bar [number]="4" [slices]="10"></acorn-progress-bar>
                </div>
            </div>
            <div class="action-text">
                <p>
                    You do not have any actions yet. These will appear after your Wealth Coach Strategy Session.
                </p>
            </div>
        </div>
    </ng-container>
</div>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import {
  AnswerSelection,
  APPLICATION_ID,
  CategoryResponse,
  CommonResponse,
  FinancialQuestion,
  FinancialSnapshot,
  FinancialUserAnswer,
} from '@acorn/util';

@Injectable()
export class FinancialSituationService {
  #httpClient = inject(HttpClient);
  #applicationId = inject(APPLICATION_ID);

  getCategories() {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '';

    return this.#httpClient.get<CommonResponse<CategoryResponse[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation/app-categories${params}`
    );
  }

  getQuestions(applicationId?: string) {
    const params = applicationId ? `?applicationId=${applicationId}` : '';
    return this.#httpClient.get<CommonResponse<FinancialQuestion[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation/questions${params}`
    );
  }

  getAnswerSelection() {
    return this.#httpClient.get<CommonResponse<AnswerSelection[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation/answer-selection`
    );
  }

  getAnswers() {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '/current';

    return this.#httpClient.get<CommonResponse<FinancialUserAnswer[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation${params}`
    );
  }

  updateFinancialSituation(
    applicationId: string,
    answers: FinancialUserAnswer[]
  ) {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '/current';

    return this.#httpClient.post<CommonResponse<FinancialUserAnswer[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation${params}`,
      {
        applicationId,
        userAnswers: answers,
      }
    );
  }

  getFinancialSnapshot() {
    const snapshotUrl = this.#applicationId.value
      ? `financial-snapshot?applicationId=${this.#applicationId.value}`
      : `current/financial-snapshot`;

    return this.#httpClient.get<CommonResponse<FinancialSnapshot[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation/${snapshotUrl}`
    );
  }
  
  getFinancialSnapshotNew(applicationId: any) {
    const snapshotUrl = `financial-snapshot?applicationId=${applicationId}`;

    return this.#httpClient.get<CommonResponse<FinancialSnapshot[]>>(
      `${environment.apiUrls.customer}/api/FinancialSituation/${snapshotUrl}`
    );
  }
}

import {
  APPLICATION_ID,
  ApplicationGoal,
  CommonResponse,
  GoalMetricsQuestion,
  GoalStep,
} from '@acorn/util';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@env';
import { STEP_STRUCTURE } from 'libs/feature-application/src/lib/feature-your-goals/utils';
import { map, of, switchMap } from 'rxjs';

@Injectable()
export class ApplicationGoalService {
  #httpClient = inject(HttpClient);
  #applicationUserId = inject(APPLICATION_ID);

  getCurrentApplicationGoal() {
    const params = this.#applicationUserId.value
      ? `?applicationId=${this.#applicationUserId.value}`
      : '/current';

    return this.#httpClient.get<CommonResponse<ApplicationGoal>>(
      `${environment.apiUrls.customer}/api/ApplicationGoal${params}`
    );
  }

  getApplicationGoal(applicationId:string,strategyId:string) {
    return this.#httpClient.get<CommonResponse<ApplicationGoal>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/strategies/${strategyId}`
    );
  }

  deleteApplicationGoal(applicationId:string,strategyId:string,goalId:string) {
    return this.#httpClient.delete<CommonResponse<ApplicationGoal>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/strategies/${strategyId}/goals/${goalId}`
    );
  }

  saveApplicationGoal(applicationId:string,goalId:string,checked:boolean) {
    return this.#httpClient.put<CommonResponse<ApplicationGoal>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/goals/${goalId}?isSaved=${checked}`,{}
    );
  }
  setCurrentGoal(
    goalStep: GoalStep,
    selectedGoals: { id: string; order: number }[]
  ) {
    const params = this.#applicationUserId.value
      ? `/Set?applicationId=${this.#applicationUserId.value}`
      : '/SetCurrent';

    return this.#httpClient.post<
      CommonResponse<ApplicationGoal & { nextGoalStep?: GoalStep }>
    >(`${environment.apiUrls.customer}/api/ApplicationGoal${params}`, {
      goalStep,
      goals: selectedGoals,
    });
  }

  setGoals(
    currentGoalStep: GoalStep,
    selectedGoals: { id: string; order: number }[],
    isLastPast = false
  ) {
    if (!isLastPast) {
      return this.setCurrentGoal(currentGoalStep, selectedGoals);
    }
    const goalStepVerifyStructure = STEP_STRUCTURE.filter(
      (it) => it.id === GoalStep.RoundVerify
    )[0]!;
    const outOfRangeGoals =
      selectedGoals.length >= goalStepVerifyStructure.minimumRequiredGoals &&
      selectedGoals.length <= goalStepVerifyStructure.maximumRequiredGoals;
    if (
      (currentGoalStep === GoalStep.Round1 ||
        currentGoalStep === GoalStep.Round2) &&
      outOfRangeGoals
    ) {
      return this.setCurrentGoal(currentGoalStep, selectedGoals).pipe(
        switchMap(() =>
          currentGoalStep === GoalStep.Round2
            ? of(null)
            : this.setCurrentGoal(GoalStep.Round2, selectedGoals)
        ),
        switchMap(() =>
          this.setCurrentGoal(GoalStep.Round3, selectedGoals).pipe(
            map((it) => ({
              ...it,
              data: { ...it.data, nextGoalStep: GoalStep.RoundVerify },
            }))
          )
        )
      );
    } else {
      return this.setCurrentGoal(currentGoalStep, selectedGoals);
    }
  }

  getCurrentQuestions() {
    const params = this.#applicationUserId.value
      ? `/Questions?applicationId=${this.#applicationUserId.value}`
      : '/CurrentQuestions';

    return this.#httpClient.get<CommonResponse<GoalMetricsQuestion[]>>(
      `${environment.apiUrls.customer}/api/ApplicationGoal${params}`
    );
  }

  updateGoalStatus(applicationId:string){
    return this.#httpClient.put<CommonResponse<GoalMetricsQuestion[]>>(
      `${environment.apiUrls.customer}/api/Application/${applicationId}/GoalBeingApplied` ,'');}
}


import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerUser } from '@acorn/util';
import { FeatureWealthStrategyOptionsService } from '../../../feature-wealth-strategy-options/data-access/feature-wealth-strategy-options.service';
import { riskToleranceList } from '../../../feature-wealth-strategy-options/utils/wealth-helper';

@Component({
    selector: 'acorn-risk-tolerance-outcome',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './risk-tolerance-outcome.component.html',
    styleUrls: ['./risk-tolerance-outcome.component.scss'],
})
export class RiskToleranceOutcomeComponent implements OnInit {
    @Input() currentUser!: CustomerUser;
    @Input() applicationId: any;

    public value: any;
    public loading = false;
    public applicationUsers: any;
    public selectedRiskDescription: string | null = null;

    #wealthStrategyOptionsSvc = inject(FeatureWealthStrategyOptionsService);
    cd = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this.applicationUsers = this.currentUser.currentApplication?.applicationUsers;

        if (this.applicationId) {
            this.GetRiskTolerance();
        }
    }

    public GetRiskTolerance() {
        this.loading = true;
        this.#wealthStrategyOptionsSvc.GetRiskTolerance(this.applicationId).subscribe(
            (res: any) => {
                this.loading = false;
                this.value = res?.data?.value;
                this.cd.markForCheck();
                this.updateRiskToleranceDescription(res?.data?.value);
            },
            (err: any) => {
                this.loading = false;
                console.error('Error fetching strategies calculation:', err);
            }
        );
    }

    private updateRiskToleranceDescription(value: string) {
        const applicationUserCount = this.applicationUsers?.length ?? 0;

        let type = '';

        if (applicationUserCount === 1) {
            if (value === 'Low') {
                type = 'single-low';
            } else if (value === 'Medium') {
                type = 'single-medium';
            } else if (value === 'High') {
                type = 'single-high';
            }
        } else if (applicationUserCount === 2) {
            if (value === 'Low') {
                type = 'household-low';
            } else if (value === 'Medium') {
                type = 'household-medium';
            } else if (value === 'High') {
                type = 'household-high';
            }
        }

        const riskDescription = riskToleranceList.find((item) => item.type === type);
        this.selectedRiskDescription = riskDescription ? riskDescription.description : 'Description not found';
    }

    getRiskLevel(value: string): number {
        switch (value) {
            case 'Low':
                return 1;
            case 'Medium':
                return 2;
            case 'High':
                return 3;
            default:
                return 0;
        }
    }
}

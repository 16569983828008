import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'acorn-star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrls: ['star-rating.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, IconSpriteModule],
})
export class StarRatingComponent {
  @Input() rate = 0;
  @Input() fontSize = 24;

  Math = Math;

  get getRatingPercent() {
    const percent = (this.rate - parseInt('10', this.rate)) * 100;
    return percent === 0 ? 100 : percent;
  }
}

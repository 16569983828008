<div class="stepper">
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      class="stepper__item"
      [ngClass]="{
        done: step.state === STEP_STATE.DONE,
        active: selectedIndex === i || step.state === STEP_STATE.EDIT
      }"
      (click)="selectStepByIndex(i, step.state)"
    >
      <div class="relative">
        <ng-container
          *ngIf="step.state === STEP_STATE.DONE; else defaultIconTemplate"
        >
          <mat-icon>check</mat-icon>

          <div *ngIf="selectedIndex !== i" class="step-divider"></div>
        </ng-container>

        <ng-template #defaultIconTemplate>
          <div class="icon"></div>
        </ng-template>
      </div>
      <span class="label">{{ step.label }}</span>
    </div>
    <div class="sub-stepper" *ngIf="selectedIndex === i">
      <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </div>
  </ng-container>
</div>

import { Subscription, SubscriptionPlan } from '@acorn/util';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserSubscriptionPlanService {
  #userSubscription$ = new BehaviorSubject<Subscription | undefined>(undefined);
  #userSubscriptionPlan$ = new BehaviorSubject<SubscriptionPlan | undefined>(
    undefined
  );

  get userSubscription$(): Observable<Subscription | undefined> {
    return this.#userSubscription$.asObservable();
  }

  get userSubscriptionPlan$(): Observable<SubscriptionPlan | undefined> {
    return this.#userSubscriptionPlan$.asObservable();
  }

  setSubscription(
    subscription: Subscription | undefined,
    subscriptionPlan: SubscriptionPlan | undefined
  ) {
    this.#userSubscription$.next(subscription);
    this.#userSubscriptionPlan$.next(subscriptionPlan);
  }

  reset(): void {
    this.#userSubscription$.next(undefined);
    this.#userSubscriptionPlan$.next(undefined);
  }
}

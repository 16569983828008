import { CommonResponse } from '@acorn/util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { catchError, map, Observable, of } from 'rxjs';
import { ApplicationStrategyDocumentModelDto } from '../utils/wealth-helper';

@Injectable()
export class FeatureWealthStrategyOptionsService {
  #httpClient = inject(HttpClient);

  GetStrategiesCalculation(applicationId: any) {
    return this.#httpClient
      .get<CommonResponse<boolean>>(
        `${environment.apiUrls.calculation}application/${applicationId}/strategies`
      )
      .pipe(
        map((response: any) => {
          if (response?.isSuccess) {
            return response.data;
          } else {
            return [];
          }
        }),
        catchError((error) => {
          console.error('Error occurred:', error);
          return of([]);
        })
      );
  }

  GetRiskTolerance(applicationId: any) {
    return this.#httpClient.get<CommonResponse<any>>(
      `${environment.apiUrls.calculation}applications/${applicationId}/assumptions/risk-tolerance`
    );
  }

  SaveStrategies(data: Array<any>, applicationId: string, userId?: string) {
    const params = userId ? `?userId=${userId}` : '';
    return this.#httpClient.put<CommonResponse<string>>(
      `${environment.apiUrls.calculation}application/${applicationId}/strategies/selected-by-customer${params}`,
      data
    );
  }

  Download(applicationId: string, data: ApplicationStrategyDocumentModelDto): Observable<HttpResponse<Blob>> {
    return this.#httpClient.post<Blob>(
      `${environment.apiUrls.calculation}applications/${applicationId}/download`,
      data,
      { observe: 'response', responseType: 'blob' as 'json' }
    );
  }
}

import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acorn-strategy-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './strategy-popup.component.html',
  styleUrls: ['./strategy-popup.component.scss'],
})
export class StrategyPopupComponent {
  #dialogRef = inject(MatDialogRef<StrategyPopupComponent>);
  videoUrl =
    'https://acornstorageuat.blob.core.windows.net/assets/Line-drawing-videos.mp4';

  constructor(private sanitizer: DomSanitizer) {}

  sanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  closeVideo() {
    this.#dialogRef.close();
  }
}

import { Document } from '@contentful/rich-text-types';

export interface ResolvedLink<T> {
  fields: T;
  sys: {
    id: string;
  };
}

export interface ResolvedAsset {
  title: string;
  description: string;
  file: {
    url: string;
    fileName: string;
  };
}

export interface FrequentlyAskedQuestionEntry {
  title: string;
  content: any;
}

export interface OrderingCardEntry {
  cardTitle: string;
  cardDescription: string;
  image: ResolvedLink<ResolvedAsset>;
  order: number;
}

export interface ComplexOrderingCardEntry {
  title: string;
  subCardTitle: string;
  content: any;
  image: ResolvedLink<ResolvedAsset>;
  order: number;
}

export interface CustomerQuoteEntry {
  author: string;
  caption: string;
}

export interface CustomerRating {
  title: string;
  content: string;
  customerName: string;
  date: string;
  ratingNumber: number;
}

export interface HomeEntry {
  customerQuoteTitle: string;
  finePrintDescription: string;
  finePrintTitle: string;
  headerDescription: string;
  headerTitle: string;
  internalName: string;
  keyFeaturesTitle: string;
  keyFeaturesList: ResolvedLink<OrderingCardEntry>[];
  keyPointsTitle: string;
  keyPointsList: ResolvedLink<OrderingCardEntry>[];
  learnMoreTitle: string;
  learnMoreUrl: string;
  meetTheWealthCoachesUrl: string;
  howItWorksTitle: string;
  howItWorksList: ResolvedLink<OrderingCardEntry>[];
  summaryDescription: string;
  summaryImages: ResolvedLink<ResolvedAsset>[];
  summaryTitle: string;
  privacyDescription: string;
  needHelpSection: any;
}

export interface ArticleEntry {
  title: string;
  author: string;
  createdDate: string;
  timeRead: number;
  articleType: string;
  image: ResolvedLink<ResolvedAsset>;
  userName: string;
  tags: string[];
  subTitle: string;
  content: any;
}

export interface StaticSectionEntry {
  title: string;
  content: any;
}

export interface StaticPageEntry {
  title: string;
  effectiveDate: string;
  sections: ResolvedLink<StaticSectionEntry>[];
}

export interface AboutEntry {
  headerTitle: string;
  headerDescription: string;
  headerImage: ResolvedLink<ResolvedAsset>;
  yourHealthTtitle: string;
  yourHealthDescription: any;
  yourHealthList: ResolvedLink<OrderingCardEntry>[];
  stepsTitle: string;
  stepDescription: any;
  stepsList: ResolvedLink<OrderingCardEntry>[];
  cardList: ResolvedLink<ComplexOrderingCardEntry>[];
  awardTitle: string;
  awardDescription: any;
  awardImages: ResolvedLink<ResolvedAsset>[];
  customerRatings: ResolvedLink<CustomerRating>[];
  customerRatingsTitle: string;
  disclaimerTitle: string;
  disclaimerDescription: string;
}

export interface SignUpEntry {
  note: Document;
}


export interface ApplicationUserFormTemplate {
  applicationUserName: string;
  applicationUserId: string;
  order: number;
  questions?: Record<string, unknown>;
}

export interface UserModel {
  firstName?: string;
  lastName: string;
  status: string;
  email: string;
}

import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { Store } from '@ngrx/store';
import { noop } from 'rxjs';

import { SecurityActions } from './store';

import { FooterComponent, HeaderComponent } from './common/ui';
import { SecurityService } from './common/data-access';
import { GoalSettingsComponent } from 'libs/feature-application/src/lib/feature-goals/ui';
import { UnsavedChangesService } from 'libs/feature-application/src/lib/feature-goals/data-access/unsaved-changes.service';

@Component({
  standalone: true,
  selector: 'acorn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, HeaderComponent, FooterComponent],
})
export class AppComponent {
  securityService = inject(SecurityService);

  #router = inject(Router);
  #store = inject(Store);
  #unsavedChangesSvc = inject(UnsavedChangesService);

  signIn(): void {
    this.#router.navigate(['/auth/sign-in']).then(noop);
  }

  signOut(): void {
    if (this.#unsavedChangesSvc.hasUnsavedChanges) {
      const confirmLogout = confirm(
        'You have unsaved changes. Are you sure you want to log out?'
      );
      if (!confirmLogout) {
        return;
      }
    }

    this.#store.dispatch(SecurityActions.signOut());
  }
}
